export const copy_txt = (txt)=>{

    try {
        const tempInput = document.createElement('input');
        tempInput.value = txt;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand('copy');
        document.body.removeChild(tempInput);
      } catch (error) {
        console.error('Copy to clipboard failed:', error);
      }

}


export const  formatDate = (dateString)=> {
 try{ const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  
  const dateParts = dateString.split(' ');
  const monthIndex = months.indexOf(dateParts[0]);
  const day = dateParts[1].replace(/\D/g, ''); // Remove non-numeric characters
  const year = dateParts[2];

  const date = new Date(year, monthIndex, day);
  
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const formattedDay = day.padStart(2, '0');
  
  return `${month}-${formattedDay}-${year}`;}
  catch(e){return dateString}
}

