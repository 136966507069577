import { Check, CROSS } from "../SVG";
function CHECKS({ afun, bfun, a, b, c, d, e, f, g, h, i, warranty_info,manu, j, k, l, m }) {
console.log(j)
  return (
    <div className="bg-gray-400 w-full ">
     
    <ITEM
    text={a === "CLEAN" ? "Not Reported lost or stolen" : a === "BLACKLISTED" ? "Reported Lost or Stolen – Proceed with Caution and do not buy or sell this device" : "Blacklist Status not checked" }
    check_color={a === "CLEAN"  ? "#00A651" :a === "BLACKLISTED" ? "red" : "#808080"}
    check={a}
    fun={() => afun()}
  />
  {(manu==='APPLE' || manu === 'APPLE INC')&&
    <div className="flex w-2/3 ">
      { b && 
              <ITEM
              text={b === "OFF" ? "iCloud lock is OFF " : "Device Lock Found" }
              check_color={b === "OFF"  ? "#00A651" : "red"}
              check={b}
              fun={() => afun()}
            />
      }
  {
    l &&   <ITEM
    text={l === "OFF" ? "MDM lock is OFF " : "MDM Lock ON" }
    check_color={l === "OFF"  ? "#00A651" : "red"}
    check={l}
    fun={() => afun()}
  />
  }
    </div>
  }
  <ITEM
  text={  j === 'CLEAN' ? "No financial issues reported – ESN Status: Clean" : j?.includes('CONTRACT') ? `${m ? m.replace(/\.$/, '') : ''} - ESN STATUS : ${j}` : a === "CLEAN" ? "No financial issues reported" : "Financial Issues were reported" }
    check_color={ j === 'CLEAN' ? '#00A651' : j?.includes('CONTRACT') ? '#ffcc00': a === "CLEAN" ? '#00A651' : !a && !j ? "#00A651" : "red"}
    check={c}
    fun={() => bfun()}
  />
  <ITEM
    text={d === "UNLOCKED" || typeof d === 'undefined' ? `Carrier Lock Not found Device is UNLOCKED ` : "Carrier Locked Device" }
    check_color={d === "UNLOCKED" || typeof d === 'undefined'  ? "#00A651" : "red"}
    check={d}
    fun={() => bfun()}
  />
  <ITEM
    text={`Warranty Information Checked - ${typeof warranty_info === 'undefined'? 'Not Available': warranty_info}`}
    check_color = {(warranty_info === "OUT OF WARRANTY" || warranty_info === "EXPIRED" || warranty_info === "OOW" || ( warranty_info?.includes('EXPIRED')) || warranty_info === "WARRANTY EXPIRED" ||typeof warranty_info === 'undefined') ? "red" : warranty_info === "LIMITED WARRANTY" ? "#ffcc00 ": "#00A651"}

    check={e}
    fun={() => afun()}
  />
  <ITEM2
    text={f === "CLEAN" ? "No Device Lock Found" : "Repair History not checked" }
    check_color={f ? "#00A651" : "#808080"}
    check={f}
  />
  <ITEM2
    text={g === "CLEAN" ? "Battery Health" : "Battery Health not checked" }
    check_color={g ? "#00A651" : "#808080"}
    check={g}
  />
  <ITEM2
    text={h === "CLEAN" ? "Function Diagnostics Checked" : "Functional Diagnostics not performed" }
    check_color={h  ? "#00A651" : "#808080"}
    check={h}
  />
  <ITEM2
    text={i === "CLEAN" ? "Erase Data Checked" : "Data Erase not recorded " }
    check_color={i ? "#00A651" : "#808080"}
    check={i}
  />
    </div>
  );
}

function ITEM({ text, check, fun, check_color }) {
  return (
    <div
      className={`flex w-full flex-row items-center gap-x-2 py-2 border-y ${check?' dark:bg-boxdark':''} border-neutral-300  ${
        check ? " hover:bg-gray-300 hover:cursor-pointer" : ""
      }`}
      onClick={() => {
        fun();
      }}
    >
      <div>
      {check && <Check color={check_color} size={16} />}
      {!check && <CROSS color={check_color} size={16} />}
      </div>

      <div
        className={``}
        style={{ color: check_color }}
      >
        {text}
      </div>
    </div>
  );
}

function ITEM2({ text }) {
  return (
    <div className={`flex flex-row gap-x-2 py-2 border-y   border-neutral-300 items-center `}>
  
       <CROSS color={'#808080'} size={16} />
      <div
        className={``}
        style={{ color: "#808080" }}
      >
        {text}
      </div>
    </div>
      );
    }

export default CHECKS;
