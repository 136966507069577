import logo_3 from "../images/Logo_3.png";
function DEVICE_STATUS({ a, b, c, d, e, f, REF,g,h,i, j, k,l, m, n, o, p }) {
  if(a && a.includes('REPORTED STOLEN OR LOST')){
    a = 'BLACKLISTED'
  }
console.log(k)
  return (
    <div ref={REF} className=" rounded-lg my-10 p-5 px-1 sm:px-5 shadow-xl break-before-page">
      <div className="flex  items-center">
        <img src={logo_3} alt={"logo_3"} className="mr-3" />
        <h1 className="text-black font-bold text-2xl  ">Device Status</h1>
      </div>
      <div className="mt-3">
      { a && <ITEM_1 t={"Blacklist Status"} v={a} reason={`${k}`}/>}
      { b && <ITEM_2 t={"iCloud Lock"} v={b} reason={''}/>}
      { o && <ITEM_1 t={"MDM Lock"} v={o} reason={''}/>}
      { c && <ITEM_1 t={"Date Sold"} v={c} reason={''}/>}
      { d &&  <ITEM_2 t={"Warranty"} v={d} reason={''}/>}
      { e &&  <ITEM_1 t={"Loaner Device"} v={e} reason={''} />}
      { f && <ITEM_2 t={"Refurbished Device"} v={f} reason={''} />}
      { g && <ITEM_1 t={"Demo Unit"} v={g} reason={''}/>}
      { h && <ITEM_2 t={"Replaced Device"} v={h} reason={''}/>}
      { i && <ITEM_1 t={"Replacement Device"} v={i} reason={''}/>}
      { j &&  <ITEM_2 t={"ESN STATUS"} v={j} reason={''}/>}
      { l &&  <ITEM_1 t={"BLACKLISTED BY"} v={l} reason={''}/>}
      { m  && <ITEM_2 t={"BLACKLISTED COUNTRY"} v={m} reason={''}/>}
      { n &&  <ITEM_1 t={"BLACKLISTED ON"} v={n} reason={''}/>}
    
      </div>
    </div>
  );
}

function ITEM_1({ t, v,reason }) {
  return (
    <div 
    style={{background:'rgba(230, 230, 230, 0.6)'}}
    className="bg-gray-100 flex text-black">
      <div className="w-2/3 p-2">
        <p className="text-base">{t}</p>
      </div>
      <div className="w-1/2 p-2 ">
        <p className={`      
        text-base 
        ${v=== 'ON' ? 'text-free-500':''} 
        ${v=== 'OFF' ? 'text-success':''} 
        ${v=== 'NO' ? 'text-success':''} 
        ${v=== 'YES' ? 'text-meta-1':''} 
        ${v=== 'OUT OF WARRANTY' ? 'text-meta-1':''}
        ${v=== 'ELIGIBLE' ? 'text-success':''}
        ${v=== 'LIMITED WARRANTY' ? 'text-yellow-500':''}
        ${v=== 'ACTIVE' ? 'text-success':''}
        ${v=== 'ACTIVE WARRANTY' ? 'text-success':''}
        ${v=== 'CLEAN' ? 'text-success':''}
        ${v=== 'BLACKLIST' ? 'text-meta-1':''}
        ${v=== 'BLACKLISTED' ? 'text-meta-1':''}
        ${v=== 'WARRANTY EXPIRED' ? 'text-meta-1':''}
        ${v=== 'COVERAGE EXPIRED' ? 'text-meta-1':''}
        ${v=== 'EXPIRED' ? 'text-meta-1':''}
         ${v=== 'CLEAN (CONTRACT)' ? 'text-meta-1':''}
        ${t=== 'BLACKLISTED BY' ? 'text-meta-1':''}
        ${t=== 'BLACKLISTED ON' ? 'text-meta-1':''}
        ${t=== 'BLACKLISTED COUNTRY' ? 'text-meta-1':''}
        `}>{v}  {(v !=='CLEAN')&&reason}</p>
      </div>
    </div>
  );
}
function ITEM_2({ t, v }) {
  return (
    <div 

    className="flex text-black ">
      <div className="w-2/3 p-2  ">
        <p className="text-base">{t}</p>
      </div>
      <div className="w-1/2 p-2 ">
        <p className={`text-base 
        ${v=== 'ON' ? 'text-meta-1':''} 
        ${v=== 'OFF' ? 'text-success':''} 
        ${v=== 'NO' ? 'text-success':''} 
        ${v=== 'YES' ? 'text-meta-1':''} 
        ${v=== 'OUT OF WARRANTY' ? 'text-meta-1':''}
        ${v=== 'ELIGIBLE' ? 'text-success':''}
        ${v=== 'LIMITED WARRANTY' ? 'text-warning':''}
        ${v=== 'ACTIVE' ? 'text-success':''}
        ${v=== 'ACTIVE WARRANTY' ? 'text-success':''}
        ${v=== 'CLEAN' ? 'text-success':''}
        ${v=== 'BLACKLISTED' ? 'text-meta-1':''}
        ${v=== 'BLACKLIST' ? 'text-meta-1':''}
        ${v=== 'WARRANTY EXPIRED' ? 'text-meta-1':''}
        ${v=== 'COVERAGE EXPIRED' ? 'text-meta-1':''}
        ${v=== 'EXPIRED' ? 'text-meta-1':''}
        ${v=== 'BLOCKED' ? 'text-meta-1':''}
          ${v=== 'CLEAN (CONTRACT)' ? 'text-warning':''}
        ${t=== 'BLACKLISTED BY' ? 'text-meta-1':''}
        ${t=== 'BLACKLISTED ON' ? 'text-meta-1':''}
        ${t=== 'BLACKLISTED COUNTRY' ? 'text-meta-1':''}
        `}>{v}</p>
      </div>
    </div>
  );
}
export default DEVICE_STATUS;
