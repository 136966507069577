import React, { useEffect, useRef, useState } from "react";
import DefaultLayout from "../layout/DefaultLayout";
import Breadcrumb from "../components/Breadcrumb";
import ReactToPrint from "react-to-print";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Banner from "../components/PackageBanner/Banner";
import LABEL_1 from "../components/barcode_label_1";
import LABEL_2 from "../components/bar_code_label_2";
import { formatDate } from "../scripts/services";
import axios from "axios";
const pageStyle = `
@media print {
  html, body {
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }
}
@media print {
    .onprintlyout {
     
        border: 1px solid;
        border-radius: 65px;
        background-color: #fff !important;
        print-color-adjust: exact; 
    }
    .print_portait{
      width: 192px;
      height:384px;
    }

    .print_landscape{
      height : 192px;
      width:384px;
    }
    .print_medium{
      width:216px;
      height : 120px;

    }
    .pageBreak{
      page-break-before: always;
    }
}`;

const SelectBarcode = () => {
  const [userPermission, setUserPermission] = useState(false);
  const [currency_symbol, set_currency_symbol] = useState("$");
  const [A_Number, set_A_Number] = useState("");
  const [layout, setlayout] = useState(localStorage.getItem('barcodeLayout') ? localStorage.getItem('barcodeLayout') : "portrait");
  const [manufacturer, setManufacturer] = useState(false);
  const [manufacturer_value, setmanufacturer_value] = useState("~");
  const [Model, setModel] = useState(false);
  const [Model_value, setModel_value] = useState("~");
  const [Carrier, setCarrier] = useState(false);
  const [Carrier_value, setCarrier_value] = useState("~");
  const [CarrierStatus, setCarrierStatus] = useState(false);
  const [CarrierStatus_value, setCarrierStatus_value] = useState("~");
  const [Possible_Carrier_value, set_Possible_Carrier_value] = useState("");
  const [IMEI, setIMEI] = useState(false);
  const [IMEI_value, setIMEI_value] = useState("010101010101010");
  const [IMEI_value2, setIMEI_value2] = useState("~");
  const [SerialNumber, setSerialNumber] = useState(false);
  const [SerialNumber_value, setSerialNumber_value] = useState("~");
  const [BatteryHealth, setBatteryHealth] = useState(false);
  const [BatteryHealth_value, setBatteryHealth_value] = useState("~");
  const [Color, setColor] = useState(false);
  const [Color_Value, setColor_Value] = useState("~");
  const [BlacklistStatus, setBlacklistStatus] = useState(false);
  const [BlacklistStatus_Value, setBlacklistStatus_Value] = useState("~");
  const [FMI, setFMI] = useState(false);
  const [FMI_value, setFMI_value] = useState("~");
  const [MDM, setMDM] = useState(false);
  const [Grade, setGrade] = useState(false);
  const [Grade_value, setGrade_value] = useState("~");
  const [Price, setPrice] = useState(false);
  const [Price_value, setPrice_value] = useState("~");
  const [Notes, setNotes] = useState(false)
  const [Notes_value, setNotes_value] = useState("~")
  const [Datee, setDate] = useState(false);
  const [Datee_value, setDate_value] = useState("~");
  const [ADd_AlL, set_add_alL] = useState(false);
  const [Ref_ID,setRef_ID] = useState(false);
  const [Ref_ID_value, setRef_ID_value] = useState("~");
  const [Vendor, setVendor] = useState(false);
  const [Vendor_Value, setVendor_Value] = useState("~");
  const [User, setUser] = useState(false);
  const [User_Value, setUser_Value] = useState("~");
  const [MODELDESCRIPTION, SET_MODEL_DESCRIPTION] = useState(false)
  const [MODEL_DESCRIPTION_Value, SET_MODEL_DESCRIPTION_Value] = useState("~");
  const [customerid, setCustomerId] = useState(false)
  const [CustomerId_value, setCustomerId_value] = useState("~");

 
  const currencyOptions = [
    { value: "$", label: "USD" },
    { value: "€", label: "EUR" },
    { value: "£", label: "GBP" },
    { value: "¥", label: "JPY" },
  ];

  function fun_set_Currency_Symbol(value) {set_currency_symbol(value);}
  function fun_togle_manufacturer() {setManufacturer(!manufacturer);}
  function fun_togle_Model() {setModel(!Model);}
  function fun_togle_Carrier() {setCarrier(!Carrier);}
  function fun_togle_CarrierStatus() {setCarrierStatus(!CarrierStatus);}
  function fun_togle_IMEI() {setIMEI(!IMEI);}
  function fun_togle_SerialNumber() {setSerialNumber(!SerialNumber);}
  function fun_togle_BatteryHealth() {setBatteryHealth(!BatteryHealth);}
  function fun_togle_Color() {setColor(!Color);}
  function fun_togle_BlacklistStatus() {setBlacklistStatus(!BlacklistStatus);}
  function fun_togle_Vendor() {setVendor(!Vendor);}
  function fun_togle_User() {setUser(!User);}
  function fun_togle_FMI() {setFMI(!FMI);}
  function fun_togle_Grade() {setGrade(!Grade);}
  function fun_togle_Datee() {setDate(!Datee);}
  function fun_togle_Price() {setPrice(!Price);}
  function fun_togle_Notes() {setNotes(!Notes);}
  function fun_togle_BatteryHealth_value(e) {setBatteryHealth_value(e);}
  function fun_togle_Color_Value(e) {setColor_Value(e);}
  function fun_togle_Grade_value(e) {setGrade_value(e);}
  function fun_togle_Price_value(e) {setPrice_value(e);}
  function fun_togle_Notes_value(e) {setNotes_value(e);}
  function fun_togle_Ref_ID() {setRef_ID(!Ref_ID)}
  function fun_togle_Ref_ID_value(e) {setRef_ID_value(e)}
  function fun_togle_Model_Description() {SET_MODEL_DESCRIPTION(!MODELDESCRIPTION)}
  function fun_togle_CustomerId() {setCustomerId(!customerid)}

  const [UID, set_UID] = useState("~");
  const [UID_Search, set_UID_Search] = useState(false);
  const ref = useRef();

  const handlePortrait = () => {setlayout("portrait");};
  const handleLandscape = () => {setlayout("landscape");};
  const handleMedium = () => {setlayout("medium");};
const handleSaveBarcodeLayout =()=>{
  localStorage.setItem('barcodeLayout',layout)
  toast.success('Barcode layout is saved.')
}
  async function API_Search_by_id() {
    var myHeaders = new Headers();
    myHeaders.append("token", localStorage.getItem("token"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `https://api.cellercertified.com/Search_Report_By_UID?UID=${UID}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        set_UID_Search(false);

        if (!result.status) {
          toast.error("No report found");
          return;
        }
        setDate_value(formatDate(result.data.DATE));
        setmanufacturer_value(result.data.MANUFACTURER);
        setIMEI_value(result.data.IMEI);
        setModel_value(result.data.MODEL);
        setSerialNumber_value(result.data.SERIAL_NUMBER);
        setCarrier_value(result.data.SIM_LOCK_STATUS ? result.data.SIM_LOCK_STATUS : "n/a");
        setCarrierStatus_value(result.data.SIM_LOCK_STATUS ? result.data.SIM_LOCK_STATUS : "n/a");
        set_Possible_Carrier_value(result.data.POSSIBLE_CARRIER ? result.data.POSSIBLE_CARRIER : "n/a");
        setIMEI_value2(result.data.IMEI2 ? result.data.IMEI2 : "n/a");
        set_A_Number(result.data.A_NUMBER ? result.data.A_NUMBER : "");
        toast.success("Data Found");
        setIMEI(result.data.IMEI);
        setBatteryHealth_value(result.data.BATTERY_HEALTH)
        setColor_Value(result.data.Color)
        setVendor_Value(result.data.PURCHASE_COUNTRY)
        setUser_Value(result.data.USER)
        setBlacklistStatus_Value(result.data.BLACKLIST_STATUS)
        setGrade_value(result.data.GRADE);
        setPrice_value(result.data.PRICE)
        setNotes_value(result.data?.NOTES)
        setFMI_value(result.data?.ICLOUD_LOCK)
        setRef_ID_value(result.data?.INTERNAL_REFERENCE_ID)
        SET_MODEL_DESCRIPTION_Value(result.data?.MODEL_DESCRIPTION)
        setCustomerId_value(result.data?.CUSTOMER_ID)
      })
      .catch((error) => {
        set_UID_Search(false);
        toast.error("Network Error");
        console.log("error", error);
      });
  }

  useEffect(() => {
    Load_default_checks();
    const storedUID = localStorage.getItem("reportID") || "";
    set_UID(storedUID.trim());

    // Trigger the search action if the storedUID is valid
    if (valid_UID(storedUID)) {HandleSearch();}
  }, []);


  function valid_UID() {
    if (UID === "") {
      return false;
    }
    if (UID.length <= 20) {
      return false;
    }
    return true;
  }

  async function HandleSearch() {
    set_UID_Search(true);
    if (!valid_UID()) {
      set_UID_Search(false);
      toast.error("unvalid UID : " + UID);
      return;
    }
    API_Search_by_id();
  }
  function HandleUIDchange(value) {
    set_UID(value);
  }

  function Save_defult_checks() {
    localStorage.setItem("BatteryHealth", BatteryHealth);
    localStorage.setItem("Color", Color);
    localStorage.setItem("Vendor", Vendor);
    localStorage.setItem("User", User);
    localStorage.setItem("BlacklistStatus", BlacklistStatus);
    localStorage.setItem("Manufacturer", manufacturer);
    localStorage.setItem("Model", Model);
    localStorage.setItem("Carrier", Carrier);
    localStorage.setItem("CarrierStatus", CarrierStatus);
    localStorage.setItem("IMEI", IMEI);
    localStorage.setItem("FMI", FMI);
    localStorage.setItem("MDM", MDM);
    localStorage.setItem("SerialNumber", SerialNumber);
    localStorage.setItem("Grade", Grade);
    localStorage.setItem("Price", Price);
    localStorage.setItem("Notes", Notes);
    localStorage.setItem("Date", Datee);
    localStorage.setItem("RID", Ref_ID);
    localStorage.setItem("ModelDescription", MODELDESCRIPTION)
    localStorage.setItem("CustomerID", customerid);
    toast.success("Custom layout saved");
  }
  function Load_default_checks() {
    const x1 = localStorage.getItem("BatteryHealth");
    const x3 = localStorage.getItem("IMEI");
    const x2 = localStorage.getItem("Manufacturer");
    const x4 = localStorage.getItem("Model");
    const x5 = localStorage.getItem("Carrier");
    const x6 = localStorage.getItem("FMI");
    const x7 = localStorage.getItem("MDM");
    const x8 = localStorage.getItem("SerialNumber");
    const x9 = localStorage.getItem("Grade");
    const x10 = localStorage.getItem("Price");
    const x11 = localStorage.getItem("Date");
    const x12 = localStorage.getItem("RID")
    const x13 = localStorage.getItem("Color");
    const x14 = localStorage.getItem("BlacklistStatus");
    const x15 = localStorage.getItem("Vendor");
    const x16 = localStorage.getItem("User");
    const x17 = localStorage.getItem("CarrierStatus");
    const x18 = localStorage.getItem("ModelDescription")
    const x19 = localStorage.getItem("CustomerID")
    const x20 = localStorage.getItem("Notes");

    setBatteryHealth(x1 === "true" ? true : false);
    setManufacturer(x2 === "true" ? true : false);
    setIMEI(x3 === "true" ? true : false);
    setModel(x4 === "true" ? true : false);
    setCarrier(x5 === "true" ? true : false);
    setFMI(x6 === "true" ? true : false);
    setMDM(x7 === "true" ? true : false);
    setSerialNumber(x8 === "true" ? true : false);
    setGrade(x9 === "true" ? true : false);
    setPrice(x10 === "true" ? true : false);
    setDate(x11 === "true" ? true : false);
    setRef_ID(x12 === "true" ? true : false);
    setColor(x13 === "true" ? true : false);
    setBlacklistStatus(x14 === "true" ? true : false);
    setVendor(x15 === "true" ? true : false);
    setUser(x16 === "true" ? true : false);
    setCarrierStatus(x17 === "true" ? true : false);
    SET_MODEL_DESCRIPTION(x18 === "true" ? true : false);
    setCustomerId(x19 === "true" ? true : false);
    setNotes(x20 === "true" ? true : false);
  }
  async function Get_Sub_User_Permissions(){
    try{
        const res = await axios.get("https://api.cellercertified.com/dashboard/Sub_User_Permissions",{headers:{token:localStorage.getItem('token')}})
       
        const d =res?.data?.data;
        console.log(d[0])
        if(d===undefined) {return;}
        setUserPermission(d[0])
    }catch(e){}
    }
  
    useEffect(()=>{
      Get_Sub_User_Permissions()
    },[])

  return (
    <div className="leading-6"> 
            <DefaultLayout>
      <Banner />
      <div className="mx-auto max-w-270">
        <Breadcrumb pageName="Barcode Layout" />
        { userPermission && !userPermission.account&&(
          <div className=" fixed bg-[#D34053] text-white top-16 left-0 flex w-full justify-center">
              <div className="my-2  rounded-md danger p-1.5 dark:bg-meta-4">
              You dont have permission to access this page please upgrade you plan.
            </div>
          </div>)
        }
        <div className="grid min-h-screen grid-cols-5 gap-4">
          <div className="col-span-5 xl:col-span-2 ">
            <div className="my-3 w-full border-stroke dark:border-strokedark dark:bg-boxdark">
              <h4 className="mb-2 ml-2 text-xl font-semibold text-black dark:text-white">
                Select Barcode Layout
              </h4>
              <div className="max-w-66 flex flex-col  w-full justify-start">
                <div className=" m-1 inline-flex items-start justify-start rounded-md w-full  dark:bg-meta-4">
                  <button
                  disabled={userPermission && !userPermission.account}
                    onClick={handlePortrait}
                    className={`dark:hover:bg-boxdark66 rounded-l ${
                      layout === "portrait" && "bg-white"
                    } py-3 px-3 text-xs font-medium text-black shadow-card hover:bg-white hover:shadow-card dark:bg-boxdark dark:text-white`}
                  >
                    2 x 4 Portrait
                  </button>
                  <button
                 disabled={userPermission && !userPermission.account}
                    onClick={handleLandscape}
                    className={`py-3 px-3 text-xs  ${
                      layout === "landscape" && "bg-white"
                    }  font-medium text-black shadow-card hover:bg-white hover:shadow-card dark:bg-boxdark dark:text-white`}
                  >
                    4 x 2 Landscape
                  </button>
                  <button
                 disabled={userPermission && !userPermission.account}
                    onClick={handleMedium}
                    className={`  py-3 px-3 text-xs  ${
                      layout === "medium" && "bg-white"
                    }  font-medium text-black shadow-card hover:bg-white hover:shadow-card dark:bg-boxdark dark:text-white`}
                  >
                    2-1/4 x 1-1/4
                  </button>
                  <button
                 disabled={userPermission && !userPermission.account}
                  onClick={handleSaveBarcodeLayout}
                  className={`
                            text-xs 
                            rounded-r
                            shadow-md
                            bg-darkblue 
                            text-white px-3 py-3
                            `}
                >
                  Save
                </button>
                </div>
              </div>

              {/* <Search /> */}
              <div className="flex h-10 m-1">
                <input
                
                  disabled={UID_Search || ( userPermission && !userPermission.account)}
                  value={(localStorage.getItem("reportID") || "").trim()}
                  className={`
                                w-[215px]
                                shadow-md
                                border-0
                                rounded-tl-md
                                rounded-bl-md
                                ${UID_Search ? "bg-bodydark " : " bg-white "}
                                  text-xs px-2 border  `}
                  placeholder="Enter Search"
                  onChange={(e) => {
                    const value = e.target.value;
                    localStorage.setItem("reportID", value);
                    HandleUIDchange(value);
                  }}
                />
                <button
                 disabled={UID_Search || ( userPermission && !userPermission.account)}
                  className={`
                            w-[80px]
                            text-sm h-full  
                            rounded-tr-md
                            rounded-br-md 
                            shadow-md
                            ${UID_Search ? "bg-bodydark border-0" : " bg-darkblue "}
                            text-white px-2
                            `}
                  onClick={async () => {
                    await HandleSearch();
                  }}
                >
                  Search
                </button>
              </div>
              <h1 className="ml-1 text-xs">
                Search report by unique report ID
              </h1>
            </div>

            <div className={`col-span-5  w-fit  rounded xl:col-span-2`}>
              {/* label conainer */}
              <div
                ref={ref}
                className={`
                
                  rounded-lg  border-stroke bg-white  
                dark:border-strokedark dark:bg-boxdark
                ${
                  (layout === "portrait" && "w-[192px] h-[384px]") ||
                  (layout === "medium" && "w-[288px] h-[384px]") ||
                  (layout === "landscape" && "w-[384px] h-[192px]")
                }
                `}
              >
                {/* label print conainer */}
                <div
                  className={`mb-2 onprintlyout rounded-lg border-b w-full h-full border-stroke  dark:border-strokedark relative`}
                >
                  {/*Actual Label*/}

                  {(layout === "portrait" || layout === "medium") && (
                    <LABEL_1
                      v1={
                        manufacturer_value + " " + MODEL_DESCRIPTION_Value
                      }
                      v2={IMEI_value}
                      v2_2={IMEI}
                      v3={Grade_value}
                      v3_3={Grade}
                      v4={Price_value}
                      v4_4={Price}
                      v5={currency_symbol}
                      v6={manufacturer_value}
                      v6_6={manufacturer}
                      v7={SerialNumber_value}
                      v7_7={SerialNumber}
                      v8={Carrier_value}
                      v8_8={Carrier}
                      v9={BatteryHealth_value}
                      v9_9={BatteryHealth}
                      v10={FMI}
                      v10_10={FMI_value}
                      v11={MDM}
                      v12={Datee_value}
                      v12_12={Datee}
                      v13={UID}
                      v14={Ref_ID_value}
                      v14_14={Ref_ID}
                      v15={Color_Value}
                      v15_15={Color}
                      v16={BlacklistStatus_Value}
                      v16_16={BlacklistStatus}
                      // v17={Vendor_Value}
                      // v17_17={Vendor}
                      v18={User_Value}
                      v18_18={User}
                      v19={CarrierStatus_value}
                      v19_19={CarrierStatus}
                      v20={CustomerId_value}
                      v20_20={customerid}
                      v21={Notes_value}
                      v21_21={Notes}
                    />
                  )}
                  {layout === "landscape" && (
                    <LABEL_2
                      v1={
                        manufacturer_value + " " + MODEL_DESCRIPTION_Value
                      }
                      v2={IMEI_value}
                      v3={Grade_value}
                      v3_3={Grade}
                      v4={Price_value}
                      v4_4={Price}
                      v5={currency_symbol}
                      v6={manufacturer_value}
                      v6_6={manufacturer}
                      v7={SerialNumber_value}
                      v7_7={SerialNumber}
                      v8={Carrier_value}
                      v8_8={Carrier}
                      v9={BatteryHealth_value}
                      v9_9={BatteryHealth}
                      v10={FMI}
                      v10_10={FMI_value}
                      v11={MDM}
                      v12={Datee_value}
                      v12_12={Datee}
                      v13={UID}
                      v14={Ref_ID_value}
                      v14_14={Ref_ID}
                      v15={Color_Value}
                      v15_15={Color}
                      v16={BlacklistStatus_Value}
                      v16_16={BlacklistStatus}
                      // v17={Vendor_Value}
                      // v17_17={Vendor}
                      v18={User_Value}
                      v18_18={User}
                      v19={CarrierStatus_value}
                      v19_19={CarrierStatus}
                      v20={CustomerId_value}
                      v20_20={customerid}
                      v21={Notes_value}
                      v21_21={Notes}
                    />
                  )}
                </div>

                <div className="flex justify-center underline text-sm text-darkblue ">
                  <ReactToPrint
                  
                    trigger={() => <button className={`${(userPermission &&!userPermission.account) && 'hidden'}`} disabled={userPermission && !userPermission.account}>Print</button>}
                    content={() => ref.current}
                    pageStyle={pageStyle}
                  />
                </div>
              </div>
              {/*  */}
            </div>
          </div>
          <div className="col-span-5 xl:col-span-2">
            <div className="my-3  border-stroke  shadow-default dark:border-strokedark dark:bg-boxdark">
              <div className="col-span-5 mt-5  rounded xl:col-span-2">
                <div className="my-2 rounded-lg border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
                  <div className="rounded-lg border-b border-stroke py-3 px-4 dark:border-strokedark">
                    <h3 className="py-2  text-base font-bold	 text-black dark:text-white">
                      Select your visible barcode elements
                    </h3>
                    {!ADd_AlL && (
                      <button
                      disabled={userPermission &&!userPermission.account}
                        className="btn btn-outline my-2 mr-2"
                        onClick={() => {
                          setBatteryHealth(true);
                          setColor(true);
                          // setVendor(true);
                          setUser(true);
                          setBlacklistStatus(true);
                          setManufacturer(true);
                          setModel(true);
                          setCarrier(true);
                          setCarrierStatus(true);
                          setIMEI(true);
                          setFMI(true);
                          setMDM(true);
                          setSerialNumber(true);
                          setGrade(true);
                          setPrice(true);
                          setDate(true);
                          set_add_alL(true);
                          setRef_ID(true);
                          setCustomerId(true);
                          setNotes(true)
                        }}
                      >
                        {" "}
                        Add All
                      </button>
                    )}
                    {ADd_AlL && (
                      <button
                      disabled={userPermission &&!userPermission.account}
                        className="btn btn-outline my-2 mr-2"
                        onClick={() => {
                          setBatteryHealth(false);
                          setColor(false);
                          setBlacklistStatus(false);
                          setManufacturer(false);
                          setModel(false);
                          setCarrier(false);
                          setCarrierStatus(false);
                          setIMEI(false);
                          setFMI(false);
                          setMDM(false);
                          setSerialNumber(false);
                          setGrade(false);
                          setPrice(false);
                          setDate(false);
                          set_add_alL(false);
                          setRef_ID(false);
                          // setVendor(false);
                          setUser(false);
                          setCustomerId(false)
                          setNotes(false)
                        }}
                      >
                        {" "}
                        Remove All
                      </button>
                    )}
                    <button
                    disabled={userPermission &&!userPermission.account}
                      className="btn bg-darkblue border-0 text-white"
                      onClick={Save_defult_checks}
                    >
                      Save
                    </button>

                    <CHECK_BOX_ITEM
                      Permission={userPermission}
                      name={"Manufacturer"}
                      fun={fun_togle_manufacturer}
                      checked={manufacturer}
                    />
                    <CHECK_BOX_ITEM
                      name={"Model"}
                      fun={fun_togle_Model}
                      checked={Model}
                    />
                    <CHECK_BOX_ITEM
                      name={"Carrier"}
                      fun={fun_togle_Carrier}
                      checked={Carrier}
                    />
                    <CHECK_BOX_ITEM
                      name={"Carrier Status"}
                      fun={fun_togle_CarrierStatus}
                      checked={CarrierStatus}
                    />
                    <CHECK_BOX_ITEM
                      name={"IMEI"}
                      fun={fun_togle_IMEI}
                      checked={IMEI}
                    />
                    <CHECK_BOX_ITEM
                      name={" Serial Number"}
                      fun={fun_togle_SerialNumber}
                      checked={SerialNumber}
                    />
                    {/* <CHECK_BOX_ITEM
                      name={"Vendor"}
                      fun={fun_togle_Vendor}
                      checked={Vendor}
                    /> */}
                   <CHECK_BOX_ITEM
                      name={"Customer ID"}
                      fun={fun_togle_CustomerId}
                      checked={customerid}
                    />
                    <CHECK_BOX_ITEM2
                    Permission={userPermission}
                    currencyOptions={currencyOptions}
                    currency_symbol={currency_symbol}
                    fun_set_Currency_Symbol={fun_set_Currency_Symbol}
                      name={"Color"}
                      fun={fun_togle_Color}
                      fun2={fun_togle_Color_Value}
                      checked={Color}
                      symbol_code={``}
                    />
                    <CHECK_BOX_ITEM
                      name={"User"}
                      fun={fun_togle_User}
                      checked={User}
                    />
                    <CHECK_BOX_ITEM2
                    currencyOptions={currencyOptions}
                    currency_symbol={currency_symbol}
                    fun_set_Currency_Symbol={fun_set_Currency_Symbol}
                      name={"Battery Health"}
                      fun={fun_togle_BatteryHealth}
                      fun2={fun_togle_BatteryHealth_value}
                      checked={BatteryHealth}
                      symbol_code={`%`}
                    />
                    <CHECK_BOX_ITEM
                      name={"Blacklist Status"}
                      fun={fun_togle_BlacklistStatus}
                      checked={BlacklistStatus}
                    />
                    <CHECK_BOX_ITEM
                      name={"FMI"}
                      fun={fun_togle_FMI}
                      checked={FMI}
                    />
                    <CHECK_BOX_ITEM
                    name={"Internal Reference ID"}
                    fun={fun_togle_Ref_ID}
                    checked={Ref_ID}
                  />
                    <CHECK_BOX_ITEM2
                    currencyOptions={currencyOptions}
                    currency_symbol={currency_symbol}
                    fun_set_Currency_Symbol={fun_set_Currency_Symbol}
                      name={"Grade"}
                      fun={fun_togle_Grade}
                      fun2={fun_togle_Grade_value}
                      checked={Grade}
                    />
                    <div className="flex justify-between  ">
                    
                      <CHECK_BOX_ITEM2
                      currencyOptions={currencyOptions}
                      currency_symbol={currency_symbol}
                      fun_set_Currency_Symbol={fun_set_Currency_Symbol}
                        name={"Price"}
                        fun={fun_togle_Price}
                        fun2={fun_togle_Price_value}
                        checked={Price}
                        symbol_code={currency_symbol}
                      />
                      

                    </div>
                    <CHECK_BOX_ITEM
                      name={"Date"}
                      fun={fun_togle_Datee}
                      checked={Datee}
                    />
                      <CHECK_BOX_ITEM2
                      currencyOptions={currencyOptions}
                      currency_symbol={currency_symbol}
                      fun_set_Currency_Symbol={fun_set_Currency_Symbol}
                        name={"Notes"}
                        fun={fun_togle_Notes}
                        fun2={fun_togle_Notes_value}
                        checked={Notes}
                        symbol_code={currency_symbol}
                      />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
    </div>
  );
};

export default SelectBarcode;

function CHECK_BOX_ITEM({ name, fun, checked,Permission }) {
  return (
    <div className="mb-4 flex items-center ">
      <input
      disabled={Permission &&!Permission.account}
        id="default-checkbox"
        type="checkbox"
        checked={checked}
        value=""
        onChange={() => {
          fun();
        }}
        className="text-blue-600  bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600 h-4 w-4 rounded focus:ring-2"
      />
      <label
       
        className="text-gray-900 dark:text-gray-300 ml-2  text-sm font-medium"
      >
        {name}
      </label>
    </div>
  );
}
function CHECK_BOX_ITEM2({ name, fun, fun2, checked, symbol_code,currency_symbol,fun_set_Currency_Symbol,currencyOptions,accountPermission }) {
  return (
    <div className="flex w-full justify-between">
      <div className="mb-4 flex items-center  w-2/6">
        <input
       disabled={accountPermission &&!accountPermission}
          checked={checked}
          id="default-checkbox"
          type="checkbox"
          value=""
          onChange={() => {
            fun();
          }}
          className="
      text-blue-600  bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600 h-4 w-4 rounded focus:ring-2"
        />
        <label
          className="text-gray-900 dark:text-gray-300 ml-2 text-sm font-medium"
        >
          {name}
        </label>
      </div>
      { name==='Price'&&<select
     disabled={accountPermission &&!accountPermission}
      className="h-8 text-sm bg-gray rounded-md border dark:bg-black"
      id="currency-select"
      value={currency_symbol}
      onChange={(e) =>
        fun_set_Currency_Symbol(e.target.value)
      }
    >
      <option value="">Select</option>
      {currencyOptions.map((option) => (
        <option key={option.label} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>}

      <div  className="relative ml-10">
        <input
        disabled={accountPermission &&!accountPermission}
          className="bg-white dark:bg-black border rounded h-8 text-xs px-2"
          placeholder={`Enter ${name}`}
          onChange={(e) => {
            fun2(e.target.value);
          }}
        ></input>
        <span className="font-bold absolute right-2 top-1.5 text-sm">
          {symbol_code}{" "}
        </span>
      </div>

     
      
     


    </div>
  );
}

