import React, {useState,useEffect, useRef} from "react";
import logo2 from "../images/logo_2.png";
import QRCode from 'qrcode.react';
import { Tooltip } from "@mui/material";
import ReactToPrint from "react-to-print";
import { Print } from "@mui/icons-material";
import { pageStyle } from "./Bar_Code_Template";
import ReportTemplate from "./Report_Template";

function INFO_TOP({ID,Date,link,Upload,fun, data, REF}) {
  const [report_data,set_report_data]=useState({})
  const print_ref = useRef();
  useEffect(() => {
      if(data?.data?.status){set_report_data( data?.data?.data);}
      else {set_report_data(data?.data)}
  },[data])
  return (
    <div ref={REF} className="border-b INFO_TOP_CNT flex flex-row pb-2 w-[100%] justify-between">
              <div ref={print_ref} className="hidden z-0 print:block ">
        <ReportTemplate data={report_data}/>
        </div>
      <div className=" flex items-center  w-[40vw]">
        <img src={logo2} alt="logo2" className="w-[80%] h-[80%]"/>
      </div>
      <div className="flex flex-row items-center justify-end  w-[60vw]">
        <div >
          <QRCode value={link}  style={{width:60,height:60}}/>
        </div>

        <div className="flex flex-col justify-center px-2 sm:px-3  md:px-1 text-xs md:text-[1.2vw] lg:text-xs">
        <div className="col-span-1 w-fit "><button variant="contained" > <ReactToPrint trigger={() => <div className=" font-medium  text-primary  hover:cursor-pointer" >
            Print this link
          </div>} content={()=>print_ref.current} pageStyle={pageStyle} />  </button></div>

          <div className=" font-medium  text-primary  hover:cursor-pointer" onClick={handleButtonClick}>
            Share this link 
          </div>
          <div className=" text-slate-900 font-medium"> ID: {ID}</div>
          <div className="text-slate-900 font-medium">Date: {Date}
          </div>
        </div>
      </div>
    </div>
  );
}

const handleButtonClick = () => {
    
  const myDiv = document.getElementById('main_share');
  if (myDiv) {
    myDiv.hidden =false;
  }
};
export default INFO_TOP;
