import React, { createContext, useState, useContext } from 'react';
// context api
const ItemContext = createContext();


export const ItemProvider = ({ children }) => {

  const [items, setItems] = useState([]);
  const [IMEIHistoryData, setIMEIHistoryData] = useState([]);
  const [Reports,setReports] = useState([]);

  

  return (
    <ItemContext.Provider value={{ items, setItems, IMEIHistoryData, setIMEIHistoryData,Reports, setReports }}>
      {children}
    </ItemContext.Provider>
  );
};


export const useItemContext = () => useContext(ItemContext);
