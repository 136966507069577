function Device_Info({ a,a2, b, c, c2, d, e, f, g, h, i, j, k, l, m }) {
  return (
    <div className="py-2 ">
      <h1 className="text-black font-bold text-base">Device Info</h1>
     {b !== 'APPLE' || b !== 'APPLE INC' || b!== 'SAMSUNG' || b !== 'GOOGLE' ?  <h1 className="text-black font-bold text-2xl mb-1">{a && a.includes('undefined') && l && !l.includes('undefined') ? l + ' ' + a2 : l && !l.includes('undefined') ?  l + ' ' + a : a + " " + a2}</h1> : 
       <h1 className="text-black font-bold text-2xl mb-1">{a && a.includes('undefined') ? a2 :a}</h1>
     }

      {!b ? null : (
        <div className="flex flex-row">
          <h1 className="text-darkblue text-base font-bold">
            Manufacturer: &nbsp;{" "}
          </h1>
          <h1 className="text-black font-medium text-sm">{b}</h1>
        </div>
      )}

      {!c ? null : k ? null : (
        <div className="flex flex-row">
          <h1 className="text-darkblue font-bold text-base">IMEI:&nbsp; </h1>
          <h1 className="text-black font-medium text-base">{c}</h1>
        </div>
      )}

      {!c2 ? null : k ? null : (
        <div className="flex flex-row">
          <h1 className="text-darkblue font-bold text-base">IMEI2:&nbsp; </h1>
          <h1 className="text-black font-medium text-base">{c2}</h1>
        </div>
      )}

      {!d ? null : (
        <div className="flex flex-row">
          <h1 className="text-darkblue font-bold text-base">
            Serial Number: &nbsp;{" "}
          </h1>
          <h1 className="text-black font-medium text-base">{d}</h1>
        </div>
      )}
      

      {!j || (b === 'APPLE' || b === 'APPLE INC') ? null : (
        <div className="flex flex-row">
          <h1 className="text-darkblue font-bold text-base">
            Model Number: &nbsp;{" "}
          </h1>
          <h1 className="text-black font-medium text-base">{j}</h1>
        </div>
      )}

      {!f ? null : (
        <div className="flex flex-row">
          <h1 className="text-darkblue font-bold text-base">
            Purchase Country: &nbsp;{" "}
          </h1>
          <h1 className="text-black font-medium text-base">{f}</h1>
        </div>
      )}
      {!i ? null : (
        <div className="flex flex-row">
          <h1 className="text-darkblue font-bold text-base">
            Operating System: &nbsp;{" "}
          </h1>
          <h1 className="text-black font-medium text-base">{i}</h1>
        </div>
      )}

{!a2 || (b === 'APPLE INC' ||  b === 'APPLE' || b === "GOOGLE" || b === "SAMSUNG") ? null : (
        <div className="flex flex-row">
          <h1 className="text-darkblue font-bold text-base">
            Model: &nbsp;{" "}
          </h1>
          <h1 className="text-black font-medium text-base">{a2}</h1>
        </div>
      )}

      {!g ? null : (
        <div className="flex flex-row">
          <h1 className="text-darkblue font-bold text-base">
            Part Number: &nbsp;{" "}
          </h1>
          <h1 className="text-black font-medium text-base">{g}</h1>
        </div>
      )}
{/* 
      {!h ? null : (
        <div className="flex flex-row">
          <h1 className="text-sky-500 font-bold text-base">
            A Number: &nbsp;{" "}
          </h1>
          <h1 className="text-gray-900 font-medium text-base">{h}</h1>
        </div>
      )} */}

      
{h && (b === "APPLE" || b === "APPLE INC") ?  (
        <div className="flex flex-row">
          <h1 className="text-darkblue font-bold text-base">
            A Number: &nbsp;{" "}
          </h1>
          <h1 className="text-black font-medium text-base">{h}</h1>
        </div>
      ) : null}
    </div>
  );
}

export default Device_Info;
