import Barcode from "react-barcode";
import LOGO from "../images/logo/new-barcode-logo.svg"
import { QRCodeSVG } from "qrcode.react";

function LABEL_2({v1,v2,v7,v7_7,v12,v12_12, v15,v15_15, v16, v16_16, v18, v18_18, v19, v19_19}) {
    const v2_barcode = localStorage.getItem('createIMEIBarcode');
    const v7_barcode = localStorage.getItem('create Serial NumberBarcode');
    const v1_barcode = localStorage.getItem('createModelBarcode');
    const v19_barcode = localStorage.getItem('create SourceBarcode');
    const v16_barcode = localStorage.getItem('create PO #Barcode');
    const v15_barcode = localStorage.getItem('createNotesBarcode');
    const v18_barcode = localStorage.getItem('createUserBarcode');
    const v12_barcode = localStorage.getItem('createDateBarcode');
    return ( 
        <div className=" flex flex-row  h-full justify-between ">

<div className=" w-[70%]">


<div>
<img src={LOGO} alt="label logo" className="w-25  ml-4 mt-3 mb-0"/>
<div className="border-2 border-black-2 mt-2 p-[0.1vw] ml-4 w-[100%]">
<h1 className="text-xs  text-black-2 font-semibold">{v1}</h1>
</div>
    
<div className=" mt-1 mb-0 ml-4">
<h1 className="text-xs font-semibold z-9 text-black-2">IMEI/Serial#: {v2}</h1>
{( v2 && v2_barcode) && <Barcode height={10} width={1} value={v2} displayValue={false}/>}
</div>   

{/* <div className=" h-fit mt-0 mb-0 ml-4">
<h1 className="text-xs font-semibold z-9 text-black-2">{v7_7&&<h1>SN: {v7}</h1>}</h1>
{( v7 && v7_barcode) && <Barcode height={10} width={1} value={v7} displayValue={false}/>}
</div>   */}
</div>



<div className="absolute">
{/* {v6_6&&<h1>Manufacturer:{v6}</h1>} */}
<div className=" flex gap-x-1 text-xs font-semibold leading-3 text-black-2  ml-4 w-fit font-medium">
</div>
<div className="text-xs font-semibold leading-3 text-black-2  ml-4 w-fit font-medium">
{(v19_19&&v19)&&<h1>Source : {v19}</h1>}
{( v19 && v19_barcode) && <Barcode height={10} width={1} value={v19} displayValue={false}/>}
</div>
</div>

</div> {/*------------------- */}

<div className=" relative pr-3 w-[30%] mt-2 mb-2">
{(v18_18&&v18)&&<h1 className=" text-xs font-semibold leading-3 text-black-2 top-4 relative font-medium text-right  w-full">User: {v18}</h1>}
{v12_12&&<div className=" text-xs font-semibold leading-3 text-black-2 top-4 relative font-medium text-right  w-full"><h1>Date: {v12}</h1></div>}


<div className="
w-full
absolute
right-4 bottom-0  tracking-tighter
text-xs leading-3 text-black-2  font-semibold text-left ">
    {(v16_16&&v16)&&<h1>PO # : {v16}</h1>}
    {( v16 && v16_barcode) && <Barcode height={10} width={1} value={v16} displayValue={false}/>}
{(v15_15&&v15)&&<h1>Notes: {v15}</h1>}
{( v15 && v15_barcode) && <Barcode height={15} width={0.5} value={v15} displayValue={false}/>}

</div>

</div>


</div>
         );
}

export default LABEL_2;
