
function GetPackageName(pkg_code){

    let name = "";
    if(pkg_code?.includes("p1")){name = '1'}
    if(pkg_code?.includes("p2")){name = '100'}
    if(pkg_code?.includes("p3")){name = '500'}
    if(pkg_code?.includes("m1")){name = 'Starter'}
    if(pkg_code?.includes("m2")){name = 'Pro'}
    if(pkg_code?.includes("m3")){name = 'Enterprise'}
    return name;
}
function GetPackageCost(pkg_code){
    let res = "";
    if(pkg_code?.includes("p1")){res = '$1.99'}
    if(pkg_code?.includes("p2")){res = '$150'}
    if(pkg_code?.includes("p3")){res = '$500'}
    if(pkg_code?.includes("m1")){res = '$15'}
    if(pkg_code?.includes("m2")){res = '$50'}
    if(pkg_code?.includes("m3")){res = '$100'}
    return res;
}
function GetPackageChecks(pkg_code){

    let res = "";
    if(pkg_code?.includes("p1")){res = '1'}
    if(pkg_code?.includes("p2")){res = '150'}
    if(pkg_code?.includes("p3")){res = '500'}
    if(pkg_code?.includes("m1")){res = '200'}
    if(pkg_code?.includes("m2")){res = '1000'}
    if(pkg_code?.includes("m3")){res = '1000+'}
    return res;

}
function GetPackageCostPerCheck(pkg_code){
    let res = "";
    if(pkg_code?.includes("p1")){res = '~'}
    if(pkg_code?.includes("p2")){res = '~'}
    if(pkg_code?.includes("p3")){res = '~'}
    if(pkg_code?.includes("m1")){res = '$0.75'}
    if(pkg_code?.includes("m2")){res = '$0.55'}
    if(pkg_code?.includes("m3")){res = '$0.50'}
    return res;
}
function GetImeiChecksCost(pkg_code){
    let res = "";
    if(pkg_code?.includes("p1")){res = '~'}
    if(pkg_code?.includes("p2")){res = '~'}
    if(pkg_code?.includes("p3")){res = '~'}
    if(pkg_code?.includes("m1")){res = '$0.75'}
    if(pkg_code?.includes("m2")){res = '$0.55'}
    if(pkg_code?.includes("m3")){res = '$0.50'}
    return res;
}
function GetMonthlyCost(pkg_code){
    let res = "";
    if(pkg_code?.includes("p1")){res = '~'}
    if(pkg_code?.includes("p2")){res = '~'}
    if(pkg_code?.includes("p3")){res = '~'}
    if(pkg_code?.includes("m1")){res = '$0.75'}
    if(pkg_code?.includes("m2")){res = '$0.55'}
    if(pkg_code?.includes("m3")){res = '$0.50'}
    return res;
}

function GetPackageType(pkg_code){
    let res = "";
    if(pkg_code?.includes("p1")){res = 'pay as you go'}
    if(pkg_code?.includes("p2")){res = 'pay as you go'}
    if(pkg_code?.includes("p3")){res = 'pay as you go'}
    if(pkg_code?.includes("m1")){res = 'monthly'}
    if(pkg_code?.includes("m2")){res = 'monthly'}
    if(pkg_code?.includes("m3")){res = 'monthly'}
    return res;
}

module.exports = {
    GetPackageName,
    GetPackageCost,
    GetPackageChecks,
    GetPackageCostPerCheck,
    GetImeiChecksCost,
    GetMonthlyCost,
    GetPackageType
}