import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import axios from "axios";
import { useSelector } from "react-redux";

const limit = 6;

const ChartOne = ({filter}) => {
  const userdata = useSelector((state) => state.user.value);
  const [historyData, setHistoryData] = useState([]);
  const [userData, setUserData] = useState([])
  const [categories, setCategories] = useState([]);
  const [categoriesAll, setCategoriesAll] = useState([]);
  const [userCategories, setUserCategories] = useState([]);
  const [allReports, setAllReports] = useState([])

  const token = localStorage.getItem(`token`);

  useEffect(() => {
    // Fetch the API data and update the state
    fetch("https://api.cellercertified.com/dashboard/All_SUB_USERS", {
      headers: { token },
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data?.data)
        let historyArray1 = []
        historyArray1 = data?.data;
        setUserData([userdata, ...historyArray1]);

       
        const uniqueCategories1 = [...new Set(historyArray1.map((user) => user.First_Name))];
        setUserCategories(uniqueCategories1);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [userdata]);



  useEffect(() => {
    // Fetch the API data and update the state
    fetch("https://api.cellercertified.com/All_User_reports_by_Email", {
      headers: { token },
    })
      .then((response) => response.json())
      .then((data) => {
        let historyArray = [];

        if (Array.isArray(data)) {
          historyArray = data.filter((device) => device.MANUFACTURER === "APPLE INC" || device.MANUFACTURER === "APPLE");
        } else if (typeof data === "object") {
          historyArray = Object.values(data).filter((device) => device.MANUFACTURER === "APPLE INC" || device.MANUFACTURER === "APPLE");
        } else {
          throw new Error("Invalid data format. Expected an array or object.");
        }


        const filteredData = filterData(historyArray, filter);
        setHistoryData(filteredData);

        // Extract unique phone models as categories
        const uniqueCategories = [...new Set(filteredData.map((device) => device.MODEL))];
        setCategories(uniqueCategories);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [filter]);

  useEffect(() => {
    // Fetch the API data and update the state
    fetch("https://api.cellercertified.com/All_User_Reports", {
      headers: { token },
    })
      .then((response) => response.json())
      .then((data) => {
        let reportArray = [];

        if (Array.isArray(data)) {
          reportArray = data.filter((device) => device.MANUFACTURER === "APPLE INC" || device.MANUFACTURER === "APPLE");
        } else if (typeof data === "object") {
          reportArray = Object.values(data).filter((device) => device.MANUFACTURER === "APPLE INC" || device.MANUFACTURER === "APPLE");
        } else {
          throw new Error("Invalid data format. Expected an array or object.");
        }


        const filteredData = filterData(reportArray, filter);
        setAllReports(filteredData);

        // Extract unique phone models as categories
        const uniqueCategories2 = [...new Set(filteredData.map((device) => device.MARKETING_NAME))];
    
        setCategoriesAll(uniqueCategories2);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [filter]);

  // Create the series based on the phone models
  const createSeriesData1 = () => {
    const emailReportCounts = {};

    userData.forEach((user) => {
      const email = user.Email; 
   
      const matchingReports = allReports.filter((report) => report.EMAIL === email);
    
      const reportCount = matchingReports.length;
  
      emailReportCounts[user.First_Name] = reportCount;
    });

    const seriesData = Object.entries(emailReportCounts).map(([name, count]) => ({
      name: name,
      data: [count],
    }));

    return seriesData.slice(0, limit);
  };

  const createSeriesData = () => {
    const modelCounts = {};

    historyData.forEach((device) => {
      const model = device.MARKETING_NAME;
      if (modelCounts[model]) {
        modelCounts[model] += 1;
      } else {
        modelCounts[model] = 1;
      }
    });

    const seriesData = Object.entries(modelCounts).map(([model, count]) => ({
      name: model,
      data: [count],
    }));
   
    return seriesData.slice(0, limit);
  };


  const options1 = {
    chart: {
      type: "bar",
      // scrollablePlotArea: {minWidth: 600 },
      height: 350
    },
    plotOptions: {
      bar: {horizontal: false, columnWidth: '100%',         
      dataLabels: {
        position: 'top', // top, center, bottom
        
      },},
      
    },
    dataLabels: {enabled: true,},
    xaxis: {
      categories: userCategories.slice(4, limit),
      labels : {
        rotate: 0,
        show: false
      }
    },
    tooltip: {
      style: {
        fontSize: '12px',
        background: '#333'
      },
      onDatasetHover: {
          highlightDataSeries: false,
      },
      x: {
          show: false,
          formatter: undefined,
      },
      y: {
          formatter: undefined,
          title: {
              formatter: (seriesName) => seriesName,
          },
      },
      
  }
  };

  const options = {
    chart: {
      id: "basic-bar",
    },
    plotOptions: {
      bar: {horizontal: false, columnWidth: '100%',         
      dataLabels: {
        position: 'top', // top, center, bottom
      },},
      
    },
    dataLabels: {enabled: true,},
    xaxis: {
      categories: categories.slice(5, limit),
      labels : {
        rotate: 0,
        show:false
      }
    },
    tooltip: {
      style: {
        fontSize: '12px',
        background: '#333'
      },
      onDatasetHover: {
          highlightDataSeries: false,
      },
      x: {
          show: false,
          formatter: undefined,
      },
      y: {
          formatter: undefined,
          title: {
              formatter: (seriesName) => seriesName,
          },
      },
      
  }
  };

// console.log(allReports)

  const filterData = (data, selectedFilter) => {
    const currentDate = new Date();
  
    let startDate, endDate;
  
    switch (selectedFilter) {
      case "This Week":
        startDate = new Date(currentDate);
        startDate.setDate(currentDate.getDate() - currentDate.getDay());
        endDate = new Date(currentDate);
        endDate.setDate(startDate.getDate() + 6); 
        break;
      case "This Month":
        startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
        break;
      case "This Year":
        startDate = new Date(currentDate.getFullYear(), 0, 1);
        endDate = new Date(currentDate.getFullYear(), 11, 31);
        break;
        default:
          // Check if it's a custom date range
          if (selectedFilter && selectedFilter.length === 2) {
            startDate = selectedFilter[0];
            endDate = selectedFilter[1];
          } else {
            return data;
          }
    }
  
   
    return data.filter(item => {
      const date = parseDate(item.DATE); 
      return date >= startDate && date <= endDate;
    });
  };
  
  const parseDate = (dateString) => {
  
    const dateParts = dateString.split(' ');
    const month = dateParts[0];
    const day = parseInt(dateParts[1]);
    const year = parseInt(dateParts[2]);
  
    return new Date(`${month} ${day}, ${year}`);
  };


  return (
    <div className="xl:flex col-span-12 rounded-sm border border-stroke bg-white px-5 pt-7.5 pb-5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:col-span-8">
      <div className="w-full xl:w-[66%]">
      <div className="flex flex-wrap items-start justify-between gap-3 sm:flex-nowrap">
        <div className="flex w-full flex-wrap gap-3 sm:gap-5">
          <div className="flex min-w-47.5">
            <div className="w-full">
              <p className="font-semibold ">By Model Breakdown (Apple)</p>
            </div>
          </div>
        </div>
      </div>
      <ReactApexChart options={options} series={createSeriesData()} type="bar" height={400} />
      </div>
      {
        userData.length !== 0 && 
            <div className="w-full xl:w-[34%]">
            <div className="flex flex-wrap items-start justify-between gap-3 sm:flex-nowrap">
              <div className="flex w-full flex-wrap gap-3 sm:gap-5">
                <div className="flex ">
                  <div className="w-full">
                    <p className="font-semibold text-[1vw]">No. of Checks(Sub-Users)</p>
                  </div>
                </div>
              </div>
            </div>
            <ReactApexChart options={options1} series={createSeriesData1()} type="bar" height={400} />
            </div>
      }
    </div>
  );
};

export default ChartOne;
