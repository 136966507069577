import React from "react";

const Footer = () => {
  return (
<footer className="fixed bottom-0 left-0 z-999 flex w-full bg-white drop-shadow-1 dark:bg-boxdark dark:drop-shadow-none">
  <div className="flex flex-grow items-center justify-center py-4 px-4 shadow-2 md:px-6 2xl:px-11">
    <h6 className="text-sm font-medium sm:block">
      Copyright 2023 Celler Certified LLC
    </h6>
  </div>
</footer>

  );
};

export default Footer;
