import React, { useEffect, useState } from "react";
import firstboxsvg from "../images/svgs/Frame.svg";
import axios from "axios";
import { NavLink } from "react-router-dom";

const CardThree = ({filter}) => {
  const [checks, setChecks] = useState();
  const token = localStorage.getItem(`token`);
  useEffect(() => {
    const userfunc = async () => {
      try {
        const res = await axios.get(
          "https://api.cellercertified.com/Package_information",
          {
            headers: { token },
          }
        );
        if (res.data) {
          setChecks(res.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    userfunc();
  }, [token]);

  console.log(checks)
  return (
    <div className="rounded-xl h-50 border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
      {/* <div className="flex h-11.5 w-11.5 items-center justify-center rounded-full bg-meta-2 dark:bg-meta-4"></div> */}
      <div className="flex w-full  justify-between">
        <div className="w-75 px-4 py-7">
          <h1 className="text-1xl font-extrabold">PLAN DETAILS</h1>
          <h4
            className=" text-sm font-bold text-black dark:text-white "
            style={{ color: "#00AEEF", whiteSpace: "nowrap" }}
          >
            {checks?.Sub_Package_Name === 'm3' ? 'Monthly Subscription - Enterprise' 
            : checks?.Sub_Package_Name === 'm2' ? 'Monthly Subscription - Pro' 
            : checks?.Sub_Package_Name === 'm1' ? 'Monthly Subscription - Starter' : 'No Subscription'}
            <br/>
                      <NavLink
                  to="/accBillingplan"
                  className={`text-md font-bold underline             
                 `}
                 style={{color: '#00AEEF'}}
                >
                  View Plan
                </NavLink>
          </h4>

          <hr className="my-2" style={{ color: "#D9D9D9" }} />
          <div className="font-extrabold">
            <h5 className="text-1xl">Checks Remaining</h5>
            <span className="text-2xl">
              <span style={{ color: "#00AEEF" }}>
                {checks?.Checks_Available} /
              </span>
              {checks?.Total_Checks}
            </span>
          </div>
        </div>
        <div className="flex h-50 w-25 items-center justify-center bg-gray  pl-1 dark:border-strokedark dark:bg-boxdark">
          <img src={firstboxsvg} alt="" className="scale-150" />
        </div>
      </div>
    </div>
  );
};

export default CardThree;
