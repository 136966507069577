import React from "react";
import { useEffect } from "react";
import DefaultLayout from "../layout/DefaultLayout";
import Breadcrumb from "../components/Breadcrumb";
import FIND_IMEI_POPUP from "../components/Find_IMEI_Popup";
import { useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PuffLoader from "react-spinners/PuffLoader";
import axios from "axios";
import Banner from "../components/PackageBanner/Banner";
import ItemList from "../components/List";



const BulkIMEI = () => {

  const [reports,setreports] = useState([])
  let navigate = useNavigate();
  const [userPermission, setUserPermission] = useState(false)

  const [inputValue, setInputValue] = useState("");
  const controller = new AbortController();
  const signal = controller.signal;

  const [LOADING,setLOADING] = useState(false)

  async function bulk_api(){
    var myHeaders = new Headers();
    myHeaders.append("token", localStorage.getItem('token'));
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({"IMEIs": inputValue});

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("https://api.cellercertified.com/dashboard/bulk_imei_check", requestOptions)
      .then(response => response.json())
      .then(result => {
        if(!result.status){toast.error(result.msg); }
          setreports(result.data)
          toast.success('Success')
          setLOADING(false);
        return result;})

      .catch((error) => {
        setLOADING(false);
        console.log(error);return{status:false,msg:'network error'} });

      } 

  function handleInputChange(event) {
    const value = event.target.value;
     
      setInputValue(value);   
  }

  function valid_bulkImeis(){
    const pattern = /^(\d{15},){1,}\d{15}$/;
    if(!pattern.test(inputValue)){
      toast.info('try input imeis in following patteren :\n IMEI1,IMEI2,IMEI3')
    return false;
    }
    toast.success(' IMEIs in valid patteren');
    return true;
      

  }

 async function handlesubmit(){
  setLOADING(true);
    const valid_input =  valid_bulkImeis();
    if(!valid_input){ setLOADING(false); return; }
    const x= await bulk_api();
  }
  const checksAvailable = localStorage.getItem("checksAvailable");

  async function Get_Sub_User_Permissions(){
    try{
        const res = await axios.get("https://api.cellercertified.com/dashboard/Sub_User_Permissions",{headers:{token:localStorage.getItem('token')}})
       
        const d =res?.data?.data;
        console.log(d[0])
        if(d===undefined) {return;}
        setUserPermission(d[0])
    }catch(e){}
    }

    useEffect(()=> {
      Get_Sub_User_Permissions()
    }, [])

  return (
    <div className="leading-6">
    <DefaultLayout>
      <Banner/>
      <Breadcrumb pageName="Bulk Check IMEI" pageLink="bulk" />
      { userPermission && !userPermission.Bulk_Check_IMEI_Access&&(
          <div className=" fixed bg-[#D34053] text-white top-16 left-0 flex w-full justify-center">
            <div className="my-2  rounded-md danger p-1.5 dark:bg-meta-4">
              You dont have permission to access this page please upgrade you plan.
            </div>
          </div>)
        }
      <div className="bg-white rounded-md dark:bg-graydark min-h-[70vh] relative">
      
      <div className="bg-meta-2 right-1  text-xs w-fit py-2 px-1 m-2 rounded-lg shadow-1  absolute">
      <h1>Checks Available :<strong> {checksAvailable}</strong> </h1>
      <h1>Bulk Check Limit : <strong> 20</strong> </h1>
      </div>

      <ItemList bulkImeiPermission={userPermission}/>
      
      </div>
    
    </DefaultLayout>
    </div>
  );
};

function TABLE_HEADER(){
  return(
    <div 
    style={{ background:'rgba(0, 162, 255, 1)'}}
    className="flex text-center rounded">
    <div className="w-[50%] font-bold border-r-2 text-white">IMEI</div>
    <div className="w-[50%] font-bold text-white">Link</div>
    </div>
  )
}

function TABLE_ITEM({UID,IMEI}){
  return(
    <div 
    style={{ background:'rgba(152, 152, 152, 0.09)'}}
    className="flex text-center items-center">
    <div className="w-[50%] h-[100%] border-r-2 text-black ">{IMEI}</div>
    <div className="w-[50%]  h-[100%] text-black">
   
    <a 
    className="underline italic"
      href={`https://www.cellercertified.com/publicreport?UID=${UID}`}
      target="_blank" 
      rel="noopener noreferrer">
       {UID}
      </a>
   
    </div>
    </div>
  )
}
function ALL_ITEMS({REPORTS}){
  return  REPORTS.map(obj => {
    return <TABLE_ITEM UID={obj.UID} IMEI={obj.IMEI} />
  });
}
export default BulkIMEI;
