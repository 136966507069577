import axios from "axios";
import firstboxsvg from "../../../images/svgs/imei-frame-1.svg";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';


const CardThree = ({state, userdata, InventoryData}) => {

  const [historyData, setHistoryData] = useState([]);
  const token = localStorage.getItem(`token`);

  console.log('state : ',state, 'user data', userdata, 'inventory data', InventoryData)
 

  const [products, setProducts] = useState([]); // Assuming you have a state for products
  const [scannedProducts, setScannedProducts] = useState([]); // State to store filtered products

  useEffect(() => {
    const fetchScanResults = async () => {
      try {
        const res = await axios.get('https://api.cellercertified.com/get-scan-results');
        const data = res.data.data;

        const scanned = data.filter(scan => scan.ReportTitle === state.title);
        //  console.log(scanned)
        //  console.log("iNVENTORYDATA",InventoryData)
          
        const matchedProducts = InventoryData.filter(product =>
          scanned.some(scan => scan.IMEI === product.IMEI[0])
        );

        // console.log(matchedProducts)

        setScannedProducts(matchedProducts);
      } catch (error) {
        console.error('Error fetching scan results:', error);
      }
    };

    fetchScanResults();
  }, [scannedProducts]);



const total = state.expectedCount || state.products.length
const scanned = scannedProducts.length
const unscanned = total - scanned;
const per = (scanned / total ) * 100 ;
// console.log(total, scanned, unscanned, state)

  return (
    <div className="bg-[#F0F0F0] rounded-xl border border-[#C0C0C0] shadow-default dark:border-strokedark dark:bg-boxdark">
      <div className="flex w-full justify-between">
        <div className="w-full p-5 flex flex-col">
          <h1 className=" text-md font-bold text-black dark:text-white">Progress</h1>
          <div className="my-2"><BorderLinearProgress variant="determinate" value={per} /></div>
          <div className="flex gap-x-2 justify-between font-semibold text-black">
            <div className="flex flex-col items-center ">
                <h2>Scanned</h2>
                <h2>{scanned}</h2>
            </div>
            <div className="flex flex-col items-center ">
                <h2>Unscanned</h2>
                <h2>{unscanned}</h2>
            </div>
            <div className="flex flex-col items-center ">
                <h2>Total</h2>
                <h2>{total}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardThree;

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 20,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 300 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
  }));