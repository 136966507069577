import React, { useState } from "react";
import { AiFillCheckCircle } from "react-icons/ai";

const Toggle = ({ show, active }) => {
  const [enabled, setEnabled] = useState('dark');

  return (
    // <li>
    <div onClick={() => { show() }}>

      <label
        className={`flex items-center justify-center relative m-0 block h-7.5 w-14 rounded-full 
      
          ${active ? "bg-primary" : "bg-black"}`}
      >
        <input
          type="checkbox"
          onChange={() => setEnabled(enabled === "1" ? "2" : "1")}
          className="dur absolute top-0 z-50 m-0 h-full w-full cursor-pointer opacity-0"
        />
        <span
          className={`absolute top-1/2 left-[3px] flex h-6 w-6 -translate-y-1/2 translate-x-0 items-center justify-center rounded-full bg-white shadow-switcher duration-75 ease-linear ${enabled === "2" && "!right-[3px] !translate-x-full"
            }`}
        >
          <span className="">
            <AiFillCheckCircle />
          </span>
        </span>
      </label>
    </div>

    // </li>
  );
};

export default Toggle;
