import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import DefaultLayout from "../layout/DefaultLayout";
import Breadcrumb from "../components/Breadcrumb";
import { ImCross } from "react-icons/im";
import { FaRegEdit } from "react-icons/fa";
import Toggle from "../components/Toggle";
import Banner from "../components/PackageBanner/Banner";
import { toast } from "react-toastify";
import { PuffLoader } from "react-spinners";
import { Modal } from "antd";

const APIAccess = () => {
  const [userPermission, setUserPermission] = useState(false)
  const [apiKeyInfo, setApiKeyInfo] = useState(null)
  const [keyName, setKeyName] = useState('')
  const [loading, setLoading] = useState(false)
  const [loadingData, setLoadingData] = useState(false)
  // const apiAccess = localStorage.getItem('apiAccess');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  var apiAccess = null;
  if (userPermission) {
    apiAccess = userPermission.API_Access;
  } else {
    apiAccess = localStorage.getItem('apiAccess');
  }
  async function Get_Sub_User_Permissions() {
    try {
      const res = await axios.get("https://api.cellercertified.com/dashboard/Sub_User_Permissions", { headers: { token: localStorage.getItem('token') } })

      const d = res?.data?.data;
      console.log(d[0].users)
      if (d === undefined) { return; }
      setUserPermission(d[0])
    } catch (e) { }
  }

  useEffect(() => {
    Get_Sub_User_Permissions()
    getAPiKeyInfo()
  }, [])


  const getAPiKeyInfo = async () => {
    const res = await axios.get("https://api.cellercertified.com/get-api-key-info", { headers: { token: localStorage.getItem('token') } })
    if (res?.data?.status == true) {
      if (res?.data?.api_key?.length > 0) {
        setApiKeyInfo(res?.data?.api_key[0])
      }
    }
  }

  const generateAPiKey = async () => {
    try {
      if (!keyName) {
        return toast.error('Please enter Api Key name')
      }
      setLoading(true)
      const res = await axios.post("https://api.cellercertified.com/generate-api-key",
        { name: keyName },
        { headers: { token: localStorage.getItem('token') } })
      if (res.data.status == true) {
        getAPiKeyInfo()
        setLoading(false)
        console.log('API key generated successfully:', res.data.apiKey);
      } else {
        setLoading(false)
        toast.error(res.data.message)
      }
    } catch (error) {

    }
  }
  const activateDeActive = async () => {
    try {

      setLoading(true)
      const res = await axios.get("https://api.cellercertified.com/active-deactive",
        { headers: { token: localStorage.getItem('token') } })
      if (res.data.status == true) {
        getAPiKeyInfo()
        setLoading(false)
        handleOk()
      } else {
        setLoading(false)
        toast.error(res.data.message || 'not able to activate or deactivate')
      }
    } catch (error) {

    }
  }
  return (
    <div className="leading-6">
          <DefaultLayout>
      <div className="text-center flex justify-center ">
        <PuffLoader
          color={"skyblue"}
          loading={loadingData}
          size={300}
          aria-label="Loading Spinner"
          data-testid="loader"
          cssOverride={{ position: "absolute" }}
        />
      </div>

      {!loadingData && <div>
        {apiAccess === 'true' ? (<>
          <Banner />
          <div className="mx-auto min-h-screen max-w-270">
            <Breadcrumb pageName="API Access" pageLink="apiAccess" />
            {/* Api Access page */}
            <div className="">
              <div className="flex flex-wrap justify-between rounded-sm  text-left text-xs dark:bg-meta-4  ">
                <div className="p-1.5 xl:p-2">
                  <h5 className="text-xs font-bold uppercase xsm:text-base">Key</h5>
                 
                    <h5 className="text-xs font-medium  xsm:text-xs">
                      {apiKeyInfo ? apiKeyInfo?.key : ''}
                    </h5>
                 
                </div>
                <div className="p-1.5 text-center xl:p-2 ">
                  <h5 className="text-xs font-bold uppercase xsm:text-base">
                    Name
                  </h5>
                  <h5 className="text-xs font-medium uppercase xsm:text-xs">
                    {apiKeyInfo ? apiKeyInfo?.name : ''}
                  </h5>
                </div>
                <div className="p-1.5 text-center xl:p-2">
                  <h5 className="text-xs font-bold uppercase xsm:text-base">
                    Status
                  </h5>
                  <h5 className="text-xs font-medium uppercase xsm:text-xs">
                    {apiKeyInfo ? (apiKeyInfo?.status == true ? 'Active' : 'Inactive') : ''}
                  </h5>
                </div>{" "}
                <div className="p-1.5 text-center xl:p-2">
                  <h5 className="text-xs font-bold uppercase xsm:text-base">
                    Actions
                  </h5>
                  {apiKeyInfo ? <div className="flex items-center justify-around text-xs font-medium uppercase xsm:text-xs">
                    {/* <FaRegEdit size={20} /> */}
                    <Toggle show={() => showModal()} active={apiKeyInfo?.status} />
                    {/* <ImCross size={20} /> */}
                  </div> : ''}
                </div>
                <div className="p-1.5 text-left xl:p-2">
                  <h5 className="text-xs font-bold uppercase xsm:text-base">
                    Create Key
                  </h5>
                  <div className="flex">
                    <input
                      onChange={(e) => { setKeyName(e.target.value) }}
                      className="w-25 rounded-l border border-stroke bg-gray font-bold text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                      type="text"
                      placeholder="API Key Name"
                    />
                    <button disabled={loading} onClick={generateAPiKey} className="rounded-xl bg-black p-1 font-medium text-gray">
                      Generate
                    </button>
                  </div>
                </div>
              </div>
              {/* <div className="my-4 mx-1 flex justify-between rounded-sm text-sm  dark:bg-meta-4 ">
                <div className=" p-1.5 xl:p-2">
                  <h5 className="text-xs font-medium  xsm:text-xs">
                    {apiKeyInfo ? apiKeyInfo?.key : ''}
                  </h5>
                </div>
                <div className="p-1.5  px-2 text-center xl:p-2">
                  <h5 className="text-xs font-medium uppercase xsm:text-xs">
                    {apiKeyInfo ? apiKeyInfo?.name : ''}
                  </h5>
                </div>
                <div className="p-1.5 text-center xl:p-2">
                  <h5 className="text-xs font-medium uppercase xsm:text-xs">
                    {apiKeyInfo ? (apiKeyInfo?.status == true ? 'Active' : 'Inactive') : ''}
                  </h5>
                </div>
                <div className="hidden px-1.5 text-center sm:block xl:px-1">
                  {apiKeyInfo ? <div className="flex items-center justify-around text-xs font-medium uppercase xsm:text-xs">
                
                    <Toggle show={() => showModal()} active={apiKeyInfo?.status} />
                
                  </div> : ''}
                </div>
                <div className="text-center sm:block xl:p-1">
                  <div className="flex">
                    <input
                      onChange={(e) => { setKeyName(e.target.value) }}
                      className="w-25 rounded-l border border-stroke bg-gray font-bold text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                      type="text"
                      placeholder="API Key Name"
                    />
                    <button disabled={loading} onClick={generateAPiKey} className="rounded-xl bg-black p-1 font-medium text-gray">
                      Generate
                    </button>
                  </div>
                </div>
              </div>{" "} */}
              {/* <div className="my-4 mx-1 grid grid-cols-3 rounded-sm text-sm  dark:bg-meta-4 sm:grid-cols-5">
            <div className=" p-1.5 xl:p-2">
              <h5 className="text-xs font-medium   uppercase xsm:text-xs">
                309e0ca5c6f9b5d45b31a3f80407ba7a
              </h5>
            </div>
            <div className="p-1.5  px-2 text-center xl:p-2">
              <h5 className="text-xs font-medium uppercase xsm:text-xs">
                myweather
              </h5>
            </div>
            <div className="p-1.5 text-center xl:p-2">
              <h5 className="text-xs font-medium uppercase xsm:text-xs">
                Active
              </h5>
            </div>
            <div className="hidden px-1.5 text-center sm:block xl:px-1">
              <div className="flex items-center justify-around text-xs font-medium uppercase xsm:text-xs">
                <FaRegEdit size={20} />
                <Toggle />
                <ImCross size={20} />
              </div>
            </div>
            <div className=" text-center sm:block xl:p-1">
              <div className="flex">
              </div>
            </div>
          </div> */}
            </div>
          </div>
        </>) : (<>
          <div style={{ backgroundColor: "#D34053", color: "white", marginBottom: "10px" }} className=" flex w-full justify-center">
            <div className="my-2  rounded-md danger p-1.5 dark:bg-meta-4">
              You dont have permission to access this page please upgrade you plan.
            </div>
          </div>
          <div className="mx-auto min-h-screen max-w-270">
            <Breadcrumb pageName="API Access" />
            {/* Api Access page */}
            <div className="flex flex-col ">
              <div className="grid grid-cols-8 rounded-sm  text-left text-xs dark:bg-meta-4 sm:grid-cols-5">
                <div className="p-1.5 xl:p-2">
                  <h5 className="text-xs font-bold uppercase xsm:text-base">Key</h5>
                </div>
                <div className="p-1.5 text-center xl:p-2 ">
                  <h5 className="text-xs font-bold uppercase xsm:text-base">
                    Name
                  </h5>
                </div>
                <div className="p-1.5 text-center xl:p-2">
                  <h5 className="text-xs font-bold uppercase xsm:text-base">
                    Status
                  </h5>
                </div>{" "}
                <div className="p-1.5 text-center xl:p-2">
                  <h5 className="text-xs font-bold uppercase xsm:text-base">
                    Actions
                  </h5>
                </div>
                <div className="p-1.5 text-left xl:p-2">
                  <h5 className="text-xs font-bold uppercase xsm:text-base">
                    Create Key
                  </h5>
                </div>
              </div>
              <div className="my-4 mx-1 grid grid-cols-3 rounded-sm text-sm  dark:bg-meta-4 sm:grid-cols-5">
                <div className=" p-1.5 xl:p-2">
                  <h5 className="text-xs font-medium uppercase xsm:text-xs">
                    309e0ca5c6f9b5d45b31a3f80407ba7a
                  </h5>
                </div>
                <div className="p-1.5  px-2 text-center xl:p-2">
                  <h5 className="text-xs font-medium uppercase xsm:text-xs">
                    Defualt
                  </h5>
                </div>
                <div className="p-1.5 text-center xl:p-2">
                  <h5 className="text-xs font-medium uppercase xsm:text-xs">
                    Active
                  </h5>
                </div>
                <div className="hidden px-1.5 text-center sm:block xl:px-1">
                  <div className="flex items-center justify-around text-xs font-medium uppercase xsm:text-xs">
                    {/* <FaRegEdit size={20} /> */}
                    <Toggle />
                    {/* <ImCross size={20} /> */}
                  </div>
                </div>
                <div className="text-center sm:block xl:p-1">
                  <div className="flex">
                    <input
                      disabled={!apiAccess}
                      className="w-25 rounded-l border border-stroke bg-gray font-bold text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                      type="text"
                      placeholder="API Key Name"
                    />
                    <button disabled={!apiAccess} className="rounded-xl bg-black p-1 font-medium text-gray">
                      Generate
                    </button>
                  </div>
                </div>
              </div>{" "}
              <div className="my-4 mx-1 grid grid-cols-3 rounded-sm text-sm  dark:bg-meta-4 sm:grid-cols-5">
                <div className=" p-1.5 xl:p-2">
                  <h5 className="text-xs font-medium   uppercase xsm:text-xs">
                    309e0ca5c6f9b5d45b31a3f80407ba7a
                    {/* c04216626dd98ce6c719f2cfe4eaa53a */}
                  </h5>
                </div>
                <div className="p-1.5  px-2 text-center xl:p-2">
                  <h5 className="text-xs font-medium uppercase xsm:text-xs">
                    myweather
                  </h5>
                </div>
                <div className="p-1.5 text-center xl:p-2">
                  <h5 className="text-xs font-medium uppercase xsm:text-xs">
                    Active
                  </h5>
                </div>
                <div className="hidden px-1.5 text-center sm:block xl:px-1">
                  <div className="flex items-center justify-around text-xs font-medium uppercase xsm:text-xs">
                    <FaRegEdit size={20} />
                    <Toggle />
                    <ImCross size={20} />
                  </div>
                </div>
                <div className=" text-center sm:block xl:p-1">
                  <div className="flex">
                  </div>
                </div>
              </div>
            </div>
          </div></>)}

      </div>}
      <Modal footer={null} title={apiKeyInfo?.status ? "Deactivate Api Key" : 'Activate Api Key'} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <p>{apiKeyInfo?.status ? 'Are you sure you want to deactivate the api key!' : 'Are you sure you want to activate the api key!'}</p>
        <div className="flex justify-end items-center">
          <button className="rounded bg-danger text-white p-2 mx-2" onClick={handleCancel}>
            Cancel
          </button>
          <button disabled={loading} className="rounded bg-logocolor text-white p-2 mx-2" onClick={activateDeActive}>
            {apiKeyInfo?.status ? "Deactivate" : 'Activate'}
          </button>
        </div>
      </Modal>
    </DefaultLayout>
    </div>
  );
};

export default APIAccess;