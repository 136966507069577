import React, { useEffect,  useState,useRef } from "react"
import "react-toastify/dist/ReactToastify.css";
import { MaterialReactTable } from 'material-react-table';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
// import { PushAddationalFields } from "../scripts/addational_fields";
import {  CircularProgress, IconButton,} from '@mui/material';
import {   CopyAll, DeleteForever, Edit,    Print,   QrCodeScannerTwoTone } from '@mui/icons-material';
import ReactToPrint from "react-to-print";
import { toast } from "react-toastify";
// import { copy_txt } from "../scripts/services";
// import {Barcode,pageStyle} from "./Bar_Code_Template";
// import ReportTemplate from "./Report_Template";
// import SimpleModal from "./Simple_Modal";
import { Modal } from "antd";
import { saveAs } from "file-saver";
import Select from 'react-select'
import { Button } from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
// import EditCustomFields from "./Edit_Custom_Fields";
import { useDispatch, useSelector } from "react-redux"
import moment from 'moment-timezone';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import GroupedInventory from "../tables/GroupedInventory";
import IndividualInventory from "../tables/IndividualInventory";
const XLSX = require('xlsx');

const ToolTip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      boxShadow: theme.shadows[1],
      fontSize: 11,
      lineHeight: '1.5rem'
    },
  }));

function InventoryTab({state, InventoryData,scanResults, CustomFilter, setCustomfilter, scannedProduct, imeiFound}) {

const [activeTab, setActiveTab] = useState('grp')

    
      let columns = [
        {header: "Product Name",accessorKey: "productName",enableEditing: false},
        {header: "IMEI",accessorKey: "IMEI",enableEditing: false},
        {header: "Condition",accessorKey: "condition",enableEditing: false},
        {header: "Unit Price",accessorKey:"unitPrice" ,enableEditing: false},
        {header: "Quantity",accessorKey:"quantity" ,enableEditing: false},
      ]

      const handleExportRows = (rows) => {
        console.log("exporting ...")
        const mappedRows = rows.map((row) => {
          const R = { ...row._valuesCache };
          return R;
        });
    
        const headerRow = columns.map((column) => column.header)
        const data = [headerRow, ...mappedRows.map((row) => Object.values(row))];
    
        const worksheet = XLSX.utils.aoa_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    
        const buffer = XLSX.write(workbook, { type: 'buffer', bookType: 'xlsx' });
        const DATE = Date.now();
        saveAs(new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }), "Inventory_Data_" + DATE + ".xlsx");
    
      };


// console.log(InventoryData)
  return (
    <div className="rounded-sm border border-stroke bg-white px-5 pt-4 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-3.5 xl:pb-5">
      <h4 className="mb-4 text-xl font-semibold text-black dark:text-white">
        Inventory
      </h4>

     {
      activeTab === 'grp' ? 
      <GroupedInventory CustomFilter={CustomFilter} scanResults={scanResults} setCustomfilter={setCustomfilter} handleExportRows={handleExportRows} columns={columns} InventoryData={InventoryData} activeTab={activeTab} setActiveTab={setActiveTab} scannedProduct={scannedProduct} />
       :
       activeTab === 'idvl' ? 
       <IndividualInventory CustomFilter={CustomFilter} scanResults={scanResults} setCustomfilter={setCustomfilter} handleExportRows={handleExportRows} columns={columns} InventoryData={InventoryData} activeTab={activeTab} setActiveTab={setActiveTab} scannedProduct={scannedProduct} />
       : ''
     }
      {/* <MaterialReactTable 
      data={InventoryData} 
      columns={columns.map(c => ({...c,size: 5,minSize: 10}))} 
      
      positionToolbarAlertBanner="bottom"
      enableFullScreenToggle={false} 
      enableRowSelection={true}
      enableStickyHeader 
      editingMode="modal"
      // enableEditing
      // onEditingRowSave={handleSave}
      // Pagination
      enablePagination={true}
      initialState={{
        density: 'compact',
        pagination: { pageIndex: 0, pageSize: 10 },
        // showColumnFilters: true,
        // showGlobalFilter: true 
      }}
      muiTablePaginationProps={{
        rowsPerPageOptions: [10, 25, 50, 100, 200, 250, 500],
        showFirstButton: true,
        showLastButton: true,
        SelectProps: {
          native: true
        },
        labelRowsPerPage: 'Rows per pages'
      }}
      muiTableBodyCellEditTextFieldProps={{variant: 'filled'}} 
      muiTableContainerProps={{sx:{background:'transparent',borderRadius:'8px', }}}
      muiTableProps={{sx:{padding:'0px',background:'transparent',}}}
      muiTablePaperProps={{sx:{background:'transparent'}}}
      muiTableBodyCellProps={({cell,column})=> ({
      
        align:'center',
        sx:{
          border: '1px solid rgba(255, 255, 255, 1)', 
          borderBlockEnd:'1px solid rgba(0,0,0,0.1)',
          color:'rgba(100,100,100,1)',
          background:( 
            cell.getValue() ==="CLEAN")?'rgba(0, 255, 81,0.5)':''|| 
            ( cell.getValue() ==="BAD")?'rgba(255, 0, 25,0.4)':''|| 
            ( cell.getValue() ==="BLACKLISTED")?'rgba(255, 0, 25,0.4)':''||
            ( cell.getValue() ==="ON")? 'rgba(255, 0, 25,0.4)': ''||
            ( cell.getValue() ==="OFF")? 'rgba(0, 255, 25,0.4)': ''||
            ( cell.getValue() ==="LOCKED")? 'rgba(255, 0, 81,0.5)': ''||
            ( cell.getValue() ==="UNLOCKED")? 'rgba(0, 255, 81,0.5)': ''||
            ( cell.getValue() ==="LOCKED ON VERIZON")? 'rgba(255, 0, 25,0.4)': ''||
            ( cell.getValue() ==="LOCKED ON T-MOBILE")? 'rgba(255, 0, 25,0.4)': ''||
            ( cell.getValue() ==="LOCKED ON AT&T")? 'rgba(255, 0, 25,0.4)': ''||
            ( cell.getValue() ==="OUT OF WARRANTY")? 'rgba(255, 0, 25,0.4)': ''||
            ( cell.getValue() ==="OOW")? 'rgba(255, 0, 25,0.4)': ''||
            ( cell.getValue() ==="WARRANTY ACTIVE")? 'rgba(0, 255, 81,0.5)': ''||
            ( cell.getValue() ==="ACTIVE")? 'rgba(0, 255, 81,0.5)': ''||
            ( cell.getValue() ==="WARRANTY EXPIRED")? 'rgba(255, 0, 25,0.4)': ''||
            ( cell.getValue() ==="EXPIRED")? 'rgba(255, 0, 25,0.4)': ''||
            ( cell.getValue() ==="LIMITED WARRANTY")? 'rgba(244, 255, 151, 0.8)': ''||
            ( cell.getValue() ==="APPLE CARE")? 'rgba(0, 255, 81,0.5)': '',
            padding: '8px 0px',
            

        }
      })}
      muiTableHeadCellProps={{sx:{ background:'rgb(0 174 239)',color:'white', padding: '0px',   border: '1px solid rgba(255, 255, 255, 1)', },align:'center',}}
      muiTableHeadRowProps={{sx:{}}}
      muiTableBodyRowProps={({row}) => ({
        style: {
          backgroundColor: row.original.IMEI === scannedProduct ? '#dff0d8' : '', // Highlight with a blue color if selected
        }
      })}
      muiTableBodyProps={{sx:{background:'transparent'}}}
      muiTopToolbarProps={{sx:{background:'transparent'}}}
      muiBottomToolbarProps={{sx:{background:'transparent'}}}
      
//       renderRowActions={({ row, table }) => (
//         <span className="w-52 " >
        
//         <ToolTip title="Edit Custom Fields">
//         <IconButton disabled={Permission && !Permission.account} onClick={() => handleRowSelect(row)}>
//                 <label
//                   className="cursor-pointer"
//                   htmlFor="my-customEdit-modal"
//                 >
//                   <Edit />
//                 </label>
//               </IconButton>
      
//         </ToolTip>
//         <ToolTip title="Copy Link">
//         <IconButton
//          disabled={Permission && !Permission.account} 
//         onClick={()=>{
//           copy_txt("https://www.cellercertified.com/publicreport?UID="+row.original.UID);
//           toast.success("Link Copied")
//           }}
//         ><CopyAll/></IconButton>
//         </ToolTip>
        
//         <span className=" w-fit h-fit" onPointerOver={()=>{ set_print_report_data(row.original)}}>
//         <ReactToPrint
//         trigger={()=><ToolTip title='Print Report PDF'><IconButton  disabled={Permission && !Permission.account} ><Print/></IconButton></ToolTip>}
//         content={()=>Report_Ref.current}
//         pageStyle={pageStyle}
//         />  
//         </span>        

//        <ToolTip title="View Report PDF">
//         <IconButton disabled={Permission && !Permission.account}  onPointerOver={()=>{ set_print_report_data(row.original)}}>
//         <SimpleModal data={print_report_data}/>
//         </IconButton>
//         </ToolTip>

//         <span className=" w-fit h-fit" onPointerOver={()=>{ Set_Single_Bar_Code_Data(row.original)}}>
//         <ReactToPrint
//         trigger={()=><ToolTip title="Print Barcode"><IconButton disabled={Permission && !Permission.account} ><QrCodeScannerTwoTone/></IconButton></ToolTip>}
//         content={()=>ref2.current}
//         pageStyle={pageStyle}
//         />  
//         </span>

//         <ToolTip title="information">
//         <IconButton onClick={()=>showModal(row)}>
//         <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-6 h-6 ${row.original.BLACKLIST_STATUS === 'BLACKLISTED' ? 'text-danger': row.original.ICLOUD_LOCK === 'ON' ? 'text-danger' : ''}`}>
//   <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
// </svg>

//         </IconButton>
//         </ToolTip>
//         </span>)}


      // renderBottomToolbarCustomActions={({table, row}) =>(
      //   <div>
      //     Displaying {table.getFilteredRowModel().rows.length} results out of {IMEIHistoryData?.length}
      //   </div>
      // )}


      renderTopToolbarCustomActions={({ table }) => (
  <div className="flex gap-x-4">    
    <div className="mb-0">
      <input 
        className="bg-gray p-2 w-65 dark:bg-black rounded-md"
        placeholder="Search Product Name or IMEI" 
        onChange={(e) => setCustomfilter(e.target.value)}
        // disabled={Permission && !Permission.account} 
      />
      <button 
        className="rounded bg-logocolor text-white p-2 mx-1"
        // disabled={Permission && !Permission.account} 
        onClick={() => {
          table.setColumnFilters(prev => {
            if (isNaN(Number(CustomFilter))) {
              // If the input is not a number, filter by productName
              return [{ id: 'productName', value: CustomFilter }];
            } else {
              // If the input is a number, filter by IMEI
              return [{ id: 'IMEI', value: CustomFilter }];
            }
          });
        }}
      >
        Search
      </button>
    </div>
    <div className="flex">
              <button
                className="px-5 py-1 disabled:text-graydark disabled:bg-bodydark1 text-bodydark1 bg-logocolor  rounded-md transition-all active:bg-black"
                disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
                onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
                variant="contained">
                <FileDownloadIcon />
                Export as XLSX
              </button>
              </div>
              <div className="flex">
              <button
                className={` ${ activeTab === 'grp' ? 'bg-logocolor' : 'bg-bodydark1 text-graydark' }  px-5 py-1 text-white  rounded-l-md transition-all active:bg-black`}
                onClick={() => setActiveTab('grp')}
                variant="contained">
                Grouped
              </button>
              <button
               className={` ${ activeTab === 'idvl' ? 'bg-logocolor' : 'bg-bodydark1 text-graydark' }  px-5 py-1 text-white  rounded-r-md transition-all active:bg-black`}
                onClick={() => setActiveTab('idvl')}
                variant="contained">
                Individual
              </button>
              </div>
  </div>
)}
      /> */}
      </div>

  );
}

export default InventoryTab