import { Cancel, Close } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Check,CROSS, LOGO1 } from '../SVG';
import BulkTable from './Bulk_IMEI_TAble';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import { toast } from "react-toastify";
import * as XLSX from 'xlsx';
import sampleCsv from './sampleCsv/sampleBulkAdd.xlsx';
import { AiOutlineBarcode } from "react-icons/ai";
import {HiOutlineDocumentReport} from "react-icons/hi";
import DownloadIcon from '@mui/icons-material/Download';
import { PuffLoader } from 'react-spinners';
import { useItemContext } from '../Context/itemContext';
import { FileUploader } from "react-drag-drop-files";
import BatchReports from './BatchReports';

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));


const fileTypes = [ "XLS", "XLSX", "csv"];

const ItemList = ({bulkImeiPermission}) => {
  const [inputValue, setInputValue] = useState('');
  // const [Reports,setReports] = useState([]);
  // const [items, setItems] = useState([]);
  const [STATE,setSTATE] = useState(0)
  const [useBatch,setuseBatch] = useState(false)
  const [Batch_id,setBatch_id] = useState("")
  const [ipAddress, setIpAddress] = useState('')
  const [cancelBulkAdd,setCancelBulkAdd] = useState("")
  const [textareaImei, setTextareaImei] = useState('');

  // const [IMEIHistoryData, setIMEIHistoryData] = useState([]);
  const { items, setItems, IMEIHistoryData, setIMEIHistoryData, Reports, setReports } = useItemContext();
  // const [loading, setLoading] = useState(false);
  const backendURL = 'https://api.cellercertified.com/uploads/sampleBulkAdd.xlsx';

// 
  useEffect(() => {
       
        // const storedItems = localStorage.getItem('items');
        // if (storedItems) {
        //   setItems(JSON.parse(storedItems));
        // }
        
    const fetchData = async () => {
      try {
        const res = await axios.get('https://geolocation-db.com/json/');
        // console.log(res.data.IPv4)
        setIpAddress(res.data.IPv4);
      } catch (error) {
        console.error('Error fetching IP address:', error);
      }
    };

    fetchData();
  }, []);

  // useEffect(() => {
  //   // Save items to localStorage whenever items state changes
  //   localStorage.setItem('items', JSON.stringify(items));
  // }, [items]);

  // useEffect(() => {
  //   const storedIMEIHistoryData = localStorage.getItem('IMEIHistoryData');
 
  //   if (storedIMEIHistoryData) {
  //     setIMEIHistoryData(JSON.parse(storedIMEIHistoryData));
  //   }
  //   // setLoading(false);
  // }, []);

  // useEffect(() => {
  //   if (IMEIHistoryData.length > 0) {
  //     localStorage.setItem('IMEIHistoryData', JSON.stringify(IMEIHistoryData));
  //     setLoading(false)
  //   }
  // }, [IMEIHistoryData]); // Runs only when IMEIHistoryData changes
  

  const handleInputChange = (e) => {
    const value = e.target.value.slice(0, 15);
    setInputValue(value);
  };

  const handleTextareaChange = (e) => {
    setTextareaImei(e.target.value);
  };

  const handleAddItem = () => {
    if (inputValue.trim() !== '') {
      setItems([...items, {IMEI:inputValue,STATE:0}]);
      setInputValue('');
    }
  };

  const handleMultipleInput = () => {
    if (textareaImei.trim() !== '') {
     
      const imeisArray = textareaImei.split(/[,|\n]/).map(imei => imei.trim());
  
      const validImeis = imeisArray.filter(imei => imei.length === 15);
      const newItems = validImeis.map(imei => ({ IMEI: imei, STATE: 0 }));
  
      if (newItems.length > 0) {
        setItems([...items, ...newItems]);
        setTextareaImei('');
  
        toast.success(`${newItems.length} Items added successfully`);
      } else {
        toast.error('No valid items to add');
      }
    }
  };
  

  const handleFileUpload = async (e) => {
    const fileInput = e;
    console.log(fileInput)
    const file = fileInput;
  
    if (file) {
      try {
        const reader = new FileReader();
  
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
  
         
          const sheet = workbook.Sheets[workbook.SheetNames[0]];
          const rows = XLSX.utils.sheet_to_json(sheet, { header: 1 });
  
          const newItems = [];
  
          for (const row of rows) {
            const [imei, referenceId] = row.map((entry) => String(entry).trim());

  
            if (imei.length === 15) {
              const newItem = { IMEI: imei, STATE: 0 };
  
          
              if (referenceId) {
                newItem.ReferenceID = referenceId;
              }
  
              newItems.push(newItem);
            }
          }
          console.log(newItems)
          if (newItems.length > 0) {
            setItems([...items, ...newItems]);
            toast.success('File Uplaoded')
            toast.success(`${newItems.length} Items added successfully`);
          } else {
            toast.error('No valid items to add');
          }
        };
  
        reader.readAsArrayBuffer(file);
      } catch (error) {
        console.error('Error reading file:', error);
      }
    }
  };
  
  
  
  const readFileContent = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
  
      reader.onload = (event) => {
        resolve(event.target.result);
      };
  
      reader.onerror = (error) => {
        reject(error);
      };
  
      reader.readAsText(file);
    });
  };
    
  const handleRemoveItem = (index) => {
    const updatedItems = [...items];
    updatedItems.splice(index, 1);
    setItems(updatedItems);
  };
  async function Valid_IMEI(){
    if (inputValue.length !== 15) {setSTATE(0); return}
    setSTATE(1);

    const api = `https://api.cellercertified.com/validimei/${inputValue}`;
    const res = await axios.get(api)
    if(res.data.valid){setSTATE(2)}
    else {setSTATE(3)}
   

  }
  let token = localStorage.getItem('token');

  const get_User_Reports = async () => {
    try {
      const res = await axios.get("https://api.cellercertified.com/All_User_reports_by_Email", {
        headers: { token },
      });
      const DATA = res.data;

      const filteredReports = DATA.filter((report) =>
      Reports.some((r) => r.UID === report.UID)
    );
      // console.log("filtered", filteredReports)
     if(filteredReports){
      setIMEIHistoryData(filteredReports)
     }
    } catch (error) {
      console.error("Error fetching user reports:", error);
    }
   
  };
  async function handleSearch(){
    setSTATE(4)
    const Items = [...items];
    Items.forEach(item=>{item.STATE = 1 })
    setItems(Items);
    // setLoading(true)
    if(useBatch){await  Batch_ID_Update();}
    let REPORTS = []
    console.log(items)
    for (var i=0; i<items.length; i++) {
        let api = `https://api.cellercertified.com/Dashboard/Check_Imei_2/${items[i].IMEI}`
        let Headers = {token:localStorage.getItem("token"), keepalive: true};
        let body = {IID:items[i].IID,ipAddress:ipAddress}
       console.log(body)
        const res =  await axios.post(api,body,{headers:Headers},);
        // console.log("keep alive",res)
        let state = res.data.status;
        let Items =  [...items];
        if(state){
            
            REPORTS.push(res.data.data);
            
            Items[i].STATE = 2;}else{Items[i].STATE = 3;} 
        setItems(Items); 
    }

    setReports(REPORTS)
    setSTATE(5)
    
  }

  function handleIIDChange(value,index){

    let data = items;
    let index_data = data[index]
    index_data.IID =value;
    data[index] = index_data;
    setItems(data)

  }
  async function Batch_ID_Update(){
    let data = items;
    for(let i=0;i<data.length;i++){
      let index_data = data[i]
      index_data.IID =Batch_id;
      data[i] = index_data;
      setItems(data)
    }
  }
  function Reset(){
    setSTATE(0);
    setReports([]);
    setItems([])
  }
  useEffect(()=>{Valid_IMEI()},[inputValue])
  useEffect(() => {
    get_User_Reports();
  },[Reports])

  // console.log(items)
  // console.log("rports bulk imei",Reports)
  // console.log("IMEI HISTORY : ", IMEIHistoryData)

  return (
    <div>
            {/* {loading && (
    <div
    className="w-full h-full z-50 fixed  items-center justify-center flex flex-col gap-y-2 left-20"
    style={{ backgroundColor: "white", marginTop: "-60px" }}
  >
    <PuffLoader
      color={"skyblue"}
      loading={true}
      size={420}
      aria-label="Loading Spinner"
      data-testid="loader"
      cssOverride={{ position: "absolute" }}
    />

    <LOGO1  h={70} />
    <h1 className='text-black text-lg font-semibold'>Please don't leave this page until we fetch the data. </h1>
  </div>
       )}  */}
        <div className='p-2 grid grid-cols-8 gap-2  '>
        <div className='col-span-3 h-full relative '>
       
      <input
      type='textfield'
      disabled={items.length ===20 || STATE===1||STATE===4 || (bulkImeiPermission && !bulkImeiPermission.users ?true:false)}
      placeholder='Enter IMEI'
     className='disabled:bg-bodydark text-info-content bg-white border border-body rounded  h-full w-full p-2' 
      value={inputValue}
       onChange={handleInputChange} />
      {STATE===1&&<div className='absolute top-4 right-4 '><CircularProgress size={20} thickness={20}/></div>}
      {STATE===2&&<div className='rounded-full  text-meta-3 font-bold absolute'> Valid</div>}
      {STATE===3&&<div className='rounded-full  text-meta-1 font-bold absolute'> Un Valid</div>}
      {items.length >= 20&&<div className='rounded-full  text-meta-1 text-sm p-2 absolute'> *Limit Reached</div>}
      </div>

      <button 
      disabled={(STATE===2)?false:true}
      className='btn bg-logocolor border-none text-white h-fit  disabled:text-bodydark ' onClick={handleAddItem}>Add IMEI</button>
      <button 
      disabled={items.length ===0||STATE===4?true:false}
      className='btn bg-logocolor border-none text-white disabled:text-bodydark h-fit ' onClick={handleSearch}>BULK CHECK</button>
      <button 
      disabled={STATE!==5?true:false}
      className='btn bg-logocolor border-none text-white disabled:text-bodydark h-fit ' onClick={Reset}>Reset</button>
              <button 
        className=" btn bg-logocolor border-none text-[0.85vw] text-white disabled:text-bodydark h-fit right-40 absolute -mt-16"
        >
        <label  htmlFor="batchreport-modal" className="flex gap-x-2 items-center justify-center hover:cursor-pointer m-0 py-4  " > <HiOutlineDocumentReport className='w-5 h-5'/> Batch Reports</label>
      </button>
        <button 
        className=" btn bg-logocolor border-none text-[0.85vw] text-white disabled:text-bodydark h-fit right-3 absolute -mt-16"
        >
        <label  htmlFor="my-modal3" className="flex gap-x-2 items-center justify-center hover:cursor-pointer m-0 py-4  " > <AiOutlineBarcode className='w-5 h-5'/> Bulk Add</label>
      </button>
        </div>
        <div>
      <input 
      type="checkbox" id="batchreport-modal" className="modal-toggle" />
      <div className={`modal z-999`}>
      <div className="w-[50%] p-4 rounded-lg bg-white dark:bg-black-2 flex flex-col gap-y-2 ">
      <div className='flex justify-between'>
      <h1 className="font-bold text-black-2 dark:text-white text-lg">Batch Reports</h1>  <label htmlFor="batchreport-modal" className='flex items-center justify-center rounded-full w-6 h-6 bg-danger border-none text-white disabled:text-bodydark' 
       onClick={()=>{setCancelBulkAdd("")}}
       ><Close/></label>
      </div>
      <div className='flex flex-col gap-y-2'>
      <BatchReports/>
      </div>

      </div>
      </div>
      </div>
        <div>
      <input 
      type="checkbox" id="my-modal3" className="modal-toggle" />
      <div className={`modal z-999`}>
      <div className="modal-box bg-white dark:bg-black-2 flex flex-col gap-y-2 ">
      <h1 className="font-bold text-black-2 dark:text-white text-lg">Bulk Add</h1>
      <div className='flex gap-x-2'>
          <label htmlFor='copyPasteModal' className='btn bg-logocolor border-none text-white disabled:text-bodydark h-fit'>Copy and Paste</label>
          <div className='flex flex-col gap-y-1'>
          <label htmlFor="fileInput" className='btn bg-logocolor border-none text-white disabled:text-bodydark h-fit'>Upload File</label>
          </div>
          <label htmlFor="my-modal3" className='btn bg-danger border-none text-white disabled:text-bodydark w-1/3 h-fit' 
       onClick={()=>{setCancelBulkAdd("")}}
       >Cancel</label>
      </div>

      </div>
      </div>
      </div>
      <div>
      <input 
      type="checkbox" id="copyPasteModal" className="modal-toggle" />
      <div className={`modal z-999`}>
      <div className="modal-box bg-white dark:bg-black-2 flex flex-col gap-y-2">
      <h1 className="font-bold text-black-2 dark:text-white text-lg">Copy and Paste</h1>
      <h1>Paste IMEI'S seperated by comma or line...</h1>
      
      <textarea id="textareaInput" value={textareaImei} onChange={handleTextareaChange} type='textfield' placeholder='Paste IMEIs separated by comma or Line'
      className='disabled:bg-bodydark text-info-content bg-white border border-body rounded  h-full w-full p-2' />
     <div className='flex gap-x-2'>
     <label  className='btn bg-logocolor border-none text-white disabled:text-bodydark w-1/4 h-fit' 
      onClick={handleMultipleInput}
       >ADD IMEI</label>
     <label htmlFor="copyPasteModal" className='btn bg-danger border-none text-white disabled:text-bodydark w-1/4 h-fit' 
       onClick={()=>{setCancelBulkAdd("")}}
       >Cancel</label>
     </div>
      </div>
      </div>
      </div>
      <input 
      type="checkbox" id="fileInput" className="modal-toggle" />
      <div className={`modal z-999`}>
      <div className="py-6 px-8 rounded-xl bg-white dark:bg-black-2 flex flex-col gap-y-2">
      <h1 className="font-bold text-black-2 dark:text-white text-lg">Upload Excel File</h1>
     
      <div className='flex flex-col gap-y-2'>
      <FileUploader classes='p-2'  handleChange={handleFileUpload} name="file" types={fileTypes} >
      <div className='flex flex-col p-4 gap-y-4 justify-center items-center border-2 border-dashed border-logocolor w-full text-md'>
        <div><u>Upload</u> Excel File containing IMEI'S and their Reference ID (<i> Ref.. ID Optional</i> ).</div>
        <label  className='btn bg-logocolor border-none text-white disabled:text-bodydark' >Upload File</label>
      </div>
      </FileUploader>

      <div className='text-md text-logocolor'><DownloadIcon/><a className='underline tracking-tighter cursor-pointer' href={backendURL} download >Click here to download sample file</a></div>
      </div>
     <div className='flex w-full justify-end gap-x-2'>

     <label htmlFor="fileInput" className='p-2 bg-danger rounded-md border-none text-white  disabled:text-bodydark ' 
       onClick={()=>{setCancelBulkAdd("")}}
       >Cancel</label>
     </div>
      </div>
      </div>
        <input
          type="file"
          id="uploadFile"
          accept=".txt, .csv, .xlsx" 
          onChange={handleFileUpload}
          style={{ display: 'none' }}
         />
        <div className='flex items-center justify-center'>
        <FormControlLabel
         onClick={()=>{setuseBatch(!useBatch)}}
        control={<IOSSwitch sx={{ m: 1 }}  />}
        label="Use Batch ID"
      />
        {/* <input 
        onClick={()=>{setuseBatch(!useBatch)}}
        type="checkbox"  className="toggle toggle-sm toggle-info  bg-green-600 " checked={useBatch} />   */}
        {/* <h1 className='mx-4'>Use Batch ID</h1> */}
        
        <input
        disabled={!useBatch}
        onChange={(e)=>{setBatch_id(e.target.value);}}
        placeholder='Batch ID..'
         className='bg-bodydark1 disabled:bg-bodydark px-4 border-none ml-4 w-60 h-8 rounded-md mr-2'/>
        
        </div>


      <ul className='mx-3 mt-6'>
    
      <div className={`grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 ${items.length > 4 ? 'md:grid-rows-5' : items.length > 3 ? 'md:grid-rows-4' : items.length > 2 ? 'md:grid-rows-3' : items.length > 1 ? 'md:grid-rows-2' : 'md:grid-rows-1' }`} style={{ gridAutoFlow: 'column' }}>
  {items.map((item, index) => (
    <div className='flex items-center'>
      {item.STATE === 0 && <button onClick={() => handleRemoveItem(index)}><Cancel className='mx-2 hover:bg-meta-9 rounded-lg' /></button>}
      {item.STATE === 1 && <div className='mx-2 flex '><CircularProgress size={15} thickness={8} /></div>}
      {item.STATE === 2 && <div className='w-fit h-fit mx-2'><Check size={20} color={"#00A651"} /></div>}
      {item.STATE === 3 && <div className='w-fit h-fit mx-2'><CROSS size={20} color={"red"} /></div>}
      <li key={index} className='py-1 my-2 flex flex-col items-center bg-meta-2 w-fit rounded-md '>
      <div>{item.IMEI}</div>
      {/* {useBatch && ( */}
        <input
        onChange={(e) => {
          const updatedItems = [...items];
          updatedItems[index].ReferenceID = e.target.value;
          setItems(updatedItems);
        }}
          placeholder={'Internal ID'}
          value={item.ReferenceID || ''}  
          className='bg-bodydark1 rounded-sm border-none mx-2 w-40 h-6 text-xs'
        />
      {/* // )} */}
    </li>
    </div>
  ))}
</div>

      </ul>
    <div className={`px-10 ${IMEIHistoryData.length===0?'hidden':'block'}`}>
      <BulkTable get_User_Reports={get_User_Reports} data={IMEIHistoryData} setIMEIHistoryData={setIMEIHistoryData} setItems={setItems} setReports={setReports} useBatch={useBatch} setuseBatch={setuseBatch} Batch_ID_Update={Batch_ID_Update} setBatch_id={setBatch_id} items={items} handleSearch={handleSearch} handleIIDChange={handleIIDChange}/>
      </div>
    </div>
  );
};

export default ItemList;
