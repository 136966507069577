import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton
  } from "react-share";

function ShareLinks({ID}) {
    const [copied, setCopied] = useState(false);
    const [url1,seturl1]= useState(`https://www.cellercertified.com/publicreport?UID=${ID}`)

    useEffect(()=>{
        seturl1(`https://www.cellercertified.com/publicreport?UID=${ID}`)
    },[ID])

    const handleButtonClick = () => {    
    const myDiv = document.getElementById('main_share');
    if (myDiv) {myDiv.hidden =true;}
};
function handleCopy() {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  }


    return ( <div>

        <div className="w-full h-full fixed top-0 right-0 z-50  bg-black bg-opacity-40" id="main_share" hidden={true}>
        <div className="flex justify-center items-center h-full ">
        <div className="bg-white dark:bg-black-2 flex flex-col justify-center items-center md:w-[30%]  w-[80%] rounded-md shadow-2xl py-5">
        <h1 className="font-bold text-lg">Share Your Report</h1>
        <Link to={url1}arget="_blank" className="text-sky-500 text-sm m-2 text-center ">{url1}</Link>

        <CopyToClipboard text={url1} onCopy={handleCopy}><button className="">Copy Link</button></CopyToClipboard>
        {copied && <span style={{ color: 'green' }}>Copied!</span>}
        <div className="m-2">
        <FacebookShareButton url={url1} >
        <FacebookIcon size={32} round={true}/>
        </FacebookShareButton>

        <WhatsappShareButton url={url1}>
        <WhatsappIcon size={32} round={true}/>
        </WhatsappShareButton>

        <TwitterShareButton url={url1}>
        <TwitterIcon size={32} round={true}/>
        </TwitterShareButton>

        <EmailShareButton  url={url1}>
        <EmailIcon size={32} round={true}/>
        </EmailShareButton>
       
        </div>
        <button className="bg-logocolor dark:bg-black text-white px-2 py-1 rounded-md shadow-sm " onClick={handleButtonClick} >CLOSE</button>
        </div>
        </div>
        </div>

        </div> );
}

export default ShareLinks;