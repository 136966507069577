import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { MaterialReactTable } from 'material-react-table';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { saveAs } from "file-saver";
import moment from 'moment-timezone';
const XLSX = require('xlsx');


function ScanResultsTab({state, CustomFilter, setCustomfilter, scanResults, timeZone, dateFormat}) {
  

  // useEffect(() => {
  //   fetchData();
  // }, []);

  // const fetchData = async () => {
  //   try {
  //     const response = await axios.get('https://api.cellercertified.com/get-scan-results');
  //     // console.log(response.data.data)
  //     const res = response.data.data;
  //     const matchingTitles = res.filter(item => item.ReportTitle === state.state.title);
  //     if (matchingTitles) {
  //       const reverseData = matchingTitles.reverse()
  //       setScanResults(reverseData);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching scan results:', error);
  //   }
  // };
  console.log('scanResults', scanResults , timeZone, dateFormat)
  let columns = [
    {header: "Scan",accessorKey: "scannedIMEI",enableEditing: false},
    {header: "Result",accessorKey: "IMEIFound",enableEditing: false,Cell:({row}) => row.original.IMEIFound === 'true' && !row.original.scan ? 'Scan Successful' : row.original.scan?.includes('Already Scanned') ? 'Already Scanned' : 'Not Found' },
    {header: "Product Name",accessorKey: "productName",enableEditing: false},
    {header: "IMEI",accessorKey: "IMEI",enableEditing: false},
    {header: "Condition",accessorKey: "condition",enableEditing: false},
    {header: "Unit Price",accessorKey:"unitPrice" ,enableEditing: false},
    // {header: "Quantity",accessorKey:"quantity" ,enableEditing: false},
    {
      header: "Scanned At",
      accessorKey: 'date',
      enableEditing: false,
      filterVariant: 'date',
      Cell: ({ row }) => {
        
        const parsedDate = moment(row.original.date, 'MM/DD/YYYY, hh:mm:ss A');
        
   
        const convertedDate = parsedDate.tz(timeZone);
    
        return (
          <div>
          
            <h2>{convertedDate.format(dateFormat)}</h2>
            <h2>{convertedDate.format('hh:mm:ss A')}</h2>
          </div>
        );
      }
    }
    
    
    ,
    {header: "Scanned By",accessorKey:"User" ,enableEditing: false},
  ]

  const handleExportRows = (rows) => {
    console.log("exporting ...")
    const mappedRows = rows.map((row) => {
      const R = { ...row._valuesCache };
      return R;
    });

    const headerRow = columns.map((column) => column.header)
    const data = [headerRow, ...mappedRows.map((row) => Object.values(row))];

    const worksheet = XLSX.utils.aoa_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    const buffer = XLSX.write(workbook, { type: 'buffer', bookType: 'xlsx' });
    const DATE = Date.now();
    saveAs(new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }), "Inventory_Data_" + DATE + ".xlsx");

  };



// console.log(state.state)
  return (
      <div className="rounded-sm border border-stroke bg-white px-5 pt-4 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-3.5 xl:pb-5">
      <h4 className="mb-4 text-xl font-semibold text-black dark:text-white">
        Scan Results
      </h4>
      
      <MaterialReactTable 
      data={scanResults} 
      columns={columns.map(c => ({...c,size: 5,minSize: 10}))} 
      
      positionToolbarAlertBanner="bottom"
      enableFullScreenToggle={false} 
      enableRowSelection={true}
      enableStickyHeader 
      editingMode="modal"
      // enableEditing
      // onEditingRowSave={handleSave}
      // Pagination
      enablePagination={true}
      initialState={{
        density: 'compact',
        pagination: { pageIndex: 0, pageSize: 10 },
        // showColumnFilters: true,
        // showGlobalFilter: true 
      }}
      muiTablePaginationProps={{
        rowsPerPageOptions: [10, 25, 50, 100, 200, 250, 500],
        showFirstButton: true,
        showLastButton: true,
        SelectProps: {
          native: true
        },
        labelRowsPerPage: 'Rows per pages'
      }}
      muiTableBodyCellEditTextFieldProps={{variant: 'filled'}} 
      muiTableContainerProps={{sx:{background:'transparent',borderRadius:'8px', }}}
      muiTableProps={{sx:{padding:'0px',background:'transparent',}}}
      muiTablePaperProps={{sx:{background:'transparent'}}}
      muiTableBodyCellProps={({cell,column})=> ({
      
        align:'center',
        sx:{
          border: '1px solid rgba(255, 255, 255, 1)', 
          borderBlockEnd:'1px solid rgba(0,0,0,0.1)',
          color:'rgba(100,100,100,1)',
          background:( 
            cell.getValue() ==="CLEAN")?'rgba(0, 255, 81,0.5)':''|| 
            ( cell.getValue() ==="BAD")?'rgba(255, 0, 25,0.4)':''|| 
            ( cell.getValue() ==="BLACKLISTED")?'rgba(255, 0, 25,0.4)':''||
            ( cell.getValue() ==="ON")? 'rgba(255, 0, 25,0.4)': ''||
            ( cell.getValue() ==="OFF")? 'rgba(0, 255, 25,0.4)': ''||
            ( cell.getValue() ==="LOCKED")? 'rgba(255, 0, 81,0.5)': ''||
            ( cell.getValue() ==="UNLOCKED")? 'rgba(0, 255, 81,0.5)': ''||
            ( cell.getValue() ==="LOCKED ON VERIZON")? 'rgba(255, 0, 25,0.4)': ''||
            ( cell.getValue() ==="LOCKED ON T-MOBILE")? 'rgba(255, 0, 25,0.4)': ''||
            ( cell.getValue() ==="LOCKED ON AT&T")? 'rgba(255, 0, 25,0.4)': ''||
            ( cell.getValue() ==="OUT OF WARRANTY")? 'rgba(255, 0, 25,0.4)': ''||
            ( cell.getValue() ==="OOW")? 'rgba(255, 0, 25,0.4)': ''||
            ( cell.getValue() ==="WARRANTY ACTIVE")? 'rgba(0, 255, 81,0.5)': ''||
            ( cell.getValue() ==="ACTIVE")? 'rgba(0, 255, 81,0.5)': ''||
            ( cell.getValue() ==="WARRANTY EXPIRED")? 'rgba(255, 0, 25,0.4)': ''||
            ( cell.getValue() ==="EXPIRED")? 'rgba(255, 0, 25,0.4)': ''||
            ( cell.getValue() ==="LIMITED WARRANTY")? 'rgba(244, 255, 151, 0.8)': ''||
            ( cell.getValue() ==="APPLE CARE")? 'rgba(0, 255, 81,0.5)': '',
            padding: '12px 10px',
            

        }
      })}
      muiTableHeadCellProps={{sx:{ background:'rgb(0 174 239)',color:'white', padding: '0px',   border: '1px solid rgba(255, 255, 255, 1)', },align:'center',}}
      muiTableHeadRowProps={{sx:{}}}
      muiTableBodyRowProps={({row}) => ({
        style: {
          backgroundColor: row.original.IMEIFound === 'true' && !row.original.scan ? '#cef0c0' : row.original.scan?.includes('Already Scanned') ? '#fcefac' : '#f2dede', 
        }
      })}
      muiTableBodyProps={{sx:{background:'transparent'}}}
      muiTopToolbarProps={{sx:{background:'transparent'}}}
      muiBottomToolbarProps={{sx:{background:'transparent'}}}

      renderTopToolbarCustomActions={({ table }) => (
  <div className='flex gap-x-4'>    
    <div className="mb-0">
    <input
            className="bg-gray p-2 dark:bg-black rounded-md"
            placeholder="Search Product Name"
            onChange={(e) => {
              const value = e.target.value;
              setCustomfilter(value);
              table.setColumnFilters([{ id: 'productName', value }]); // Apply the filter in real-time
            }}
            
          />
    </div>
    <div className="flex">
              <button
                className="px-5 py-1 disabled:text-graydark disabled:bg-bodydark1 text-bodydark1 bg-logocolor  rounded-md transition-all active:bg-black"
                disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
                onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
                variant="contained">
                <FileDownloadIcon />
                Export as XLSX
              </button>
              </div>
  </div>
)}


      />
      </div>
  )
}

export default ScanResultsTab