import React from "react";
import "./login.css";
import Logo from "../../images/logo/admin-logodark.jpeg";

const Login = () => {
  return (
    <div className="login leading-6">
      <div className="loginBox">
        <div className="CellerCertified mt-2 flex items-center justify-center">
          <img src={Logo} alt="" width="45%" height="45%" />
        </div>
        <div className="loginFormContainer">
          <h1>Login</h1>
          <p className="py-2">
            Please enter username and password to access the dashboard{" "}
          </p>
          <input
            type="text"
            className="inputfield"
            placeholder="Your Username"
          />
          <input
            type="password"
            className="inputfield"
            placeholder="Your Password"
          />
          <br />
          <div className="rememberforget">
            <div>
              <input type="checkbox" className="checkbox" />
              &nbsp; Remember Me
            </div>
            {/* <Link to="/forget" style={{ color: "#00AEEF" }}>
              Forget Password
            </Link> */}
          </div>
          <button
            style={{ background: "#00AEEF" }}
            className="loginbtn flex w-full justify-center p-3 font-medium text-gray"
          >
            Log In Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
