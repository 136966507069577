import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import axios from "axios";

function IRID(data) {

    const [Edit_State,set_Edit_State] = useState(0)    
    const [Res,SetRes]= useState("~")
    const [UID,setUid] = useState("")
    const [ID,set_ID]= useState("~")


    async function Confirm_Edit(){

        set_Edit_State(1)
        
        const url = "https://api.cellercertified.com/SET_IRID";
      try {
      const body ={UID,ID}
      const headers = { token:localStorage.getItem("token")}
      const res = await axios.post( url ,body, { headers});
      if(res.data.status === true){
        set_Edit_State(2)
        SetRes(res.data.msg)
      }
      else{set_Edit_State(2)}
      }
      catch(e) {SetRes(e.message)}

    }

    useEffect(()=>{
        if(data.data.UID ===undefined){setUid(data.data.data.UID) ;return;}
        setUid(data.data.UID);console.log()},[data])
    return ( <div>
        
        <div>
      <input type="checkbox" id="IRID_edit" className="modal-toggle" />
      <div className={`modal z-999`}>
      <div className="modal-box bg-white dark:bg-black-2">
      
      
      <h1 className="font-bold text-lg text-black">Add Internal Reference ID</h1>
      <div className="flex justify-between my-2"><h1>Report ID</h1> <h1>{UID}</h1></div>


      <div className="flex justify-between">
    <h1>Internal Reference ID </h1>
    <input 
    disabled={Edit_State===0?false:true} 
    onChange={(e)=>{set_ID(e.target.value)}}
    placeholder="~" 
     className=" disabled:bg-bodydark
     bg-white dark:bg-black border p-1 text-black rounded-md dark:text-white"/>
      </div>

      <div>
      <button 
      onClick={()=>Confirm_Edit()}
      disabled={(Edit_State ===0) ?false:true }
      className='btn btn-sm my-1 mx-2 h-full bg-meta-3 w-20  dark:bg-black border-0 text-white'>Update</button>

      <label disabled={Edit_State ===0 ?false:true}
       htmlFor="IRID_edit" className='btn btn-sm my-1 mx-2 bg-danger  dark:bg-black border-0 text-white' >Cancel</label>
      </div>

      <div className={`${Edit_State ===1 ?'block':'hidden'}  p-2 flex items-center`}>
      <CircularProgress size={20} thickness={8}/>
      <h1 className="mx-2 italic">Updating ... </h1>
      </div>

      <div className={`${Edit_State ===2 ?'block':'hidden'} flex`}>
      <h1>{Res}</h1>
      <label htmlFor="IRID_edit" className="mx-2 underline cursor-pointer" onClick={()=>{set_Edit_State(0)}}>Close</label>
      </div>

      </div>

     

      </div></div></div> );
}

export default IRID;