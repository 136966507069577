import DefaultLayout from "../layout/DefaultLayout";
import React,{useEffect, useState} from 'react';
import { MaterialReactTable } from 'material-react-table';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv';
import '../css/billinghistory_table.css';
import axios from "axios";
import { Link } from "react-router-dom";
import { LOGO1 } from "../SVG";
import PuffLoader from "react-spinners/PuffLoader";
import { GetPackageChecks,GetPackageCost,GetMonthlyCost,GetPackageType,GetImeiChecksCost,GetPackageName, GetPackageCostPerCheck } from "../scripts/package_related";

function Table2() {
  const [loadingtext, setloadingtext] = useState("Loading...");
    const [History_data, set_History_data] = useState([]);
    const [userPermission, setUserPermission] = useState(false)

    useEffect(() => {
      const interval = setInterval(() => {
        const loading_div = document.getElementById("black");
        loading_div.classList.add("hidden");
      }, 3000);
    
      return () => clearInterval(interval);
    }, []);

    const columns = [
        {header: "Date",accessorKey: "Invoice_Date",id: "date", Cell: ({row}) => (row.original._doc.Invoice_Date)},
        {header: "Type",accessorKey: "Subscription_Code",id: "type",Cell: ({ row }) => (GetPackageType(row.original._doc.Subscription_Code))},
        {header: "Name",accessorKey: "Subscription_Code",id: "name",Cell: ({ row }) => (GetPackageName(row.original._doc.Subscription_Code))},
        {header: "Plan Cost",id: "plancost",accessorKey: "Subscription_Code",Cell: ({ row }) => (GetPackageCost(row.original._doc.Subscription_Code)),},
        {header: "# of Checks",accessorKey: "Subscription_Code",id: "checks",Cell: ({ row }) => (GetPackageChecks(row.original._doc.Subscription_Code)),},
        {header: "Cost Per Check",accessorKey: "Subscription_Code",id: "cost",Cell: ({ row }) => (GetPackageCostPerCheck(row.original._doc.Subscription_Code)),},
        {header: "IMEI Checks Costs",accessorKey: "Subscription_Code",id: "checkcost",Cell: ({ row }) => (GetImeiChecksCost(row.original._doc.Subscription_Code) ),},
        {header: "Total Monthly Costs",accessorKey: "Subscription_Code",id: "monthly",Cell: ({ row }) => ( GetMonthlyCost(row.original._doc.Subscription_Code) ),},
        {header: "Status",Accessor: "Status",Cell: ({ row  }) => <div>{row.original._doc.Status}</div>},
        {header: "PDF Receipt",accessorKey: "Invoice_PDF",Cell: ({ row }) => (
            <div>{row.original.Invoice_PDF && (<a href={`https://api.cellercertified.com${row.original.Invoice_PDF}`} target="_blank" className="underline text-darkblue">Link</a>)}</div>
          ),},
      ]
    

    
    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        headers: columns.map((c) => c.header),
      };  
      const csvExporter = new ExportToCsv(csvOptions);
    
    const handleExportRows = (rows) => {
        const mappedRows = rows.map((row) => {
            return {
            Date: row.original.Invoice_Date,
            Type: GetPackageType(row.original.Subscription_Code),
            Name:GetPackageName(row.original.Subscription_Code),
            PlanCost:GetPackageCost(row.original.Subscription_Code),
            ofChecks:GetPackageChecks(row.original.Subscription_Code),
            CostPerCheck:GetPackageCostPerCheck(row.original.Subscription_Code),
            IMEICheck:GetImeiChecksCost(row.original.Subscription_Code),
            TotalMonthly:GetMonthlyCost(row.original.Subscription_Code),
            Status:row.original.Status,
            PDFReceipt:row.original.Invoice_PDF,
            };
          });
          csvExporter.generateCsv(mappedRows);
      };
      async function API_Billing_History() {
        const token = localStorage.getItem("token");
        var myHeaders = new Headers();
        myHeaders.append("token", token);
    
        var requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };
    console.log("fetching")
        fetch("https://api.cellercertified.com/Dashboard/All_Invoices", requestOptions)
          .then((response) => response.json())
          .then((result) => {
            console.log(result)
            const reversedData = result.data.reverse();
            set_History_data(reversedData);
            console.log(reversedData)
          })
          .catch((error) => console.log("error", error));
      }
      
      useEffect(()=>{API_Billing_History();},[])

      async function Get_Sub_User_Permissions(){
        try{
            const res = await axios.get("https://api.cellercertified.com/dashboard/Sub_User_Permissions",{headers:{token:localStorage.getItem('token')}})
           
            const d =res?.data?.data;
            console.log(d[0])
            if(d===undefined) {return;}
            setUserPermission(d[0])

        }catch(e){}
        }
      
        useEffect(()=>{
          Get_Sub_User_Permissions()
        },[])

    return ( 
      <div className="leading-6">
      <LOADING loadingtext={loadingtext} />
      <DefaultLayout>
        <div className="dark:bg-boxdark rounded-lg p-2" >
        { userPermission && !userPermission.account&&(
          <div className=" fixed bg-[#D34053] text-white top-16 left-0 flex w-full justify-center">
            <div className="my-2  rounded-md danger p-1.5 dark:bg-meta-4">
              You dont have permission to access this page please upgrade you plan.
            </div>
          </div>)
        }
        <MaterialReactTable
        columns={columns} data={userPermission && !userPermission.account ? '' : History_data} 
        enableStickyHeader 
        enableRowSelection 
        positionToolbarAlertBanner="bottom"
        enablePagination={true}
        initialState={{
          pagination: { pageIndex: 0, pageSize: 10 },
        }}
        muiTablePaginationProps={{
          rowsPerPageOptions: [10, 25, 50, 100, 200, 250, 500],
          showFirstButton: false,
          showLastButton: false,
          SelectProps: {
            native: true
          },
          labelRowsPerPage: 'Rows per pages'
        }}
        muiTableContainerProps={{sx:{background:'transparent',borderRadius:'8px', }}}
        muiTableProps={{sx:{padding:'',background:'transparent'}}}
        muiTablePaperProps={{sx:{background:'transparent'}}}
        muiTableBodyCellProps={{sx:{border:'0px', borderBlockEnd:'1px solid rgba(0,0,0,0.1)',color:'rgba(100,100,100,1)',background:'transparent'}}}
        muiTableHeadCellProps={{sx:{ background:'rgb(0 174 239)',color:'white',}}}
        muiTableHeadRowProps={{sx:{}}}
        muiTableBodyRowProps={{sx:{background:'transparent'}}}
        muiTableBodyProps={{sx:{background:'transparent'}}}
        muiTopToolbarProps={{sx:{background:'transparent'}}}
        muiBottomToolbarProps={{sx:{background:'transparent'}}}
      
        renderTopToolbarCustomActions={({ table }) => (                
                <button
                className="px-5 py-1 disabled:text-graydark disabled:bg-bodydark1 text-bodydark1 bg-logocolor  rounded-md transition-all active:bg-black"
                disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
                onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
                variant="contained">
                <FileDownloadIcon/>
                Export as CSV
                </button>          
               
               )}

           
        />
        </div>
        </DefaultLayout>
      </div>
         );
}

function LOADING({ loadingtext }) {
  return (
    <div
      id="black"
      className="w-full h-full z-50 fixed  items-center justify-center flex flex-col"
      style={{ backgroundColor: "white", marginTop: "-50px" }}
    >
      <PuffLoader
        color={"skyblue"}
        loading={true}
        size={300}
        aria-label="Loading Spinner"
        data-testid="loader"
        cssOverride={{ position: "absolute" }}
      />

      <LOGO1  h={53} />
      <h1>{loadingtext}</h1>
    </div>
  );
}


export default Table2;