
import React, { useEffect, useRef, useState } from 'react';
import { Form, Upload, Button, Input, Select, Space, message, Card, Tabs, DatePicker, Modal } from 'antd';
import { MaterialReactTable } from 'material-react-table';
import { UploadOutlined, PlusOutlined, DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useNavigate } from 'react-router-dom';
// import { API_ENDPOINTS } from '../../constants';
import { toast } from 'react-toastify';
// import apiCall from '../../utils/apiCall';
import * as XLSX from 'xlsx';
import DefaultLayout from '../layout/DefaultLayout';
import Banner from './PackageBanner/Banner';
import Breadcrumb from "../components/Breadcrumb";
import { Add, Download, Edit } from '@mui/icons-material';
import { saveAs } from "file-saver";
import axios from 'axios';
import dayjs from 'dayjs';
import swal from "sweetalert";
import { set } from 'lodash';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { FileUploader } from "react-drag-drop-files";
import DownloadIcon from '@mui/icons-material/Download';
// import Papa from 'papaparse';
// import BulkUploadProduct from '../../sampleFile'
const { Option } = Select;


const fileTypes = [ "XLS", "XLSX", "csv"];

const AddPurchaseOrder = ({ Permission }) => {
  const userdata =  useSelector((state) => state.user.value);
  const fileURL = '/BulkUploadProduct.xlsx';
  const backendURL = 'https://api.cellercertified.com/uploads/SamplePurchaseOrders.xlsx';
  const backendURLIMEI = 'https://api.cellercertified.com/uploads/sampleBulkAdd.xlsx';
  const navigate = useNavigate()
  const [form, form1, form2] = Form.useForm();
  const [form3] = Form.useForm();
  const [form4] = Form.useForm();
  const [form5] = Form.useForm();
  const [fileLists, setFileLists] = useState([]);
  const [products, setProducts] = useState([{ key: 0 }]);
  const [loading, setLoading] = useState(false)
  const [conditionSelectedIds, setConditionSelectedIds] = useState([])
  const [bulkAdd, setBulkAdd] = useState([])
  const [showForm, setShowForm] = useState(false)
  const [showForm1, setShowForm1] = useState(true)
  const [showCustomValue, setShowCustomValue] = useState("")
  const { TabPane } = Tabs;
  const [activeTab, setActiveTab] = useState('tab1');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imiesModalOpen, setIMEISModalOpen] = useState(false);
  const [imiesUpdateModal, setIMEISUpdateModal] = useState(false);
  const [productData, setProductData] = useState([]);
  const [CustomFilter, setCustomfilter] = useState([])
  const [Rows_To_Edit, Set_Rows_To_Edit] = useState({});
  const [quantityData, setQuantityData] = useState([]);
  const [imeiData, setIMEIData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [bulkPurchaseOrder, setBulkPurchaseOrder] = useState({});
  const [imeis, setImeis] = useState(Array(quantity > 0 ? quantity : 1).fill(''));
  const [updateData, setUpdateData] = useState(false);
  const [active, setActive] = useState(null);
  const formRef = useRef(null);
  const maxProducts = showCustomValue.expectedcount;
  const [textareaImei, setTextareaImei] = useState('');
  const [items, setItems] = useState([]);
  const [editingMode, setEditingMode] = useState(false);
  const [note, setNote] = useState('');




  const apiCall = async ({ url, method, data }) => {
    try {
      const response = await axios({
        method,
        url,
        data,
      });
      return response.data;
    } catch (error) {
      console.error('API call failed:', error);
      throw error;
    }
  };


  const showModal = () => {
    setIMEISModalOpen(true);
  };

  const UpdateShowModal = () => {
    setIMEISUpdateModal(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setIMEISModalOpen(false);
    setIMEISUpdateModal(false);
  };

  const SaveData = async (values) => {
    if (imeiData && Object.keys(imeiData).length >= 0) {
      const imeis = Object.values(imeiData);
      values.imeis = imeis || [];
    }
    // if (productData.length < maxProducts) {
      setProductData([...productData, values]);
      toast.success('Product added successfully');
      form.resetFields();
      setIMEIData([]);
    // } else {
    //   form.resetFields();
    //   toast.error(`Maximum products reached ${maxProducts}`)
    // }
  }

  const SaveIEMEIData = async (values) => {
    setIMEIData(values);
    setIMEISModalOpen(false)
    formRef.current.resetFields();
    // ,form4.resetFields();
  }

  const UpdateIEMEIData = async (values) => {
    setIMEIData(values);
    setIMEISUpdateModal(false)
    // form5.resetFields();
  }

  const SubmitData = async (values) => {
    console.log(values);
    setShowCustomValue(values);
    setLoading(true);
    try {
      const response = await apiCall({
        // url: 'http://localhost:8000/add-purchase-order',
        url: 'https://api.cellercertified.com/add-purchase-order',
        method: 'POST',
        data: { products: productData, purchaseorder: values },
      });

      console.log(response)
  
      if (response.existingPO) {
        // If PO already exists, show modal with options
        showExistingModal(response.existingPO, response.message, values);
      } else {
        // PO was created successfully
        toast.success('Purchase Order created!');
        setShowForm(true);
        console.log('response', response);
      }
    } catch (error) {
      toast.error('Failed to create Purchase Order');
    } finally {
      setLoading(false);
    }
  };
  
  // Modal to display options when PO exists
  const showExistingModal = (existingPO, message, values) => {
    Modal.confirm({
      title: (
        <div style={{ fontWeight: 'bold', fontSize: '18px' }}>
          <span style={{ color: '#faad14' }}>⚠️ PO ALREADY EXISTS</span>
        </div>
      ),
      content: (
        <div>
          <p style={{ fontSize: '16px' }}>
            The Purchase Order <strong>{existingPO.poNumber}</strong> already exists, created on <strong>{existingPO.poDate}</strong>.
          </p>
          <div style={{border: '2px solid grey', padding: '5px', margin: '5px 0'}}>
          <p><strong>Vendor : </strong> {existingPO.vendor}</p>
          <p><strong>PO Number : </strong> {existingPO.poNumber}</p>
          <p><strong>Date Created : </strong> {existingPO.poDate}</p>
          <p><strong>Expected Count : </strong> {existingPO.expectedCount}</p>
          </div>
          <p style={{ fontSize: '14px' }}>
            Do you want to <strong>Edit this existing one</strong>, <strong>Create a new one</strong>, or <strong>Cancel</strong>?
          </p>
          <p style={{ fontSize: '14px' }}>
            1. On clicking <strong>Edit</strong> the exsiitng Purchase order details will be updated with the details you entered in the form.
          </p>
          <p style={{ fontSize: '14px' }}>
            2. On clicking <strong>Create new</strong> a <strong>new Purchase order</strong> will be created with the <strong>same PO Number</strong> and the Purchase order details you entered.
          </p>
        </div>
      ),
      icon: null, // No icon
      footer: (
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '8px' }}>
          <Button onClick={() => editExistingPO(values, existingPO)} type="primary" style={{ backgroundColor: '#1890ff', borderColor: '#1890ff', marginRight: '8px' }}>
            Edit
          </Button>
          <Button
            onClick={() => createNewPO(values)} 
            type="default"
            style={{ backgroundColor: '#52c41a', color: 'white', borderColor: '#52c41a', marginRight: '8px' }}
          >
            Create New
          </Button>
          <Button onClick={() => Modal.destroyAll()} type="default" style={{ backgroundColor: '#f5222d', color: 'white', borderColor: '#f5222d' }}>
            Cancel
          </Button>
        </div>
      ),
      width: 500,  // Adjust width if necessary
      centered: true,  // Center the modal on the screen
    });
  };
  
  // Create new PO with same number
  const createNewPO = async (values) => {
    try {
      const response = await apiCall({
        // url: 'http://localhost:8000/add-purchase-order',
        url: 'https://api.cellercertified.com/add-purchase-order',
        method: 'POST',
        data: { products: productData, purchaseorder: { ...values, forceCreate: true } },
      });
      toast.success('Purchase Order created!');
      Modal.destroyAll(); // Close the modal after success
      setShowForm(true);  // Update your form state or UI
    } catch (error) {
      toast.error('Failed to create new Purchase Order');
    }
  };
  
  // Edit existing PO
  const editExistingPO = async (values, existingPO) => {
    try {
      const response = await apiCall({
          // url: 'http://localhost:8000/update-purchase-order', 
           url: 'https://api.cellercertified.com/update-purchase-order', 
          method: 'PUT',
          data: {
              purchaseorder: values, // Pass the new values directly
          },
      });
      console.log(response)
      toast.success('Purchase Order updated!');
      Modal.destroyAll(); // Close the modal after success
      setShowForm(true);  // Update your form state or UI
  } catch (error) {
      toast.error('Failed to update Purchase Order');
  }
  };

  const SubmitProducts = async (values) => {
    console.log(values)
    setLoading(true);
    try {

      const response = await apiCall({
        //  url: 'http://localhost:8000/add-products-to-PO',
          url: 'https://api.cellercertified.com/add-products-to-PO',
        method: 'POST',
        data: { products: productData, poNumber: showCustomValue.poNumber, note: note },
      });
      toast.success('Products added to PO!');
      navigate(`/poDetails/${response.data.poNumber}/id=${response.data._id}`, { state: response.data });
      console.log('response', response)
      // form.resetFields();
      // setProducts([{ key: 0 }]);
    } catch (error) {
      toast.error('Failed to create Purchase Order');
    } finally {
      setLoading(false);
    }
  }



  const AddProduct = async (values) => {
    if (values) {
      setShowCustomValue(values)
      setShowForm(true);
      setIMEIData([]);
      form.resetFields();
    }
  }
  const UpdateProduct = async (values) => {
    if (values) {
      setShowForm(true);
      // setShowCustomValue(values)
      form.resetFields();
    }
  }


  const ModalData = async (value) => {
    if (value.formName == "bulkUpload") {
      setShowForm1(false)
    } else {
      setShowForm(true)
    }
  }



  const addProduct = () => {
    if (products.length < maxProducts) {
      const newKey = products[products.length - 1].key + 1;
      setProducts([...products, { key: newKey }]);
    } else {
      toast.error(`Maximum products reached ${maxProducts}`);
    }
  };
  const removeProduct = (key) => {
    const updatedProducts = products.filter((product) => product.key !== key);
    setProducts(updatedProducts);
  };


  const excelDateToJSDate = (serial) => {
    const utc_days = Math.floor(serial - 25569);
    const utc_value = utc_days * 86400;
    const date_info = new Date(utc_value * 1000);

    const fractional_day = serial - Math.floor(serial) + 0.0000001;

    let total_seconds = Math.floor(86400 * fractional_day);

    const seconds = total_seconds % 60;

    total_seconds -= seconds;

    const hours = Math.floor(total_seconds / (60 * 60));
    const minutes = Math.floor(total_seconds / 60) % 60;

    return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
  };

  // console.log("showCustomValue", showCustomValue);
  //add purchase order and product from bulk 
  const handleExcelUpload = async (file) => {
    setLoading(true);
    const reader = new FileReader();
    reader.onload = async (e) => {
      try {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0]; // Assuming there's only one sheet
        const excelData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
        // console.log("excelData", excelData);
        // Group products by vendor and poNumber
        const groupedPurchaseOrders = excelData.reduce((acc, row) => {
          const vendor = row['Vendor Name'].trim();
          // const poNumber = Number(row['PO#'].match(/\d+/)[0]);
          const poNumber = row['PO#'];
          const poDate = dayjs(excelDateToJSDate(row['PO Date'])).format('MM/DD/YYYY');
          const key = `${vendor}_${poNumber}`;
          if (!acc[key]) {
            acc[key] = { purchaseOrder: { vendor, poNumber, poDate }, products: [] };
          }
          acc[key].products.push({
            condition: row['Condition'],
            IMEI: row['IMEI'] === 'n/a' ? [] : [row['IMEI']],
            unitPrice: row['Unit Price'],
            productName: row['Product Name'],
          });
          return acc;
        }, {});
        const derivedBulkPurchaseOrder = Object.values(groupedPurchaseOrders);
        setBulkPurchaseOrder(derivedBulkPurchaseOrder);

        // Filter based on showCustomValue
        // const filteredOrders = Object.values(groupedPurchaseOrders).filter(order =>
        //   order.purchaseOrder.vendor === showCustomValue.vendor.trim() &&
        //   dayjs(showCustomValue.poDate).format('MM/DD/YYYY') === order.purchaseOrder.poDate &&
        //   order.purchaseOrder.poNumber === showCustomValue.poNumber
        // );
        setBulkAdd(derivedBulkPurchaseOrder)
        // console.log("bulkPurchaseOrder", derivedBulkPurchaseOrder);
        setLoading(false);
      } catch (error) {
        console.error('Error processing file:', error);
        setLoading(false);
      }
    };
    reader.readAsArrayBuffer(file);
  };


  const beforeUpload = (file) => {

    const fileType = file.type;
    if (fileType === 'application/vnd.ms-excel' || fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      handleExcelUpload(file);
    } else if (fileType === 'text/csv') {
      handleExcelUpload(file);
    } else {
      message.error('Unsupported file type. Please upload a valid CSV or Excel file.');
    }
    return false;  // Prevent default upload behavior
  };

  // const beforeUpload = (file) => {
  //   const reader = new FileReader();
  //   reader.onload = async (e) => {
  //     const data = e.target.result;
  //     const workbook = XLSX.read(data, { type: 'binary' });
  //     const firstSheetName = workbook.SheetNames[0];
  //     const worksheet = workbook.Sheets[firstSheetName];
  //     const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

  //     const purchaseOrders = [];
  //     let currentPurchaseOrder = {};
  //     let currentProducts = [];

  //     for (const row of jsonData) {
  //       if (row[0] && typeof row[0] === 'number') {
  //         if (currentPurchaseOrder.products && currentProducts.length > 0) {
  //           currentPurchaseOrder.products = currentProducts;
  //           purchaseOrders.push(currentPurchaseOrder);
  //         }


  //         const excelDateToJSDate = (serial) => {
  //           const utcDays = Math.floor(serial - 25569);
  //           const utcValue = utcDays * 86400;
  //           return new Date(utcValue * 1000);
  //         };

  //         currentPurchaseOrder = {
  //           poNumber: row[0],
  //           vendor: row[1],
  //           PODate: row[2] ? excelDateToJSDate(row[2]) : 'Invalid Date',
  //           expectedCount: row[3],
  //           products: []
  //         };
  //         currentProducts = [];
  //       }
  //       if (row[4]) {
  //         currentProducts.push({
  //           productName: row[4],
  //           quantity: row[5],
  //           unitPrice: row[6],
  //           condition: row[7]
  //         });
  //       }
  //     }
  //     if (currentPurchaseOrder.products && currentProducts.length > 0) {
  //       currentPurchaseOrder.products = currentProducts;
  //       purchaseOrders.push(currentPurchaseOrder);
  //     }

  //     setBulkAdd(purchaseOrders);
  //     form.setFieldsValue({
  //       purchaseOrders,
  //     });
  //   };
  //   reader.readAsBinaryString(file);
  //   return false;
  // };

  const dateSet = showCustomValue.poDate ? dayjs(showCustomValue.poDate).format('YYYY-MM-DD') : ""

  const onSubmit = async () => {
    setLoading(true);
    try {

      const response = await apiCall({
        url: 'https://api.cellercertified.com/bullCreatePurchaseOrders',
        // url: 'http://localhost:8000/bullCreatePurchaseOrders',
        method: 'POST',
        data: bulkAdd,
      });
      toast.success('PurchaseOrder created!');
      form.resetFields();

    } catch (error) {
      toast.error('Failed to create Purchase Order');
    } finally {
      setLoading(false);
    }
  };

  const handelSearch = async () => {
    const filtered = productData.filter(item => item.productName.toLowerCase() === CustomFilter.toLowerCase() || item.IMEI === CustomFilter);
    setProductData(filtered);
  }

  const handleExportRows = (rows) => {
    const mappedRows = rows.map((row) => {
      const R = { ...row._valuesCache };
      return R;
    });

    const headerRow = columns.map((column) => column.header)
    const data = [headerRow, ...mappedRows.map((row) => Object.values(row))];

    const worksheet = XLSX.utils.aoa_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    const buffer = XLSX.write(workbook, { type: 'buffer', bookType: 'xlsx' });
    const DATE = Date.now();
    saveAs(new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }), "Report_History_" + DATE + ".xlsx");

  };


  // const handleEdit = (row) => {
  //   setUpdateData(true)
  //   form.setFieldsValue({
  //     productName: row.productName,
  //     quantity: row.quantity,
  //     unitPrice: row.unitPrice,
  //     condition: row.condition,
  //   })
  // };

  // const handleEdit = (row) => {
  //   setActive(row.index);
  //   setUpdateData(true);
  //   form.setFieldsValue({
  //     productName: row.original.productName,
  //     quantity: row.original.quantity,
  //     unitPrice: row.original.unitPrice,
  //     condition: row.original.condition,
  //   });

  //   if (row.original.imeis && row.original.imeis.length > 0) {
  //     const imeiValues = {};
  //     row.original.imeis.forEach((imei, index) => {
  //       imeiValues[`IMEI${index}`] = imei;
  //     });
  //     setQuantity(row.original.quantity)
  //     form3.setFieldsValue(imeiValues);
  //   }
  // };

  const handleEdit = (row) => {
    setActive(row.index);
    setUpdateData(true);
    form4.setFieldsValue({
      productName: row.original.productName,
      quantity: row.original.quantity,
      unitPrice: row.original.unitPrice,
      condition: row.original.condition,
    });

    if (row.original.imeis && row.original.imeis.length > 0) {
      const imeiValues = {};
      row.original.imeis.forEach((imei, index) => {
        imeiValues[`IMEI${index}`] = imei;
      });
      setQuantity(row.original.quantity)
      form5.setFieldsValue(imeiValues);
    }
  };


  // const handelEditPopup = (row) => {
  //   swal({
  //     title: "Are you sure?",
  //     text: "Once edited, You won't be able to revert this!",
  //     icon: "warning",
  //     cancel: true,
  //     buttons: {
  //       cancel: {
  //         text: "Cancel",
  //         value: null,
  //         visible: true,
  //         className: "p-[20px] bg-red-700",
  //         closeModal: true,
  //       },
  //       confirm: {
  //         text: "OK",
  //         value: true,
  //         visible: true,
  //         className: "swal-button--confirm",
  //         closeModal: true,
  //       },
  //     },
  //     dangerMode: true,
  //   }).then((willDelete) => {
  //     if (willDelete) {
  //       handleEdit(row);
  //     }
  //   });
  // };



  const handelUpdate = async (updatedValues) => {
    if (imeiData && Object.keys(imeiData).length >= 0) {
      const imeis = Object.values(imeiData);
      updatedValues.imeis = imeis || [];
    }
    if (active !== null && active !== undefined) {
      let update = [...productData];
      Object.assign(update[active], updatedValues);
      setProductData(update);
      const UpdateIEMEIData = updatedValues.imeis;
      setIMEIData(UpdateIEMEIData);
      toast.success('Product updated successfully');
    } else {
      console.error('Active index is not set');
    }
    setActive(null);
    setUpdateData(false);
    form4.resetFields();
    form5.resetFields();
  };

  // const handelUpdate = async (index, values) => {
  //   console.log("values", imeiData);
  //   if (imeiData && Object.keys(imeiData).length >= 0) {
  //     const imeis = Object.values(imeiData);
  //     values.imeis = imeis || [];
  //   }
  //   console.log("values", values);
  //   
  //   if (index >= 0 && index < productData.length) {
  //     const updatedProducts = [...productData];
  //     updatedProducts[index] = values;
  //     setProductData(updatedProducts);
  //     console.log("imeiData", imeiData, "productData", updatedProducts);
  //     form.resetFields();
  //     setIMEIData([]);
  //   } else {
  //     form.resetFields();
  //     toast.error(`Invalid product index ${index}`);
  //   }
  // }


  // const handleEdit = (row) => {
  //   setUpdateData(true);
  //   form4.setFieldsValue({
  //     productName: row.productName,
  //     quantity: row.quantity,
  //     unitPrice: row.unitPrice,
  //     condition: row.condition,
  //   });
  // };


  // const handelUpdate = async (updatedValues) => {
  //   if (imeiData && Object.keys(imeiData).length >= 0) {
  //     const imeis = Object.values(imeiData);
  //     updatedValues.imeis = imeis || [];
  //   }
  //   
  //   let update = [...productData];
  //   const index = update[updatedValues.index];
  //   const updatedProductData = update.map((product, i) => {
  //     if (i === updatedValues.index) {
  //       return updatedValues;
  //     } else {
  //       return product;
  //     }
  //   });
  //   setProductData(updatedProductData);
  //   setUpdateData(false);
  // };


  const handleDelete = (rowKey) => {
    const updatedData = productData.filter((item) => item !== rowKey);
    setProductData([...updatedData]);
  }


  const handleTextareaChange = (e) => {
    setTextareaImei(e.target.value);
  };


  const handleMultipleInput = () => {
    if (textareaImei.trim() !== '') {
     
      const imeisArray = textareaImei.split(/[,|\n]/).map(imei => imei.trim());
  
      const validImeis = imeisArray.filter(imei => imei.length === 15);
      const newItems = validImeis.map(imei => (imei));
      console.log(newItems)
  
      if (newItems.length > 0) {
        setItems([...newItems]);
        setIMEIData([...newItems]);
        setTextareaImei('');
        
        toast.success(`${newItems.length} IMEI added successfully`);
        document.getElementById('copyPasteModal').checked = false;
      } else {
        toast.error('No valid items to add');
      }
    }
  };

  const handleFileUpload = async (e) => {
    const fileInput = e;
    console.log(fileInput)
    const file = fileInput;
  
    if (file) {
      try {
        const reader = new FileReader();
  
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
  
         
          const sheet = workbook.Sheets[workbook.SheetNames[0]];
          const rows = XLSX.utils.sheet_to_json(sheet, { header: 1 });
  
          const newItems = [];
  
          for (const row of rows) {
            const [imei, referenceId] = row.map((entry) => String(entry).trim());

  
            if (imei.length === 15) {
              const newItem = { IMEI: imei };
  
          
              if (referenceId) {
                newItem.ReferenceID = referenceId;
              }
  
              newItems.push(newItem);
            }
          }
          console.log(newItems)
          if (newItems.length > 0) {
            setItems([...newItems]);
            setIMEIData([...newItems]);
            toast.success('File Uplaoded')
            toast.success(`${newItems.length} IMEI added successfully`);
            document.getElementById('fileInput').checked = false;
          } else {
            toast.error('No valid items to add');
          }
        };
  
        reader.readAsArrayBuffer(file);
      } catch (error) {
        console.error('Error reading file:', error);
      }
    }
  };

  console.log(imeiData)
  console.log(items)



  let columns = [
    { header: "Product Name", accessorKey: "productName", enableEditing: false },
    { header: "Condition", accessorKey: "condition", enableEditing: false },
    { header: "Quantity", accessorKey: "quantity", enableEditing: false },
    { header: "Unit Price", accessorKey: "unitPrice", enableEditing: false },
    { header: "Total Price", accessorKey: "totalPrice", enableEditing: false },
    {
      header: "IMEI",
      accessorKey: "imeis",
      enableEditing: false,
      Cell: ({ cell }) => {
        console.log(cell.getValue()); // Check what is being returned
        const imeis = cell.getValue();
        console.log(imeis)
        return (
          <div style={{ maxHeight: '120px', overflowY: 'auto' }}>
            {Array.isArray(imeis) ? (
              imeis.map((imei, index) => <p key={index}>{imei}</p>)
            ) : (
              <p>Invalid data</p>
            )}
          </div>
        );
      }
    },
    
    { header: "Action", accessorKey: "key", enableEditing: false, Cell: ({ row, index }) => <Space><Button type="primary" icon={<Edit />} onClick={() => handleEdit(row)}>Edit</Button><Button type="primary" danger icon={<DeleteOutlined />} onClick={() => handleDelete(row.original)}>Delete</Button></Space> },



    // {
    //   header: 'Actions',
    //   Cell: ({ row }) => (
    //     <Space>
    //       <Button
    //         type="primary"
    //         icon={<Edit />}
    //         onClick={() => handleEdit(row.original.key)}
    //       >
    //         Edit
    //       </Button>
    //       <Button
    //         type="danger"
    //         icon={<DeleteOutlined />}
    //         onClick={() => handleDelete(row.original.key)}
    //       >
    //         Delete
    //       </Button>
    //     </Space>
    //   ),
    // },
  ]

  const selectArray = [
    {
      label: "Basic Form",
      value: "basicForm",
    },
    {
      label: "Bulk Upload",
      value: "bulkUpload",
    }
  ]

  const handleFormEdit = () => {
    setShowForm(false);
    setShowForm1(false);
    form.setFieldsValue({
      vendor: showCustomValue.vendor,
      poNumber: showCustomValue.poNumber,
      expectedcount: showCustomValue.expectedcount,
      poDate: showCustomValue.poDate,
    })
  }



  // const renderIMEIsInputs = () => {
  //   const inputs = [];
  //   for (let i = 0; i < quantity; i++) {
  //     inputs.push(
  //       <div key={i} className='col-span-6 spanText'>
  //         <Form.Item
  //           name={`IMEI${i}`}
  //           label={`IMEI ${i + 1}`}
  //           rules={[{ required: true, message: `Please enter the IMEI${i + 1}` }]}
  //         >
  //           <Input placeholder={`Enter IMEI ${i + 1}`} type='number' className='[&::-webkit-inner-spin-button]:appearance-none' />
  //         </Form.Item>
  //       </div>
  //     );
  //   }
  //   return inputs;
  // };


  const renderIMEIsInputs = () => {
    const inputs = [];

    if (quantity > 0) {
      for (let i = 0; i < quantity; i++) {
        inputs.push(
          <div key={i} className='col-span-6 spanText'>
            <Form.Item
              name={`IMEI${i}`}
              label={`IMEI ${i + 1}`}
              rules={[
                { required: true, message: `Please enter the IMEI${i + 1}` },
                { validator: (_, value) => value.length === 15 ? Promise.resolve() : Promise.reject('IMEI must be 15 digits') }
              ]}
            >

              <Input placeholder={`Enter IMEI ${i + 1}`} type='number' className='[&::-webkit-inner-spin-button]:appearance-none' />
            </Form.Item>
          </div >
        );
      }
    } else {
      // Render a single input field by default
      inputs.push(
        <div key={0} className='col-span-3 spanText'>
          <Form.Item
            name={`IMEI`}
            label={`IMEI`}
            rules={[
              { required: true, message: 'Please enter the IMEI' },
              { validator: (_, value) => value.length === 15 ? Promise.resolve() : Promise.reject('IMEI must be 15 digits') }
            ]}
          >

            <Input placeholder={`Enter IMEI`} type='number' className='[&::-webkit-inner-spin-button]:appearance-none' />
          </Form.Item>
        </div >
      );
    }
    return inputs;
  };

  const handleIMEIChange = (index, value) => {
    const newImeis = [...imeis];
    newImeis[index] = value;
    setImeis(newImeis);
  };

  {/*const renderValidationMessages = () => {
    return imeis.map((imei, index) => (
      <div key={index}>
        {loading && (
          <h3 className="text-green-500 font-semibold">
            Verifying IMEI ...
          </h3>
        )}
        {!loading && imei.length === 15 && (
          <h3 className="text-green-500 font-semibold">
            Valid IMEI!
          </h3>
        )}
        {!loading && imei.length !== 15 && imei.length > 0 && (
          <h3 className="text-red-500 font-semibold">
            Invalid IMEI!
          </h3>
        )}
      </div>
    ));
  };*/}

  console.log("showCustomValue", showCustomValue.expectedcount);
  console.log("productData", productData);

  const totalQuantity = productData.reduce((total, product) => {
    return total + parseInt(product.quantity, 10); 
  }, 0);
  
  console.log("Total Quantity:", totalQuantity); 

  useEffect(() => {
    // Convert expectedcount to a number and check the condition
    const expectedCountNumber = Number(showCustomValue.expectedcount);

    if(totalQuantity === expectedCountNumber) {
      toast.success("Yay! Your counts match!")
    }
  }, [totalQuantity]);

  const dateFormat = userdata?.dateFormat 

  return (
    <div className='leading-6'>
      <DefaultLayout>
        <Banner />
        <Breadcrumb pageName="Add Purchase Order" parentName="Purchase Orders" parentLink="/purchase-orders"  />
        <div className='mx-3'><span className='text-lg font-bold'>PRO TIP: </span>First enter your Purchase Order Details and then you will be able to add Product Details.</div>
        <div className="flex min-h-screen mx-3 flex-col gap-10">
          <Tabs activeKey={activeTab} onChange={key => setActiveTab(key)}>
            <TabPane tab="One by One" key="tab1">
              {!showForm === true ? <div>
                <div className='grid grid-cols-12 mt-4 gap-4'>
                  <div className='col-span-12 md:col-span-6'>
                    <Card title={`Add Purchase Order +`} styleTitle={{ color: "black", darkColor: "white" }} >
                      <div className="bg-white  dark:bg-black-2 dark:text-white rounded-xl relative ">
                        <Form form={form} layout="vertical" onFinish={SubmitData} className='bg-white' >
                          <div className='grid grid-cols-1 gap-x-1'>
                            <div>
                              <label className='flex items-center font-semibold'><span className='text-[#FF4D4F] font-bold mr-1'>*</span>Vendor</label>
                              <div className='form-description '>The vendor or organization you purchased from</div>
                              <Form.Item
                                name='vendor'
                                // label="Vendor"
                                rules={[{ required: true, message: 'Please enter the Vendor' }]}
                              >
                                <Input placeholder="Enter Vendor" />
                              </Form.Item>
                            </div>
                            <div>
                              <label className='flex items-center font-semibold'><span className='text-[#FF4D4F] font-bold mr-1'>*</span>PO Number</label>
                              <div className='form-description '>An internal transaction number or one provided by your vendor</div>
                              <Form.Item
                                name='poNumber'
                                // label="PO Number"
                                rules={[{ required: true, message: 'Please enter the PO Number' }]}
                              >
                                <Input placeholder="Enter PO Number" type='text' className='[&::-webkit-inner-spin-button]:appearance-none' />
                              </Form.Item>
                            </div>
                            <div>
                              <label className='flex items-center font-semibold '><span className='text-[#FF4D4F] font-bold mr-1'>*</span>PO Date</label>
                              <div className='form-description '>The date the PO was initiated</div>
                              <Form.Item
                                name='poDate'
                                // label="PO Date"
                                rules={[{ required: true, message: 'Please enter the PO Date' }]}
                              >
                                <DatePicker placeholder={`Enter PO Date`} className='w-full' />
                              </Form.Item>
                            </div>
                            <div>
                              <label className='flex items-center font-semibold '><span className='text-[#FF4D4F] font-bold mr-1'></span>Received Date</label>
                              <div className='form-description '>Date the PO was Received</div>
                              <Form.Item
                                name='receivedDate'
                                // label="PO Date"
                                // rules={[{ message: 'Please enter the Received Date' }]}
                              >
                                <DatePicker placeholder={`Enter Received Date`} className='w-full' />
                              </Form.Item>
                            </div>
                            <div>
                              <label className='flex items-center font-semibold'><span className='text-[#FF4D4F] font-bold mr-1'>*</span>Expected Count</label>
                              <div className='form-description '>How many items you purchased on this PO</div>
                              <Form.Item
                                name='expectedcount'
                                // label="Expected Count"
                                rules={[{ required: true, message: 'Please enter the Expected Count' }]}
                              >
                                <Input placeholder="Enter ExpectedCount" type='number' className='[&::-webkit-inner-spin-button]:appearance-none' />
                              </Form.Item>
                            </div>

                          </div>
                          <Form.Item className='my-2'>
                            <Button className='bg-logocolor' type="primary" htmlType="submit">
                              {showForm1 ? "Create Purchase Order" : "Update Purchase Order"}
                            </Button>
                          </Form.Item>
                        </Form>
                      </div>
                    </Card>

                  </div>

                  {/*<div className='col-span-3 text-center bg-[#f8f8f8] shadow-md  rounded-lg max-h-[350px] min-h-[350px] p-10 overflow-auto'>
                  <h1 className='text-lg font-semibold'>{`Purchase Order ${showCustomValue.poNumber ? `# ${showCustomValue.poNumber}` : ''}`}</h1>
                  <div>
                    <div>Vendor: {showCustomValue.vendor}</div>
                    <div>PO Numbe: {showCustomValue.poNumber}</div>
                    <div>PO Date: {dateSet}</div>
                    <div>expectedCount: {showCustomValue.expectedcount}</div>
                  </div>
            </div>*/}

                </div>
              </div> : null}
              {showForm === false ? null :
                <div>
                  <div className='grid grid-cols-12 gap-2'>
                    <div className='col-span-12 md:col-span-9'>
                      <Card title={`Product ${productData.length + 1}`} extra={<div className='flex flex-col font-semibold text-base'><div>{`Total No of product(s) added : ${productData.length}`}</div><div>{`Total Quantity Added : ${totalQuantity}`}</div></div>} className='text-red-500 mt-5'>
                        <Form form={form} name="create-products-form" onFinish={SaveData} layout="vertical">
                          <div className='grid grid-cols-12 gap-2'>
                          <div className='col-span-6 md:col-span-3'>
                            <Form.Item
                              name='productName'
                              label={<span style={{ fontWeight: 'bold' }}>Product Name</span>}
                              rules={[{ required: true, message: 'Please enter the product name' }]}
                            >
                              <Input placeholder="Enter product name" />
                            </Form.Item>
                          </div>

                            <div className='col-span-6 md:col-span-3'>
                              <Form.Item
                                name='condition'
                                label={<span style={{ fontWeight: 'bold' }}>Condition</span>}
                                rules={[{ required: true, message: 'Please enter the condition' }]}
                              >
                                <Input placeholder="Enter condition" />
                              </Form.Item>
                            </div>
                            <div className='col-span-6 md:col-span-3'>
                              <Form.Item
                                name='unitPrice'
                                label={<span style={{ fontWeight: 'bold' }}>Unit Price</span>}
                                rules={[{ required: true, message: 'Please enter the unit price' }]}
                              >
                                <Input placeholder="Enter price" type='number' className='[&::-webkit-inner-spin-button]:appearance-none' />
                              </Form.Item>
                            </div>
                            <div className='col-span-6 md:col-span-3 spanText'>
                              <Form.Item
                                name='quantity'
                                label={<span style={{ fontWeight: 'bold' }}>Quantity</span>}
                                rules={[{ required: true, message: 'Please enter the quantity' }]}
                              >
                                <Input placeholder="Enter quantity" type='number' className='[&::-webkit-inner-spin-button]:appearance-none' onChange={(e) => setQuantity(parseInt(e.target.value))} />
                              </Form.Item>
                              <span>
                              <label htmlFor="my-modal3" className='text-xs font-medium text-darkblue cursor-pointer border-bottom mx-1'>Add IMEIs</label>
                              </span>
                            </div>
                            <div>
                              <input 
                              type="checkbox" id="my-modal3" className="modal-toggle" />
                              <div className={`modal z-999`}>
                              <div className="modal-box bg-white dark:bg-black-2 flex flex-col gap-y-2 ">
                              <h1 className="font-bold text-black-2 dark:text-white text-lg">Bulk Add</h1>
                              <div className='flex gap-x-2'>
                                  <label 
                                      htmlFor='copyPasteModal' 
                                      onClick={() => { document.getElementById('my-modal3').checked = false; }}
                                      className='btn bg-logocolor border-none text-white disabled:text-bodydark h-fit'>Copy and Paste</label>
                                  <div className='flex flex-col gap-y-1'>
                                  <label 
                                        htmlFor="fileInput"
                                        onClick={() => { document.getElementById('my-modal3').checked = false; }}
                                        className='btn bg-logocolor border-none text-white disabled:text-bodydark h-fit'>Upload File</label>
                                  </div>
                                  <label htmlFor="my-modal3" className='btn bg-danger border-none text-white disabled:text-bodydark w-1/3 h-fit' 
                              //  onClick={()=>{setCancelBulkAdd("")}}
                              >Cancel</label>
                              </div>

                              </div>
                              </div>
                          </div>
                          <div>
                              <input 
                              type="checkbox" id="copyPasteModal" className="modal-toggle" />
                              <div className={`modal z-999`}>
                              <div className="modal-box bg-white dark:bg-black-2 flex flex-col gap-y-2">
                              <h1 className="font-bold text-black-2 dark:text-white text-lg">Copy and Paste</h1>
                              <h1>Paste IMEI'S seperated by comma or line...</h1>
                              
                              <textarea id="textareaInput" 
                                        value={textareaImei} 
                                        onChange={handleTextareaChange} 
                                        type='textfield' placeholder='Paste IMEIs separated by comma or Line'
                              className='disabled:bg-bodydark text-info-content bg-white border border-body rounded  h-full w-full p-2' />
                            <div className='flex gap-x-2'>
                            <label  className='btn bg-logocolor border-none text-white disabled:text-bodydark w-1/4 h-fit' 
                              onClick={handleMultipleInput}
                              >ADD IMEI</label>
                            <label htmlFor="copyPasteModal" className='btn bg-danger border-none text-white disabled:text-bodydark w-1/4 h-fit' 
                              // onClick={()=>{setCancelBulkAdd("")}}
                              >Cancel</label>
                            </div>
                              </div>
                              </div>
                          </div>
                          <div>
                           <input 
                              type="checkbox" id="fileInput" className="modal-toggle" />
                              <div className={`modal z-999`}>
                              <div className="py-6 px-8 rounded-xl bg-white dark:bg-black-2 flex flex-col gap-y-2">
                              <h1 className="font-bold text-black-2 dark:text-white text-lg">Upload Excel File</h1>
                            
                              <div className='flex flex-col gap-y-2'>
                              <FileUploader classes='p-2'  
                              handleChange={handleFileUpload} name="file" types={fileTypes}
                               >
                              <div className='flex flex-col p-4 gap-y-4 justify-center items-center border-2 border-dashed border-logocolor w-full text-md'>
                                <div><u>Upload</u> Excel File containing IMEI'S (<i> Ref... ID is Optional</i> ).</div>
                                <label  className='btn bg-logocolor border-none text-white disabled:text-bodydark' >Upload File</label>
                              </div>
                              </FileUploader>

                              <div className='text-md text-logocolor'><DownloadIcon/><a className='underline tracking-tighter cursor-pointer' href={backendURLIMEI} download >Click here to download sample file</a></div>
                              </div>
                            <div className='flex w-full justify-end gap-x-2'>

                            <label htmlFor="fileInput" className='p-2 bg-danger rounded-md border-none text-white  disabled:text-bodydark ' 
                              // onClick={()=>{setCancelBulkAdd("")}}
                              >Cancel</label>
                            </div>
                              </div>
                              </div>
                          </div>
                          </div>
                          <Form.Item className='my-2'>
                            <Button className='bg-logocolor' type="primary" htmlType="submit">
                              Save
                            </Button>
                          </Form.Item>
                        </Form>
                      </Card>
                    </div>
                    <Card className='col-span-12 md:col-span-3 text-center bg-[#f8f8f8] mt-5 shadow-md rounded-lg overflow-auto'>
  <>
    <h1 className='text-lg font-semibold'>{`Purchase Order ${showCustomValue.poNumber ? `# ${showCustomValue.poNumber}` : ''}`}</h1>
    <div>
      <div>Vendor : {showCustomValue.vendor}</div>
      <div>PO Number : {showCustomValue.poNumber}</div>
      <div>PO Date : {moment(dateSet).format(dateFormat)}</div>
      <div>Expected Count : {showCustomValue.expectedcount}</div>
      {note ?  <div>Note : {note}</div> : null}

    </div>

    <div>
      <span className='text-base font-medium text-darkblue cursor-pointer border-bottom mx-1 underline' onClick={() => handleFormEdit()}>Edit</span>
    </div>
  </>

  {/* Flexbox wrapper to keep content at the top and input at the bottom */}
  <div className='flex flex-col justify-between h-full'>
    <div className='mt-auto'>
    <input
            type='text'
            placeholder='Add Note'
            className='w-full p-2 rounded-lg border mt-5'
            value={note} 
            onChange={(e) => setNote(e.target.value)}
          />
    </div>
  </div>
</Card>

                  </div>
                  <div>
                    <Modal
                      title="Add IMEIs" centered open={imiesModalOpen} footer={null} onCancel={handleCancel}
                    >
                      <Form form={form3} onFinish={SaveIEMEIData} ref={formRef} layout="vertical">
                        <div className='grid grid-cols-12 gap-2'>
                          {renderIMEIsInputs()}
                          {/*{renderValidationMessages()}*/}
                        </div>
                        <Form.Item className='my-2'>
                          <Button className='bg-logocolor' type="primary" htmlType="submit">
                            Save
                          </Button>
                        </Form.Item>
                      </Form>
                    </Modal>
                  </div>

                </div>
              }
              <div>
                <Modal
                  title="Update IMEIs" centered open={imiesUpdateModal} footer={null} onCancel={handleCancel}
                >
                  <Form form={form5} onFinish={UpdateIEMEIData} layout="vertical">
                    <div className='grid grid-cols-12 gap-2'>
                      {renderIMEIsInputs()}
                      {/*{renderValidationMessages()}*/}
                    </div>
                    <Form.Item className='my-2'>
                      <Button className='bg-logocolor' type="primary" htmlType="submit">
                        Update
                      </Button>
                    </Form.Item>
                  </Form>
                </Modal>
              </div>
              <div>
                <Modal
                  title="Update Product"
                  open={updateData}
                  onCancel={() => setUpdateData(false)}
                  footer={null}
                  layout="vertical"
                  centered
                >
                  <Form
                    form={form4}
                    onFinish={handelUpdate}
                    layout="vertical"
                  >
                    <div className='grid grid-cols-12 gap-2'>
                      <div className='col-span-6'>
                        <Form.Item
                          name='productName'
                          label="Product Name"
                          rules={[{ required: true, message: 'Please enter the product name' }]}
                        >
                          <Input placeholder="Enter product name" />
                        </Form.Item>
                      </div>
                      <div className='col-span-6'>
                        <Form.Item
                          name='condition'
                          label="Condition"
                          rules={[{ required: true, message: 'Please enter the condition' }]}
                        >
                          <Input placeholder="Enter condition" />
                        </Form.Item>
                      </div>
                      <div className='col-span-6'>
                        <Form.Item
                          name='unitPrice'
                          label="Unit Price"
                          rules={[{ required: true, message: 'Please enter the unit price' }]}
                        >
                          <Input placeholder="Enter price" type='number' className='[&::-webkit-inner-spin-button]:appearance-none' />
                        </Form.Item>
                      </div>
                      <div className='col-span-6 spanText'>
                        <Form.Item
                          name='quantity'
                          label="Quantity"
                          rules={[{ required: true, message: 'Please enter the quantity' }]}
                        >
                          <Input placeholder="Enter quantity" type='number' className='[&::-webkit-inner-spin-button]:appearance-none' onChange={(e) => setQuantity(parseInt(e.target.value))} />
                        </Form.Item>
                        <span className='text-xs font-medium text-darkblue cursor-pointer border-bottom mx-1' onClick={() => UpdateShowModal()}>Update IMEIs</span>
                      </div>
                    </div>
                    <Form.Item className='my-2'>
                      <Button className='bg-logocolor' type="primary" htmlType="submit">
                        Update
                      </Button>
                    </Form.Item>
                  </Form>
                </Modal>
              </div>
              {showForm === false ? null : <div className="rounded-sm border mt-4 border-stroke bg-white px-5 pt-4 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-3.5 xl:pb-5">
                <MaterialReactTable
                  data={productData}
                  columns={columns.map(c => ({ ...c, size: 5, minSize: 10 }))}
                  positionToolbarAlertBanner="bottom"
                  enableFullScreenToggle={false}
                  enableRowSelection={Permission ? !Permission.account : false}
                  enableStickyHeader
                  editingMode="modal"
                  enableEditing={false}
                  enableGlobalFilter={true}
                  enablePagination={true}
                  initialState={{
                    density: 'compact',
                    pagination: { pageIndex: 0, pageSize: 10 },

                  }}
                  muiTablePaginationProps={{
                    rowsPerPageOptions: [10, 25, 50, 100, 200, 250, 500],
                    showFirstButton: true,
                    showLastButton: true,
                    SelectProps: {
                      native: true
                    },
                    labelRowsPerPage: 'Rows per pages'
                  }}
                  muiTableBodyCellEditTextFieldProps={{ variant: 'filled' }}
                  muiTableContainerProps={{ sx: { background: 'transparent', borderRadius: '8px', } }}
                  muiTableProps={{ sx: { padding: '0px', background: 'transparent', } }}
                  muiTablePaperProps={{ sx: { background: 'transparent' } }}
                  muiTableBodyCellProps={({ cell, column }) => ({

                    align: 'center',
                    sx: {
                      border: '1px solid rgba(255, 255, 255, 1)',
                      borderBlockEnd: '1px solid rgba(0,0,0,0.1)',
                      color: 'rgba(100,100,100,1)',
                      background: (
                        cell.getValue() === "CLEAN") ? 'rgba(0, 255, 81,0.5)' : '' ||
                          (cell.getValue() === "BAD") ? 'rgba(255, 0, 25,0.4)' : '' ||
                            (cell.getValue() === "BLACKLISTED") ? 'rgba(255, 0, 25,0.4)' : '' ||
                              (cell.getValue() === "ON") ? 'rgba(255, 0, 25,0.4)' : '' ||
                                (cell.getValue() === "OFF") ? 'rgba(0, 255, 25,0.4)' : '' ||
                                  (cell.getValue() === "LOCKED") ? 'rgba(255, 0, 81,0.5)' : '' ||
                                    (cell.getValue() === "UNLOCKED") ? 'rgba(0, 255, 81,0.5)' : '' ||
                                      (cell.getValue() === "LOCKED ON VERIZON") ? 'rgba(255, 0, 25,0.4)' : '' ||
                                        (cell.getValue() === "LOCKED ON T-MOBILE") ? 'rgba(255, 0, 25,0.4)' : '' ||
                                          (cell.getValue() === "LOCKED ON AT&T") ? 'rgba(255, 0, 25,0.4)' : '' ||
                                            (cell.getValue() === "OUT OF WARRANTY") ? 'rgba(255, 0, 25,0.4)' : '' ||
                                              (cell.getValue() === "OOW") ? 'rgba(255, 0, 25,0.4)' : '' ||
                                                (cell.getValue() === "WARRANTY ACTIVE") ? 'rgba(0, 255, 81,0.5)' : '' ||
                                                  (cell.getValue() === "ACTIVE") ? 'rgba(0, 255, 81,0.5)' : '' ||
                                                    (cell.getValue() === "WARRANTY EXPIRED") ? 'rgba(255, 0, 25,0.4)' : '' ||
                                                      (cell.getValue() === "EXPIRED") ? 'rgba(255, 0, 25,0.4)' : '' ||
                                                        (cell.getValue() === "LIMITED WARRANTY") ? 'rgba(244, 255, 151, 0.8)' : '' ||
                                                          (cell.getValue() === "APPLE CARE") ? 'rgba(0, 255, 81,0.5)' : '',
                      padding: '8px 0px',


                    }
                  })}
                  muiTableHeadCellProps={{ sx: { background: 'rgb(0 174 239)', color: 'white', padding: '0px', border: '1px solid rgba(255, 255, 255, 1)', }, align: 'center', }}
                  muiTableHeadRowProps={{ sx: {} }}
                  muiTableBodyRowProps={{ sx: {} }}
                  muiTableBodyProps={{ sx: { background: 'transparent' } }}
                  muiTopToolbarProps={{ sx: { background: 'transparent' } }}
                  muiBottomToolbarProps={{ sx: { background: 'transparent' } }}

                  renderTopToolbarCustomActions={({ table }) => (
                    <div className='w-full' >

                      <div className="mb-4 flex items-center justify-between">
                      <div>
                      <input
            className="bg-gray p-2 dark:bg-black rounded-md"
            placeholder="Search Product Name"
            onChange={(e) => {
              const value = e.target.value;
              setCustomfilter(value);
              table.setColumnFilters([{ id: 'productName', value }]); // Apply the filter in real-time
            }}
            disabled={Permission && !Permission.account}
          />
                        <button
                          className="rounded bg-logocolor text-white p-2 mx-2"
                          disabled={Permission && !Permission.account}
                          onClick={handelSearch}
                        >Search</button>
                      </div>
                        <button
                          className="py-2 text-white bg-logocolor  rounded-md transition-all active:bg-black"
                        >
                          <label htmlFor="my-modal3" className="hover:cursor-pointer py-1 px-5" > <Add />New Scan Report</label>
                        </button>
                      </div>
                      {/*<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-3">
                      <button
                        className="px-5 py-1 disabled:text-graydark disabled:bg-bodydark1 text-bodydark1 bg-logocolor  rounded-md transition-all active:bg-black"
                        disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
                        onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
                        variant="contained">
                        <FileDownloadIcon />
                        Export as XLSX
                      </button>
                      <button
                        className=" py-1  disabled:text-graydark disabled:bg-bodydark1 text-bodydark1 bg-meta-6  rounded-md transition-all active:bg-black"
                        disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
                        onMouseOver={() => { Set_Rows_To_Edit(table.getSelectedRowModel().rows) }}
                      >
                        <label htmlFor="my-modal2" className="hover:cursor-pointer py-1 px-5" > <Edit />Bulk Edit</label>
                      </button>
                </div>*/}
                    </div>

                  )}
                />
              </div>}
              {showForm === false ? null : <div className="flex gap-x-4 my-6 ">
                <Button type="primary" disabled={productData.length > 0 ? false : true }  
                onClick={() => {
                SubmitProducts(); // Call your existing function
      }} icon={<PlusOutlined />}>
                Add Products to complete PO
                </Button>
                <div className='flex items-center'>Once your total quantity matches your expected count, you can create the Purchase Order</div>
              </div>}




            </TabPane>
            <TabPane tab="Bulk Upload" key="tab2">
              <div className="mt-4">
                <Card className='w-1/3' style={{ marginBottom: 0 }}>
                  <Form form={form2} name="upload-form" onFinish={onSubmit} layout="vertical">
                    <div className='mb-4'>
                      <span>
                        Click the <b>Upload</b>  button to upload a .csv or .xls file.
                        column data will be uploaded automatically
                      </span>
                    </div>
                    <div className='mb-4'>
                      <a href={backendURL} className='text-logocolor text-sm hover:underline hover:font-semibold' download>
                        Use this template for sample products <Download fontSize='small' />
                      </a>
                    </div>
                    <div className=''>
                      <Form.Item
                        name="excelData"
                        // label="Upload Excel Sheet"
                        valuePropName="fileList"
                        getValueFromEvent={(fileInfo) => [fileInfo.file]}
                      >
                        <Upload
                          name="excelData"
                          accept=".xlsx"
                          beforeUpload={beforeUpload}
                        >
                          <Button disabled={loading} icon={!loading ? <UploadOutlined /> : <LoadingOutlined />}>{loading ? "Uploading" : "Upload"}</Button>
                        </Upload>
                      </Form.Item>
                      <Form.Item>
                        <Button type='primary' className='bg-logocolor' onClick={onSubmit}>Submit</Button>
                      </Form.Item>
                    </div>
                  </Form>
                </Card>
              </div>
            </TabPane>

          </Tabs>



        </div>
      </DefaultLayout>
    </div>
  );
};
export default AddPurchaseOrder;


function CHECK_BOX_ITEM2({ name, fun2, descraption }) {

  return (
    <>
      {name === 'PODate' ? <Form.Item
        name={['purchaseorder', name]}
        label={name}
        rules={[{ required: true, message: `Please Enter the ${name}` }]}
      >
        <span className='text-xs'>{descraption}</span>
        <DatePicker placeholder={`Enter ${name}`} className='w-full' />
      </Form.Item> :
        <Form.Item
          name={['purchaseorder', name]}
          label={name}
          rules={[{ required: true, message: `Please Enter the ${name}` }]}
        >
          <span className='text-xs'>{descraption}</span>
          <Input placeholder={`Enter ${name}`} />
        </Form.Item>

      }</>
  );
}