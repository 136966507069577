import React from "react";
function FIND_IMEI_POPUP() {
  return (
    <div
      id="IMEI-Popup"
      className="fixed top-0 z-30 h-screen  w-[100%] bg-[rgba(0,0,0,0.8)] "
      hidden={true}
    >
      <div className="flex h-screen w-[100%] items-center justify-center ">
        <div className="relative w-[90%]  flex-col rounded-md bg-white px-2 pb-5 shadow-default dark:border-strokedark dark:bg-boxdark sm:w-[40%] sm:px-10">
          <button
            className="bg-gray-300 absolute right-0 top-0 justify-center rounded-sm px-2 "
            onClick={() => {
              Hide_popup();
            }}
          >
            {" "}
            x
          </button>
          <h1 className="m-2 text-center text-2xl font-bold">
            Where is my device's IMEI Number located?
          </h1>
          <div className="flex flex-col  ">
            <img
              className="w-48 sm:w-60"
              src="https://d2vh5kejdcy27x.cloudfront.net/images/serial-iphone-menu.jpg"
              alt="Image Goes Here"
            />
            <span>
              1.Unlock your iOS device, and go to{" "}
              <strong>Settings &rarr; General &rarr; About</strong>.
            </span>
            <span>2.Scroll down to find your "IMEI Number”</span>
            <span>
              3.Touch and hold on the IMEI Number and <strong>Copy</strong>{" "}
              should appear.
            </span>
            <span>
              4.Tap <strong>Copy</strong> to save the IMEI Number
            </span>
            <span>
              5.Paste the IMEI Number into the Enter IMEI Number
              searchbar on our site to get your Device History Report.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

function Hide_popup() {
  const Popup = document.getElementById("IMEI-Popup");
  Popup.hidden = true;
}

export default FIND_IMEI_POPUP;
