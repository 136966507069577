import React from "react";
import { Link } from "react-router-dom";

const Breadcrumb = ({ pageName, parentName, parentLink }) => {
  return (
    <div className="mb-2 flex flex-col gap-3 sm:flex-row sm:items-center sm:justify-start">
      {/* <h2 className="text-title-md2 font-semibold text-black dark:text-white">
        {parentName ? `${parentName} / ${pageName}` : pageName}
      </h2> */}

      <nav>
        <ol className="flex items-center gap-2">
          <li>
            <Link to="/">Home</Link>
            <span> / </span>
          </li>
          {parentName && (
            <li>
              <Link to={parentLink} style={{ color: "#00AEEF" }}>
                {parentName}
              </Link>
              <span> / </span>
            </li>
          )}
          <li style={{ color: "#00AEEF" }}>{pageName}</li>
        </ol>
      </nav>
    </div>
  );
};

export default Breadcrumb;