import React, {useState, useEffect} from "react";
import axios from "axios";
import DefaultLayout from "../../layout/DefaultLayout";
import CardOne from "./Cards/ScanCard";
import CardTwo from "./Cards/DetailsCards";
import CardThree from "./Cards/ProgressCard";
import CardFour from "./Cards/PrevScanCard"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Banner from "../../components/PackageBanner/Banner";
import Permissions from "../../components/Permissions/Permissions";
import "react-datepicker/dist/react-datepicker.css";
import { Form, Upload, Button, Input, Select, Space, message, Card, Tabs, DatePicker } from 'antd';
import ScanResultsTab from "./Tabs/ScanResultsTab";
import InventoryTab from "./Tabs/InventoryTab";
import { useLocation } from 'react-router-dom';
import Breadcrumb from "../Breadcrumb";
import { useSelector } from "react-redux";


const DashboardAnalytics = () => {
  const userdata = useSelector((state) => state.user.value);

  const [scanInput, setScanInput] = useState('')
  const [scannedProduct, setScannedProduct] = useState('')
  const [imeiFound, setImeiFound] = useState()
  const [alreadyScanned, setAlreadyScanned] = useState()
  const location = useLocation();
  const state = location.state;
  const {TabPane} = Tabs;
  const [activeTab, setActiveTab] = useState('tab1')
  
  const [InventoryData, setInventoryData] = useState([]);
  const [scanResults, setScanResults] = useState([]);
  const [CustomFilter,setCustomfilter] = useState([])
  let token = localStorage.getItem('token');

  const timeZone = userdata.timeZone
  const dateFormat = userdata.dateFormat

  const fetchData = async () => {
    try {
      const response = await axios.get('https://api.cellercertified.com/get-scan-results');
      // console.log(response.data.data)
      const res = response.data.data;
      const matchingTitles = res.filter(item => item.ReportTitle === state.title);
      if (matchingTitles) {
        const reverseData = matchingTitles.reverse()
        setScanResults(reverseData);
      }
    } catch (error) {
      console.error('Error fetching scan results:', error);
    }
  };

  const get_User_Reports = async () => {
    try {
      const res = await axios.get("https://api.cellercertified.com/get-purchase-order", {
        headers: { token },
      });
      // console.log(res)
      const reversedData = res.data.data.reverse();
      // console.log(reversedData)
      const matchingPO = reversedData.find(item => item.poNumber === state.poNumber);
      if (matchingPO) {
      
        // console.log("Matching PO found:", matchingPO.products);
        setInventoryData(matchingPO.products);
      } else {
        console.log("No matching PO found");
      }
      // const productsArrays = reversedData.map(purchaseOrder => purchaseOrder.products);
      // console.log(productsArrays)
     
    } catch (error) {
      console.error("Error fetching user reports:", error);
    }
  };
  // console.log("Inventory DATA found:", InventoryData);

    useEffect(() => {
      get_User_Reports();
      fetchData();
    }, []);

  const handleInputChange = (e) => {
    setScanInput(e.target.value);
  };


  const findDeviceByIMEI = (imei) => {
    return InventoryData.find(item => item.IMEI.includes(imei));
  };

  const handleSearchIMEI = async () => {
    const result = findDeviceByIMEI(scanInput);
  
    if (result) {
      console.log("Device found:", result);
      // setScannedProduct(scanInput)
      // setImeiFound(true)



      try {
      
        const previousScansResponse = await axios.get('https://api.cellercertified.com/get-scan-results');
        const previousScans = previousScansResponse.data.data;
 
        const alreadyScanned = previousScans.find(scan => scan.IMEI === scanInput && scan.ReportTitle === state.title);
   
        if (alreadyScanned) {
         
          console.log("IMEI has already been scanned");
          setScannedProduct(scanInput);
          setImeiFound(true);
          setAlreadyScanned(true);
    
         
          try {
            const now = new Date();
            const currentTime = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')} ${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}:${String(now.getSeconds()).padStart(2, '0')}`;
            console.log(currentTime); // Custom formatted local time

            const response = await axios.post('https://api.cellercertified.com/add-scan-results', {
              scannedProduct: scanInput,
              imeiFound: true,
              scan: 'Already Scanned',
              result: result,
              currentTime: currentTime,
              title: state.title,
              user: userdata.First_Name + " " + userdata.Last_Name,
            });
            console.log('Data sent to backend:', response.data);
            console.log(currentTime)
          } catch (error) {
            console.error('Error sending data to backend:', error);
            
          }
        }  else {
      try {
        const now = new Date();
        const currentTime = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')} ${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}:${String(now.getSeconds()).padStart(2, '0')}`;
        console.log(currentTime); // Custom formatted local time

        const response = await axios.post('https://api.cellercertified.com/add-scan-results', {
          scannedProduct: scanInput,
          imeiFound: true,
          result: result,
          currentTime: currentTime,
          title: state.title,
          user: userdata.First_Name + " " + userdata.Last_Name,
        });
        console.log('Data sent to backend:', response.data);
        console.log(currentTime)
        fetchData();
      } catch (error) {
        console.error('Error sending data to backend:', error);
     
      } 
    } }
    catch (error) {
      console.log('Error Sending data to backend: ', error)
    }
  }
    else {
      console.log("No device found with that IMEI");
      setScannedProduct(scanInput);
      setImeiFound(false)

      try {
        const now = new Date();
        const currentTime = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')} ${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}:${String(now.getSeconds()).padStart(2, '0')}`;
        console.log(currentTime); // Custom formatted local time

        const response = await axios.post('https://api.cellercertified.com/add-scan-results', {
          scannedProduct: scanInput,
          imeiFound: false,
          result: result,
          currentTime: currentTime,
          title: state.title,
          user: userdata.First_Name + " " + userdata.Last_Name,
        });
        console.log('Data sent to backend:', response.data);
        console.log(currentTime)
        fetchData();
      } catch (error) {
        console.error('Error sending data to backend:', error);
        
      }
    }
    // setScanInput('');
    // setImeiFound('');
    // setAlreadyScanned('');
  };
  console.log(userdata.First_Name+ " " + userdata.Last_Name)
  
  return (
    <div className="leading-6">
          <DefaultLayout >
      <ToastContainer />
      <Permissions/>
      <Banner/>

      <Breadcrumb pageName={`Scan Report / ${state.title}` }/>
      <div className="grid grid-cols-4 gap-4 md:grid-cols-4 md:gap-6 xl:grid-cols-4 leading-6 2xl:gap-7.5">
        <CardOne userdata={userdata} state={state}/>
        <CardTwo scanInput={scanInput} alreadyScanned={alreadyScanned} setScanInput={setScanInput} handleInputChange={handleInputChange} handleSearchIMEI={handleSearchIMEI} scannedProduct={scannedProduct} imeiFound={imeiFound} state={state}/>
        <CardThree InventoryData={InventoryData} userdata={userdata} state={state}/>
        <CardFour userdata={userdata} state={state}/>
      </div>

      <div className="flex min-h-screen m-2 flex-col gap-10">
      <Tabs activeKey={activeTab} onChange={key => setActiveTab(key)}>
        
        <TabPane tab="Inventory" key="tab1">
          <div><InventoryTab InventoryData={InventoryData} alreadyScanned={alreadyScanned} scanResults={scanResults} CustomFilter={CustomFilter} setCustomfilter={setCustomfilter} scannedProduct={scannedProduct} imeiFound={imeiFound}  state={state}/></div>
          </TabPane>
          <TabPane tab="Scan Results" key="tab2">
      <div><ScanResultsTab timeZone={timeZone} dateFormat={dateFormat} CustomFilter={CustomFilter} scanResults={scanResults} userdata={userdata} setCustomfilter={setCustomfilter} state={state}/></div>
          </TabPane>
        </Tabs>
      </div>
    </DefaultLayout>
    </div>
  );
};

export default DashboardAnalytics;