import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

function EDIT_SUB_USER_PERMISSIONS({Email}) {

    const [error,seterror] = useState();
    const [loading_subuser,set_loading_sub] = useState(false);
    const [dashboard , setDashboard] = useState(false);
    const [history, setHistory] = useState(false);
    const [Billing_History_Access,setBilling_History_Access]=  useState(false);
    const [Barcode_Layout_Access,setBarcode_Layout_Access] = useState(false);
    const [Check_IMEI_Access,setCheck_IMEI_Access]= useState(false);
    const [account , setAccount] = useState(false);
    const [users, setUsers] = useState(false);
    const [Bulk_Check_IMEI_Access,setBulk_Check_IMEI_Access] = useState(false);
    const [API_Access,setAPI_Access] = useState(false);
    const [Delete_Reports,setDelete_Reports] = useState(false);
    const [user,setUser] = useState(false);

    function togle_Dashboard(){setDashboard(!dashboard)}
    function togle_History(){setHistory(!history)}
    function togle_Account(){setAccount(!account)}
    function togle_Users(){setUsers(!users)}
    function togle_billing_history(){setBilling_History_Access(!Billing_History_Access)}
    function togle_Barcode_Layout_Access(){setBarcode_Layout_Access (!Barcode_Layout_Access)}
    function togle_Check_IMEI_Access(){setCheck_IMEI_Access (!Check_IMEI_Access)}
    function togle_Bulk_Check_IMEI_Access(){setBulk_Check_IMEI_Access (!Bulk_Check_IMEI_Access)}
    function togle_API_Access(){setAPI_Access (!API_Access)}
    function toggle_Delete_Reports(){setDelete_Reports(!Delete_Reports)}

    const [Updateing,setupdating] = useState(false);


    function API_Update_Permissions(){

        setupdating(true);
        var myHeaders = new Headers();
        myHeaders.append("token", localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
        "Sub_User_Email": Email,
        "First_Name": user,
        "Dashboard": dashboard,
        "History" : history,
        "Account": account,
        "Users": user,
        "Billing_History_Access": Billing_History_Access,
        "Barcode_Layout_Access": Barcode_Layout_Access,
        "Check_IMEI_Access": Check_IMEI_Access,
        "Bulk_Check_IMEI_Access": Bulk_Check_IMEI_Access,
        "API_Access": API_Access,
        "Delete_Reports": Delete_Reports
        });

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        fetch("https://api.cellercertified.com/Update_Sub_User", requestOptions)
        .then(response => response.json())
        .then(result => {
            result?.status ?  window.location.reload() : toast.error(result.msg)
        })
        .catch(error => console.log('error', error));

    }

    useEffect(() =>{
       set_loading_sub(true)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({"Email": Email});

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        fetch("https://api.cellercertified.com/get_sub_user_permissions", requestOptions)
        .then(response => response.json())
        .then(result => {
           setDashboard(result[0]?.dashboard)
           setHistory(result[0]?.history)
           setAccount(result[0]?.account)
           setUsers(result[0]?.users)
           setBilling_History_Access (result[0]?.Billing_History_Access)
           setAPI_Access (result[0]?.API_Access)
           setBarcode_Layout_Access (result[0]?.Barcode_Layout_Access)
           setBulk_Check_IMEI_Access (result[0]?.Bulk_Check_IMEI_Access)
           setCheck_IMEI_Access (result[0]?.Check_IMEI_Access)
           setUser(result[0]?.First_Name)
           setDelete_Reports(result[0]?.Delete_Reports)
           set_loading_sub(false)
        })
        .catch(error => console.log('error', error));
            },[Email] )

    function handlesubmit(){API_Update_Permissions()}
    return ( 
        <dvi>
        
        <input type="checkbox" id="my-modal3" className="modal-toggle" />
        <div className="modal ">
          <div className="modal-box bg-white dark:bg-black-2">
            <h3 className="text-lg font-bold">Update User Permissions</h3>
            <div className="flex flex-col ">
           </div>
           <table className="text-left w-full">
           <tbody >
             <div className="bg-bodydark1 rounded p-2 my-2"><th className="w-14">User</th><td className="border-0 cursor-default" >{user}</td></div>
             <div className="bg-bodydark1 rounded p-2 my-2"><th className="w-14">Email</th><td className="border-0 cursor-default" >{Email}</td></div>
           </tbody>
         </table>
           
         <div className="">
         <h1 className="py-2 text-black text-lg">Select Permissions</h1>
         <CHECK_BOX name={"Dashboard"} checked={dashboard} load={loading_subuser} f={togle_Dashboard} updt={Updateing}/>
         <CHECK_BOX name={"History"} checked={history} load={loading_subuser} f={togle_History} updt={Updateing}/>
         <CHECK_BOX name={"Check IMEI"} checked={Check_IMEI_Access} load={loading_subuser} f={togle_Check_IMEI_Access}/>
         <CHECK_BOX name={"Account"} checked={account} load={loading_subuser} f={togle_Account} updt={Updateing}/>
         <CHECK_BOX name={"Users"} checked={users} load={loading_subuser} f={togle_Users} updt={Updateing}/>
        
         {/* <CHECK_BOX name={"Barcode Layout"} checked={Barcode_Layout_Access} load={loading_subuser} f={togle_Barcode_Layout_Access} updt={Updateing}/>
         <CHECK_BOX name={"Billing History"} checked={Billing_History_Access} load={loading_subuser} f={togle_billing_history} updt={Updateing}/> */}
         <CHECK_BOX name={"Bulk Check"} checked={Bulk_Check_IMEI_Access} load={loading_subuser} f={togle_Bulk_Check_IMEI_Access} updt={Updateing}/>
         <CHECK_BOX name={"API Access"} checked={API_Access} load={loading_subuser} f={togle_API_Access} updt={Updateing}/>
         <CHECK_BOX name={"Delete Reports"} checked={Delete_Reports} load={loading_subuser} f={toggle_Delete_Reports} updt={Updateing}/>
         </div>

         <div className="flex ">
            <button disabled={Updateing}  onClick={handlesubmit}
             className='btn btn-sm my-1 bg-darkblue dark:bg-black border-0 disabled:bg-gray text-white ' >
             <div  className={`${Updateing?'block mr-1':'hidden'}`} ><CircularProgress thickness={8} size={20}/></div>
             Update Permissions</button>
            <label disabled={Updateing} htmlFor="my-modal3" className='btn btn-sm my-1 mx-2 bg-danger  dark:bg-black border-0 text-white' >Cancel</label>
            </div>
            {error&&<h1 className="text-danger">{error}</h1>}
            </div>
        </div>
              

        
        </dvi>
        
        );
}

export default EDIT_SUB_USER_PERMISSIONS;

function CHECK_BOX ({name,checked,load,f,updt}){
    return(
        <div class="mb-3 flex items-center">
        <input
        onClick={f}
        disabled={load}
        checked={checked||updt} 
         
          type="checkbox"
          class="text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600 h-4 w-4 rounded focus:ring-2"
        />
        <label
          
          class="text-gray-900 dark:text-gray-300 ml-2 text-sm font-medium"
        >
          {name}
        </label>
      </div>
    )
}