import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./index.css";
// import './satoshi.css'

import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import useReducer from "./States/User.js";
import { AuthcontextProvider } from "./Context/AuthContext";
import { ItemProvider } from "./Context/itemContext.js";

const store = configureStore({
  reducer: { user: useReducer },
});

ReactDOM.createRoot(document.getElementById("root")).render(
  <BrowserRouter basename="/">
    <Provider store={store}>
      <AuthcontextProvider>
        <ItemProvider>
        <App />
        </ItemProvider>
      </AuthcontextProvider>
    </Provider>
  </BrowserRouter>
);
