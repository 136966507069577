import { MaterialReactTable } from 'material-react-table';
import {  useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Dialog, DialogTitle, DialogActions, Button, TextField } from '@mui/material';
import {   AssessmentOutlined, CopyAll, DeleteForever, Edit,    Print,   QrCodeScannerTwoTone, Report } from '@mui/icons-material';
import ReactToPrint from "react-to-print";
import {Barcode,pageStyle} from "./Bar_Code_Template";
import { LOGO1 } from "../SVG";
import PuffLoader from "react-spinners/PuffLoader";
import { Download } from '@mui/icons-material';
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { saveAs } from "file-saver";
import { toast } from 'react-toastify';
import { NavLink } from 'react-router-dom';
const XLSX = require('xlsx');


function BulkTable({get_User_Reports, data, setIMEIHistoryData, items,setItems, setReports, setBatch_id, useBatch, setuseBatch, Batch_ID_Update}) {

    const [DataWithIndex,setDataWithIndex] = useState([])
    const [isOpen, setIsOpen] = useState(false);
    const [isBatchOpen, setBatchOpen] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [Multi_Bar_Code_Data,Set_Multi_Bar_Code_Data] = useState([])
    const [showOptions, setShowOptions] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingText, setLoadingText] = useState("Adding Internal ID");
    const [internalReferenceIds, setInternalReferenceIds] = useState([]);
    const [BatchReportID, setBatchReportID] = useState()
    const [CustomerID, setCustomerID] = useState([])
    const [BatchReportData, setBatchReportData] = useState([])
    const [batchLink, setBatchLink] = useState(false)
    let now = new Date(Date.now());
let dateString = now.toLocaleDateString(); 
    const [currentDate, setCurrentDate] = useState(dateString)


    const [Edit_State,set_Edit_State] = useState(0)    
    const [Res,SetRes]= useState("~")
    const [UID,setUid] = useState("")
    const [ID,set_ID]= useState("~")
    const dropdownRef = useRef(null);
    const ref  = useRef();

    useEffect(() => {
      function handleClickOutside(event) {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setShowOptions(false);
        }
      }
  
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);
  //   useEffect(() => {
  //     localStorage.setItem('bulkTableData', JSON.stringify({ data }));
  // }, [data]);

    useEffect(()=>{
      
      setDataWithIndex (data.map((item, index) => ({ ...item, index: index + 1 })))},[data])

    const handleExportRows = (rows) => {
      
      const mappedRows = rows.map((row) => {       
        const R = {...row._valuesCache};
        return R;        
        });

        const headerRow=columns.map((column) => column.header)
        const data = [headerRow, ...mappedRows.map((row) => Object.values(row))];

        const worksheet = XLSX.utils.aoa_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        const buffer = XLSX.write(workbook, { type: 'buffer', bookType: 'xlsx' });
        const DATE = Date.now();
         saveAs(new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }), "IMEI_BULK_CHECK_"+DATE+".xlsx" );

    };
    

    let columns = [
        { header: "#", accessorKey: "index", enableEditing: false },
        {header: "IMEI#",accessorKey: "IMEI",enableEditing: false},
        {header: "Manufacturer",accessorKey: "MANUFACTURER",enableEditing: false},
        {header: "Model Name",accessorKey: "MODEL",enableEditing: false},
        {header: "BlackList Status",accessorKey: "BLACKLIST_STATUS",enableEditing: false,},
        {header: "Lock Status",accessorKey: "ICLOUD_LOCK",enableEditing: false},
        {header: "Carrier Status",accessorKey: "SIM_LOCK_STATUS",enableEditing: false},
        {header: "Internal ID",accessorKey:"INTERNAL_REFERENCE_ID"},
        {header: "Report",accessorKey: "UID",Cell:({row}) => 
        <a className="hover:underline text-logocolor " href={`https://www.cellercertified.com/publicreport?UID=${row.original.UID}`} rel="noreferrer" target="_blank">Link</a>} ]

    
        const handleOpenModal = () => {
        
          setIsModalOpen(true);
        };
      
        const handleCloseModal = () => {
          setIsModalOpen(false);
        };


        const handleIsOpen = () => {
          setIsOpen(true);
        };
      
        const handleIsClose = () => {
          setIsOpen(false);
        };

        const handleBatchClose = () => {
          setBatchOpen(false);
        };


        async function Confirm_Edit(selectedRows) {
          set_Edit_State(1);
          // console.log("Confirm clicked");
        
          const url = "https://api.cellercertified.com/SET_IRID";
        
          try {
            const body = {
              items: selectedRows.map((selectedRow, index) => ({
                UID: selectedRow.original.UID,
                ID: internalReferenceIds[index],
              })),
            };
            // console.log(body)
            const headers = { token: localStorage.getItem("token") };
            const res = await axios.post(url, body, { headers });
            setIMEIHistoryData([])
            if (res.data.status === true) {
              set_Edit_State(2);
              SetRes(res.data.msg);
              toast.success("Internal Reference ID Updated")
            } else {
              set_Edit_State(2);
            }
          } catch (e) {
            SetRes(e.message);
          }
        }
        

        async function Confirm_Edit_All(selectedRows) {
          set_Edit_State(1);
          // console.log("Confirm clicked")
        
          const url = "https://api.cellercertified.com/SET_IRID";
        
          try {
           
            const filteredItems = selectedRows.map((item) => ({ UID: item.original.UID, ID: ID }));
            // console.log(filteredItems)
            const body = { items: filteredItems };
            
            const headers = { token: localStorage.getItem("token") };
            const res = await axios.post(url, body, { headers });
            setIMEIHistoryData([])
            if (res.data.status === true) {
              set_Edit_State(2);
              SetRes(res.data.msg);
        
              toast.success("Internal Reference ID Updated")
            } else {
              set_Edit_State(2);
            }
          } catch (e) {
            SetRes(e.message);
          }
        }
        
        

        // async function handleSearch() {
   
        //   const Items = [...items];
        //   Items.forEach((item) => {
        //     item.STATE = 1;
        //   });
        //   setItems(Items);
        //   if(useBatch){await  Batch_ID_Update();}
        //   let REPORTS = [];
          
    
        //   const apiRequests = items.map((item) => {
        //     const api = `https://api.cellercertified.com/Dashboard/Check_Imei_2/${item.IMEI}`;
        //     const Headers = { token: localStorage.getItem("token") };
        //     const body = { IID: item.IID };
        //     return axios.post(api, body, { headers: Headers });
        //   });
        
        //   try {
     
        //     const responses = await Promise.all(apiRequests);
        
        //     responses.forEach((res, index) => {
        //       const state = res.data.status;
        //       const Items = [...items];
        //       if (state) {
        //         REPORTS.push(res.data.data);
        //         Items[index].STATE = 2;
        //       } else {
        //         Items[index].STATE = 3;
        //       }
        //       setItems(Items);
        //     });
        
        //     setReports(REPORTS);
    
        //   } catch (error) {
        
        //     console.error("Error:", error);
        //   }
        // }
        

        // const handleIIDChange = (value, index) => {
        //   const dataa = items;
        //   const data_index = dataa[index];
        //   data_index.IID = value;
        //   data[index] =  data_index;
        //   setItems(data)
        // }

        // const handleAdd = async () => {
        //   setIsLoading(true); 
      
        //   try {
        //     // await handleSearch();
        //     setIsOpen(false);
            
     
        //     setTimeout(() => {
        //       if (isLoading) {
        //         setLoadingText("Almost Done!");
        //       }
        //     }, 3000);
        //   } catch (error) {
        //     console.error('Error:', error);
        //   } finally {
        //     setIsLoading(false); 
        //   }
        // };
     
     
     
        useEffect(() => {
        get_User_Reports();
        SetRes(false)
      }, [Res])
  
    //   useEffect(() => {
    //     const persistedData = JSON.parse(localStorage.getItem('bulkTableData'));
    //     console.log("perdata",persistedData.data[0])
    //     if (persistedData) {
    //         setDataWithIndex(persistedData.data[0]);
    //         // Set other states as needed...
    //     }
    // }, []);


    const createBatchReport = async (selectedRows) => {
      try {
          
          const batchReportData = {
              'BatchReportID': BatchReportID,
              'CustomerID': CustomerID,
              'Date': currentDate,
              'Data': selectedRows
          };
  
          
          const url = 'https://api.cellercertified.com/create-batchreport'; 
  
    
          const response = await axios.post(url, batchReportData);
  
          console.log('Response from server:', response.data);

          setBatchLink(true)
          // Assuming setBatchReportData is a function to update state, you can call it here if necessary
          // setBatchReportData(response.data);
      } catch (error) {
          // Handle any errors
          console.error('Error creating batch report:', error);
      }
  };
 console.log(BatchReportData)
        if (isLoading) {
          return <LOADING loadingtext={loadingText} />;
        }
        
    return ( <div>
            <div ref={ref} className="hidden print:block">      
      {Multi_Bar_Code_Data.map((item,index)=> 
      <div className={`${Multi_Bar_Code_Data.length !==index+1 ?'break-after-page':''}`}>
      <Barcode data={item.original}/></div>)}
      </div>

        <MaterialReactTable 
      data={DataWithIndex} 
      columns={columns.map(c => ({...c,size: 5,minSize: 10}))} 
      
      positionToolbarAlertBanner="bottom"
      // initialState={{density: 'compact' }}
      enableBottomToolbar={true} 
      enableColumnActions={false}      
      enableDensityToggle={false} 
      enableFullScreenToggle={false} 
      enableFilters={false} 
      enablePagination={true}
      enableRowSelection

      initialState={{
        density: 'compact',
        pagination: { pageIndex: 0, pageSize: 10 },
        // showColumnFilters: true,
        // showGlobalFilter: true 
      }}
      muiTablePaginationProps={{
        rowsPerPageOptions: [10, 25, 50, 100, 200, 250, 500],
        showFirstButton: true,
        showLastButton: true,
        SelectProps: {
          native: true
        },
        labelRowsPerPage: 'Rows per pages'
      }}
        
      muiTableBodyCellEditTextFieldProps={{variant: 'filled'}} 
      
      muiTableContainerProps={{sx:{background:'transparent',borderRadius:'8px', }}}
      muiTableProps={{sx:{padding:'',background:'transparent'}}}
      muiTablePaperProps={{sx:{background:'transparent'}}}
      muiTableBodyCellProps={({cell,column})=> ({
        
        align:'center',
        sx:{
          border:'0px', 
          borderBlockEnd:'1px solid rgba(0,0,0,0.1)',
          color:'rgba(100,100,100,1)',
          background:( 
            cell.getValue() ==="CLEAN")?'rgba(0, 255, 81,0.5)':''|| 
            ( cell.getValue() ==="BAD")?'rgba(255, 0, 25,0.4)':''|| 
            ( cell.getValue() ==="BLACKLISTED")?'rgba(255, 0, 25,0.4)':''||
            ( cell.getValue() ==="OFF")? 'rgba(0, 255, 81,0.5)': ''||
            ( cell.getValue() ==="ON")? 'rgba(255, 0, 25,0.4)': ''||
            ( cell.getValue() ==="LOCKED")? 'rgba(255, 0, 25,0.4)': ''||
            ( cell.getValue() ==="UNLOCKED")? 'rgba(0, 255, 81,0.5)': ''||
            ( cell.getValue() ==="OUT OF WARRANTY")? 'rgba(255, 0, 25,0.4)': ''||
            ( cell.getValue() ==="WARRANTY EXPIRED")? 'rgba(255, 0, 25,0.4)': ''||
            ( cell.getValue() ==="LIMITED WARRANTY")? 'rgba(244, 255, 151, 0.8)': ''||
            ( cell.getValue() ==="APPLE CARE")? 'rgba(0, 255, 81,0.5)': ''

        }
      })}
      muiTableHeadCellProps={{sx:{ background:'rgb(0 174 239)',color:'white',},align:'center'}}
      muiTableHeadRowProps={{sx:{}}}
      muiTableBodyRowProps={{sx:{background:'transparent'},hover:false}}
      muiTableBodyProps={{sx:{background:'transparent'}}}
      muiTopToolbarProps={{sx:{background:'transparent'}}}
      muiBottomToolbarProps={{sx:{background:'transparent'}}}
     
      renderTopToolbarCustomActions={({ table }) => (
        <div className='flex gap-x-3'>
        <button 
        className="px-5 py-1 disabled:text-graydark disabled:bg-bodydark1 text-bodydark1 bg-logocolor  rounded-md transition-all active:bg-black"
                disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
        onClick={()=>{handleExportRows(table.getSelectedRowModel().rows)}}
        ><Download/>Export To XLSX</button>

    <div className="dropdown" ref={dropdownRef}>
    <button
        className="px-5 py-1 disabled:text-graydark disabled:bg-bodydark1 text-bodydark1 bg-logocolor  rounded-md transition-all active:bg-black"
        disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
        onClick={handleOpenModal}
      >
        BULK ACTIONS
      </button>

      <Dialog open={isModalOpen} onClose={handleCloseModal}>
        <DialogTitle>Bulk Actions</DialogTitle>
        <DialogActions className='flex flex-col gap-y-4 justify-center items-center'>
        <Button 
        className='w-[100%]'
        variant='contained' color="primary"
        disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
        onMouseOver={()=>{Set_Multi_Bar_Code_Data(table.getSelectedRowModel().rows)}}
        >
        <ReactToPrint
        trigger={() => (<span><QrCodeScannerTwoTone /> Bulk Print Labels</span>)}
        content={()=> ref.current}
        pageStyle={pageStyle}
      />
      </Button>
      <Button 
        className="w-[100%]"
        variant='contained'
        color='primary'
        disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
        onClick={()=>{setIsOpen(table.getSelectedRowModel().rows); setIsModalOpen(false)}}
        >
        <label className="hover:cursor-pointer py-1 px-5" > <Edit/> Bulk Add Internal ID</label>
      </Button>
      <Button 
        className="w-[100%]"
        variant='contained'
        color='primary'
        disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
        onClick={()=>{setBatchOpen(table.getSelectedRowModel().rows); setIsModalOpen(false)}}
        >
        <label  htmlFor="my-modal2" className="hover:cursor-pointer py-1 px-5" > <AssessmentOutlined/> Create Batch Report</label>
      </Button>
        </DialogActions>
      </Dialog>

      <Dialog
  open={isOpen}
  onClose={handleIsClose}
>
  <DialogTitle>
    Add Internal ID
  </DialogTitle>
  <div className='px-2 '>
  <input
        // disabled={!useBatch}
        onChange={(e) => {
          set_ID(e.target.value);
        }}
        placeholder='Add Internal Reference ID to all Rows'
         className='bg-bodydark1 disabled:bg-bodydark px-2 border-none ml-4 w-65 h-10 rounded-md mr-1 text-sm'/>
    <Button autoFocus variant='contained'  onClick={() => {Confirm_Edit_All(table.getSelectedRowModel().rows); handleIsClose()}}>
      Apply to All
    </Button>
  </div>
  {/* {console.log(table.getSelectedRowModel())} */}
  {table.getSelectedRowModel().rows.map((selectedRow, index) => (
    
    <div className=" px-6 py-2" key={index}>
      <h2 className='text-sm text-black font-semibold'>{selectedRow.original.MODEL}</h2>
      <h2 className='text-sm'><span className=' text-black font-semibold'>IMEI#:</span> {selectedRow.original.IMEI}</h2>
      <input
        className="p-2 w-100 bg-white rounded-md border"
        placeholder="Enter Value"
        onChange={(e) => {
          const updatedIds = [...internalReferenceIds];
          updatedIds[index] = e.target.value;
          setInternalReferenceIds(updatedIds);
        }}
      ></input>

    </div>
  ))}
  
  <DialogActions>
        <Button autoFocus variant='contained' onClick={() => {Confirm_Edit(table.getSelectedRowModel().rows); handleIsClose()}}>
  Add
</Button>

    <Button onClick={handleIsClose}>Cancel</Button>
  </DialogActions>
</Dialog>

<Dialog
  open={isBatchOpen}
  onClose={handleIsClose}
>
  <DialogTitle>
    Create Batch Report
  </DialogTitle>

  <div className='px-6'>
    <div className='py-2'>
      <h2  className='text-sm text-black font-semibold'>Add Batch Report ID : </h2>
      <input
         className="p-2 w-100 bg-white rounded-md border"
         placeholder="Enter Value"
         onChange={(e) => {
          setBatchReportID(e.target.value)
         }}
      />
    </div>
    <div className='py-2'>
    <h2  className='text-sm text-black font-semibold'>Add Customer ID : </h2>
      <input
         className="p-2 w-100 bg-white rounded-md border"
         placeholder="Enter Value"
         onChange={(e) => {
          setCustomerID(e.target.value)
         }}
      />
    </div>
    {batchLink && ( <div className='py-4 flex gap-x-2' >
      <div className='text-[#00A651]'>Batch report created successfully!</div>
      <a className='underline text-logocolor font-semibold' href={`https://cellercertified.com/batchReport?BatchReportID=${BatchReportID}`} target="_blank" rel="noopener noreferrer">View Batch Report</a> 
    </div>) }
  </div>
  
  <DialogActions>
  
        <Button autoFocus variant='contained' onClick={() => {createBatchReport(table.getSelectedRowModel().rows)}}>
  CREATE BATCH REPORT
</Button>

    <Button onClick={() => {handleBatchClose(); setBatchLink(false)}}>Cancel</Button>
  </DialogActions>
</Dialog>


    </div>
        </div>
      )}

      />

        
      
      </div> );
}

function LOADING({ loadingtext }) {
  return (
    <div
      id="black"
      className="w-full h-full z-50 fixed  items-center justify-center flex flex-col"
      style={{ backgroundColor: "white", left: 0, top: 0 }}
    >
      <PuffLoader
        color={"skyblue"}
        loading={true}
        size={300}
        aria-label="Loading Spinner"
        data-testid="loader"
        cssOverride={{ position: "absolute" }}
      />

      <LOGO1  h={53} />
      <h1>{loadingtext}</h1>
    </div>
  );
}

export default BulkTable;