import { useEffect, useRef,useState } from "react";
import ReactToPrint from "react-to-print";
import { formatDate } from "../scripts/services";
import LABEL_1 from "./barcode_label_1";
import LABEL_2 from "./bar_code_label_2";


export const pageStyle = `
@media print {
  html, body {
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }
}
@media print {
    .onprintlyout {
     
        border: 1px solid;
        border-radius: 65px;
        background-color: #fff !important;
        print-color-adjust: exact; 
    }
    .print_portait{
      width: 192px;
      height:384px;
    }

    .print_landscape{
      height : 192px;
      width:384px;
    }
    .print_medium{
      width:216px;
      height : 120px;

    }
    .pageBreak{
      page-break-before: always;
    }
}`;

export function Barcode({data}) {
console.log(data)
  const [UID, set_UID] = useState(data?.UID);
  const [currency_symbol, set_currency_symbol] = useState("$");
  const [A_Number, set_A_Number] = useState("");
  const [layout, setlayout] = useState(localStorage.getItem('barcodeLayout') ?localStorage.getItem('barcodeLayout') : "portrait");
  const [manufacturer, setManufacturer] = useState(true);
  const [manufacturer_value, setmanufacturer_value] = useState(data?.MANUFACTURER);
  const [Model, setModel] = useState(true);
  const [Model_value, setModel_value] = useState(data?.MODEL);
  const [Carrier, setCarrier] = useState(true);
  const [Carrier_value, setCarrier_value] = useState(data?.SIM_LOCK_STATUS);
  const [Possible_Carrier_value, set_Possible_Carrier_value] = useState("");
  const [IMEI, setIMEI] = useState(true);
  const [IMEI_value, setIMEI_value] = useState(data?.IMEI);
  const [IMEI_value2, setIMEI_value2] = useState(data?.IMEI2);
  const [SerialNumber, setSerialNumber] = useState(true);
  const [SerialNumber_value, setSerialNumber_value] = useState(data?.SERIAL_NUMBER);
  const [BatteryHealth, setBatteryHealth] = useState(true);
  const [BatteryHealth_value, setBatteryHealth_value] = useState(data?.BatteryHealth);
  const [FMI, setFMI] = useState(true);
  const [FMI_value, setFMI_value] = useState(data?.ICLOUD_LOCK);
  const [Grade, setGrade] = useState(true);
  const [Grade_value, setGrade_value] = useState("~");
  const [Price, setPrice] = useState(true);
  const [Price_value, setPrice_value] = useState("~");
  const [Datee, setDate] = useState(true);
  const [Notes, setNotes] = useState(false)
  const [Notes_value, setNotes_value] = useState("~")
  const [Datee_value, setDate_value] = useState(data?.DATE);
  const [Ref_ID,setRef_ID] = useState(true);
  const [Ref_ID_value, setRef_ID_value] = useState(data?.INTERNATIONAL_REFERANCE);
  const [MDM, setMDM] = useState(false);
  const [CarrierStatus, setCarrierStatus] = useState(true);
  const [CarrierStatus_value, setCarrierStatus_value] =useState(data?.SIM_LOCK_STATUS)
  const [BlackList_Status, setBlacklist_Status] = useState(true)
  const [BlackList_Status_Value, setBlacklist_Status_Value] = useState(data?.BLACKLIST_STATUS)
  // const [Vendor, setVendor] = useState(true);
  // const [Vendor_Value, setVendor_Value] = useState(data?.PURCHASE_COUNTRY);
  const [User, setUser] = useState(true);
  const [User_Value, setUser_Value] = useState(data?.USER);
  const [Model_Description, setModel_Description] = useState(true)
  const [Model_Description_value, setModel_Description_value] = useState(data?.MODEL_DESCRIPTION)
  const [Customer_id, setCustomer_id] = useState(true);
  const [Customer_id_value, setCustomer_id_value] = useState(data?.CUSTOMER_ID);

  const ref = useRef();

  function Load_default_checks() {
    const x1 = localStorage.getItem("BatteryHealth");
    const x3 = localStorage.getItem("IMEI");
    const x2 = localStorage.getItem("Manufacturer");
    const x4 = localStorage.getItem("Model");
    const x5 = localStorage.getItem("Carrier");
    const x6 = localStorage.getItem("FMI");
    const x7 = localStorage.getItem("MDM");
    const x8 = localStorage.getItem("SerialNumber");
    const x9 = localStorage.getItem("Grade");
    const x10 = localStorage.getItem("Price");
    const x11 = localStorage.getItem("Date");
    const x12 = localStorage.getItem("RID")
    const x13 = localStorage.getItem("CarrierStatus")
    const x14 = localStorage.getItem("BlacklistStatus")
    // const x15 = localStorage.getItem("Vendor")
    const x16 = localStorage.getItem("User")
    const x17 = localStorage.getItem("ModelDescription")
    const x18 = localStorage.getItem("CustomerID")
    const x20 = localStorage.getItem("Notes");

    setBatteryHealth(x1 === "true" ? true : false);
    setManufacturer(x2 === "true" ? true : false);
    setIMEI(x3 === "true" ? true : false);
    setModel(x4 === "true" ? true : false);
    setCarrier(x5 === "true" ? true : false);
    setFMI(x6 === "true" ? true : false);
    setMDM(x7 === "true" ? true : false);
    setSerialNumber(x8 === "true" ? true : false);
    setGrade(x9 === "true" ? true : false);
    setPrice(x10 === "true" ? true : false);
    setDate(x11 === "true" ? true : false);
    setRef_ID(x12 === "true" ? true : false);
    setCarrierStatus(x13 === "true" ? true : false)
    setBlacklist_Status(x14 === "true" ? true : false)
    // setVendor(x15 === "true" ? true : false)
    setUser(x16 === "true" ? true : false)
    setModel_Description(x17 === "true" ? true : false)
    setCustomer_id(x18 === "true" ? true : false)
    setNotes(x20 === "true" ? true : false);
  }

  useEffect(()=>{

    Load_default_checks();
    set_UID(data?.UID)
    setBatteryHealth_value(data?.BATTERY_HEALTH)
    setCarrier_value(data?.SIM_LOCK_STATUS)    
    setSerialNumber_value(data?.SERIAL_NUMBER)
    setDate_value(formatDate(data?.DATE))
    setModel_value(data?.MODEL)
    setmanufacturer_value(data?.MANUFACTURER)
    setIMEI_value(data?.IMEI)
    setIMEI_value2(data?.IMEI2)
    setPrice_value(data?.PRICE)
    setNotes_value(data?.NOTES)
    setGrade_value(data?.GRADE)
    setFMI_value(data?.ICLOUD_LOCK)
    setRef_ID_value(data?.INTERNATIONAL_REFERANCE)
    setCarrierStatus_value(data?.SIM_LOCK_STATUS)
    setBlacklist_Status_Value(data?.BLACKLIST_STATUS)
    // setVendor_Value(data?.PURCHASE_COUNTRY)
    setUser_Value(data?.USER)
    setModel_Description_value(data?.MODEL_DESCRIPTION)
    setCustomer_id_value(data?.CUSTOMER_ID)
  },[data])
    return ( 
        <div>
        <div className={`col-span-5  w-fit  rounded xl:col-span-2`}>
        {/* label conainer */}
        <div
          ref={ref}
          className={`
          
            rounded-lg  border-stroke bg-white  
          dark:border-strokedark dark:bg-boxdark
          ${
            (layout === "portrait" && "w-[192px] h-[384px]") ||
            (layout === "medium" && "w-[288px] h-[384px]") ||
            (layout === "landscape" && "w-[384px] h-[192px]")
          }
          `}
        >
          {/* label print conainer */}
          <div
            className={`mb-2 onprintlyout rounded-lg border-b w-full h-full border-stroke  dark:border-strokedark relative`}
          >
            {/*Actual Label*/}

            {(layout === "portrait" || layout === "medium") && (
              <LABEL_1
              v1={manufacturer_value + " " + Model_Description_value}
              v2={IMEI_value}
              v2_2={IMEI}
              v3={Grade_value}
              v3_3={Grade}
              v4={Price_value}
              v4_4={Price}
              v5={currency_symbol}
              v6={manufacturer_value}
              v6_6={manufacturer}
              v7={SerialNumber_value}
              v7_7={SerialNumber}
              v8={Carrier_value}
              v8_8={Carrier}
              v9={BatteryHealth_value}
              v9_9={BatteryHealth}
              v10={FMI}
              v10_10={FMI_value}
              v11={MDM}              
              v12={Datee_value}
              v12_12={Datee}
              v13={UID}
              v14={Ref_ID_value}
              v14_14={Ref_ID}
              v16={BlackList_Status_Value}
              v16_16={BlackList_Status}
              // v17={Vendor_Value}
              // v17_17={Vendor}
              v18={User_Value}
              v18_18={User}
              v19={CarrierStatus_value}
              v19_19={CarrierStatus}
              v20={Customer_id_value}
              v20_20={Customer_id}
              v21={Notes_value}
              v21_21={Notes}
              />
            )}
            {layout === "landscape" && (
              <LABEL_2
                v1={
                  manufacturer_value + " " + Model_Description_value
                }
                v2={IMEI_value}
                v3={Grade_value}
                v3_3={Grade}
                v4={Price_value}
                v4_4={Price}
                v5={currency_symbol}
                v6={manufacturer_value}
                v6_6={manufacturer}
                v7={SerialNumber_value}
                v7_7={SerialNumber}
                v8={Carrier_value}
                v8_8={Carrier}
                v9={BatteryHealth_value}
                v9_9={BatteryHealth}
                v10={FMI}
                v12={Datee_value}
                v12_12={Datee}
                v13={UID}
                v14={Ref_ID_value}
                v14_14={Ref_ID}
                v16={BlackList_Status_Value}
                v16_16={BlackList_Status}
                // v17={Vendor_Value}
                // v17_17={Vendor}
                v18={User_Value}
                v18_18={User}
                v19={CarrierStatus_value}
                v19_19={CarrierStatus}
                v20={Customer_id_value}
                v20_20={Customer_id}
              />
            )}
          </div>

          
        </div>
        </div>
        </div> );
}

