import React, { useState } from "react";
import { toast } from "react-toastify";
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';


function Address_Update_Card({modalVisible, handleCloseModal,fun}) {

  
    
    const [error,seterror] = useState();
    const [loading,set_loading] = useState(false);

    const [country,setCountry] = useState('United States');
    const [region, setRegion] = useState('');
    const [ad2,setad2] = useState();
    const [city,setcity] = useState();
    // const [state,setstate] = useState();
    const [code,setcode] = useState();

    function check_fields(){
        if(!country){ seterror('Please provide Country'); return false;}
        if(!city){ seterror('Please provide City name'); return false;}
        if(!region){ seterror('Please provide State name'); return false;}
        if(!code){ seterror('Please provide Postal Code'); return false;}
        seterror()
        return true;
    }

    function handleButtonClick(){
        set_loading(true)
        if( !check_fields()){set_loading(false); return;};



        var myHeaders = new Headers();
        myHeaders.append("token", localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");
        
        var raw = JSON.stringify({
          "Country": country,
          "Address_2": ad2,
          "City": city,
          "State": region,
          "PostalCode": code
        });
        console.log(raw)
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };
        
        fetch("https://api.cellercertified.com/dashboard/Update_Address_info", requestOptions)
          .then(response => response.json())
          .then(result => {
            console.log(result)
            if(result.status) {
                set_loading(false);
                seterror()
                toast.success('Address updated')
                fun(country,ad2,region,city,code)
                setTimeout(() => {
                  // Perform the desired action after 2 seconds
                  set_loading(false);
                  // Execute the htmlFor action
                  document.getElementById('my-modal2').click();
                }, 1000);
                return;
                
            }
            set_loading(false);
            seterror('unable to update address')
            toast.error('unable to update address')
          })
          .catch(error => 
            {
                set_loading(false);
                seterror('unable to update address')
                toast.error('unable to update address')
            });
            handleCloseModal();

           

    }
    console.log(country)
    return (  

        <dvi>
        
        <input type="checkbox" id="my-modal2" className="modal-toggle" />
        <div className={`modal ${modalVisible ? 'open' : ''}`}>
          <div className="modal-box bg-white dark:bg-black-2">
            <h3 className="text-lg font-bold">Update Your Address Information</h3>
            <div className="flex flex-col ">
            <CountryDropdown disabled={loading} value={country} onChange={(value) => {setCountry(value)}} className={`bg-bodydark1 disabled:bg-gray  dark:bg-black  rounded my-2 p-1 text-lg`}/>
            <RegionDropdown className={`bg-bodydark1 disabled:bg-gray  dark:bg-black  rounded my-2 p-1 text-lg`} country={country} value={region} onChange={(value) => {setRegion(value)}}/>
            {/* <input disabled={loading} onChange={(e)=>{setstate(e.target.value)}} className={`bg-bodydark1 disabled:bg-gray dark:bg-black  rounded my-2 p-1 text-lg`} placeholder="State/Province"></input> */}
            <input disabled={loading} onChange={(e)=>{setcity(e.target.value)}} className={`bg-bodydark1 disabled:bg-gray  dark:bg-black  rounded my-2 p-1 text-lg`} placeholder="City"></input>
            <input disabled={loading} onChange={(e)=>{setad2(e.target.value)}} className={`bg-bodydark1 disabled:bg-gray  dark:bg-black  rounded my-2 p-1 text-lg`} placeholder="Address"></input>
            <input disabled={loading} onChange={(e)=>{setcode(e.target.value)}} className={`bg-bodydark1 disabled:bg-gray  dark:bg-black  rounded my-2 p-1 text-lg`} placeholder="Postal Code"></input>
            </div>
            <button  disabled={loading} onClick={handleButtonClick}
             className='btn btn-sm my-1 bg-darkblue dark:bg-black border-0 disabled:bg-gray text-white ' ><label disabled={loading} >Update Address</label></button>
            <label disabled={loading}  htmlFor="my-modal2" className='btn btn-sm my-1 mx-2 bg-danger  dark:bg-black border-0 text-white' >Cancel</label>
            {error&&<h1 className="text-danger">{error}</h1>}
            </div>
        </div>
              

        </dvi>
    );
}

export default Address_Update_Card;