import { ImCross } from "react-icons/im";
import Breadcrumb from "../components/Breadcrumb";
import Toggle from "../components/Toggle";
import { FaRegEdit } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import Banner from "../components/PackageBanner/Banner";
import DefaultLayout from "../layout/DefaultLayout";
import { useEffect, useState } from "react";
import { Modal, Card,Form, Tabs} from 'antd';
import { toast } from "react-toastify";
import TabPane from "antd/es/tabs/TabPane";
import { FiMail } from "react-icons/fi";
const Help = () => {
  const help = localStorage.getItem('help');
  const [activeTab, setActiveTab] = useState("active");
  const [inputValue, setInputValue] = useState('');
  const [token, setToken] = useState(localStorage.getItem('token') || '');
  const [issues, setIssues] = useState([])
  const [form] = Form.useForm();
  // const [activeTab, setActiveTab] = useState('tab1');



  useEffect(() => {

    fetchData();
  }, []);
  const fetchData = async () => {
    var myHeaders = new Headers();
    myHeaders.append("token", token);
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,

    };

    fetch('https://api.cellercertified.com/user-issues', requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        setIssues(result?.issues)
      })
      .catch(error => {
        console.log(error)
      });
  };

  const handleSubmit = async (values) => {
    try {
      const response = await fetch('http://api.cellercertified.com/create-issue', {
        method: 'POST',
        headers: {
          'token': token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ issue: values.issue }),
      });

      if (response.ok) {
        fetchData()
        console.log('Data successfully posted!');
      } else {
        // Handle errors
        console.error('Failed to create issue');
      }
    } catch (error) {
      console.error('Error:', error);
    }
    setInputValue('')
    form.resetFields();
    setIsModalOpen(false);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  
  const activeIssues = issues.filter((issue) => !issue.isResolved);
const clearedIssues = issues.filter((issue) => issue.isResolved);
  return (
    <div className="leading-6">
      <DefaultLayout>
        {help === 'true' ? (<>
          <Banner />
          <div className="mx-auto min-h-screen max-w-270">
            <Breadcrumb pageName="Help" />
            {/* Api Access page */}
          </div>
        </>) : (<>
          {/* <div style={{backgroundColor: "#D34053", color:"white", marginBottom:"10px"}} className="max-w-64 flex w-full justify-center">
          
        </div> */}
          <div className="flex justify-between font-semibold text-black">
            <div>
              <h1>
              Help & Message Center
              </h1>
            </div>
            <button className="rounded bg-logocolor text-white p-2 mx-2" onClick={showModal}>
              create an issue
            </button>
          </div>
          <Modal title="Enter Your Query Here" footer={null} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
          <Form
 form={form}
    onFinish={handleSubmit}   
  >
            <div className="mx-auto h-30 max-w-270">
            <Form.Item
      label="Issue"
      name="issue"
      rules={[
        {
          required: true,
          message: 'Please input your username!',
        },
      ]}
    >
              <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                placeholder="Enter data..."
                // value={inputValue}
                />
             
              </Form.Item>
              <div className="flex justify-end gap-2">
              <Form.Item>
                <button className="rounded bg-danger text-white p-2 mx-2" onClick={handleCancel} >
                  Cancel
                </button>
                </Form.Item>
                <Form.Item>
                <button className="rounded bg-logocolor text-white p-2 mx-2" type="submit">
                  Submit
                </button>
                </Form.Item>
              </div>

            </div>
            </Form>
          </Modal>
          {/* <div className="flex justify-start mt-3 mb-3">
              <button
                className={`rounded ${
                  activeTab === "active" ? "bg-logocolor" : "bg-gray-300"
                }  p-2 mx-2`}
                onClick={() => setActiveTab("active")}
              >
                Active Issues
              </button>
              <button
                className={`rounded ${
                  activeTab === "cleared" ? "bg-logocolor" : "bg-gray-300"
                }  p-2 mx-2`}
                onClick={() => setActiveTab("cleared")}
              >
                Cleared Issues
              </button>
            </div> */}
            <Tabs activeKey={activeTab} onChange={key => setActiveTab(key)}>
          <TabPane tab="Active" key="active">

          </TabPane>
          <TabPane tab="Cleared" key="cleared">

          </TabPane>
        </Tabs>
            <div className="grid grid-cols gap-2 sm:grid-cols xs:grid-cols xl:grid-cols-3 lg:grid-cols-3 md:grid-col-2">
              {(activeTab === "active" ? activeIssues : clearedIssues).map(
                (issue, index) => {
                  return <Item key={index} issue={issue} />;
                }
              )}
            </div>
          {/* <div className="grid grid-cols gap-2 mt-3 sm:grid-cols xs:grid-cols xl:grid-cols-3 lg:grid-cols-3 md:grid-col-2">
            {issues.length > 0 &&
              issues.map((issue, index) => {
                return <Item key={index} issue={issue} />
              })
            }
          </div> */}
        </>)}
      </DefaultLayout>
    </div>
  )
}

function Item({ issue, key }) {
  const navigate= useNavigate()
  const handleClick = () => {
    localStorage.setItem("issue_id",JSON.stringify(issue?._id));
    navigate('/issue-chat')
    // console.log('State before navigation:', { issue: issue });
  };

  return (
    <>
      <Card
        bordered={false}
        style={{
          width: "100%",
        }}
      >
        <p><strong>Issue:</strong>{issue.issue}</p>
        <Link to={`/issue-chat/${issue?._id}`}>
        <button  className="rounded-full hover:bg-slate-200 mx-1 mt-4"  ><FiMail /></button>
        {/* <button className="bg-darkblue hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">chat</button> */}
        
        </Link>
        {/* <Link to={{ pathname: "/issue-chat", issue: issue}} onClick={handleClick} className="flex justify-end">Chat</Link> */}
        {/* to={{ pathname: '/componentB', state: stateToPass }}  */}
        {/* {issue?.reply && <p><strong>Reply:</strong>{issue.reply ? issue.reply : 'not replied'}</p>} */}
      </Card>
    </>
  )
}
export default Help;