// import { LOGO1 } from "../SVGs";
import PuffLoader from "react-spinners/PuffLoader";
// import bg from "../images/bg.jpg";
// import { LOGO } from "../SVGs";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function Unregistered() {
  let location = useLocation();
  let navigate = useNavigate();
  const [IMEI, setIMEI] = useState();

  const [loadingtext, setloadingtext] = useState("Loading...");

  useEffect(() => {
    try {
      setIMEI(location.state.IMEI);
    } catch (e) {
      navigate("/");
    }

    const interval = setInterval(() => {
      const loading_div = document.getElementById("black");
      loading_div.classList.add("hidden");
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="leading-6">
      <LOADING loadingtext={loadingtext} />
      <div className="App h-fit ">
        {/* <img
          src={bg}
          alt="bg img"
          className="absolute h-96 w-full print:hidden"
        /> */}
        <div
          className="s:flex-row z-10 
        
        mt-28
        mb-10
        flex
        w-[90%]
        flex-col  
        items-center bg-white 
        p-10
        shadow-lg
        sm:w-[70%] sm:justify-evenly        
        md:w-[70%] lg:w-[80%] lg:flex-row lg:justify-around xl:w-[70%]"
        >
          <div
            className=" 
        h-[100%]
        "
          >
            <div
              className="
          bg-green-200
          text-green-500
          w-fit
          rounded-lg
          px-6
          py-2
          text-[25px]
          font-semibold
          "
            >
              CERTIFIED
            </div>

            <div className="mb-4 text-6xl font-semibold">
              <h1>Your History</h1>
              <h1>Report is ready!</h1>
            </div>

            <div className="bg-gray-300  m-2 w-[350px] flex-wrap rounded-lg border-[1px] p-2 text-black">
              <h1>
                Buy & Sell With Confidence For Top Dollar Remember, every Celler
                Certified Device History Report Checks For:
              </h1>
            </div>
            <div className="mb-10 mr-4">
              <ITEM text="Reported Stolen or lost?" />
              <ITEM text="Eligible to activate?" />
              <ITEM text="Locked to previous owner?" />
              <ITEM text="Locked to a carrier?" />
              <ITEM text="And More !" />
            </div>
          </div>

          {/* Right Card -> Card information */}
          <div className="border-gray-400 w-[350px] rounded-xl border-[1.5px] p-5 shadow-2xl">
            <div className="flex flex-col items-center justify-center text-center text-black">
              <h1 className="border-b-[1px] pb-4 text-2xl">
                View your Device History Report now!
              </h1>
              <h1 className="px-2  pt-4  text-3xl">
                Know the true value of this device for only
              </h1>
              <h1 className=" bg-gray-300 m-2 w-fit rounded-lg border-[1px] px-3 py-1 text-3xl font-bold">
                $1.99
              </h1>
            </div>

            <div className="flex flex-col ">
              <CUSTOM_INPUT Placeholder_txt={"Email"} />
              <CUSTOM_INPUT Placeholder_txt={"Card Number"} />
            </div>

            <div className="flex flex-col items-center justify-center text-sm">
              <h1 className="items-center px-8  text-center text-black">
                If you are selling this device we recommend{" "}
                <a
                  href="https://support.apple.com/en-us/HT201351"
                  className="font-semibold underline"
                >
                  disabling iCloud
                </a>{" "}
                first to ensure a clean report.
              </h1>

              <button
                className="
        bg-gray-300
        my-6
        self-center
        rounded-lg
        px-4
        py-2
        text-lg
        font-thin
        text-white

        "
                onClick={() => {
                  // navigate("/report", { state: { IMEI: IMEI } });

                  const url = `https://api.cellercertified.com/pay/${IMEI}`;

                  window.open(url, "_blank");
                }}
              >
                Get Device History Report
              </button>
            </div>
          </div>
        </div>
      </div>{" "}
    </div>
  );
}

function ITEM({ text }) {
  return (
    <div
      className="
    text-gray-500
    flex
    items-center
    border-t-[1px]
    p-2
    text-2xl
    font-semibold
    "
    >
      <div className="mr-4">{/* <LOGO1 w={25} /> */}</div>
      {text}
    </div>
  );
}

function CUSTOM_INPUT({ Placeholder_txt }) {
  return (
    <input
      className="
        mx-2
        my-4
        border-[1.5px]
        px-3
        py-1
        text-lg
        text-black
        shadow-inner
        "
      placeholder={Placeholder_txt}
    ></input>
  );
}

function LOADING({ loadingtext }) {
  return (
    <div
      id="black"
      className="fixed z-50 flex h-full  w-full flex-col items-center justify-center"
      style={{ backgroundColor: "white" }}
    >
      <PuffLoader
        color={"skyblue"}
        loading={true}
        size={300}
        aria-label="Loading Spinner"
        data-testid="loader"
        cssOverride={{ position: "absolute" }}
      />

      {/* <LOGO1 h={53} /> */}
      <h1>{loadingtext}</h1>
    </div>
  );
}

export default Unregistered;
