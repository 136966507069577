import React, { useEffect, useState } from "react";
import axios from "axios";

function Permissions() {

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const res = await axios.get("https://api.cellercertified.com/page_access", {
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        });

        const pkgPermissions = res.data.data.pkg_permissions; 

        // Store state values in localStorage
        localStorage.setItem("apiAccess", pkgPermissions.page_API_access);
        localStorage.setItem("barCode", pkgPermissions.page_barcode);
        localStorage.setItem("bulkCheck", pkgPermissions.page_bulk_check);
        localStorage.setItem("checkImei", pkgPermissions.page_cehck_imei);
        localStorage.setItem("customization", pkgPermissions.page_customization);
        localStorage.setItem("subUsers", pkgPermissions.page_sub_users);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, []);
  const token = localStorage.getItem("token");
  const [checks, setChecks] = useState();

  useEffect(() => {

    const userfunc = async () => {
      try {

        const res = await axios.get(
          "https://api.cellercertified.com/Package_information",
          {
            headers: { token },
          }
        );
        if (res.data) {
          const pkgPermissions = res.data; 
          localStorage.setItem("checksAvailable", pkgPermissions.Checks_Available);
        }
      } catch (error) {
        console.log(error);
      }
    };
    userfunc();
  }, [token]);

  return (
    <div>
      {/* {apiAccess || barCode || bulkCheck || checkImei || customization || subUsers ? (
        <>
          {apiAccess === "true" && <APIAccess/> }
        </>
      ) : (
        <div>Access Denied</div>
      )} */}
    </div>
  );
}

export default Permissions;
