import React, {useState, useEffect} from "react";
import DefaultLayout from "../layout/DefaultLayout";
import Breadcrumb from "../components/Breadcrumb";
import IMEIHistory from "../components/IMEIHistory";
import PurchaseOrders from "../components/Purchase_Orders"
import Banner from "../components/PackageBanner/Banner";
import { LOGO1 } from "../SVG";
import PuffLoader from "react-spinners/PuffLoader";
import axios from "axios";
import { Link } from "react-router-dom";


const IMEICheckHistory = () => {

  const [loadingtext, setloadingtext] = useState("Loading...");
  const [userPermission, setUserPermission] = useState(false)
  useEffect(() => {
  const interval = setInterval(() => {
    const loading_div = document.getElementById("black");
    loading_div.classList.add("hidden");
  }, 2000);

  return () => clearInterval(interval);
}, []);
async function Get_Sub_User_Permissions(){
  try{
      const res = await axios.get("https://api.cellercertified.com/dashboard/Sub_User_Permissions",{headers:{token:localStorage.getItem('token')}})
     
      const d =res?.data?.data;
      console.log(d[0])
      if(d===undefined) {return;}
      setUserPermission(d[0])
  }catch(e){}
  }

  useEffect(()=>{
    Get_Sub_User_Permissions()
  },[])
  return (<div className="leading-6">
        <LOADING loadingtext={loadingtext} />
    <DefaultLayout>

      <Banner/>
      <Breadcrumb pageName="Purchase Orders" pageLink="purchaseOrders" />
      { userPermission && !userPermission.history&&(
          <div className=" fixed bg-[#D34053] text-white top-16 left-0 flex w-full justify-center">
            <div className="my-2  rounded-md danger p-1.5 dark:bg-meta-4">
              You dont have permission to access this page please upgrade you plan.
            </div>
          </div>)
        }

      <div className="flex min-h-screen flex-col gap-10">
        <PurchaseOrders  Permission={userPermission} />
      </div>
    </DefaultLayout>
    </div>
  );
};

function LOADING({ loadingtext }) {
  return (
    <div
      id="black"
      className="w-full h-full z-50 fixed  items-center justify-center flex flex-col"
      style={{ backgroundColor: "white", marginTop: "-50px" }}
    >
      <PuffLoader
        color={"skyblue"}
        loading={true}
        size={300}
        aria-label="Loading Spinner"
        data-testid="loader"
        cssOverride={{ position: "absolute" }}
      />

      <LOGO1  h={53} />
      <h1>{loadingtext}</h1>
    </div>
  );
}

export default IMEICheckHistory;
