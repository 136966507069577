import axios from "axios";
import firstboxsvg from "../../../images/svgs/imei-frame-1.svg";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';


const CardFour = ({state, userdata}) => {

  const [historyData, setHistoryData] = useState([]);
  const token = localStorage.getItem(`token`);
  const [scannedProducts, setScannedProducts] = useState([]); // State to store filtered products

  useEffect(() => {
    const fetchScanResults = async () => {
      try {
        const res = await axios.get('https://api.cellercertified.com/get-scan-results');
        const data = res.data.data;

        const scanned = data.filter(scan => scan.ReportTitle === state.title);
          // console.log(scanned)

          const validScans = scanned.filter(scan => scan.IMEIFound !== "false");

          // Sort the valid scans by date (assuming scan has a date property)
          validScans.sort((a, b) => new Date(b.date) - new Date(a.date));
  
          // Get the most recent valid scan
          const mostRecentScan = validScans[0];

        setScannedProducts(mostRecentScan);
      } catch (error) {
        console.error('Error fetching scan results:', error);
      }
    };

    fetchScanResults();
  },);



  // console.log(scannedProducts)
  return (
    <div className="bg-[#F0F0F0] rounded-xl border border-[#C0C0C0] shadow-default dark:border-strokedark dark:bg-boxdark">
      <div className="flex w-full justify-between">
        <div className="w-full p-5 flex flex-col">
          <h1 className=" text-md mb-2 font-bold text-black dark:text-white">Last Scanned</h1>
          {/* <div className="my-2"><BorderLinearProgress variant="determinate" value={per} /></div> */}
          <div className="flex flex-col justify-between ">
            <div className="flex font-semibold text-black">
                {scannedProducts?.productName}
               
            </div>
            <div className="flex text-black">
            {scannedProducts?.IMEI}
                
            </div>
            <div className="flex text-black">
            {scannedProducts?.condition}
              
            </div>
            <div className="flex text-black">
            {scannedProducts?.unitPrice}
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardFour;

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 20,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 300 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
  }));