import axios from "axios";
import firstboxsvg from "../../../images/svgs/imei-frame-1.svg";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Button from '@mui/material/Button';
import { Cancel, CheckCircle, HighlightOff } from "@mui/icons-material";
import { FaBarcode } from "react-icons/fa";
import CircularProgress from '@mui/material/CircularProgress';

const CardOne = ({scanInput, setScanInput, handleInputChange, handleSearchIMEI, scannedProduct, alreadyScanned,imeiFound}) => {
  const [scanning, setScanning] = useState(false)

  const handleSearchIMEIWithDelay = async () => {
    setScanning(true);
     handleSearchIMEI();
     setTimeout(() => {
      setScanning(false);
    }, 8000); 
  };

  return (
    <div className="rounded-xl border border-[#C0C0C0] bg-[#F0F0F0] shadow-default dark:border-strokedark dark:bg-boxdark">
      <div className="flex w-full justify-between">
        <div className="w-full p-5 flex flex-col gap-y-2">
          <div className="flex w-full flex-col">
          <h1 className="text-lg text-black font-extrabold mb-1">Scan IMEI Number</h1>
          <div className="flex w-full">
          <div className="rounded-l bg-[#C0C0C0] border border-[#C0C0C0] text-white p-2"><FaBarcode className="w-5 h-5"/></div>
          <input 
              value={scanInput} 
              onChange={handleInputChange} 
              onKeyDown={(e) => {
                  if (e.key === 'Enter' && scanInput.trim()) {
                    handleSearchIMEIWithDelay();
                  }
                 }} 
              className="bg-white border border-[#C0C0C0]  px-2 w-full text-sm focus:outline-none"/>
          <button 
          onClick={handleSearchIMEIWithDelay}
          disabled={!scanInput.trim()}
        className="rounded-r bg-logocolor text-xs text-white p-2">SCAN</button>
          </div>
          </div>
 <div>
          { scanning === true ?
            <div className="flex items-center gap-x-2 font-semibold justify-center"> <CircularProgress size={20} />Scanning...</div>
          :
              imeiFound === false ? (
              <div className="bg-[#f2dede] p-3 w-58 text-sm rounded text-[#a94442] border-[#ebccd1]">
                <Cancel /> {scannedProduct} was not found.
              </div>
            ) : (
              imeiFound && scannedProduct && (
                alreadyScanned ? (
                  <div className="bg-[#fcefac] p-2 rounded w-58 text-sm text-[#8a6d3b]">
                    <CheckCircle sx={{ fontSize: 'large' }} /> {scannedProduct} has already been scanned.
                  </div>
                ) : (
                  <div className="bg-[#dff0d8] p-2 rounded w-58 text-sm text-[#3c763d]">
                    <CheckCircle sx={{ fontSize: 'large' }} /> {scannedProduct} scanned successfully.
                  </div>
                )
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardOne;
