
export function PushAddationalFields(columns){

    let new_cols = columns;


    const batteryHealth = localStorage.getItem('Battery Health');
    const CG  = localStorage.getItem('Cosmetic Grade');
    const FG = localStorage.getItem('Functional Grade');
    const G = localStorage.getItem('Grade');
    const INTERNATIONAL_REFERANCE = localStorage.getItem('Internal Reference #');
    const Notes = localStorage.getItem('Notes');
    const CID = localStorage.getItem('Customer ID'); 
    const Price = localStorage.getItem('Price');
    const Color = localStorage.getItem('Color');   
    new_cols = push_field("Battery Health","BATTERY_HEALTH",new_cols,batteryHealth)
    new_cols = push_field("Cosmetic Grade","COSMETIC_GRADE",new_cols,CG)
    new_cols = push_field("Functional Grade","FUNCTIONAL_GRADE",new_cols,FG)
    new_cols = push_field("Grade","GRADE",new_cols,G)
    new_cols = push_field("Internal Reference","INTERNAL_REFERENCE_ID",new_cols,INTERNATIONAL_REFERANCE)
    new_cols = push_field("Customer ID","CUSTOMER_ID",new_cols,CID)
    new_cols = push_field("Notes","NOTES",new_cols,Notes)    
    new_cols = push_field("Price","PRICE",new_cols,Price)
    new_cols = push_field("Color","COLOR",new_cols,Color)     

    return new_cols;;
    

}

function push_field(fieldName,key,columns,state){
    if(state==='true'){
        if( isFieldAlreadyInColumns(fieldName,columns)){return;};
        const new_col = {header:fieldName,accessorKey:key ,Cell:({row})=> {
        
            switch(key) {
                case 'BATTERY_HEALTH':return row.original.BATTERY_HEALTH!==undefined?row.original?.BATTERY_HEALTH + "%":'~'
                case 'PRICE': return  row.original.PRICE!==undefined?"$"+row.original.PRICE:'~'
                case 'COSMETIC_GRADE':return row.original.COSMETIC_GRADE
                case 'FUNCTIONAL_GRADE':return row.original.FUNCTIONAL_GRADE
                case 'GRADE':return row.original.GRADE
                case 'INTERNAL_REFERENCE_ID':return row.original.INTERNAL_REFERENCE_ID
                case 'CUSTOMER_ID':return row.original.CUSTOMER_ID
                case 'COLOR' :return row.original.COLOR
                case 'NOTES':return row.original.NOTES     
                default: return '~'
            }
           
        
        } }
        columns.push(new_col)
    }
    
        else{        
            const columnIndex = columns.findIndex((column) => column.header === fieldName);
        if (columnIndex !== -1) {columns.splice(columnIndex, 1);}}
        
        return columns;
}

function isFieldAlreadyInColumns(fieldName,columns) {
    return columns.some((column) => column.header === fieldName || column.accessorKey === fieldName);
  }