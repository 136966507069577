import {CopyAll,   Edit,   NumbersTwoTone,  Print,  } from "@mui/icons-material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Button } from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { toast } from "react-toastify";
import { copy_txt } from "../scripts/services";
import { useEffect, useRef, useState } from "react";
import ReportTemplate from "./Report_Template";
import ReactToPrint from "react-to-print";
import { pageStyle } from "./Bar_Code_Template";
import SimpleModal from "./Simple_Modal";
import EditCustomFields from "./Edit_Custom_Fields";
import IRID from "./SET_IRID";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import {Barcode} from "./Bar_Code_Template";


const ToolTip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      boxShadow: theme.shadows[1],
      fontSize: 11,
      lineHeight: '1.5rem'
    },
  }));



function Actions( data, fun_Report ) {
    const [Single_Bar_Code_Data,Set_Single_Bar_Code_Data] = useState({})
    const [report_data,set_report_data]=useState({})
    const print_ref = useRef();
    const ref2  = useRef();
    useEffect(() => {
        if(data.data?.status){set_report_data( data.data.data);}
        else {set_report_data(data.data)}
    },[data])
    console.log(data?.data)
    return (  
    <div className="leading-6">
        <div ref={print_ref} className="hidden print:block ">
        <ReportTemplate data={report_data}/>
        </div>
    <EditCustomFields data={data?.data}/>
    <IRID data={data?.data}/>
    <div>
      <input type="checkbox" id="Info" className="modal-toggle" />
      <div className={`modal z-999`}>
      <div className="modal-box dark:bg-black-2">
      <div className="flex justify-between">
        <h1 className="font-bold text-lg text-black">Quick Information Overview</h1>
        <label 
          htmlFor="Info" className='btn btn-sm border-0 rounded-full hover:bg-white bg-white text-danger' ><CancelOutlinedIcon/></label>
        </div>
        <div className="grid grid-cols-1 gap-2">
            <div className="flex">
            <h3 className="font-bold">IMEI#: <span className="font-normal">{report_data?.IMEI}</span> </h3>
            </div>
            <div className="flex">
            <h3 className="font-bold">Serial#: <span className="font-normal">{report_data?.SERIAL_NUMBER}</span> </h3>
            </div>
            <div className="flex">
            <h3 className="font-bold">Manufacturer: <span className="font-normal">{report_data?.MANUFACTURER}</span></h3>
            </div>
            <div className="flex">
            <h3 className="font-bold">Model Description: <span className="font-normal">{report_data?.MODEL_DESCRIPTION}</span></h3>
            </div>
            <div className="flex">
            <h3 className="font-bold">Model#: {
              report_data?.A_NUMBER ? <span className="font-normal">{report_data?.A_NUMBER}</span> :
              <span className="font-normal">{report_data?.MODEL_NUMBER}</span>
            }</h3>

            </div>
            <div className={`flex ${report_data?.BLACKLIST_STATUS === 'BLACKLISTED' ? 'text-danger' : 'CLEAN' ? 'text-success': ''}`}>
            <h3 className="font-bold">Blacklist Status: <span className="font-normal">{report_data?.BLACKLIST_STATUS}</span></h3>

            </div>
            {
              report_data?.MANUFACTURER?.includes('APPLE') ? 
              <div className={`flex ${report_data?.ICLOUD_LOCK === 'ON' ? 'text-danger' : 'OFF' ? 'text-success' : ''}`}>
              <h3 className="font-bold">Lock Status: <span className="font-normal">{report_data?.ICLOUD_LOCK}</span></h3>
  
              </div> : ''
            }
            <div className={`flex ${report_data?.SIM_LOCK_STATUS?.includes('LOCKED') ? 'text-danger' : 'text-success'}`}>
            <h3 className="font-bold">Carrier status: <span className="font-normal">{report_data?.SIM_LOCK_STATUS}</span></h3>

            </div>
            <div className="flex">
            <h3 className="font-bold">Warranty Status: <span className="font-normal">{report_data?.WARRANTY_STATUS}</span></h3>

            </div>
            <div className="flex">
            <h3 className="font-bold">User: <span className="font-normal">{report_data?.USER}</span></h3>

            </div>
        </div>
      </div>
      </div></div>
      <div  className="hidden print:block " ref={ref2}>
      <Barcode data={Single_Bar_Code_Data} />
      </div>
    <div className="flex flex-col  items-center">
    <div style={{lineHeight: '1.5rem'}} className="grid grid-cols-3 gap-1 md:gap-2 mt-6 md:grid-cols-7  ">

        <div className="col-span-1 w-fit "><ToolTip title="Add Internal Reference ID"><Button variant="contained" className="h-full"><label className=" p-3 absolute cursor-pointer" htmlFor="IRID_edit"><NumbersTwoTone/></label></Button></ToolTip></div>
        <div className="col-span-1 w-fit  "><ToolTip title="Copy Report URL"><Button variant="contained" onClick={()=>{copy_txt("https://www.cellercertified.com/publicreport?UID="+report_data.UID);toast.success("Link Copied")}}><CopyAll/></Button></ToolTip></div>
        <div className="col-span-1 w-fit  "><ToolTip title="Edit Custom Fields"><Button variant="contained" className="h-full"><label className=" p-3 absolute cursor-pointer" htmlFor="custom_edit"><Edit/></label></Button></ToolTip></div>
        <div className="col-span-1 w-fit "><ToolTip title="Print Report PDF"><Button variant="contained" > <ReactToPrint trigger={()=><Print/>} content={()=>print_ref.current} pageStyle={pageStyle} />  </Button></ToolTip></div>
        <div className="col-span-1 w-fit "> <ToolTip title="View Report PDF"><Button variant="contained"><SimpleModal data={report_data}/></Button></ToolTip></div>
        <div className="col-span-1 w-fit "> <ToolTip title="Information Overview"><Button variant="contained"><label className="cursor-pointer" htmlFor="Info"><InfoOutlinedIcon/></label></Button></ToolTip></div>
        <div className="col-span-1 w-fit " onPointerOver={()=>{ Set_Single_Bar_Code_Data(data?.data)}}>
        <ToolTip title="Print Barcode"><Button variant="contained" ><ReactToPrint trigger={()=><QrCodeScannerIcon/>} content={()=>ref2.current} pageStyle={pageStyle}/>  </Button></ToolTip></div>
    </div>

    </div>
    </div>);
}

export default Actions; 