import React, { useEffect, useRef, useState } from "react";
import DefaultLayout from "../layout/DefaultLayout";
import Breadcrumb from "../components/Breadcrumb";
import ReactToPrint from "react-to-print";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Banner from "../components/PackageBanner/Banner";
import LABEL_1 from "../components/receiving_label_1";
import LABEL_2 from "../components/receiving_label_2";
import { formatDate } from "../scripts/services";
import axios from "axios";
const pageStyle = `
@media print {
  html, body {
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }
}
@media print {
    .onprintlyout {
     
        border: 1px solid;
        border-radius: 65px;
        background-color: #fff !important;
        print-color-adjust: exact; 
    }
    .print_portait{
      width: 192px;
      height:384px;
    }

    .print_landscape{
      height : 192px;
      width:384px;
    }
    .print_medium{
      width:216px;
      height : 120px;

    }
    .pageBreak{
      page-break-before: always;
    }
}`;

const SelectBarcode = () => {
  const [userPermission, setUserPermission] = useState(false);
  const [currency_symbol, set_currency_symbol] = useState("$");
  const [A_Number, set_A_Number] = useState("");
  const [layout, setlayout] = useState(localStorage.getItem('barcodeLayout') ? localStorage.getItem('barcodeLayout') : "portrait");
  const [Model, setModel] = useState(false);
  const [Model_value, setModel_value] = useState("~");
  const [CarrierStatus, setCarrierStatus] = useState(false);
  const [CarrierStatus_value, setCarrierStatus_value] = useState("~");
 
  const [IMEI, setIMEI] = useState(false);
  const [IMEI_value, setIMEI_value] = useState("");
  
  const [SerialNumber, setSerialNumber] = useState(false);
  const [SerialNumber_value, setSerialNumber_value] = useState("~");
  const [Notes, setNotes] = useState(false);
  const [Notes_Value, setNotes_Value] = useState("~")
  const [Datee, setDate] = useState(false);
  const [Datee_value, setDate_value] = useState("~");

  const [User, setUser] = useState(false);
  const [User_Value, setUser_Value] = useState("~");
  const [source, setSource] =  useState(false);
  const [source_value, setSource_value] = useState("~");
  const [poNumber, setPO_number] = useState(false)
  const [poNumber_value, setPO_number_value] = useState("~")

 
  const currencyOptions = [
    { value: "$", label: "USD" },
    { value: "€", label: "EUR" },
    { value: "£", label: "GBP" },
    { value: "¥", label: "JPY" },
  ];

  function fun_set_Currency_Symbol(value) {set_currency_symbol(value);}
  
  function fun_togle_Model() {setModel(!Model);}
 
  function fun_togle_CarrierStatus() {setCarrierStatus(!CarrierStatus);}
  function fun_togle_IMEI() {setIMEI(!IMEI);}
  function fun_togle_SerialNumber() {setSerialNumber(!SerialNumber);}
  function fun_togle_Notes(){setNotes(!Notes)}

  function fun_togle_User() {setUser(!User);}
  function fun_togle_User_Value(e) {setUser_Value(e)}

  function fun_togle_Datee() {setDate(!Datee);}

  function fun_togle_Date_value(e) {setDate_value(e);}
  function fun_togle_IMEI_value(e) {setIMEI_value(e);}
  
  function fun_togle_Model_Value(e) {setModel_value(e)};
  function fun_togle_SerialNumber_Value(e) {setSerialNumber_value(e)};
  function fun_togle_Notes_Value(e) {setNotes_Value(e)};

  function fun_togle_Source() {setSource(!source)};
  function fun_togle_SouceValue(e) { setSource_value(e)};
  function fun_togle_POnumber() {setPO_number(!poNumber)}
  function fun_togle_POnumber_Value(e){setPO_number_value(e)}

  const [UID, set_UID] = useState("~");
  const [UID_Search, set_UID_Search] = useState(false);
  const ref = useRef();

  const handlePortrait = () => {setlayout("portrait");};
  const handleLandscape = () => {setlayout("landscape");};
  const handleMedium = () => {setlayout("medium");};
const handleSaveBarcodeLayout =()=>{
  localStorage.setItem('barcodeLayout',layout)
  toast.success('Barcode layout is saved.')
}
  // async function API_Search_by_id() {
  //   var myHeaders = new Headers();
  //   myHeaders.append("token", localStorage.getItem("token"));

  //   var requestOptions = {
  //     method: "GET",
  //     headers: myHeaders,
  //     redirect: "follow",
  //   };

  //   fetch(
  //     `https://api.cellercertified.com/Search_Report_By_UID?UID=${UID}`,
  //     requestOptions
  //   )
  //     .then((response) => response.json())
  //     .then((result) => {
  //       set_UID_Search(false);

  //       if (!result.status) {
  //         toast.error("No report found");
  //         return;
  //       }
  //       setDate_value(formatDate(result.data.DATE));
  //       setmanufacturer_value(result.data.MANUFACTURER);
  //       setIMEI_value(result.data.IMEI);
  //       setModel_value(result.data.MODEL);
  //       setSerialNumber_value(result.data.SERIAL_NUMBER);
  //       setCarrier_value(result.data.SIM_LOCK_STATUS ? result.data.SIM_LOCK_STATUS : "n/a");
  //       setCarrierStatus_value(result.data.SIM_LOCK_STATUS ? result.data.SIM_LOCK_STATUS : "n/a");
  //       set_Possible_Carrier_value(result.data.POSSIBLE_CARRIER ? result.data.POSSIBLE_CARRIER : "n/a");
  //       setIMEI_value2(result.data.IMEI2 ? result.data.IMEI2 : "n/a");
  //       set_A_Number(result.data.A_NUMBER ? result.data.A_NUMBER : "");
  //       toast.success("Data Found");
  //       setIMEI(result.data.IMEI);
  //       setBatteryHealth_value(result.data.BATTERY_HEALTH)
  //       setColor_Value(result.data.Color)
  //       setVendor_Value(result.data.PURCHASE_COUNTRY)
  //       setUser_Value(result.data.USER)
  //       setBlacklistStatus_Value(result.data.BLACKLIST_STATUS)
  //       setGrade_value(result.data.GRADE);
  //       setPrice_value(result.data.PRICE)
  //       setFMI_value(result.data?.ICLOUD_LOCK)
  //       setRef_ID_value(result.data?.INTERNAL_REFERENCE_ID)
  //       SET_MODEL_DESCRIPTION_Value(result.data?.MODEL_DESCRIPTION)
  //       setCustomerId_value(result.data?.CUSTOMER_ID)
  //     })
  //     .catch((error) => {
  //       set_UID_Search(false);
  //       toast.error("Network Error");
  //       console.log("error", error);
  //     });
  // }

  // useEffect(() => {
  //   Load_default_checks();
  //   const storedUID = localStorage.getItem("reportID") || "";
  //   set_UID(storedUID.trim());

  //   // Trigger the search action if the storedUID is valid
  //   if (valid_UID(storedUID)) {HandleSearch();}
  // }, []);


  function valid_UID() {
    if (UID === "") {
      return false;
    }
    if (UID.length <= 20) {
      return false;
    }
    return true;
  }

  async function HandleSearch() {
    set_UID_Search(true);
    if (!valid_UID()) {
      set_UID_Search(false);
      toast.error("unvalid UID : " + UID);
      return;
    }
    // API_Search_by_id();
  }
  function HandleUIDchange(value) {
    set_UID(value);
  }

  // function Save_defult_checks() {
  //   localStorage.setItem("BatteryHealth", BatteryHealth);
  //   localStorage.setItem("Color", Color);
  //   localStorage.setItem("Vendor", Vendor);
  //   localStorage.setItem("User", User);
  //   localStorage.setItem("BlacklistStatus", BlacklistStatus);
  //   localStorage.setItem("Manufacturer", manufacturer);
  //   localStorage.setItem("Model", Model);
  //   localStorage.setItem("Carrier", Carrier);
  //   localStorage.setItem("CarrierStatus", CarrierStatus);
  //   localStorage.setItem("IMEI", IMEI);
  //   localStorage.setItem("FMI", FMI);
  //   localStorage.setItem("MDM", MDM);
  //   localStorage.setItem("SerialNumber", SerialNumber);
  //   localStorage.setItem("Grade", Grade);
  //   localStorage.setItem("Price", Price);
  //   localStorage.setItem("Date", Datee);
  //   localStorage.setItem("RID", Ref_ID);
  //   localStorage.setItem("ModelDescription", MODELDESCRIPTION)
  //   localStorage.setItem("CustomerID", customerid);
  //   toast.success("Custom layout saved");
  // }
  // function Load_default_checks() {
  //   const x1 = localStorage.getItem("BatteryHealth");
  //   const x3 = localStorage.getItem("IMEI");
  //   const x2 = localStorage.getItem("Manufacturer");
  //   const x4 = localStorage.getItem("Model");
  //   const x5 = localStorage.getItem("Carrier");
  //   const x6 = localStorage.getItem("FMI");
  //   const x7 = localStorage.getItem("MDM");
  //   const x8 = localStorage.getItem("SerialNumber");
  //   const x9 = localStorage.getItem("Grade");
  //   const x10 = localStorage.getItem("Price");
  //   const x11 = localStorage.getItem("Date");
  //   const x12 = localStorage.getItem("RID")
  //   const x13 = localStorage.getItem("Color");
  //   const x14 = localStorage.getItem("BlacklistStatus");
  //   const x15 = localStorage.getItem("Vendor");
  //   const x16 = localStorage.getItem("User");
  //   const x17 = localStorage.getItem("CarrierStatus");
  //   const x18 = localStorage.getItem("ModelDescription")
  //   const x19 = localStorage.getItem("CustomerID")

  //   setBatteryHealth(x1 === "true" ? true : false);
  //   setManufacturer(x2 === "true" ? true : false);
  //   setIMEI(x3 === "true" ? true : false);
  //   setModel(x4 === "true" ? true : false);
  //   setCarrier(x5 === "true" ? true : false);
  //   setFMI(x6 === "true" ? true : false);
  //   setMDM(x7 === "true" ? true : false);
  //   setSerialNumber(x8 === "true" ? true : false);
  //   setGrade(x9 === "true" ? true : false);
  //   setPrice(x10 === "true" ? true : false);
  //   setDate(x11 === "true" ? true : false);
  //   setRef_ID(x12 === "true" ? true : false);
  //   setColor(x13 === "true" ? true : false);
  //   setBlacklistStatus(x14 === "true" ? true : false);
  //   setVendor(x15 === "true" ? true : false);
  //   setUser(x16 === "true" ? true : false);
  //   setCarrierStatus(x17 === "true" ? true : false);
  //   SET_MODEL_DESCRIPTION(x18 === "true" ? true : false);
  //   setCustomerId(x19 === "true" ? true : false);
  // }
  async function Get_Sub_User_Permissions(){
    try{
        const res = await axios.get("https://api.cellercertified.com/dashboard/Sub_User_Permissions",{headers:{token:localStorage.getItem('token')}})
       console.log(res)
        const d =res?.data?.data;
        console.log(d[0])
        if(d===undefined) {return;}
        setUserPermission(d[0])
    }catch(e){}
    }
  
    useEffect(()=>{
      Get_Sub_User_Permissions()
    },[])
    console.log(userPermission)

  return (
    <div className="leading-6"> 
            <DefaultLayout>
      <Banner />
      <div className="mx-auto max-w-270">
        <Breadcrumb pageName="Receiving Labels Formatting" />
        { userPermission && !userPermission.Receiving_Lables&&(
          <div className=" fixed bg-[#D34053] text-white top-16 left-0 flex w-full justify-center">
              <div className="my-2  rounded-md danger p-1.5 dark:bg-meta-4">
              You dont have permission to access this page please upgrade you plan.
            </div>
          </div>)
        }
        <h2 className="bg-white text-black drop-shadow-lg rounded-lg p-4 text-center text-xl font-semibold">You can <span className="text-logocolor font-extrabold">Label</span> items without running an <span className="text-logocolor font-extrabold">IMEI Check</span>.
        <br/> 
             <span className="text-base font-normal text-[#64748B]"> Simply add a Product Name and other details and our system will create labels for you.
              This can be useful for Receiving Purchase Orders or labeling items during different parts of your triage process.</span> </h2>
        <div className="grid min-h-screen grid-cols-6 gap-4">
          
    
          <div className="col-span-5 xl:col-span-3">
            <div className="my-3  border-stroke  shadow-default dark:border-strokedark dark:bg-boxdark">
              <div className="col-span-5 mt-5  rounded xl:col-span-2">
                <div className="my-2 rounded-lg border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
                  <div className="rounded-lg border-b border-stroke py-3 px-4 dark:border-strokedark">
                    <div className="flex items-center justify-between">
                    <h3 className="py-2  text-base font-bold	 text-black dark:text-white">
                      Select your visible label elements
                    </h3>
                    <h3 className="py-2  text-base font-bold	 text-black dark:text-white">
                      Display as Barcode (Max 3)
                    </h3>
                    </div>

                    <CHECK_BOX_ITEM2
                      Permission={userPermission}
                      currencyOptions={currencyOptions}
                      currency_symbol={currency_symbol}
                      fun_set_Currency_Symbol={fun_set_Currency_Symbol}
                      name={" Source / Vendor"}
                      fun={fun_togle_Source}
                      fun2={fun_togle_SouceValue}
                      checked={source}
                    />
                                        <CHECK_BOX_ITEM2
                      Permission={userPermission}
                      currencyOptions={currencyOptions}
                      currency_symbol={currency_symbol}
                      fun_set_Currency_Symbol={fun_set_Currency_Symbol}
                      name={" PO# / Internal Reference#"}
                      fun={fun_togle_POnumber}
                      fun2={fun_togle_POnumber_Value}
                      checked={poNumber}
                    />
 
                    <CHECK_BOX_ITEM2
                      Permission={userPermission}
                      currencyOptions={currencyOptions}
                      currency_symbol={currency_symbol}
                      fun_set_Currency_Symbol={fun_set_Currency_Symbol}
                      name={"Model"}
                      fun={fun_togle_Model}
                      fun2={fun_togle_Model_Value}
                      checked={Model}
                    />
        
                    <CHECK_BOX_ITEM2
                      Permission={userPermission}
                      currencyOptions={currencyOptions}
                      currency_symbol={currency_symbol}
                      fun_set_Currency_Symbol={fun_set_Currency_Symbol}
                      name={"IMEI / Serial Number"}
                      fun={fun_togle_IMEI}
                      fun2={fun_togle_IMEI_value}
                      checked={IMEI}
                    />
                    {/* <CHECK_BOX_ITEM2
                      Permission={userPermission}
                      currencyOptions={currencyOptions}
                      currency_symbol={currency_symbol}
                      fun_set_Currency_Symbol={fun_set_Currency_Symbol}
                      name={" Serial Number"}
                      fun={fun_togle_SerialNumber}
                      fun2={fun_togle_SerialNumber_Value}
                      checked={SerialNumber}
                    /> */}

<CHECK_BOX_ITEM2
                      currencyOptions={currencyOptions}
                      currency_symbol={currency_symbol}
                      fun_set_Currency_Symbol={fun_set_Currency_Symbol}
                      name={"Date"}
                      fun={fun_togle_Datee}
                      fun2={fun_togle_Date_value}
                      checked={Datee}
                    />
                                        <CHECK_BOX_ITEM2
                      Permission={userPermission}
                      currencyOptions={currencyOptions}
                      currency_symbol={currency_symbol}
                      fun_set_Currency_Symbol={fun_set_Currency_Symbol}
                      name={"User"}
                      fun={fun_togle_User}
                      fun2={fun_togle_User_Value}
                      checked={User}
                    />


    
                      <CHECK_BOX_ITEM2
                      Permission={userPermission}
                      currencyOptions={currencyOptions}
                      currency_symbol={currency_symbol}
                      fun_set_Currency_Symbol={fun_set_Currency_Symbol}
                      name={"Notes"}
                      fun={fun_togle_Notes}
                      fun2={fun_togle_Notes_Value}
                      checked={Notes}
                    />
                   

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
    </div>
  );
};

export default SelectBarcode;


function CHECK_BOX_ITEM2({ name, fun, fun2, checked, symbol_code, currency_symbol, fun_set_Currency_Symbol, currencyOptions, accountPermission }) {
  const [createBarcode, setCreateBarcode] = useState(false); 

  const handleOptionChange = () => {
    fun();
    if (!checked) {
      localStorage.setItem(name, "true");
    } else {
      localStorage.removeItem(name);
    }
  };

  const handleCreateBarcodeChange = (e) => {
    const isChecked = e.target.checked;
    if (isChecked) {
  
      const existingBarcodeCount = Object.keys(localStorage).filter(key => key.startsWith('create') && key.endsWith('Barcode')).length;
      if (existingBarcodeCount >= 3) {
       
        e.preventDefault();
        return;
      }
      setCreateBarcode(true);
      localStorage.setItem(`create${name}Barcode`, "true");
    } else {
      setCreateBarcode(false);
      localStorage.removeItem(`create${name}Barcode`);
    }
  };
  
  

  useEffect(() => {
    const isChecked = localStorage.getItem(name) === "true";
    if (isChecked !== checked) {
      fun();
    }
    const isBarcodeChecked = localStorage.getItem(`create${name}Barcode`) === "true";
    setCreateBarcode(isBarcodeChecked);
  }, [name, checked, fun]);

  return (
    <div className="flex w-full justify-between">
      <div className="mb-4 flex items-center whitespace-nowrap w-2/6">
        <input
          disabled={accountPermission && !accountPermission}
          checked={checked}
          id="default-checkbox"
          type="checkbox"
          value=""
          onChange={handleOptionChange}
          className="text-blue-600  bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600 h-4 w-4 rounded focus:ring-2"
        />
        <label className="text-gray-900 dark:text-gray-300 ml-2 text-sm font-medium">
          {name}
        </label>
      </div>
     
      {name === 'User' || name === 'Date' ? '' :   <div className="mb-4 flex items-center whitespace-nowrap w-2/6">
          <input
            checked={createBarcode}
            id={`create-barcode-${name}`}
            name="createBarcode"
            type="checkbox"
            value=""
            onChange={handleCreateBarcodeChange}
            className="text-blue-600  bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600 h-4 w-4 rounded focus:ring-2"
          />
          <label className="text-gray-900 dark:text-gray-300 ml-2 text-sm font-medium">
            Create  Barcode
          </label>
        </div>}
    </div>
  );
}


