import { useSelector, useDispatch } from "react-redux";

export async function verify_token() {
  const token = localStorage.getItem("token");

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    token: token,
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  fetch("https://api.cellercertified.com/verify_token", requestOptions)
    .then((response) => response.json())
    .then((result) => {
      return result.valid;
    })
    .catch((error) => {
      return false;
    });
}

export function Get_Token() {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");
  return token;
}
