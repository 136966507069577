import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    value: {
      First_Name: "",
      Last_Name: "",
      Email: "",
      Phone: "",
      Type: "",
      Sub_Package_Name: "none",
      Sub_Date: "none",
      Checks_Available: 0,
      Monthl: false,
      Pay_As_You_Go: false,
      timeZone: "",
      dateFormat: ""
    },
  },
  reducers: {
    set_user_Data: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { set_user_Data } = userSlice.actions;
export default userSlice.reducer;
