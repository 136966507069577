import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

function Banner() {

    const [monthly, setMonthly] = useState("");
    const [payAsYouGo, setPayAsYouGo] = useState("");

    useEffect(() => {
        const fetchData = async () => {
          try {
            const token = localStorage.getItem('token');
            const res = await axios.post(
              'https://api.cellercertified.com/user_details',
              {},
              {
                headers: {
                  'Content-Type': 'application/json',
                  token: token,
                },
              }
            );

            setPayAsYouGo(res.data[0].Pay_As_You_Go);
            setMonthly(res.data[0].Monthly);
          } catch (err) {
            console.log(err);
          }
        };

        fetchData();
    
      }, []);

  return (
    <div>
        {monthly === false && payAsYouGo === false && (
                  <div style={{backgroundColor: "#D34053", color:"white", marginBottom:"10px"}} className=" flex w-full justify-center">
                  <div className="my-2 inline-flex items-center justify-center rounded-md danger p-1.5 dark:bg-meta-4">
                  You need to upgrade to a Paid Plan to access all account features. To upgrade your plan  <Link to="/accBillingplan"><strong>, Click Here</strong></Link>
                  </div>
                </div>
      )}</div>
  )
}

export default Banner