import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import io from 'socket.io-client';

import UserOne from "../images/user/user-01.png";
import UserTwo from "../images/user/user-02.png";
import UserThree from "../images/user/user-03.png";
import UserFour from "../images/user/user-04.png";
import { Spin } from "antd";
import { LOGO1 } from "../SVG";
import { PuffLoader } from "react-spinners";

const DropdownMessage = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [token, setToken] = useState(localStorage.getItem('token') || '');
  const [issues, setIssues] = useState([])
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const trigger = useRef(null);
  const dropdown = useRef(null);
  useEffect(() => {

    fetchData();
  }, []);
  const fetchData = async () => {
    var myHeaders = new Headers();
    myHeaders.append("token", token);
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,

    };

    fetch('https://api.cellercertified.com/get-unread-replies', requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        setIssues(result?.issues)
      })
      .catch(error => {
        console.log(error)
      });
  };
  // socket 
  useEffect(() => {
    const socket = io('https://api.cellercertified.com');

    // socket.on('newIssue', (newIssue) => {
    //   console.log('Received newIssue event:', newIssue);
    //   setIssues((prevIssues) => {
    //     const updatedIssues = [...prevIssues, newIssue?.issueNew];
    //     return updatedIssues;
    //   });
    // });
    socket.on('issueUpdate', (updatedIssue) => {

      fetchData()
      console.log('Received issueUpdate event:', updatedIssue);
    });
    return () => {
      socket.disconnect();
    };
  }, [issues]);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  const checkResolved = () => {
    const check = issues.some((issue, index) => {
      // return !singleIssue.isResolvedFform
      return issue?.isResolved == false;
    });
    return check
  };

  const markAsUnread = (issueId) => {
    const existingIssues = [...issues]
    const remainingIssues = existingIssues.filter((iss) => {
      return iss?._id != issueId
    })
    readIssueReplies(issueId)
    setIssues(remainingIssues)
    // setIssues((prevIssues) => {
    //   const updatedIssues = prevIssues.filter((issue) => {
    //     return issue?._id != issueId
    //   });
    //   return updatedIssues;
    // });
  };
  const readIssueReplies = (issueId) => {
    setLoading(true)
    const url = `https://api.cellercertified.com/read-replies/${issueId}`
    var myHeaders = new Headers();
    myHeaders.append("token", token);
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
    };

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status == true) {
          setLoading(false)
          navigate(`/issue-chat/${issueId}`)
        }

      })
      .catch(error => {
        setLoading(false)
        navigate(`/issue-chat/${issueId}`)
        console.log("issue not read")

      });
  }
  const hasUnreadMessage = () => {
    const check = issues?.some((issue) => {
      return issue?.unreadReplies && issue.unreadReplies?.length > 0 && issue?.unreadReplies?.some((rep) => { return rep?.isReadByUser == false })
    })
    return check
  }



  return (

    
     
      <li className="relative" x-data="{ dropdownOpen: false, notifying: true }">
        <Link
          ref={trigger}
          onClick={() => setDropdownOpen(!dropdownOpen)}
          className="relative flex h-8.5 w-8.5 items-center justify-center rounded-full border-[0.5px] border-stroke bg-gray hover:text-primary dark:border-strokedark dark:bg-meta-4 dark:text-white"
          to="#"
        >
        {hasUnreadMessage() &&
          <span
          style={{ background: "#00AEEF" }}
          className="absolute -top-0.5	 -right-0.5 z-1 h-2 w-2 rounded-full"
        >
          <span
            style={{ background: "#00AEEF" }}
            className={` absolute -z-1 inline-flex h-full w-full  animate-ping    rounded-full opacity-75`}
          //  className=" absolute -z-1 inline-flex h-full w-full animate-ping rounded-full opacity-75"
          ></span>
        </span>
        }  

          <svg
            className="fill-current duration-300 ease-in-out"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.9688 1.57495H7.03135C3.43135 1.57495 0.506348 4.41558 0.506348 7.90308C0.506348 11.3906 2.75635 13.8375 8.26885 16.3125C8.40947 16.3687 8.52197 16.3968 8.6626 16.3968C8.85947 16.3968 9.02822 16.3406 9.19697 16.2281C9.47822 16.0593 9.64697 15.75 9.64697 15.4125V14.2031H10.9688C14.5688 14.2031 17.522 11.3625 17.522 7.87495C17.522 4.38745 14.5688 1.57495 10.9688 1.57495ZM10.9688 12.9937H9.3376C8.80322 12.9937 8.35322 13.4437 8.35322 13.9781V15.0187C3.6001 12.825 1.74385 10.8 1.74385 7.9312C1.74385 5.14683 4.10635 2.8687 7.03135 2.8687H10.9688C13.8657 2.8687 16.2563 5.14683 16.2563 7.9312C16.2563 10.7156 13.8657 12.9937 10.9688 12.9937Z"
              fill=""
            />
            <path
              d="M5.42812 7.28442C5.0625 7.28442 4.78125 7.56567 4.78125 7.9313C4.78125 8.29692 5.0625 8.57817 5.42812 8.57817C5.79375 8.57817 6.075 8.29692 6.075 7.9313C6.075 7.56567 5.79375 7.28442 5.42812 7.28442Z"
              fill=""
            />
            <path
              d="M9.00015 7.28442C8.63452 7.28442 8.35327 7.56567 8.35327 7.9313C8.35327 8.29692 8.63452 8.57817 9.00015 8.57817C9.33765 8.57817 9.64702 8.29692 9.64702 7.9313C9.64702 7.56567 9.33765 7.28442 9.00015 7.28442Z"
              fill=""
            />
            <path
              d="M12.5719 7.28442C12.2063 7.28442 11.925 7.56567 11.925 7.9313C11.925 8.29692 12.2063 8.57817 12.5719 8.57817C12.9375 8.57817 13.2188 8.29692 13.2188 7.9313C13.2188 7.56567 12.9094 7.28442 12.5719 7.28442Z"
              fill=""
            />
          </svg>
        </Link>

        {/* <!-- Dropdown Start --> */}
        <div
          ref={dropdown}
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
          className={`absolute -right-16 mt-2.5 flex h-90 w-75 flex-col rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark sm:right-0 sm:w-80 ${dropdownOpen === true ? "block" : "hidden"
            }`}
        >
          <div className="px-4.5 py-3">
            <h5 className="text-sm font-medium text-bodydark2">Messages</h5>
          </div>

          <ul className="flex h-auto flex-col overflow-y-auto">

            {issues.length > 0 &&
              issues.map((issue, index) => {
                return <li onClick={() => { markAsUnread(issue?._id) }}>
                  <div
                    className="flex gap-4.5 border-t border-stroke px-4.5 py-3 hover:bg-gray-2 dark:border-strokedark dark:hover:bg-meta-4 hover:cursor-pointer"

                  >


                    <div>
                      <h6 className="text-sm font-medium text-black dark:text-white">
                        Admin
                      </h6>
                      <p className="text-sm"><strong>Issue:</strong>{issue?.issue}</p>
                      {issue?.unreadReplies?.length > 0 && issue?.unreadReplies.filter((reply) => { return reply.repliedBy == 'admin' }).map((rep) => {
                        return <p className="text-sm"><strong>Message:</strong>{rep?.replyText}</p>
                      })}
                    </div>
                  </div>
                </li>
              })
            }
          </ul>
        </div>
        {/* <!-- Dropdown End --> */}
      </li>
   

  );
};

export default DropdownMessage;
