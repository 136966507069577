import axios from "axios";
import firstboxsvg from "../../../images/svgs/imei-frame-1.svg";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
const CardOne = ({state, userdata}) => {
  const [historyData, setHistoryData] = useState([]);
  const token = localStorage.getItem(`token`);
  // useEffect(() => {
  //   const userfunc = async () => {
  //     try {
  //       const res = await axios.get("https://api.cellercertified.com/All_User_reports_by_Email", {
  //         headers: { token },
  //       });
       
  //       const filteredData = filterData(res.data, filter);
       
  //       setHistoryData(filteredData);
  //       // console.log(historyData)
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  //   userfunc();
  // }, [filter]); 
  
  // const filterData = (data, selectedFilter) => {
  //   const currentDate = new Date();
  
  //   let startDate, endDate;

  //   switch (selectedFilter) {
  //     case "This Week":
  //       startDate = new Date(currentDate);
  //       startDate.setDate(currentDate.getDate() - currentDate.getDay()- 7);
  //       endDate = new Date(currentDate);
  //       endDate.setDate(currentDate.getDate()); 
  //       console.log("StartDate: ", startDate)
  //       console.log("Endata: ", endDate)
  //       break;
  //     case "This Month":
  //       startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  //       endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
  //       break;
  //     case "This Year":
  //       startDate = new Date(currentDate.getFullYear(), 0, 1);
  //       endDate = new Date(currentDate.getFullYear(), 11, 31);
  //       break;
  //       default:
  //         // Check if it's a custom date range
  //         if (selectedFilter && selectedFilter.length === 2) {
  //           startDate = selectedFilter[0];
  //           endDate = selectedFilter[1];
  //         } else {
  //           return data;
  //         }
  //   }
  
   
  //   return data.filter(item => {
  //     const date = parseDate(item.DATE); 
  //     return date >= startDate && date <= endDate;
  //   });
  // };
  
  // const parseDate = (dateString) => {
  
  //   const dateParts = dateString.split(' ');
  //   const month = dateParts[0];
  //   const day = parseInt(dateParts[1]);
  //   const year = parseInt(dateParts[2]);
  
  //   return new Date(`${month} ${day}, ${year}`);
  // };
  
 

  return (
    <div className="bg-[#F0F0F0] rounded-xl border border-[#C0C0C0] shadow-default dark:border-strokedark dark:bg-boxdark">
      <div className="flex w-full justify-between">
        <div className="w-full p-5 flex flex-col items-center">
          <h1 className=" text-lg font-bold text-black dark:text-white">Purchase Order {state.poNumber}</h1>
          <p>--from--</p>
          <h4 className=" text-md font-bold text-black dark:text-white">
            {state.vendor}
          </h4>
          <h4 className=" text-sm dark:text-white">
            Created by {userdata.First_Name} on {state.date}
          </h4>
          <h4 className=" text-sm dark:text-white">
            Expected Count : {state.expectedCount}
          </h4>
        </div>
      </div>
    </div>
  );
};

export default CardOne;
