import React, { useState, useEffect, useContext } from "react";
import { Routes, Route, redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { set_user_Data } from "./States/User";

import DashboardAnalytics from "./pages/DashboardAnalytics";
import CheckIMEI from "./pages/CheckIMEI";
import CHECKLABELS from "./pages/ReceivingLabels"
import DashboardProfile from "./pages/DashboardProfile";
import Customization from "./components/Customization";
import Billinghistory from "./pages/Billinghistory";
import BillingPlan from "./pages/BillingPlan";

import Login from "./pages/login/Login";
import Users from "./pages/Users";
import APIAccess from "./pages/APIAccess";
import SelectBarcode from "./pages/SelectBarcode";
import IMEICheckHistory from "./pages/IMEICheckHistory";
import Unregistered from "./pages/Unregistered";
import BulkIMEI from "./pages/BulkIMEI";
import BatchReports from "./pages/BatchReports"
import Table2 from "./pages/tabletest";
import UserListV2 from "./pages/User_List_v2";

import { verify_token, Get_Token } from "./Functions/Auth";
import AuthContext from "./Context/AuthContext";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserList from "./pages/UserList";
import Help from "./pages/Help";
import IssueChat from "./pages/issue-chat";
import PrintReceivingLabels from "./pages/PrintReceivingLabels";
import PurchaseOrders from "./pages/PurchaseOrders";
import AddPurchaseOrder from "./components/AddPurchaseOrder";
import PurchaseOrderDetails from "./components/PurchaseOrderDetails";
import ScanReports from "./components/ScanComponents/ScanReports";
import ScanResults from "./components/ScanComponents/ScanResults";
const App = () => {
  const { permissions } = useContext(AuthContext);

  const [token, setToken] = useState();
  const dispatch = useDispatch();
  let userdata = useSelector((state) => state.user.value);

  const [loading, setLoading] = useState(true);
  const preloader = document.getElementById("preloader");

  async function API_Update_Checks(email) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      Email: email,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://api.cellercertified.com/update_no_of_checks", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          toast.success("New Checks added successfully");
        } else {
          toast.info("Account up to date");
        }
      })
      .catch((error) => console.log("error", error));
  }

  if (preloader) {
    setTimeout(() => {
      preloader.style.display = "none";
      setLoading(false);
    }, 100);
  }

  useEffect(() => {
    let token = Get_Token();

    if (!token) {
      console.log("no token in url");
      token = localStorage.getItem("token");
    } else {
      localStorage.setItem("token", token);
    }
    setToken(token);
    if (!token) {
      window.location.href = "https://www.cellercertified.com/login";

      return;
    } else {
    }

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("token", token);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch("https://api.cellercertified.com/user_details", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        dispatch(
          set_user_Data({
            First_Name: result[0].First_Name,
            Last_Name: result[0].Last_Name,
            Email: result[0].Email,
            Phone: result[0].Phone,
            timeZone: result[0].timeZone,
            dateFormat: result[0].dateFormat,
            Type: result[0].Type,
            Sub_Package_Name: result[0].Sub_Package_Name,
            Sub_Date: result[0].Sub_Date,
            Checks_Available: result[0].Checks_Available,
            Monthl: result[0].Monthl,
            Pay_As_You_Go: result[0].Pay_As_You_Go,
          })
        );
        API_Update_Checks(result[0].Email);
      })
      .catch((error) => console.log("error", error));

    setTimeout(() => {
      setLoading(false);
    }, 100);
  }, []);

  return (
    !loading && (
      <>
        <ToastContainer />
        <Routes>
          {token ? (
            <>
              <Route exact path="/" element={<DashboardAnalytics />} />
              <Route path="/imeicheckhistory" element={<IMEICheckHistory />} />
              {/* {permissions.AddNewUsers && ( */}
              <Route path="/users" element={<Users />} />
              <Route path="/userList" element={<UserListV2 />} />
              {/* )} */}
              <Route path="/unreg" element={<Unregistered />} />
              <Route
                path="/accDshboardProfile"
                element={<DashboardProfile />}
              />
              <Route path="/accBillingplan" element={<BillingPlan />} />
              {/* {permissions.billingHistory && ( */}
              <Route path="/accBillinghistory" element={<Table2 />} />
              {/* )} */}
              {/* {permissions.barcodeLayout && ( */}
              <Route path="/accBarcodelayout" element={<SelectBarcode />} />
              {/* )} */}
              <Route path="/accCustomization" element={<Customization />} />
              {/* {permissions.ApiAccess && ( */}
              <Route path="/apiAccess" element={<APIAccess />} />
                {/* {permissions.Help && ( */}
                <Route path="/help" element={<Help />} />
           {/* issue chat  */}
           <Route path="/issue-chat/:issueId" element={<IssueChat />} />
              {/* )} */}
              {/* {permissions.bulkCheckIMEI && ( */}
              <Route path="/bulk" element={<BulkIMEI />} />
              <Route path="/batch-reports" element={<BatchReports />} />
              {/* )} */}
              {/* {permissions.checkIMEI && ( */}
              <Route path="/checkdevice" element={<CheckIMEI />} />
              {/* )} */}
              {/* {permissions.checkIMEI && ( */}
              <Route path="/purchase-orders" element={<PurchaseOrders/>} />
              <Route path="/scanReports" element={<ScanReports/>} />
              <Route path="/scanResults" element={<ScanResults/>} />
              <Route path="/purchase-orders/addPO" element={<AddPurchaseOrder/>}/>
              <Route path="poDetails/:poNumber" element={<PurchaseOrderDetails/>}/>
              <Route path="/PrintLabels" element={<PrintReceivingLabels/>} />
              <Route path="/receivinglabels" element={<CHECKLABELS />} />
              {/* )} */}
              <Route path="/login" element={<Login />} />
            </>
          ) : (
            window.redirect("https://www.cellercertified.com/login")
          )}
        </Routes>
      </>
    )
  );
};

export default App;
