import { useState, useEffect } from "react";
import { createContext } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
const AuthContext = createContext();
// const permisn = {
//   barcodeLayout: false,
//   ApiAccess: false,
//   AddNewUsers: false,
//   bulkCheckIMEI: false,
//   checkIMEI: false,
//   billingHistory: false,
// };
// setEmail(userdata.Email);
// const permisn = {
//   barcodeLayout: true,
//   ApiAccess: true,
//   AddNewUsers: true,
//   bulkCheckIMEI: true,
//   checkIMEI: true,
//   billingHistory: true,
// };
const AuthcontextProvider = (props) => {
  let userdata = useSelector((state) => state.user.value);
  const [permissions, setPermissions] = useState(``);
  const [isSubUser, setisSubUser] = useState(false);
  const [Email, setEmail] = useState(userdata.Email);

  useEffect(() => {
    async function subUserCheck() {
      const isSubUserRes = await axios.post(
        "https://api.cellercertified.com/is_sub_user",
        {
          Email,
        }
      );
      setisSubUser(isSubUserRes.data);
    }
    subUserCheck();
  }, [Email]);

  async function getPermissions() {
    if (isSubUser.sub_user) {
      const permissionsRes = await axios.post(
        "https://api.cellercertified.com/get_sub_user_permissions",
        {
          Email,
        }
      );
      setPermissions(permissionsRes.data);
    }
  }
  useEffect(() => {
    getPermissions();
  }, []);
  return (
    <AuthContext.Provider
      value={{
        permissions: permissions,
        getLoggedIn: getPermissions,
        isSubUser: isSubUser,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
export default AuthContext;
export { AuthcontextProvider };
