import logo_3 from "../images/Logo_3.png";
function BUYBACKVALUE({ v, s1, s2, s3 }) {
  return (
    <div className="relative rounded-lg my-10 p-5 px-5  sm:px-10 shadow-xl">

    <div 
    style={{backgroundColor:'rgba(0,0,0,0.5)'}}
    className="
    rounded-lg
    absolute inset-0 flex justify-center items-center bg-gray-400/50 z-10">
    <h1 className="absolute text-gray-600 font-extrabold text-3xl -rotate-45 z-20 ">COMING SOON!</h1>
    </div>

      <div className="flex  items-center">
        <img src={logo_3} alt={"logo_3"} className="mr-3" />
        <h1 className="text-black font-bold text-2xl  ">BuyBack Value</h1>
      </div>

      <div className="relative z-0 flex items-center justify-center mt-5">
        <div className="w-11/12 h-3 bg-slate-200 rounded-2xl z-0 "></div>
        <div className=" w-11/12 h-2 absolute flex justify-around -top-1">
          <div
            className={`w-5 h-5 rounded-2xl shadow hover:cursor-pointer ${
              v === 1 ? "bg-sky-400 " : "bg-slate-300"
            }  z-10`}
            onClick={() => s1()}
          ></div>
          <div
            className={`w-5 h-5 rounded-2xl  z-10 shadow  hover:cursor-pointer ${
              v === 2 ? "bg-sky-400 " : "bg-slate-300"
            }`}
            onClick={() => s2()}
          ></div>
          <div
            className={`w-5 h-5 rounded-2xl  z-10 shadow hover:cursor-pointer ${
              v === 3 ? "bg-sky-400 " : "bg-slate-300"
            }`}
            onClick={() => s3()}
          ></div>
        </div>
      </div>

      <div
        className={`flex justify-evenly text-gray-400 font-semibold text-sm text-center p-2`}
      >
        <div className={`${v === 1 ? "text-sky-400" : ""}`}>Non-Working $3</div>
        <div className={`${v === 2 ? "text-sky-400" : ""}`}>Used, Working $20</div>
        <div className={`${v === 3 ? "text-sky-400" : ""}`}>New, Sealed $100</div>
      </div>
      <div className="flex items-center justify-center">
        <button className="w-11/12 my-3 h-16 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded shadow-xl ">
          <div className="text-xl">SELL YOURS NOW</div>
        </button>
      </div>
      <div className="text-black text-center" style={{ fontSize: 10 }}>
        * without running a functional diagnostic on your device, we cannot
        determine the actual condition so we are displaying a range you can
        expect to receive from a buyback company
      </div>
    </div>
  );
}

export default BUYBACKVALUE;
