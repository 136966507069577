import { MaterialReactTable } from "material-react-table";
import DefaultLayout from "../layout/DefaultLayout";
import Breadcrumb from "../components/Breadcrumb";
import {  Box,} from '@mui/material';
import {  Delete, Edit,  } from '@mui/icons-material';
import axios from "axios";
import { useEffect, useState } from "react";
import { CROSS, Check } from "../SVG";
import EDIT_SUB_USER_PERMISSIONS from "../components/edit_user_card"
import DeleteSubUser from "../components/Popups/Delete_Sub_User";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { FaStar } from "react-icons/fa";

function UserListV2() {

  const userdata = useSelector((state) => state.user.value);
    let [data,setdata] =useState([]);
    const [Email,setEmail] = useState()
    const [userPermission, setUserPermission] = useState(false);
    const [headUser, setHeadUser] = useState(() => {
      // Try to retrieve initialUserData from localStorage
      const storedUserData = localStorage.getItem('userdata');
      return storedUserData ? JSON.parse(storedUserData) : null;
    });
    let columns = [
        {header: "Name",accessorKey: "First_Name"},
        {header: "Email",accessorKey:"Email"},
        {header: "User Type",accessorKey:"Sub_User", 
        Cell: ({ row }) => (
          <div>
            {row.original.Sub_User ? "Sub User" : " Parent/Head User"}
          </div>
        ),  
      },
        {header: "Permissions",  Cell: ({row}) => 
        <Box sx={{alignItems:'end', textAlign:'start'}}>
          {
            row.original.Sub_User ?
            <ul>  
            {<li className={`${row.original.dashboard?'text-meta-3 ':'text-meta-1' } border-b border-bodydark1 flex justify-between`}>
              Dashboard
              {!row.original.dashboard?<CROSS size={15} color={'red'}/>:<Check size={15} color={'green'}/>}
              </li>}
              {<li className={`${row.original.history?'text-meta-3 ':'text-meta-1' } border-b border-bodydark1 flex justify-between`}>
              History
              {!row.original.history?<CROSS size={15} color={'red'}/>:<Check size={15} color={'green'}/>}
              </li>}
              {<li className={`${row.original.Check_IMEI_Access?'text-meta-3 ':'text-meta-1' } border-b border-bodydark1 flex justify-between`}>
              Check IMEI
              {!row.original.Check_IMEI_Access?<CROSS size={15} color={'red'}/>:<Check size={15} color={'green'}/>}
              </li>}
              {<li className={`${row.original.Receiving_Lables?'text-meta-3 ':'text-meta-1' } border-b border-bodydark1 flex justify-between`}>
              Receiving Labels
              {!row.original.Receiving_Lables?<CROSS size={15} color={'red'}/>:<Check size={15} color={'green'}/>}
              </li>}
              {<li className={`${row.original.account?'text-meta-3 ':'text-meta-1' } border-b border-bodydark1 flex justify-between`}>
              Account
              {!row.original.account?<CROSS size={15} color={'red'}/>:<Check size={15} color={'green'}/>}
              </li>}

                        
              {<li className={`${row.original.users?'text-meta-3 ':'text-meta-1' } border-b border-bodydark1 flex justify-between`}>
              Users
              {!row.original.users?<CROSS size={15} color={'red'}/>:<Check size={15} color={'green'}/>}
              </li>}
{/* 
              {<li className={`${row.original.Billing_History_Access?'text-meta-3 ':'text-meta-1' } border-b border-bodydark1 flex justify-between`}>
              Billing History Access
              {!row.original.Billing_History_Access?<CROSS size={15} color={'red'}/>:<Check size={15} color={'green'}/>}
              </li>} */}

              {<li className={`${row.original.Bulk_Check_IMEI_Access?'text-meta-3 ':'text-meta-1' } border-b border-bodydark1 flex justify-between`}>
              Bulk IMEI
              {!row.original.Bulk_Check_IMEI_Access?<CROSS size={15} color={'red'}/>:<Check size={15} color={'green'}/>}
              </li>}

              {<li className={`${row.original.API_Access?'text-meta-3 ':'text-meta-1' } border-b border-bodydark1 flex justify-between`}>
              API Access
              {!row.original.API_Access?<CROSS size={15} color={'red'}/>:<Check size={15} color={'green'}/>}
              </li>}

              {<li className={`${row.original.Delete_Reports?'text-meta-3 ':'text-meta-1' } border-b border-bodydark1 flex justify-between`}>
              Delete Reports
              {!row.original.Delete_Reports?<CROSS size={15} color={'red'}/>:<Check size={15} color={'green'}/>}
              </li>}

             
            </ul> :
            <p className="flex justify-between text-meta-3">
              Access to all pages <Check size={15} color={'green'}/>
            </p>
          }
        </Box>
          ,},
        ];

 async function Get_Sub_Users(){
    try{
        const res = await axios.get("https://api.cellercertified.com/dashboard/All_SUB_USERS",{headers:{token:localStorage.getItem('token')}})
        const d =res?.data?.data
        if(d===undefined) {return;}
        setdata([headUser, ...d])
    }catch(e){}
    }

    async function Get_Sub_User_Permissions(){
        try{
            const res = await axios.get("https://api.cellercertified.com/dashboard/Sub_User_Permissions",{headers:{token:localStorage.getItem('token')}})
           
            const d =res?.data?.data;
            console.log(d[0])
            if(d===undefined) {return;}
            setUserPermission(d[0].users)
        }catch(e){}
        }

 console.log(data)
useEffect(()=>{
  if (userdata.Email !== '') {
    localStorage.setItem('userdata', JSON.stringify(userdata));
  }
    Get_Sub_Users();
    Get_Sub_User_Permissions();
},[]);

    return (
        <div className="leading-6">
                  <DefaultLayout>
        <Breadcrumb pageName="Users"/>
        { userPermission && !userPermission&&(
          <div className=" fixed bg-[#D34053] text-white top-16 left-0 flex w-full justify-center">
            <div className="my-2  rounded-md danger p-1.5 dark:bg-meta-4">
              You dont have permission to access this page please upgrade you plan.  
              {/* <Link to="/accBillingplan"><strong>. Click Here</strong></Link> */}
            </div>
          </div>)
        }
        <div className=" lg:px-12 py-5 mb-20">
        <DeleteSubUser Email={Email}/>
        <EDIT_SUB_USER_PERMISSIONS Email={Email}/>
        <div className=" bg-white dark:bg-body rounded-md ">
        <MaterialReactTable data={userPermission && !userPermission ? '' : data} columns={columns}                
            
            positionToolbarAlertBanner="bottom"
            initialState={{density: 'compact' }}
            enableBottomToolbar={false} 
            enableColumnActions={false}
            enableTopToolbar={false}
            enableRowSelection={false}
            enableRowActions
                
            muiTableBodyCellEditTextFieldProps={{variant: 'filled'}} 
            
            muiTableContainerProps={{sx:{background:'transparent',borderRadius:'8px', }}}
            muiTableProps={{sx:{padding:'',background:'transparent'}}}
            muiTablePaperProps={{sx:{background:'transparent'}}}
            muiTableHeadCellProps={{sx:{ background:'rgb(0 174 239)',color:'white',},align:'center'}}
            muiTableHeadRowProps={{sx:{}}}
            muiTableBodyRowProps={{sx:{background:'transparent'},hover:false}}
            muiTableBodyProps={{sx:{background:'transparent'}}}
            muiTopToolbarProps={{sx:{background:'transparent'}}}
            muiBottomToolbarProps={{sx:{background:'transparent'}}}
            muiTableBodyCellProps={({cell,column})=> ({align:'center',})}

            renderRowActions={({ row, table }) => (
                <span>
                  {row.original.Sub_User ? 
                    <>
                                          
                    <label 
                    onMouseEnter={() => {setEmail(row.original.Email)}}
                    htmlFor="delete_sub_user_modal" 
                    className="p-1 py-2 hover:bg-[rgba(0,0,0,0.1)] hover:cursor-pointer rounded-full">
                    <Delete/>
                    </label>
    
    
                    <label 
                    onMouseEnter={() => {setEmail(row.original.Email)}}
                    htmlFor="my-modal3" 
                    className="p-1 py-2 hover:bg-[rgba(0,0,0,0.1)] hover:cursor-pointer rounded-full">
                    <Edit/>
                    </label> 
                    </>
                    :
                    <span>
                      <label className="flex justify-center items-center text-meta-3">
                        <FaStar/>
                      </label>
                    </span>
                  }                
            </span>
            )}
        />
        
        </div>
        </div>
        </DefaultLayout>
        </div>
     );
}

export default UserListV2;