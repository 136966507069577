import { useEffect, useState, useRef } from "react";
import DefaultLayout from "../layout/DefaultLayout";
import { Spin } from 'antd';
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import io from 'socket.io-client';
const IssueChat = () => {
  const { issueId } = useParams()
  // const [issueId,setIssueID]=useState(JSON.parse(localStorage.getItem('issue_id')) || "")
  const [issue, setIssue] = useState({})
  const [token, setToken] = useState(localStorage.getItem('token') || '');
  const [replyText, setReplyText] = useState('');
  const [replies, setReplies] = useState([])
  const [loading, setLoading] = useState(false)

  const messagesContainerRef  = useRef(null)

  useEffect(() => {
    const socket = io('https://api.cellercertified.com');
    socket.on('issueUpdate', (updatedIssue) => {
      if (issue?._id == updatedIssue?._id) {
        // setIssue(updatedIssue)
        setReplies(updatedIssue?.replies)
      }
    });
    return () => {
      socket.disconnect();
    };
  }, [issue]);
  const reply = () => {

    const url = `https://api.cellercertified.com/add-reply/${issueId}`
    var myHeaders = new Headers();
    myHeaders.append("token", token);
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: JSON.stringify({ replyText: replyText })
    };

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(result => {
        setReplyText("");
        if (result.status == true) {
          toast.success('message sent')
        }
      })
      .catch(error => {
        console.log(error)
      });
  }
  useEffect(() => {
    getIssue()
    readIssueReplies()
  }, [issueId]);
  const getIssue = () => {
    setLoading(true);
    const url = `https://api.cellercertified.com/issue/${issueId}`
    var myHeaders = new Headers();
    myHeaders.append("token", token);
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
    };

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status == true) {
          setIssue(result?.issue)
          setReplies(result?.issue?.replies)
        }
        setLoading(false);
      })
      .catch(error => {
        console.log(error)
        setLoading(false);
      });
  }
  const readIssueReplies = () => {
    setLoading(true)
    const url = `https://api.cellercertified.com/read-replies/${issueId}`
    var myHeaders = new Headers();
    myHeaders.append("token", token);
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
    };

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status == true) {
          console.log("issue read")
        }
        setLoading(false)
      })
      .catch(error => {
        console.log("issue not read")
        setLoading(false)
      });
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      reply();
    }
  };
  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }
  };

  // Call scrollToBottom when component mounts and whenever new messages are added
  useEffect(() => {
    scrollToBottom();
  }, [replies]);
  
  return (
    <div className="leading-6">
          <DefaultLayout>
      {/* <p className="bg-logocolor">Received State: {passedState && passedState.key}</p> */}
      {loading ? <div className="flex justify-center">
        <Spin size="large" />

      </div> :
        <div>
          {
            issue?.isResolved == true ?
              <div class="w-full max-w-xl p-4 mb-2 bg-success text-white shadow-md rounded-lg mx-auto ">
                <strong>Issue:</strong><span>{issue?.issue}</span>
                <div className="flex justify-center font-bold">Issue is resolved</div>

              </div> :
              <div class="w-full max-w-xl p-4 mb-2 bg-white shadow-md rounded-lg mx-auto ">
                <strong>Issue:</strong><span>{issue?.issue}</span>
                <div className="flex justify-center font-bold">Issue is not resolved</div>

              </div>
          }

          <div class="flex flex-col items-center justify-center   bg-gray-100 text-gray-800 p-4 h-[65vh]">

            {/* <!-- Component Start --> */}
            <div class="flex flex-col  flex-grow w-full max-w-xl bg-white shadow-xl rounded-lg h-[65vh] overflow-hidden">
              <div ref={messagesContainerRef} class="flex flex-col flex-grow  max-w-2xl  p-2 overflow-auto h-[65vh]">
                {replies?.length > 0 ? replies.map((reply) => {
                  return reply?.repliedBy == 'admin' ?
                    <div class="flex w-full mt-2 space-x-3 max-w-xs">
                      <div class="flex-shrink-0 h-10 w-10 rounded-full bg-[#EFF4FB] flex items-center justify-center">
                        <img src="/logo192.png" alt="User Image" class="rounded-full h-6 w-6 object-cover" />
                      </div>
                      <div>
                        <div class="bg-[#EFF4FB] text-gray-500 p-3 rounded-r-lg rounded-bl-lg">
                          <p class="text-sm">{reply?.replyText}</p>
                        </div>
                        <span class="text-[10px] text-gray-500 leading-none">{reply?.created_at}</span>
                      </div>
                    </div>
                    :
                    <div class="flex w-full mt-2 space-x-3 max-w-xs ml-auto justify-end">
                      <div >
                        <div class="bg-[#00b7ff] text-white p-3 rounded-l-lg rounded-br-lg">
                          <p class="text-sm">{reply?.replyText}</p>
                        </div>
                        <span class="text-[10px] text-gray-500 leading-none">{reply?.created_at}</span>
                      </div>
                      <div class="flex-shrink-0 h-10 w-10 rounded-full bg-[#00b7ff] flex items-center justify-center">
                        {issue?.username ? issue.username[0] : ''}
                      </div>
                    </div>


                })
                  : 'no replies'}



              </div>

              {issue?.isResolved == true ? ""
                :
                <div class="bg-[#00b7ff] p-4 relative">

                  <input onKeyDown={handleKeyDown} onChange={(e) => setReplyText(e.target.value)} value={replyText} class="flex items-center h-10 w-full rounded px-3 text-sm" type="text" placeholder="Type your message…" />

                  <span className="absolute right-6 top-6 cursor-pointer">
                    <svg onClick={reply} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5" />
                    </svg>
                  </span>

                </div>}

            </div>
            {/* <!-- Component End  --> */}

          </div>
        </div>}

    </DefaultLayout>
    </div>
  )
}
export default IssueChat;