import { CircularProgress } from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";

function DeleteSubUser({Email}) {
    const [deleteing,setdeleting] = useState(false);
    async function API_Delte_Sub_User(){
        setdeleting(true);
        var myHeaders = new Headers();
        myHeaders.append("token", localStorage.getItem('token'));
        myHeaders.append("Content-Type", "application/json");
        
        var raw = JSON.stringify({
          "Delete_SubUser_Email": Email,
        });
        
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };
        
        fetch("https://api.cellercertified.com/dashboard/Delete_Subuser", requestOptions)
          .then(response => response.json())
          .then(result => {
            setdeleting(false);
            if(result.status) {
              toast.success('subuser deleted successfully');
              window.location.reload()
            }
          })
          .catch(error =>
            {
              setdeleting(false);
              toast.error('unable to delete sub user');
            });
      }

    return (
        <div>
        <input type="checkbox" id="delete_sub_user_modal" className="modal-toggle" />
        <div className="modal ">
          <div className="modal-box bg-white dark:bg-black-2">

          <h2 className='font-bold'>Attention !</h2>
          <p className='my-2'>Are you sure you want to delete user <strong>{Email}</strong></p>
          
          <div className="flex">

          <button 
          disabled={deleteing}
          className={'btn btn-sm my-1 mx-2 bg-danger text-white font-bold py-1 px-2 '} 
           onClick={()=>{API_Delte_Sub_User()}}>
           <div className={`${deleteing?'block mx-2':'hidden'}`}><CircularProgress size={20} thickness={8}/></div>
           Delete
           </button>

          <label 
          disabled={deleteing}
          htmlFor="delete_sub_user_modal" className={'btn btn-sm my-1 mx-2   bg-darkblue text-white font-bold py-1 px-2 '} >
          Cancel</label>

        </div>
      

          </div>
          </div>
        </div>
      );
}

export default DeleteSubUser;