import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

const CardUpdateForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);


  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("hello awaz a rahi hai");

    setLoading(true);


    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      
    });

    setLoading(false);

    if (error) {
      setError(error.message);
    } else {

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({"PM":paymentMethod.id});
    myHeaders.append("token", localStorage.getItem("token"));
        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        fetch("https://api.cellercertified.com/dashboard/Update_Card_info", requestOptions)
        .then(response => response.json())
        .then(result =>{
        console.log(result)
        if(result.status)
        {
            setError(null);
            toast.success('Card information updated ')
            setTimeout(() => {
              // Perform the desired action after 2 seconds
              setLoading(false);
              // Execute the htmlFor action
              document.getElementById('my-modal').click();
            }, 1000);
        }
        else{
        setError('error updating card information')
        toast.error(result.msg)}
        })
        .catch(error => 
            {
                setError('error updating card information')
                toast.error(error.message)
            });
            
      
    }
        setFormSubmitted(true);

  };

  return (
    <form>
      <label>
        Card Details
        <CardElement 
        className=' rounded p-2  my-2 bg-bodydark1 dark:bg-black '
        options={{style: {base: {fontSize: '18px'}}}}
        />
      </label>
      <button onClick={handleSubmit} className='btn btn-sm my-1 bg-darkblue dark:bg-black border-0 text-white ' disabled={!stripe || loading}>
      <label disabled={loading}>
        Update Card
        </label>
      </button>
      <label htmlFor="my-modal" className='btn btn-sm my-1 mx-2 bg-danger dark:bg-black border-0 text-white' >Cancel</label>
      {error && <div className='text-danger'>{error}</div>}
    </form>
  );
};

export default CardUpdateForm;
