import React, { useEffect, useRef, useState } from "react";
import DefaultLayout from "../layout/DefaultLayout";
import Breadcrumb from "../components/Breadcrumb";
import ReactToPrint from "react-to-print";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Banner from "../components/PackageBanner/Banner";
import LABEL_1 from "../components/receiving_label_1";
import LABEL_2 from "../components/receiving_label_2";
import { formatDate } from "../scripts/services";
import axios from "axios";
import DownloadIcon from '@mui/icons-material/Download';
import * as XLSX from 'xlsx';
import { FileUploader } from "react-drag-drop-files";
import { useSelector } from "react-redux";
const pageStyle = `
@media print {
  html, body {
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }
}
@media print {
    .onprintlyout {
     
        border: 1px solid;
        border-radius: 65px;
        background-color: #fff !important;
        print-color-adjust: exact; 
    }
    .print_portait{
      width: 192px;
      height:384px;
    }

    .print_landscape{
      height : 320px;
      width:384px;
    }
    .print_medium{
      width:216px;
      height : 120px;

    }
    .pageBreak{
      page-break-before: always;
    }
}`;

const fileTypes = [ "XLS", "XLSX", "csv"];

const SelectBarcode = () => {
  let userdata  = useSelector((state)=>state.user.value);
  let now = new Date(Date.now());
  let dateString = now.toLocaleDateString();
  const [userPermission, setUserPermission] = useState(false);
  const [currency_symbol, set_currency_symbol] = useState("$");
  const [A_Number, set_A_Number] = useState("");
  const [layout, setlayout] = useState(localStorage.getItem('barcodeLayout') ? localStorage.getItem('barcodeLayout') : "portrait");
  const [Model, setModel] = useState(localStorage.getItem('Model'));
  const [Model_value, setModel_value] = useState("~");
  const [CarrierStatus, setCarrierStatus] = useState(false);
  const [CarrierStatus_value, setCarrierStatus_value] = useState("~");
 
  const [IMEI, setIMEI] = useState(localStorage.getItem('IMEI / Serial Number'));
  const [IMEI_value, setIMEI_value] = useState([]);
  
  const [SerialNumber, setSerialNumber] = useState(localStorage.getItem(' Serial Number'));
  const [SerialNumber_value, setSerialNumber_value] = useState("");
  const [Notes, setNotes] = useState(localStorage.getItem('Notes'));
  const [Notes_Value, setNotes_Value] = useState("")
  const [Datee, setDate] = useState(localStorage.getItem('Date'));
  const [Datee_value, setDate_value] = useState(dateString);

  const [User, setUser] = useState(localStorage.getItem('User'));
  const [User_Value, setUser_Value] = useState('');
  const [source, setSource] =  useState(localStorage.getItem(' Source / Vendor'));
  const [source_value, setSource_value] = useState("");
  const [poNumber, setPO_number] = useState(localStorage.getItem(' PO# / Internal Reference#'))
  const [poNumber_value, setPO_number_value] = useState("")


  const currencyOptions = [
    { value: "$", label: "USD" },
    { value: "€", label: "EUR" },
    { value: "£", label: "GBP" },
    { value: "¥", label: "JPY" },
  ];

  function fun_set_Currency_Symbol(value) {set_currency_symbol(value);}
  
  function fun_togle_Model() {setModel(!Model);}
 
  function fun_togle_CarrierStatus() {setCarrierStatus(!CarrierStatus);}
  function fun_togle_IMEI() {setIMEI(!IMEI);}
  function fun_togle_SerialNumber() {setSerialNumber(!SerialNumber);}
  function fun_togle_Notes(){setNotes(!Notes)}

  function fun_togle_User() {setUser(!User);}
  function fun_togle_User_Value(e) {setUser_Value(e)}

  function fun_togle_Datee() {setDate(!Datee);}

  function fun_togle_Date_value(e) {setDate_value(e);}
  function fun_togle_IMEI_value(e) {setIMEI_value(e);}
  
  function fun_togle_Model_Value(e) {setModel_value(e)};
  function fun_togle_SerialNumber_Value(e) {setSerialNumber_value(e)};
  function fun_togle_Notes_Value(e) {setNotes_Value(e)};

  function fun_togle_Source() {setSource(!source)};
  function fun_togle_SouceValue(e) { setSource_value(e)};
  function fun_togle_POnumber() {setPO_number(!poNumber)}
  function fun_togle_POnumber_Value(e){setPO_number_value(e)}

  const [UID, set_UID] = useState("~");
  const [UID_Search, set_UID_Search] = useState(false);
  const ref = useRef();

  const handlePortrait = () => {setlayout("portrait");};
  const handleLandscape = () => {setlayout("landscape");};
  const handleMedium = () => {setlayout("medium");};
const handleSaveBarcodeLayout =()=>{
  localStorage.setItem('barcodeLayout',layout)
  toast.success('Barcode layout is saved.')
}


  async function Get_Sub_User_Permissions(){
    try{
        const res = await axios.get("https://api.cellercertified.com/dashboard/Sub_User_Permissions",{headers:{token:localStorage.getItem('token')}})
       console.log(res)
        const d =res?.data?.data;
        console.log(d[0])
        if(d===undefined) {return;}
        setUserPermission(d[0])
    }catch(e){}
    }
  
    useEffect(()=>{
      Get_Sub_User_Permissions()
    },[])

    useEffect(() =>{
      setUser_Value(userdata.First_Name)
    },)
    console.log(IMEI_value)

    const handleFileUpload = (event) => {
      const file = event.target.files[0];
      const reader = new FileReader();
  
      reader.onload = (e) => {
        const contents = e.target.result;
        const lines = contents.split('\n');
        
        // Extracting headers and data
        const headers = lines[0].split(',').map(header => header.trim());
        const data = lines.slice(1);
  
        // Finding index of IMEI or Serial Number column
        const imeiIndex = headers.findIndex(header => header.toLowerCase() === 'imei');
        const serialIndex = headers.findIndex(header => header.toLowerCase() === 'serial number');
        
        // Extracting IMEI or Serial Number values
        const imeis = [];
        data.forEach(row => {
          const values = row.split(',');
          if (imeiIndex !== -1 && values[imeiIndex]) {
            imeis.push(values[imeiIndex].trim());
          } else if (serialIndex !== -1 && values[serialIndex]) {
            imeis.push(values[serialIndex].trim());
          }
        });
  
        setIMEI_value(imeis);
      };
  
      reader.readAsText(file);
    };
  

  return (
    <div className="leading-6"> 
            <DefaultLayout>
      <Banner />
      <div className="mx-auto max-w-270 mb-8">
        <Breadcrumb pageName="Receiving Labels Formatting" />
        { userPermission && !userPermission.Receiving_Lables&&(
          <div className=" fixed bg-[#D34053] text-white top-16 left-0 flex w-full justify-center">
              <div className="my-2  rounded-md danger p-1.5 dark:bg-meta-4">
              You dont have permission to access this page please upgrade you plan.
            </div>
          </div>)
        }
            <h2 className="bg-white text-black drop-shadow-lg rounded-lg p-4 text-center text-xl font-semibold">You can <span className="text-logocolor font-extrabold">Label</span> items without running an <span className="text-logocolor font-extrabold">IMEI Check</span>.
        <br/> 
             <span className="text-base font-normal text-[#64748B]"> Simply add a Product Name and other details and our system will create labels for you.
              This can be useful for Receiving Purchase Orders or labeling items during different parts of your triage process.</span> </h2>
        <div className="grid flex justify-between min-h-screen grid-cols-1 gap-1">
        <div className="col-span-5 xl:col-span-2">
            <div className="my-3  border-stroke  shadow-default dark:border-strokedark dark:bg-boxdark">
              <div className="col-span-5 mt-5  xl:col-span-2">
                <div className="my-2 rounded-lg border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
                <h3 className="py-3 px-4  text-lg font-bold	 text-black dark:text-white">
                      Enter values for the selected label elements
                    </h3>
                  <div className="grid grid-cols-4 gap-y-4 gap-x-4 rounded-lg border-b border-stroke py-2 px-4 dark:border-strokedark">

                    <CHECK_BOX_ITEM2
                      name={" Source / Vendor"}
                      fun2={fun_togle_SouceValue}
                      checked={source}
                    />
                    <CHECK_BOX_ITEM2
                      name={" PO # / Internal Reference #"}
                      fun2={fun_togle_POnumber_Value}
                      checked={poNumber}
                    />
                    <CHECK_BOX_ITEM2
                      name={"Model"}
                      fun2={fun_togle_Model_Value}
                      checked={Model}
                    />
                
                    <AddItems
                      name={"IMEI / Serial Number"}
                      fun2={fun_togle_IMEI_value}
                      checked={IMEI}
                      handleFileUpload={handleFileUpload}
                    />

                     <div className="w-full">
                      <div className="flex items-center ">
                      {User ? 
                              <label
                              className="text-gray-900 dark:text-gray-300 text-sm font-medium"
                            >
                              User
                            </label> : null}
                      </div>

                      <div  className="w-40 font-semibold text-black">
                      {User ?   User_Value : null}
                      </div>
                    </div>
                    <div className="w-full">
                      <div className="flex items-center ">
                      {Datee ? 
                              <label
                              className="text-gray-900 dark:text-gray-300 text-sm font-medium"
                            >
                              Date
                            </label> : null}
                      </div>

                      <div  className="w-40 font-semibold text-black">
                      {Datee ?   Datee_value : null}
                      </div>
                    </div>
                    <CHECK_BOX_ITEM2
                      name={"Notes"}
                      fun2={fun_togle_Notes_Value}
                      checked={Notes}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div></div>
          <div className={`grid gap-y-5 ${layout === 'landscape' ? 'grid-cols-2' : 'grid-cols-3'}`}>
          { IMEI_value?.map((imei, index) => (
            <div key={index} className="">
            <div className="col-span-5 xl:col-span-2 ">
            
            <div className="my-3 w-full border-stroke dark:border-strokedark dark:bg-boxdark">
              <h4 className="mb-2 ml-2 text-xl font-semibold text-black dark:text-white">
                Select Label Layout
              </h4>
              <div className="max-w-66 flex flex-col  w-full justify-start">
                <div className=" m-1 inline-flex items-start justify-start rounded-md w-full  dark:bg-meta-4">
                  <button
                  disabled={userPermission && !userPermission.account}
                    onClick={handlePortrait}
                    className={`dark:hover:bg-boxdark66 rounded-l ${
                      layout === "portrait" && "bg-white"
                    } py-3 px-3 text-xs font-medium text-black shadow-card hover:bg-white hover:shadow-card dark:bg-boxdark dark:text-white`}
                  >
                    2 x 4 Portrait
                  </button>
                  <button
                 disabled={userPermission && !userPermission.account}
                    onClick={handleLandscape}
                    className={`py-3 px-3 text-xs  ${
                      layout === "landscape" && "bg-white"
                    }  font-medium text-black shadow-card hover:bg-white hover:shadow-card dark:bg-boxdark dark:text-white`}
                  >
                    4 x 2 Landscape
                  </button>
                  <button
                 disabled={userPermission && !userPermission.account}
                    onClick={handleMedium}
                    className={`  py-3 px-3 text-xs  ${
                      layout === "medium" && "bg-white"
                    }  font-medium text-black shadow-card hover:bg-white hover:shadow-card dark:bg-boxdark dark:text-white`}
                  >
                    2-1/4 x 1-1/4
                  </button>
                  <button
                 disabled={userPermission && !userPermission.account}
                  onClick={handleSaveBarcodeLayout}
                  className={`
                            text-xs 
                            rounded-r
                            shadow-md
                            bg-darkblue 
                            text-white px-3 py-3
                            `}
                >
                  Save
                </button>
                </div>
              </div>
            </div>

  {/* {IMEI_value.length === 0 ? 
  
  
  <div className={`col-span-5 flex flex-col gap-y-8  w-fit  rounded xl:col-span-2`}>
  

  <div
    ref={ref}
    className={`
    
      rounded-lg  border-stroke bg-white  
    dark:border-strokedark dark:bg-boxdark
    ${
      (layout === "portrait" && "w-[192px] h-[384px]") ||
      (layout === "medium" && "w-[288px] h-[384px]") ||
      (layout === "landscape" && "w-[384px] h-[215px]")
    }
    `}
  >
 
    <div
      className={`mb-0 onprintlyout rounded-lg border-b w-full h-full border-stroke  dark:border-strokedark relative`}
    >
   

      {(layout === "portrait" || layout === "medium") && (
        <LABEL_1
          v1={Model_value}
          v2={IMEI_value}
          v2_2={IMEI}
          v7={SerialNumber_value}
          v7_7={SerialNumber}
          v12={Datee_value}
          v12_12={Datee}
          v16={poNumber_value}
          v16_16={poNumber}
          v15={Notes_Value}
          v15_15={Notes}
          v18={User_Value}
          v18_18={User}
          v19={source_value}
          v19_19={source}
        />
      )}
      {layout === "landscape" && (
        <LABEL_2
        v1={Model_value}
        v2={IMEI_value}
        v2_2={IMEI}
        v7={SerialNumber_value}
        v7_7={SerialNumber}
        v12={Datee_value}
        v12_12={Datee}
        v16={poNumber_value}
        v16_16={poNumber}
        v15={Notes_Value}
        v15_15={Notes}
        v18={User_Value}
        v18_18={User}
        v19={source_value}
        v19_19={source}
        />
      )}
    </div>

    <div className="flex justify-center underline text-sm text-darkblue ">
      <ReactToPrint
      
        trigger={() => <button className={`${(userPermission &&!userPermission.account) && 'hidden'}`} disabled={userPermission && !userPermission.account}>Print</button>}
        content={() => ref.current}
        pageStyle={pageStyle}
      />
    </div>
  </div>

</div>
  
  
  
  :     */}
         <div className={`grid grid-cols-3  gap-y-8 gap-x-4  w-fit  rounded xl:col-span-2`}>
              {/* label conainer */}
            
    <div>
              <div
                ref={ref}
                className={`
                
                  rounded-lg  border-stroke bg-white  
                dark:border-strokedark dark:bg-boxdark
                ${
                  (layout === "portrait" && "w-[192px] h-[384px]") ||
                  (layout === "medium" && "w-[288px] h-[384px]") ||
                  (layout === "landscape" && "w-[384px] h-[215px]")
                }
                `}
              >
                {/* label print conainer */}
                <div
                  className={`mb-0 onprintlyout rounded-lg border-b w-full h-full border-stroke  dark:border-strokedark relative`}
                >
                  {/*Actual Label*/}

                  {(layout === "portrait" || layout === "medium") && (
                    <LABEL_1
                      v1={Model_value}
                      v2={imei}
                      v2_2={IMEI}
                      v7={SerialNumber_value}
                      v7_7={SerialNumber}
                      v12={Datee_value}
                      v12_12={Datee}
                      v16={poNumber_value}
                      v16_16={poNumber}
                      v15={Notes_Value}
                      v15_15={Notes}
                      v18={User_Value}
                      v18_18={User}
                      v19={source_value}
                      v19_19={source}
                    />
                  )}
                  {layout === "landscape" && (
                    <LABEL_2
                    v1={Model_value}
                    v2={imei}
                    v2_2={IMEI}
                    v7={SerialNumber_value}
                    v7_7={SerialNumber}
                    v12={Datee_value}
                    v12_12={Datee}
                    v16={poNumber_value}
                    v16_16={poNumber}
                    v15={Notes_Value}
                    v15_15={Notes}
                    v18={User_Value}
                    v18_18={User}
                    v19={source_value}
                    v19_19={source}
                    />
                  )}
                </div>

                <div className="flex justify-center underline text-sm text-darkblue ">
                  <ReactToPrint
                  
                    trigger={() => <button className={`${(userPermission &&!userPermission.account) && 'hidden'}`} disabled={userPermission && !userPermission.account}>Print</button>}
                    content={() => ref.current}
                    pageStyle={pageStyle}
                  />
                </div>
              </div>
              </div> 
            
              {/*  */}
            </div>


          </div>
            </div>
          ))}
          </div>
        </div>
      </div>
    </DefaultLayout>
    </div>
  );
};

export default SelectBarcode;


function CHECK_BOX_ITEM2({ name, fun2, checked }) {
  if(!checked){
    return null;
  }
  return (
    <div className="w-full">
      <div className="flex items-center ">
      {checked ? 
              <label
              className="text-gray-900 dark:text-gray-300 text-sm font-medium"
            >
              {name}
            </label> : null}
      </div>

      <div  className="">
      {checked ?         <input
       
          className="bg-white dark:bg-black border rounded h-8 text-xs px-2"
          placeholder={`Enter ${name}`}
          onChange={(e) => {
            fun2(() => [ e.target.value]);
          }}
        ></input> : null}
      </div>
    </div>
  );
}

// function CHECK_BOX_ITEM1({ name, fun2, checked }) {
//   if(!checked){
//     return null;
//   }
//   return (
//     <div className="flex w-full justify-between">
//       <div className="flex items-center ">
//       {checked ? 
//               <label
//               className="text-gray-900 dark:text-gray-300 text-sm font-medium"
//             >
//               {name}
//             </label> : null}
//       </div>

//       <div  className="">
//       {checked ?      <textarea
//   className="bg-white dark:bg-black border rounded h-10 text-xs px-3"
//   placeholder={`Enter ${name}`}
//   onChange={(e) => {
//     const inputText = e.target.value;
//     const imeis = inputText
//       .split(/[,\n]/) // Split by comma or new line
//       .map(imei => imei.trim()) // Trim white spaces
//       .filter(imei => imei !== ''); // Remove empty strings
  
//     fun2(imeis); // Update state with array of IMEI numbers
//   }}
// ></textarea> : null}
// <div className="absolute ml-50 -mt-11 font-bold text-darkblue text-sm w-50">Enter Multiple IMEI numbers seperated by commas.</div>
//       </div>
//     </div>
//   );
// }


function AddItems({ name, fun2, checked, handleFileUpload }) {

  const [inputText, setInputText] = useState('');

  const handleInputChange = (e) => {
    setInputText(e.target.value);
  };

  const handleAddImei = () => {
    const imeis = inputText
      .split(/[,\n]/) // Split by comma or new line
      .map(imei => imei.trim()) // Trim white spaces
      .filter(imei => imei !== ''); // Remove empty strings

    fun2(imeis); // Update state with array of IMEI numbers
    setInputText(''); // Clear the textarea after adding
  };


  if(!checked){
    return null;
  }
  return (
    <div className="w-full">
      <div className="flex items-center w-50">
      {checked ? 
              <label
              className="text-gray-900 dark:text-gray-300 text-sm font-medium"
            >
              {name}
            </label> : null}
      </div>

      <div  className="w-50">
      {checked ? <label htmlFor="addItems" className="text-darkblue text-sm font-semibold border-0 cursor-pointer">+ Add IMEI or Serial Number</label> : null}
{/* <div className="absolute ml-50 -mt-11 font-bold text-darkblue text-sm w-50">Enter Multiple IMEI numbers seperated by commas.</div> */}
      </div>
      <div>
      <input 
      type="checkbox" id="addItems" className="modal-toggle" />
      <div className={`modal z-999`}>
      <div className="modal-box bg-white dark:bg-black-2 flex flex-col gap-y-2 ">
      <h1 className="font-bold text-black-2 dark:text-white text-lg">Bulk Add</h1>
      <div className='flex gap-x-2'>
          <label htmlFor='copyPasteModal' className='btn bg-logocolor border-none text-white disabled:text-bodydark h-fit'>Copy and Paste</label>
          <div className='flex flex-col gap-y-1'>
          <label htmlFor="fileInput" className='btn bg-logocolor border-none text-white disabled:text-bodydark h-fit'>Upload File</label>
          </div>
          <label htmlFor="addItems" className='btn bg-danger border-none text-white disabled:text-bodydark w-1/3 h-fit' 
       >Cancel</label>
      </div>

      </div>
      </div>
      </div>
      <div>
      <input 
      type="checkbox" id="copyPasteModal" className="modal-toggle" />
      <div className={`modal z-999`}>
      <div className="modal-box bg-white dark:bg-black-2 flex flex-col gap-y-2">
      <h1 className="font-bold text-black-2 dark:text-white text-lg">Copy and Paste</h1>
      <h1>Paste IMEI'S or Serial Number seperated by comma or line...</h1>
      
      <textarea
            className="bg-white dark:bg-black border rounded h-16 text-xs py-2 px-3"
            placeholder={`Enter ${name}`}
            value={inputText}
            onChange={handleInputChange}
          ></textarea>
     <div className='flex gap-x-2'>
     <label
              className='btn bg-logocolor border-none text-white disabled:text-bodydark w-1/4 h-fit'
              onClick={handleAddImei}
            >
              ADD
            </label>
     <label htmlFor="copyPasteModal" className='btn bg-danger border-none text-white disabled:text-bodydark w-1/4 h-fit' 
       
       >Cancel</label>
     </div>
      </div>
      </div>
      </div>
      <div>
      <input
        type="file"
        id="fileInput"
        className="modal-toggle"
        onChange={handleFileUpload} // Call handleFileUpload when file is selected
      />
      <div className={`modal z-999`}>
        <div className="py-6 px-8 rounded-xl bg-white dark:bg-black-2 flex flex-col gap-y-2">
          <h1 className="font-bold text-black-2 dark:text-white text-lg">Upload Excel File</h1>
          <div className='flex flex-col gap-y-2'>
            <div className='flex flex-col p-4 gap-y-4 justify-center items-center border-2 border-dashed border-logocolor w-full text-md'>
              <div><u>Upload</u> Excel File containing IMEI'S and their Reference ID (<i> Ref.. ID Optional</i> ).</div>
              <label className='btn bg-logocolor border-none text-white disabled:text-bodydark' >Upload File</label>
            </div>
            <div className='text-md text-logocolor'><DownloadIcon/><a className='underline tracking-tighter cursor-pointer' download >Click here to download sample file</a></div>
          </div>
          <div className='flex w-full justify-end gap-x-2'>
            <label htmlFor="fileInput" className='p-2 bg-danger rounded-md border-none text-white  disabled:text-bodydark'>Cancel</label>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}

















// const ItemList = ({IMEI_value, setIMEI_value}) => {
//   const [inputValue, setInputValue] = useState('');
//   const [textareaImei, setTextareaImei] = useState('');
//   const [cancelBulkAdd,setCancelBulkAdd] = useState("")



//   const handleTextareaChange = (e) => {
//     setTextareaImei(e.target.value);
//   };


//   const handleMultipleInput = () => {
//     if (textareaImei.trim() !== '') {
//       const imeisArray = textareaImei.split(/[,|\n]/).map(imei => imei.trim());
//       const validImeis = imeisArray.filter(imei => imei.length === 15);
//       const newItems = validImeis.map(imei => imei);
  
//       if (newItems.length > 0) {
//         setIMEI_value(prevIMEI_value => [...prevIMEI_value, ...newItems]);
//         setTextareaImei('');
  
//         toast.success(`${newItems.length} items added successfully`);
//       } else {
//         toast.error('No valid items to add');
//       }
//     }
//   };
  
  
  

// console.log(IMEI_value)
//   return (
//     <div>

// <div className='flex float-right'>
//           <label htmlFor='copyPasteModal' className='bg-logocolor text-white p-2 rounded-lg text-xs cursor-pointer'>Add Multiple IMEI's</label>
//       </div>

//       <div>
//       <input 
//       type="checkbox" id="copyPasteModal" className="modal-toggle" />
//       <div className={`modal z-999`}>
//       <div className="modal-box bg-white dark:bg-black-2 flex flex-col gap-y-2">
//       <h1 className="font-bold text-black-2 dark:text-white text-lg">Copy and Paste</h1>
//       <h1>Paste IMEI'S seperated by comma or line...</h1>
      
//       <textarea id="textareaInput" value={textareaImei} onChange={handleTextareaChange} type='textfield' placeholder='Paste IMEIs separated by comma or Line'
//       className='disabled:bg-bodydark text-info-content bg-white border border-body rounded  h-full w-full p-2' />
//      <div className='flex gap-x-2'>
//      <label  className='btn bg-logocolor border-none text-white disabled:text-bodydark w-1/4 h-fit' 
//       onClick={handleMultipleInput}
//        >ADD IMEI</label>
//      <label htmlFor="copyPasteModal" className='btn bg-danger border-none text-white disabled:text-bodydark w-1/4 h-fit' 
//        onClick={()=>{setCancelBulkAdd("")}}
//        >Cancel</label>
//      </div>
//       </div>
//       </div>
//       </div>


//     </div>
//   );
// };


