import React from "react";
import LOGO from "../images/logo/new-barcode-logo.svg";
import { QRCodeSVG } from "qrcode.react";
import Barcode from "react-barcode";

function LABEL_1({ v1, v2, v2_2, v7, v7_7, v12, v12_12, v15, v15_15, v16, v16_16, v18, v18_18, v19, v19_19 }) {
    const v2_barcode = localStorage.getItem('createIMEIBarcode');
    const v7_barcode = localStorage.getItem('create Serial NumberBarcode');
    const v1_barcode = localStorage.getItem('createModelBarcode');
    const v19_barcode = localStorage.getItem('create SourceBarcode');
    const v16_barcode = localStorage.getItem('create PO #Barcode');
    const v15_barcode = localStorage.getItem('createNotesBarcode');
    const v18_barcode = localStorage.getItem('createUserBarcode');
    const v12_barcode = localStorage.getItem('createDateBarcode');
  return (
    <div className="flex flex-col items-center h-full leading-6">
      <img src={LOGO} alt="label logo" className="w-25 mt-4" />

      <div className="border-2 border-black-2 mx-2 mt-2 p-1">
        <h1 className="text-xs text-black-2 font-semibold">Model: {v1}</h1>
        {( v1 && v1_barcode) && <Barcode height={15} width={1} value={v1} displayValue={false}/>} 
      </div>

      <div className="w-full p-2">
        {(v2_2) && <h1 className="text-xs font-semibold z-9 text-black-2 tracking-tight">IMEI/Serial# : {v2}</h1>}
        {(v2_2 && v2 && v2_barcode) && <Barcode height={15} width={1} value={v2} displayValue={false}/>}
        {/* {(v7_7) && <h1 className="text-xs z-9 font-semibold text-black-2">SN: {v7}</h1>}
        {(v7_7 && v7 && v7_barcode) && <Barcode height={15} width={1} value={v7} displayValue={false}/>} */}
      </div>


      <div className="text-xs text-black-2 w-full px-2 font-semibold tracking-tighter">
        {(v19_19 && v19) && <h1>Source: {v19}</h1>}
        {(v19_19 && v19 && v19_barcode) && <Barcode height={15} width={1} value={v19} displayValue={false}/>}
        {(v16 && v16_16) && <h1>P0 #: {v16}</h1>}
        {(v16_16 && v16 && v16_barcode) && <Barcode height={15} width={1} value={v16} displayValue={false}/>}
        {(v15_15 && v15) && <h1>Notes: {v15}</h1>}
        {(v15_15 && v15 && v15_barcode) && <Barcode height={15} width={1} value={v15} displayValue={false}/>}
      </div>
      {v18_18 && v18 && <div className="absolute text-[11px] text-black-2 font-smemibold bottom-6 left-1"><h1>User: {v18}</h1></div>}
      {v12_12 && <div className="absolute text-xs text-black-2 font-smemibold bottom-4 left-1"><h1>Date: {v12}</h1></div>}
      <div className="text-[8px] tracking-[-0.08em] font-semibold whitespace-nowrap absolute bottom-0">
        No Database Verification Performed. Receiving Label Only
      </div>
    </div>
  );
}

export default LABEL_1;
