import React from "react";
import LOGO from "../images/logo/new-barcode-logo.svg";
import { QRCodeSVG } from "qrcode.react";
import Barcode from "react-barcode";

function LABEL_1({ v1, v2, v2_2, v3, v3_3, v4, v4_4, v5, v6, v6_6, v7, v7_7, v8, v8_8, v9, v9_9, v10, v10_10, v11, v12, v12_12, v13, v14, v14_14, v15, v15_15, v16, v16_16, v17, v17_17, v18, v18_18, v19, v19_19, v20, v20_20, v21, v21_21 }) {
  return (
    <div className="flex flex-col items-center h-full leading-6">
      <img src={LOGO} alt="label logo" className="w-25 mt-4" />

      <div className="border-2 border-black-2 mx-2 mt-2 p-1">
        <h1 className="text-xs text-black-2 font-semibold">{v1}</h1>
        {(v8_8 && v8) && <h1 className="text-xs text-black-2 font-semibold">{v8}</h1>}
      </div>

      <div className="w-full h-fit  p-2 mb-1">
        {(v2_2) && <h1 className="text-[10px] relative top-[-10px] h-0 z-9 text-black-2">IMEI: {v2}</h1>}
        {(v2_2) && <Barcode height={15} width={1} value={v2} displayValue={false}></Barcode>}

        {(v7_7) && <h1 className="text-[10px] relative top-[-10px] h-0 z-9 text-black-2">SN: {v7}</h1>}
        {(v7_7) && <Barcode height={15} width={0.7} value={v7} displayValue={false}></Barcode>}

        {(v14_14 && v14) && <h1 className="text-[10px] relative top-[-10px] h-0 z-9 text-black-2">Internal ID : {v14}</h1>}
        {(v14_14 && v14)  && <Barcode height={15} width={0.7} value={v14} displayValue={false}></Barcode>}
      </div>

      <div className="flex justify-between gap-x-4 mt-[-15px] text-xs text-black-2 text-center font-semibold z-9 mb-0">
        {(v3_3 && v3) && <h1>Grade: {v3}</h1>}
        {(v4_4 && v4) && <h1>{v5}{v4}</h1>}
      </div>

      <div className="text-xs text-black-2 w-full px-2 mt-0 font-semibold tracking-tighter">
        {(v19_19 && v19) && <h1>Carrier Status: {v19}</h1>}
        {(v16 && v16_16) && <h1>Blacklist Status: {v16}</h1>}
        {(v15_15 && v15) && <h1>Color: {v15}</h1>}
        {(v9_9 && v9) && <h1>BH: {v9}%</h1>}
        {(v10 && v10_10) && <h1>FMI: {v10_10}</h1>}
      </div>
      {v20_20 && v20 && <div className="absolute text-[11px] tracking-tighter text-black-2 font-smemibold bottom-12 left-1"><span>Customer Id: {v20}</span></div>}
      {v18_18 && v18 && <div className="absolute text-[11px] text-black-2 font-smemibold bottom-9 left-1"><h1>User: {v18}</h1></div>}
      {v12_12 && <div className="absolute text-xs text-black-2 font-smemibold bottom-7 left-1"><h1>Date: {v12}</h1></div>}
      {v21_21 && <div className="w-34  h-7 absolute text-xs text-black-2 font-smemibold tracking-tighter bottom-1 leading-none left-1"><h1>Notes: {v21}</h1></div>}
      <div className="absolute right-1 bottom-2">
        <span className="text-black font-semibold text-xs flex justify-center">Celler</span>
        <QRCodeSVG value={`https://www.cellercertified.com/publicreport?UID=${v13}`} size={45}></QRCodeSVG>
        <span className="text-black font-semibold text-xs flex justify-center">Certified</span>
      </div>
    </div>
  );
}

export default LABEL_1;
