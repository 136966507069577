import {  CircularProgress } from "@mui/material";
import { Button } from "antd";
import axios from "axios";
import { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import { Barcode, pageStyle } from "./Bar_Code_Template";
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import ReactToPrint from "react-to-print";

function EditCustomFields(data) {
  const [Single_Bar_Code_Data,Set_Single_Bar_Code_Data] = useState({})
    const [Edit_State,set_Edit_State] = useState(0)
    const [Delete_Input_Text,Set_Delete_Input_Text] = useState("")
    const [Bulk_Edit_Res,setRes] = useState("~")

    const [BATTERY_HEALTH,set_BATTER_HEALTH] = useState("~")
    const [GRADE,set_GRADE] = useState("~")
    const [COSMETIC_GRADE,set_COSMETIC_GRADE] = useState("~")
    const [FUNCTIONAL_GRADE,set_FUNCTIONAL_GRADE] = useState("~")
    const [NOTES,set_NOTES] =useState("~")
    const [PRICE,set_PRICE] = useState("~")
    const [INTERNATIONAL_REFERANCE,set_INTERNATIONAL_REFERANCE] = useState("~")
    const [CUSTOMER_ID, set_CUSTOMER_ID] = useState('~')
    const [COLOR, set_COLOR] = useState('~');

    function SET_BATTER_HEALTH(value){set_BATTER_HEALTH(value)}
    function SET_GRADE(value){set_GRADE(value)}
    function SET_COSMETIC_GRADE(value){set_COSMETIC_GRADE(value)}
    function SET_FUNCTIONAL_GRADE(value){set_FUNCTIONAL_GRADE(value)}
    function SET_NOTES(value){set_NOTES(value)}
    function SET_PRICE(value){set_PRICE(value)}
    function SET_INTERNATIONAL_REFERANCE(value){set_INTERNATIONAL_REFERANCE(value)}
    function SET_CUSTOMER_ID(value){set_CUSTOMER_ID(value)}
    function SET_COLOR(value){set_COLOR(value)}


    const [UID,setUid] = useState("")
    const ref  = useRef();

   async function Confirm_Edit(){
        set_Edit_State(1)

        const url = "https://api.cellercertified.com/Dashboard/Additional_Data";
      try {
      const body ={
        Data:{
        BATTERY_HEALTH,
        COSMETIC_GRADE:COSMETIC_GRADE,
        FUNCTIONAL_GRADE,
        GRADE,
        INTERNATIONAL_REFERANCE,
        NOTES,
        UID,
        PRICE,
        CUSTOMER_ID,
        COLOR}
      }
console.log(body);
      const headers = { token:localStorage.getItem("token")}
      const res = await axios.post( url ,body, { headers});
      if(res.data.status === true){
        toast.success("Report Updated");
        set_Edit_State(2)
        setRes(res.data.msg)
      }
      else{
        set_Edit_State(2)
        toast.error("unable to update Report")}
      }catch(e) {toast.error(e.message)}


    }
   
    useEffect(()=>{
        if(data.data.UID ===undefined){setUid(data.data.data.UID) ;return;}
        setUid(data.data.UID);console.log()},[data])

        useEffect(() => {
          Set_Single_Bar_Code_Data({
            ...data?.data,
            BATTERY_HEALTH,
            GRADE,
            COSMETIC_GRADE,
            FUNCTIONAL_GRADE,
            PRICE,
            NOTES,
            INTERNATIONAL_REFERANCE,
            CUSTOMER_ID,
            COLOR
          });
        }, [BATTERY_HEALTH, GRADE,COSMETIC_GRADE,FUNCTIONAL_GRADE, PRICE, NOTES,INTERNATIONAL_REFERANCE, CUSTOMER_ID, COLOR]);

        const closeModal = (e) => {
          if (e.target.id === "custom_edit") {
            // Check if the clicked element is the modal itself
            document.getElementById("custom_edit").checked = false;
          }
        };

        const BH = localStorage.getItem('Battery Health');
        const CG  = localStorage.getItem('Cosmetic Grade');
        const FG = localStorage.getItem('Functional Grade');
        const G = localStorage.getItem('Grade');
        const IR = localStorage.getItem('Internal Reference #');
        const N = localStorage.getItem('Notes');
        const CID = localStorage.getItem('Customer ID'); 
        const P = localStorage.getItem('Price');
        const C = localStorage.getItem('Color');

    

    return (
         
        <div>
      <input type="checkbox" id="custom_edit" className="modal-toggle" />
      <div className={`modal z-999`}>
      <div className="modal-box bg-white dark:bg-black-2">
      <h1 className="font-bold text-black-2 dark:text-white text-lg">Edit Custom Fields</h1>

      <div><h1>Report ID:&nbsp;{UID}</h1></div>
            <div className="">
            {G === 'true' && <Item name={"Grade"} value={"~"} fun={SET_GRADE} s={Edit_State} />}
            {CG === 'true' && <Item name={"Cosmetic Grade"} value={"~"} fun={SET_COSMETIC_GRADE}  s={Edit_State}/>}
            {FG === 'true' && <Item name={"Functional Grade"} value={"~"} fun={SET_FUNCTIONAL_GRADE}  s={Edit_State}/>}
            {BH === 'true' && <Item name={"Battery Health"} value={"~"} fun={SET_BATTER_HEALTH} s={Edit_State} />}
            {IR === 'true' &&  <Item name={"Internal Reference ID"} value={"~"} fun={SET_INTERNATIONAL_REFERANCE} s={Edit_State}/>}
            {CID === 'true' && <Item name={"Customer ID"} value={"~"} fun={SET_CUSTOMER_ID} s={Edit_State}/>}
            {C === 'true' && <Item name={"Color"} value={"~"} fun={SET_COLOR} s={Edit_State}/>}
            {P === 'true' && <Item name={"Price"} value={"~"} fun={SET_PRICE} s={Edit_State}/>}
            {N === 'true' && <Item name={"Notes"} value={"~"} fun={SET_NOTES}  s={Edit_State}/>}
            </div>
      
        {/* <div className="flex justify-between"> */}
        <div className="flex justify-end">
          <button 
      onClick={()=>Confirm_Edit()}
      disabled={(Edit_State ===0) ?false:true }
      className='btn btn-sm my-1 mx-2 h-full bg-meta-3 w-20  dark:bg-black border-0 text-white'>Update</button>

      <label 
      disabled={Edit_State ===0 ?false:true}
       htmlFor="custom_edit" className='btn btn-sm my-1 mx-2 h-full bg-danger w-20  dark:bg-black border-0 text-white' 
       onClick={()=>{Set_Delete_Input_Text("")}}
       >Cancel</label>
          </div>
          <div className="flex justify-end ">
          <div  className="hidden print:block " ref={ref}>
      <Barcode data={Single_Bar_Code_Data} />
      </div>
      <div className=" w-1/2 flex justify-end" >
        <ReactToPrint trigger={()=><button className="btn btn-sm h-full bg-[#1589FF] border-0 w-4/5 text-white my-1 hover:text-white" variant="contained" >Print Barcode</button>} content={()=>ref.current} pageStyle={pageStyle}/>
        </div>
          </div>

      {/* </div> */}
      
      <div className={`${Edit_State ===1 ?'block':'hidden'}  p-2 flex items-center`}>
      <CircularProgress size={20} thickness={8}/>
      <h1 className="mx-2 italic">Updating ... </h1>
      </div>

      <div className={`${Edit_State ===2 ?'block':'hidden'} flex`}>
      <h1>{Bulk_Edit_Res}</h1>
      <label htmlFor="custom_edit" className="mx-2 underline cursor-pointer" onClick={()=>{Set_Delete_Input_Text("");set_Edit_State(0)}}>Close</label>
      </div>
      
      </div>
      </div>
      </div>
        );
}

function Item({name,value,fun,s}){
    return(

        <div className="flex items-center justify-between my-1">
      <h1>{name}</h1>
      <input
      disabled={s!== 0?true:false}
      onChange={(e)=>{fun(e.target.value)}}
      className="
      disabled:bg-bodydark
      bg-white dark:bg-black border p-1 text-black rounded-md dark:text-white" placeholder={value}/>
      </div>
    )
}

export default EditCustomFields;