import axios from "axios";
import React, { useEffect, useState, useRef } from "react"
import "react-toastify/dist/ReactToastify.css";
import { MaterialReactTable } from 'material-react-table';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { PushAddationalFields } from "../scripts/addational_fields";
import { CircularProgress, IconButton, } from '@mui/material';
import { CopyAll, DeleteForever, Edit, Print, QrCodeScannerTwoTone } from '@mui/icons-material';
import ReactToPrint from "react-to-print";
import { toast } from "react-toastify";
import { copy_txt } from "../scripts/services";
import { Barcode, pageStyle } from "./Bar_Code_Template";
import ReportTemplate from "./Report_Template";
import SimpleModal from "./Simple_Modal";
import { Modal } from "antd";
import { saveAs } from "file-saver";
import Select from 'react-select'
import { Button } from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import EditCustomFields from "./Edit_Custom_Fields";
import { useDispatch, useSelector } from "react-redux"
import moment from 'moment-timezone';
const XLSX = require('xlsx');


const ToolTip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    boxShadow: theme.shadows[1],
    fontSize: 11,
    lineHeight: '1.5rem'
  },
}));



const IMEIHistory = ({ Permission }) => {
  const userdata = useSelector((state) => state.user.value);
  console.log(userdata)
  const [Delete_Permission, set_delete_permission] = useState(false)
  const [Single_Bar_Code_Data, Set_Single_Bar_Code_Data] = useState({})
  const [information, set_Information] = useState('')
  const [Multi_Bar_Code_Data, Set_Multi_Bar_Code_Data] = useState([])
  const [print_report_data, set_print_report_data] = useState({})
  const [Rows_To_Edit, Set_Rows_To_Edit] = useState({});
  const [Catageory, Set_Catageory] = useState("BATTERY_HEALTH");
  const [Bulk_Edit_Data, Set_Bulk_Edit_Data] = useState("")
  const [Bulk_Edit_Res, Set_Bulk_Edit_Res] = useState("")
  const [Delete_Input_Text, Set_Delete_Input_Text] = useState("")
  const [Delete_State, set_Delete_State] = useState(0)
  const ref = useRef();
  const ref2 = useRef();
  const Report_Ref = useRef();
  const [IMEIHistoryData, setIMEIHistoryData] = useState([]);
  const [CustomFilter, setCustomfilter] = useState([])
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const showCustomValue =
    selectedRows.length > 0 && selectedRows[0].original
      ? selectedRows[0].original
      : "Empty";
  const [Bulk_Edit_Input, setBulk_Edit_Input] = useState({
    BATTERY_HEALTH: '',
    GRADE: '',
    COSMETIC_GRADE: '',
    FUNCTIONAL_GRADE: '',
    PRICE: '',
    COLOR: '',
    NOTES: '',
    CUSTOMER_ID: '',
    INTERNAL_REFERENCE_ID: "",
  });
  const [CustomEdit_Input, setCustomEdit_Input] = useState("");
  const [CustomEdit_Res, setCustomEdit_Res] = useState("");


  // information modal 
  const [isModalOpen, setIsModalOpen] = useState(false);


  const showModal = (row) => {

    // console.log(row)
    setIsModalOpen(true);
    set_Information(row?.original)
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };


  let token = localStorage.getItem('token');

  const [Bulk_Edit_State, setBulk_Edit_State] = useState(0)
  const get_User_Reports = async () => {
    try {
      const res = await axios.get("https://api.cellercertified.com/All_User_reports_by_Email", {
        headers: { token },
      });
      const reversedData = res.data.reverse();
      const formattedDates = reversedData.map(item => {
        // Parse the date string using moment
        const parsedDate = moment(item.DATE, 'MMMM Do YYYY');

        // Format the parsed date with only date components
        const formattedDate = parsedDate.format(dateFormat);

        return {
          ...item,
          formattedDate: formattedDate,
        };
      });
      setIMEIHistoryData(formattedDates);
    } catch (error) {
      console.error("Error fetching user reports:", error);
    }
  };
  const get_delete_Permission = async () => {
    const res = await axios.get("https://api.cellercertified.com/permission/delete_reports", { headers: { token } })
    set_delete_permission(res.data.status)
  }
  useEffect(() => {
    get_delete_Permission()
    get_User_Reports();

  }, []);

  useEffect(() => {
    setCustomEdit_Input(showCustomValue)
  }, [showCustomValue])


  function cleanWarrantyStatus(warrantyStatus) {
    // Check if warrantyStatus is defined and not null
    if (warrantyStatus && typeof warrantyStatus === "string") {
      // Remove HTML tags and extra whitespace
      warrantyStatus = warrantyStatus.replace(/<[^>]*>?/gm, "").trim();

      // Check for "ACTIVE" or "EXPIRED" and return only that
      if (warrantyStatus.includes("ACTIVE")) {
        return "ACTIVE";
      } else if (warrantyStatus.includes("EXPIRED")) {
        return "EXPIRED";
      } else {
        return warrantyStatus; // If neither "ACTIVE" nor "EXPIRED" is found, return the original text
      }
    } else {
      return ""; // Handle the case where warrantyStatus is undefined or not a string
    }
  }
  const timeZone = userdata.timeZone
  const dateFormat = userdata.dateFormat

  let columns = [
    {
      header: "Date / Time", accessorKey: "formattedDate" && "TIME", enableEditing: false,
      filterVariant: 'date',
      Cell: ({ row }) => (
        <div >
          <h2>{moment(row.original.DATE, 'MMMM Do YYYY')?.format(dateFormat)}</h2>
          <h2>         {
            moment?.tz(row.original.TIME, 'HH:mm:ss a', 'UTC')?.tz(timeZone)?.format('HH:mm:ss a')
          }</h2>
        </div>
      )
    },
    {
      header: "Report ID", accessorKey: "UID", enableEditing: false, Cell: ({ row }) =>
        <a className="hover:underline text-logocolor " href={`https://www.cellercertified.com/publicreport?UID=${row.original.UID}`} rel="noreferrer" target="_blank">{row.original.UID}</a>,
    },
    { header: "IMEI#", accessorKey: "IMEI", enableEditing: false },
    { header: "Manufacturer", accessorKey: "MANUFACTURER", enableEditing: false },
    { header: "Model Name", accessorKey: "MODEL", enableEditing: false },
    { header: "Model Description", accessorKey: "MODEL_DESCRIPTION", enableEditing: false },
    { header: "Serial#", accessorKey: "SERIAL_NUMBER", enableEditing: false },
    { header: "Model#", accessorKey: "A_NUMBER", enableEditing: false },
    { header: "BlackList Status", accessorKey: "BLACKLIST_STATUS", enableEditing: false, },
    { header: "Lock Status", accessorKey: "ICLOUD_LOCK", enableEditing: false },
    { header: "Carrier Status", accessorKey: "SIM_LOCK_STATUS", enableEditing: false },
    {
      header: "Warranty Status",
      accessorKey: "WARRANTY_STATUS",
      enableEditing: false,
      Cell: ({ row }) => (
        <div>
          {cleanWarrantyStatus(row.original.WARRANTY_STATUS)}
        </div>
      ),
    },
    { header: "User", accessorKey: "USER", enableEditing: false },
  ]
  columns = PushAddationalFields(columns);


  const handleExportRows = (rows) => {
    console.log("exporting ...")
    const mappedRows = rows.map((row) => {
      const R = { ...row._valuesCache };
      return R;
    });

    const headerRow = columns.map((column) => column.header)
    const data = [headerRow, ...mappedRows.map((row) => Object.values(row))];

    const worksheet = XLSX.utils.aoa_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    const buffer = XLSX.write(workbook, { type: 'buffer', bookType: 'xlsx' });
    const DATE = Date.now();
    saveAs(new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }), "Report_History_" + DATE + ".xlsx");

  };

  const handleSave = async (rows) => {
    const url = "https://api.cellercertified.com/Dashboard/Additional_Data";
    try {
      let {
        BATTERY_HEALTH,
        COSMETIC_GRADE,
        FUNCTIONAL_GRADE,
        CUSTOMER_ID,
        GRADE,
        INTERNAL_REFERENCE_ID,
        NOTES,
        UID,
        PRICE,
        COLOR
      } = (rows.original);

      const body = {
        Data: {
          BATTERY_HEALTH,
          COSMETIC_GRADE,
          FUNCTIONAL_GRADE,
          CUSTOMER_ID,
          GRADE,
          INTERNAL_REFERENCE_ID,
          NOTES,
          UID,
          COLOR,
          PRICE
        }
      }
      const headers = { token: token, body: body }
      const res = await axios.post(url, body, { headers });
      if (res.data.status === true) {
        toast.success("Report Updated");
        window.location.reload();
      }
      else { toast.error("unable to update Report") }
    } catch (e) { toast.error(e.message) }

  }

  async function Bulk_Edit_Rows() {
    if (Object.keys(Bulk_Edit_Input).length === 0) {
      toast.error("No Data");
      return;
    }

    // Set Bulk_Edit_State to indicate loading
    setBulk_Edit_State(1);

    let UIDs = "";
    Rows_To_Edit.forEach((row) => {
      UIDs += row.original.UID + ",";
    });

    try {
      const editedFields = {};
      for (const property of selectedProperties) {
        editedFields[property] = Bulk_Edit_Input[property];
      }


      const res = await axios.post(
        "https://api.cellercertified.com/Dashboard/Bulk_Edit",
        { Email: token, UIDs: UIDs, FieldsData: editedFields },
        { headers: { token } }
      );



      if (res.data.status === true) {
        toast.success("Rows have been updated")
      } else {
        toast.error("Unable to update Rows")
      }

      setIMEIHistoryData({});
      get_User_Reports();
      setBulk_Edit_State(2);
      setBulk_Edit_Input({});
      Set_Catageory();
      setSelectedProperties([])
    } catch (error) {
      console.log(error.message);

      setBulk_Edit_State(0);
    }
  }



  const handleRowSelect = (row) => {
    const isRowSelected = selectedRows.some(
      (selectedRow) => selectedRow.id === row.id
    );

    if (isRowSelected) {
      // Row is already selected, so remove it from selectedRows
      const updatedSelectedRows = selectedRows.filter(
        (selectedRow) => selectedRow.id !== row.id
      );
      setSelectedRows(updatedSelectedRows);
    } else {
      // Row is not selected, so add it to selectedRows
      setSelectedRows([...selectedRows, row]);
    }
  };


  async function Custom_Edit_Rows() {
    // Check if there are selected rows and data in CustomEdit_Input
    if (selectedRows.length === 0 || Object.keys(CustomEdit_Input).length === 0) {
      toast.error("No Data");
      return;
    }

    // Set Bulk_Edit_State to indicate loading
    setBulk_Edit_State(1);

    let UIDs = "";
    selectedRows.forEach((row) => {
      UIDs += row.original.UID + ",";
    });

    try {

      const res = await axios.post(
        "https://api.cellercertified.com/Dashboard/Bulk_Edit",
        { Email: token, UIDs: UIDs, FieldsData: CustomEdit_Input },
        { headers: { token } }
      );

      setIMEIHistoryData({});
      get_User_Reports();
      setBulk_Edit_State(2);
      setCustomEdit_Input({});
      // Display a success toast message
      toast.success("Report edited successfully");
    } catch (error) {
      console.log(error.message);

      setBulk_Edit_State(0);
      // Display an error toast message
      toast.error("An error occurred while editing the report(s)");
    }
  }

  // console.log(IMEIHistoryData)
  async function Bulk_Delete_Rows() {
    set_Delete_State(1)
    let UIDs = "";
    Rows_To_Edit.forEach(row => {
      UIDs += row.original.UID + ",";
    });

    try {
      const res = await axios.post("https://api.cellercertified.com/Dashboard/Bulk_Delete",
        { UIDs },
        { headers: { token } });
      Set_Bulk_Edit_Res(res.data.msg)
      setIMEIHistoryData({})
      get_User_Reports();
      set_Delete_State(2)
    } catch (error) { console.log(error.message); }
  }


  const customizationOptions = {
    BATTERY_HEALTH: localStorage.getItem("Battery Health") === "true",
    GRADE: localStorage.getItem("Grade") === "true",
    COSMETIC_GRADE: localStorage.getItem("Cosmetic Grade") === "true",
    FUNCTIONAL_GRADE: localStorage.getItem("Functional Grade") === "true",
    NOTES: localStorage.getItem("Notes") === "true",
    INTERNAL_REFERENCE_ID: localStorage.getItem("Internal Reference #") === "true",
    CUSTOMER_ID: localStorage.getItem("Customer ID") === "true",
    PRICE: localStorage.getItem("Price") === "true",
    COLOR: localStorage.getItem("Color") === "true",
  };


  return (
    <div className="rounded-sm border border-stroke bg-white px-5 pt-4 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-3.5 xl:pb-5">
      <h4 className="mb-4 text-xl font-semibold text-black dark:text-white">
        IMEI Check History
      </h4>

      {/* Bulk Edit Modal */}
      <div>
        <input
          disabled={Bulk_Edit_State === 0 ? false : true}
          type="checkbox" id="my-modal2" className="modal-toggle" />

        <div className={`modal z-999 `}>
          <div className="w-[38%] h-[30%] p-5 bg-white dark:bg-black-2 rounded-xl">
            <div className="flex justify-between ">
              <h1 className="font-bold text-black-2 dark:text-white text-xl flex items-center gap-x-2 ">BULK EDIT ROWS <Edit className="text-[#1DA8DF]" /></h1>
              <h1>{Rows_To_Edit.length} Report(s) Editing</h1>
            </div>
            <div className="flex flex-col justify-between my-2 gap-y-1 ">
              <h1>Select Fields to Edit</h1>

              <Select
                isMulti
                name="options"
                options={Object.entries(customizationOptions)
                  .filter(([key, value]) => value)
                  .map(([key]) => ({ value: key, label: key }))}
                className="basic-multi-select w-full text-xs "
                classNamePrefix="select"
                // defaultValue={[
                //   { value: 'BATTERY_HEALTH', label: 'BATTERY_HEALTH' }, 
                // ]}
                value={selectedProperties.index}
                onChange={(selectedOptions) => {
                  const selectedValues = selectedOptions.map((option) => option.value);
                  setSelectedProperties(selectedValues);
                }}
              />
            </div >
            {selectedProperties.map((property, index) => (
              <div key={index} className="flex justify-center items-center">
                <input
                  onChange={(e) =>
                    setBulk_Edit_Input((prev) => ({
                      ...prev,
                      [property]: e.target.value, // Store input value for the selected property
                    }))

                  }
                  value={Bulk_Edit_Input[property] || ""} // Use the value for the selected property
                  className="p-2 my-1 w-full bg-white rounded-md border text-xs"
                  placeholder={`Enter New Value for ${property}`}
                />
              </div>
            ))}


            <div className="flex gap-x-2  mt-5 justify-end">
              <button
                onClick={() => Bulk_Edit_Rows()}
                disabled={Bulk_Edit_State === 0 ? false : true}
                className='p-2 rounded-md my-1 h-full bg-[#1DA8DF] w-20  dark:bg-black border-0 text-white'>Save</button>
              <label
                disabled={Bulk_Edit_State === 0 ? false : true}
                onClick={() => { setSelectedProperties([]) }}
                htmlFor="my-modal2" className='p-2 cursor-pointer rounded-md my-1 h-full bg-danger w-20 text-center dark:bg-black border-0 text-white' >Cancel</label>
            </div>

            <div className={`${Bulk_Edit_State === 1 ? 'block' : 'hidden'}  p-2 flex items-center`}>
              <CircularProgress size={20} thickness={8} />
              <h1 className="mx-2 italic">Updating ... </h1>
            </div>

            <div className={`${Bulk_Edit_State === 2 ? 'block' : 'hidden'} flex`}>
              <h1>{Bulk_Edit_Res}</h1>
              <label htmlFor="my-modal2" className="mx-2 underline cursor-pointer" onClick={() => { setBulk_Edit_State(0) }}>Close</label>
              <h1>{Rows_To_Edit.length} Report(s) Updated Successfully</h1>
            </div>

          </div>
        </div>
      </div>



      {/* Custom Edit Modal */}
      <div>
        <input
          disabled={Bulk_Edit_State === 0 ? false : true}
          type="checkbox"
          id="my-customEdit-modal"
          className="modal-toggle"
        />

        <div className={`modal z-999`}>
          <div className="modal-box bg-white dark:bg-black-2">
            <div className="flex justify-between">
              <div>
                <h1 className="font-bold text-black-2 dark:text-white text-lg">
                  Edit
                </h1>
                <h1> Report Editing</h1>
              </div>
              <div>
                <h1 className="font-bold text-black dark:text-white text-lg">{showCustomValue.MANUFACTURER}</h1>
                <h1 className="font-bold text-black dark:text-white text-md">{showCustomValue.MODEL_DESCRIPTION}</h1>
                <h1 className="font-medium text-black dark:text-white text-md">IMEI: {showCustomValue.IMEI}</h1>
              </div>
            </div>

            <div className="my-2">
              {Object.entries(customizationOptions).map(([key, value]) => (

                <div key={key} className="flex justify-between items-center">
                  <h1 className="text-xs font-semibold">{key}</h1>

                  <input
                    name="CustomEdit_Input"
                    onChange={(e) =>
                      setCustomEdit_Input((prev) => ({
                        ...prev,
                        [key]: e.target.value, // Store input value for the selected property
                      }))
                    }
                    value={CustomEdit_Input[key]} // Use the value for the selected property, or an empty string if not found
                    className="p-2 my-1 w-[60%] bg-white rounded-md border text-xs"
                    placeholder={`Edit ${key}`}
                  />
                </div>
              ))}


            </div>

            <div>
              <button
                onClick={() => Custom_Edit_Rows()}
                className="btn btn-sm my-1 mx-2 h-full bg-meta-3 w-20 dark:bg-black border-0 text-white"
              >
                Save
              </button>
              <label
                htmlFor="my-customEdit-modal"
                className="btn btn-sm my-1 mx-2 bg-danger dark:bg-black border-0 text-white"
                onClick={() => {
                  setCustomEdit_Input({});
                  setCustomEdit_Res("");
                  setBulk_Edit_State(0);
                  setSelectedRows([]);
                }}
              >
                Cancel
              </label>
            </div>

            <div className={`${Bulk_Edit_State === 1 ? "block" : "hidden"}  p-2 flex items-center`}>
              <CircularProgress size={20} thickness={8} />
              <h1 className="mx-2 italic">Updating ... </h1>
            </div>

            <div className={`${Bulk_Edit_State === 2 ? "block" : "hidden"} flex`}>
              <h1>{CustomEdit_Res}</h1>
              <label
                htmlFor="my-customEdit-modal"
                className="mx-2 underline cursor-pointer"
                onClick={() => {
                  setCustomEdit_Input({});
                  setCustomEdit_Res("");
                  setBulk_Edit_State(0);
                  setSelectedRows([]);
                }}
              >
                Close
              </label>
            </div>
          </div>
        </div>
      </div>



      {/* Bulk Delete Modal */}
      <div>
        <input
          disabled={Bulk_Edit_State === 0 ? false : true}
          type="checkbox" id="my-modal3" className="modal-toggle" />
        <div className={`modal z-999`}>
          <div className="modal-box bg-white dark:bg-black-2">
            <h1 className="font-bold text-black-2 dark:text-white text-lg">Bulk Delete</h1>
            <h1>Are You Sure You Want To Delete {Rows_To_Edit.length} Report(s)?</h1>


            <div className="my-4">
              <h1 className="text-sm my-1">* type <strong>"DELETE"</strong> (all caps) to confirm bulk delete.</h1>
              <input
                value={Delete_Input_Text}
                onChange={(e) => { Set_Delete_Input_Text(e.target.value) }}
                className="p-2  w-full bg-white rounded-md border"
                placeholder="Enter New Value "></input>
            </div>

            <div>
              <button
                onClick={() => Bulk_Delete_Rows()}
                disabled={(Delete_Input_Text === "DELETE" && Delete_State === 0) ? false : true}
                className='btn btn-sm my-1 mx-2 h-full bg-meta-3 w-20  dark:bg-black border-0 text-white'>Delete</button>
              <label
                disabled={Delete_State === 0 ? false : true}
                htmlFor="my-modal3" className='btn btn-sm my-1 mx-2 bg-danger  dark:bg-black border-0 text-white'
                onClick={() => { Set_Delete_Input_Text("") }}
              >Cancel</label>
            </div>

            <div className={`${Delete_State === 1 ? 'block' : 'hidden'}  p-2 flex items-center`}>
              <CircularProgress size={20} thickness={8} />
              <h1 className="mx-2 italic">Deleteing ... </h1>
            </div>

            <div className={`${Delete_State === 2 ? 'block' : 'hidden'} flex`}>
              <h1>{Bulk_Edit_Res}</h1>
              <label htmlFor="my-modal3" className="mx-2 underline cursor-pointer" onClick={() => { Set_Delete_Input_Text(""); set_Delete_State(0) }}>Close</label>
            </div>

          </div>
        </div>
      </div>

      {/*Bulk Label Print Content */}
      <div ref={ref} className="hidden print:block ">
        {Multi_Bar_Code_Data.map((item, index) =>
          <div className={`${Multi_Bar_Code_Data.length !== index + 1 ? 'break-after-page' : ''}`}>
            <Barcode data={item.original} /></div>)}
      </div>
      {/*Ssingle Label Print Content */}
      <div className="hidden print:block " ref={ref2}>
        <Barcode data={Single_Bar_Code_Data} />
      </div>
      {/*Report Print Content*/}
      <div ref={Report_Ref} className="hidden print:block ">
        <ReportTemplate data={print_report_data} />
      </div>

      <div className="flex justify-center underline text-sm text-darkblue ">

      </div>
      <MaterialReactTable
        data={IMEIHistoryData}
        columns={columns.map(c => ({ ...c, size: 5, minSize: 10 }))}
        positionToolbarAlertBanner="bottom"
        enableFullScreenToggle={false}
        enableRowSelection={Permission ? !Permission.account : true}
        enableStickyHeader
        editingMode="modal"
        enableEditing
        onEditingRowSave={handleSave}
        // Pagination
        enablePagination={true}
        initialState={{
          density: 'compact',
          pagination: { pageIndex: 0, pageSize: 10 },
          // showColumnFilters: true,
          // showGlobalFilter: true 
        }}
        muiTablePaginationProps={{
          rowsPerPageOptions: [10, 25, 50, 100, 200, 250, 500],
          showFirstButton: true,
          showLastButton: true,
          SelectProps: {
            native: true
          },
          labelRowsPerPage: 'Rows per pages'
        }}
        muiTableBodyCellEditTextFieldProps={{ variant: 'filled' }}
        muiTableContainerProps={{ sx: { background: 'transparent', borderRadius: '8px', } }}
        muiTableProps={{ sx: { padding: '0px', background: 'transparent', } }}
        muiTablePaperProps={{ sx: { background: 'transparent' } }}
        muiTableBodyCellProps={({ cell, column }) => ({

          align: 'center',
          sx: {
            border: '1px solid rgba(255, 255, 255, 1)',
            borderBlockEnd: '1px solid rgba(0,0,0,0.1)',
            color: 'rgba(100,100,100,1)',
            background: (
              cell.getValue() === "CLEAN") ? 'rgba(0, 255, 81,0.5)' : '' ||
                (cell.getValue() === "BAD") ? 'rgba(255, 0, 25,0.4)' : '' ||
                  (cell.getValue() === "BLACKLISTED") ? 'rgba(255, 0, 25,0.4)' : '' ||
                    (cell.getValue() === "ON") ? 'rgba(255, 0, 25,0.4)' : '' ||
                      (cell.getValue() === "OFF") ? 'rgba(0, 255, 25,0.4)' : '' ||
                        (cell.getValue() === "LOCKED") ? 'rgba(255, 0, 81,0.5)' : '' ||
                          (cell.getValue() === "UNLOCKED") ? 'rgba(0, 255, 81,0.5)' : '' ||
                            (cell.getValue() === "LOCKED ON VERIZON") ? 'rgba(255, 0, 25,0.4)' : '' ||
                              (cell.getValue() === "LOCKED ON T-MOBILE") ? 'rgba(255, 0, 25,0.4)' : '' ||
                                (cell.getValue() === "LOCKED ON AT&T") ? 'rgba(255, 0, 25,0.4)' : '' ||
                                  (cell.getValue() === "OUT OF WARRANTY") ? 'rgba(255, 0, 25,0.4)' : '' ||
                                    (cell.getValue() === "OOW") ? 'rgba(255, 0, 25,0.4)' : '' ||
                                      (cell.getValue() === "WARRANTY ACTIVE") ? 'rgba(0, 255, 81,0.5)' : '' ||
                                        (cell.getValue() === "ACTIVE") ? 'rgba(0, 255, 81,0.5)' : '' ||
                                          (cell.getValue() === "WARRANTY EXPIRED") ? 'rgba(255, 0, 25,0.4)' : '' ||
                                            (cell.getValue() === "EXPIRED") ? 'rgba(255, 0, 25,0.4)' : '' ||
                                              (cell.getValue() === "LIMITED WARRANTY") ? 'rgba(244, 255, 151, 0.8)' : '' ||
                                                (cell.getValue() === "APPLE CARE") ? 'rgba(0, 255, 81,0.5)' : '',
            padding: '0px',


          }
        })}
        muiTableHeadCellProps={{ sx: { background: 'rgb(0 174 239)', color: 'white', padding: '0px', border: '1px solid rgba(255, 255, 255, 1)', }, align: 'center', }}
        muiTableHeadRowProps={{ sx: {} }}
        muiTableBodyRowProps={{ sx: {} }}
        muiTableBodyProps={{ sx: { background: 'transparent' } }}
        muiTopToolbarProps={{ sx: { background: 'transparent' } }}
        muiBottomToolbarProps={{ sx: { background: 'transparent' } }}

        renderRowActions={({ row, table }) => (
          <span className="w-52 " >

            <ToolTip title="Edit Custom Fields">
              <IconButton disabled={Permission && !Permission.account} onClick={() => handleRowSelect(row)}>
                <label
                  className="cursor-pointer"
                  htmlFor="my-customEdit-modal"
                >
                  <Edit />
                </label>
              </IconButton>

            </ToolTip>
            <ToolTip title="Copy Link">
              <IconButton
                disabled={Permission && !Permission.account}
                onClick={() => {
                  copy_txt("https://www.cellercertified.com/publicreport?UID=" + row.original.UID);
                  toast.success("Link Copied")
                }}
              ><CopyAll /></IconButton>
            </ToolTip>

            <span className=" w-fit h-fit" onPointerOver={() => { set_print_report_data(row.original) }}>
              <ReactToPrint
                trigger={() => <ToolTip title='Print Report PDF'><IconButton disabled={Permission && !Permission.account} ><Print /></IconButton></ToolTip>}
                content={() => Report_Ref.current}
                pageStyle={pageStyle}
              />
            </span>

            <ToolTip title="View Report PDF">
              <IconButton disabled={Permission && !Permission.account} onPointerOver={() => { set_print_report_data(row.original) }}>
                <SimpleModal data={print_report_data} />
              </IconButton>
            </ToolTip>

            <span className=" w-fit h-fit" onPointerOver={() => { Set_Single_Bar_Code_Data(row.original) }}>
              <ReactToPrint
                trigger={() => <ToolTip title="Print Barcode"><IconButton disabled={Permission && !Permission.account} ><QrCodeScannerTwoTone /></IconButton></ToolTip>}
                content={() => ref2.current}
                pageStyle={pageStyle}
              />
            </span>

            <ToolTip title="information">
              <IconButton onClick={() => showModal(row)}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-6 h-6 ${row.original.BLACKLIST_STATUS === 'BLACKLISTED' ? 'text-danger' : row.original.ICLOUD_LOCK === 'ON' ? 'text-danger' : ''}`}>
                  <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                </svg>

              </IconButton>
            </ToolTip>
          </span>)}


        renderBottomToolbarCustomActions={({ table, row }) => (
          <div>
            Displaying {table.getFilteredRowModel().rows.length} results out of {IMEIHistoryData?.length}
          </div>
        )}


        renderTopToolbarCustomActions={({ table }) => (
          <div >

            <div className="mb-4 ">
              <input
                className="bg-gray p-2  dark:bg-black rounded-md"
                placeholder="Search IMEI"
                onChange={(e) => setCustomfilter(e.target.value)}
                disabled={Permission && !Permission.account}
              >
              </input>
              <button
                className="rounded bg-logocolor text-white p-2 mx-2"
                disabled={Permission && !Permission.account}
                onClick={() => { table.setColumnFilters(prev => [{ id: 'IMEI', value: CustomFilter }]) }}>Search</button>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-3">
              <button
                className="px-5 py-1 disabled:text-graydark disabled:bg-bodydark1 text-bodydark1 bg-logocolor  rounded-md transition-all active:bg-black"
                disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
                onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
                variant="contained">
                <FileDownloadIcon />
                Export as XLSX
              </button>


              {Delete_Permission &&
                <button
                  className=" py-1
        disabled:text-graydark disabled:bg-bodydark1 text-bodydark1 bg-danger  rounded-md transition-all active:bg-black"
                  disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
                  onMouseOver={() => { Set_Rows_To_Edit(table.getSelectedRowModel().rows) }}
                >
                  <label htmlFor="my-modal3" className="hover:cursor-pointer py-1 px-5" > <DeleteForever />Delete</label>
                </button>}



              <button
                className="px-5 py-1  disabled:text-graydark disabled:bg-bodydark1 text-bodydark1 bg-meta-3  rounded-md transition-all active:bg-black"
                disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
                onMouseOver={() => { Set_Multi_Bar_Code_Data(table.getSelectedRowModel().rows) }}
              >
                <ReactToPrint
                  trigger={() => (<span><QrCodeScannerTwoTone />Print Labels</span>)}
                  content={() => ref.current}
                  pageStyle={pageStyle}
                />
              </button>

              <button
                className=" py-1
         disabled:text-graydark disabled:bg-bodydark1 text-bodydark1 bg-meta-6  rounded-md transition-all active:bg-black"
                disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
                onMouseOver={() => { Set_Rows_To_Edit(table.getSelectedRowModel().rows) }}
              >
                <label htmlFor="my-modal2" className="hover:cursor-pointer py-1 px-5" > <Edit />Bulk Edit</label>
              </button>
            </div>
          </div>

        )}
      />

      <Modal title="Quick Information Overview" footer={null} width={500} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <div className="grid grid-cols-1 gap-2">


          <div className="flex">
            <h3 className="font-bold">IMEI#: <span className="font-normal">{information?.IMEI}</span> </h3>

          </div>
          <div className="flex">
            <h3 className="font-bold">Serial#: <span className="font-normal">{information?.SERIAL_NUMBER}</span> </h3>

          </div>
          <div className="flex">

            <h3 className="font-bold">Manufacturer: <span className="font-normal">{information?.MANUFACTURER}</span></h3>

          </div>
          <div className="flex">
            <h3 className="font-bold">Model Description: <span className="font-normal">{information?.MODEL_DESCRIPTION}</span></h3>

          </div>
          <div className="flex">
            <h3 className="font-bold">Model#: {
              information?.A_NUMBER ? <span className="font-normal">{information?.A_NUMBER}</span> :
                <span className="font-normal">{information?.MODEL_NUMBER}</span>
            }</h3>

          </div>
          <div className={`flex ${information?.BLACKLIST_STATUS === 'BLACKLISTED' ? 'text-danger' : 'text-success'}`}>
            <h3 className="font-bold">Blacklist Status: <span className="font-normal">{information?.BLACKLIST_STATUS}</span></h3>

          </div>
          <div className={`flex ${information?.ICLOUD_LOCK === 'ON' ? 'text-danger' : 'text-success'}`}>
            <h3 className="font-bold">Lock Status: <span className="font-normal">{information?.ICLOUD_LOCK}</span></h3>

          </div>
          <div className={`flex ${information?.SIM_LOCK_STATUS === 'UNLOCKED' ? 'text-success' : 'text-danger'}`}>
            <h3 className="font-bold">Carrier status: <span className="font-normal">{information?.SIM_LOCK_STATUS}</span></h3>

          </div>
          <div className={`flex ${information?.WARRANTY_STATUS?.includes('EXPIRED') || information?.WARRANTY_STATUS === 'OUT OF WARRANTY' ? 'text-danger' : 'text-success'}`}>
            <h3 className="font-bold">Warranty Status: <span className="font-normal">{information?.WARRANTY_STATUS}</span></h3>

          </div>
          <div className="flex">
            <h3 className="font-bold">User: <span className="font-normal">{information?.USER}</span></h3>

          </div>
        </div>

      </Modal>
    </div>

  );
};

export default IMEIHistory;