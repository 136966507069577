import React, { useEffect, useState } from "react";
import axios from "axios";
import ReactApexChart from "react-apexcharts";

const ChartThree = ({filter}) => {
  const [data, setData] = useState([]);
  const [options] = useState({
    chart: {
      fontFamily: "Satoshi, sans-serif",
      type: "donut",
    },
    colors: ["#00A651", "#7F63F4", "#00AEEF", "#FF0033"],
    labels: ["APPLE", "SAMSUNG","GOOGLE", "Others"],
    legend: {
      show: true,
      position: "top",
    },
    plotOptions: {
      pie: {
        donut: {
          size: "65%",
          background: "transparent",
        },
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ['#fff'],
        fontSize: '16px'
      },
      dropShadow: {
        enabled: true
      }
    },
    stroke: {
      width: 1,
      colors: ['#fff']
    },
    responsive: [
      {
        breakpoint: 2600,
        options: {
          chart: {
            width: 380,
          },
        },
      },
      {
        breakpoint: 640,
        options: {
          chart: {
            width: 200,
          },
        },
      },
    ],
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem(`token`);
        const response = await axios.get("https://api.cellercertified.com/All_User_reports", {
          headers: {
            token: token,
          },
        });

        const filteredData = filterData(response.data, filter)
        setData(filteredData);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [filter]); // Empty dependency array to mimic componentDidMount behavior

  let appleCount = 0;
  let samsungCount = 0;
  let googleCount = 0;
  let otherCount = 0;

  const totalCount = appleCount + samsungCount + googleCount + otherCount;

  const applePercentage = (appleCount / totalCount) * 100;
  const samsungPercentage = (samsungCount / totalCount) * 100;
  const googlePercentage = (googleCount / totalCount) * 100;
  const otherPercentage = (otherCount / totalCount) * 100;

  if (data) {
    if (Array.isArray(data) || typeof data === "object") {
      // Calculate the data for each manufacturer
      if (Array.isArray(data)) {
        appleCount = data.filter(
          (device) =>
            device.MANUFACTURER === "APPLE INC" || device.MANUFACTURER === "APPLE"
        ).length;

        samsungCount = data.filter((device) => device.MANUFACTURER === "SAMSUNG").length;

        googleCount = data.filter((device) => device.MANUFACTURER === "GOOGLE").length;

        otherCount = data.filter(
          (device) =>
            device.MANUFACTURER !== "APPLE INC" &&
            device.MANUFACTURER !== "SAMSUNG" &&
            device.MANUFACTURER !== "APPLE" &&
            device.MANUFACTURER !== "GOOGLE"
        ).length;
      } else if (typeof data === "object") {
        const dataArray = Object.values(data);

        appleCount = dataArray.filter(
          (device) =>
            device.MANUFACTURER === "APPLE INC" || device.MANUFACTURER === "APPLE"
        ).length;

        samsungCount = dataArray.filter((device) => device.MANUFACTURER === "SAMSUNG").length;

        googleCount = data.filter((device) => device.MANUFACTURER === "GOOGLE").length;

        otherCount = dataArray.filter(
          (device) =>
            device.MANUFACTURER !== "APPLE INC" &&
            device.MANUFACTURER !== "SAMSUNG" &&
            device.MANUFACTURER !== "APPLE" &&
            device.MANUFACTURER !== "GOOGLE"
        ).length;
      }
    } else {
      console.error("Invalid data format. Expected an array or object.");
    }
  } else {
    console.error("Data not received from the API");
  }

  const seriesData = [
    { value: appleCount, percentage: applePercentage.toFixed(2) },
    { value: samsungCount, percentage: samsungPercentage.toFixed(2) },
    { value: googleCount, percentage: googlePercentage.toFixed(2) },
    { value: otherCount, percentage: otherPercentage.toFixed(2) },
  ];



  const filterData = (data, selectedFilter) => {
    const currentDate = new Date();
  
    let startDate, endDate;
  
    switch (selectedFilter) {
      case "This Week":
        startDate = new Date(currentDate);
        startDate.setDate(currentDate.getDate() - currentDate.getDay());
        endDate = new Date(currentDate);
        endDate.setDate(startDate.getDate() + 6); 
        break;
      case "This Month":
        startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
        break;
      case "This Year":
        startDate = new Date(currentDate.getFullYear(), 0, 1);
        endDate = new Date(currentDate.getFullYear(), 11, 31);
        break;
        default:
          
          if (selectedFilter && selectedFilter.length === 2) {
            startDate = selectedFilter[0];
            endDate = selectedFilter[1];
          } else {
            return data;
          } 
    }
  
   
    return data.filter(item => {
      const date = parseDate(item.DATE); 
      return date >= startDate && date <= endDate;
    });
  };
  
  const parseDate = (dateString) => {
  
    const dateParts = dateString.split(' ');
    const month = dateParts[0];
    const day = parseInt(dateParts[1]);
    const year = parseInt(dateParts[2]);
  
    return new Date(`${month} ${day}, ${year}`);
  };

  return (
    <div className="col-span-12 rounded-sm border border-stroke bg-white p-7.5 shadow-default dark:border-strokedark dark:bg-boxdark xl:col-span-4">
      <div className="mb-3 justify-between gap-4 sm:flex">
        <div>
          <h5 className="text-xl font-semibold text-black dark:text-white">
            By Device Breakdown
          </h5>
        </div>
      </div>
      <div className="mb-2">
        <div id="chartThree" className="mx-auto flex justify-center">
          <ReactApexChart
            options={options}
            series={seriesData.map((item) => item.value)}
            type="donut"
          />
        </div>
      </div>
    </div>
  );
};

export default ChartThree;
