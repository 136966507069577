import React, { useEffect, useState } from "react";
import DefaultLayout from "../layout/DefaultLayout";
import Breadcrumb from "../components/Breadcrumb";
import axios from "axios";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Banner from "../components/PackageBanner/Banner";
import { Link } from "react-router-dom";
import { CircularProgress } from "@mui/material";

const BarcodeLayout = () => {

  const subUsers = localStorage.getItem('subUsers');
  const [loading,set_loading] = useState(false)
  const [Creat_Sub_User_Permission,set_permission] = useState(false)
  const [First_Name, setFirst_Name] = useState("");
  const [Email, setEmail] = useState("");
  const [DataEmail, setDataEmail] = useState("");
  const [dashboard , setDashboard] = useState(false);
  const [history, setHistory] = useState(false);
  const [Billing_History_Access, setBilling_History_Access] = useState(false);
  const [Barcode_Layout_Access, setBarcode_Layout_Access] = useState(false);
  const [Check_IMEI_Access, setCheck_IMEI_Access] = useState(false);
  const [Receivinglables, setReceivingLabels] = useState(false)
  const [account , setAccount] = useState(false);
  const [users, setUsers] = useState(false);
  const [Bulk_Check_IMEI_Access, setBulk_Check_IMEI_Access] = useState(false);
  const [Delete_Reports,SetDEleteREports] = useState(false);
  const [API_Access, setAPI_Access] = useState(false);
  const [toastmsg, setToast] = useState(false);
  const [response, setResponse] = useState(""); 
  const [user, setUser] = useState(null);
  const [userPermission, setUserPermission] = useState(false)
  const token = localStorage.getItem("token");
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const handleAddUserClick = async () => {
    
set_loading(true);
    if(First_Name ==="" ||!emailPattern.test(Email) ){return toast.error('Type Correct Name and Email')}
    try {
      const res = await axios.post(
        "https://api.cellercertified.com/Creat_Sub_User",
        {
          First_Name,
          New_Sub_User_Email: Email,
          dashboard: dashboard,
          history: history,
          Billing_History_Access: Billing_History_Access,
          account: account,
          users: users,
          Barcode_Layout_Access: Barcode_Layout_Access,
          Check_IMEI_Access: Check_IMEI_Access,
          Receiving_Lables: Receivinglables,
          Bulk_Check_IMEI_Access: Bulk_Check_IMEI_Access,
          API_Access: API_Access,
          Delete_Reports: Delete_Reports,
        },
        {
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        }
      );
      if (res.data) {
        console.log(res.data);
        toast.success(res.data.msg);
        setResponse(res.data.msg);
        // Clear the form fields
        setFirst_Name("");
        setEmail("");
        setDashboard(false);
        setHistory(false)
        setBilling_History_Access(false);
        setAccount(false);
        setUsers(false);
        setBarcode_Layout_Access(false);
        setCheck_IMEI_Access(false);
        setReceivingLabels(false);
        setBulk_Check_IMEI_Access(false);
        setAPI_Access(false);
        SetDEleteREports(false)
        
      }
    } catch (err) {
      console.log(err);
    }
    set_loading(false)
  };

  useEffect(() => {
    const getUser = async () => {
      try {
        const res = await axios.post("https://api.cellercertified.com/Get_Sub_User",{Email: DataEmail,},{headers: {"Content-Type": "application/json","token" : token,},});
       
        setUser(res.data);
      } catch (err) {
        console.log(err);
      }
    };

    getUser();
  }, [DataEmail, token]);

  async function check_permission (){
    const res = await axios.get("https://api.cellercertified.com/permission/create_sub_user",{headers:{token}})
    console.log("Res.data::::",res)
    set_permission(res.data.status)
  }

  useEffect(()=>{check_permission()},[])

  async function Get_Sub_User_Permissions(){
    try{
        const res = await axios.get("https://api.cellercertified.com/dashboard/Sub_User_Permissions",{headers:{token:localStorage.getItem('token')}})
       
        const d =res?.data?.data;
        console.log(d[0])
        if(d===undefined) {return;}
        setUserPermission(d[0])
    }catch(e){}
    }
  
    useEffect(()=>{
      Get_Sub_User_Permissions()
    },[])

  setTimeout(() => {
    setToast(false);
    setResponse("");
  }, 9000);

  return (
    <div className="leading-6">
          <DefaultLayout>


      
<Banner/>
<div className="mx-auto max-w-270">
<ToastContainer/>
<Breadcrumb pageName="Manage Users" />
{ userPermission && !userPermission.user&&(
  <div className=" fixed bg-[#D34053] text-white top-16 left-0 flex w-full justify-center">
     <div className="my-2  rounded-md danger p-1.5 dark:bg-meta-4">
      You dont have permission to access this page please upgrade you plan.
    </div>
  </div>)
}
</div>
  

<div className="mx-auto max-w-270">

<div className="grid min-h-screen grid-cols-5 gap-4">
  <div className=" col-span-5 xl:col-span-2">
    <div className="w-full rounded-lg border border-stroke bg-white  pt-2 shadow-default dark:border-strokedark dark:bg-boxdark">
      <h4 className="mb-1 ml-2 text-xl font-semibold text-black dark:text-white">
        Add New User
      </h4>
      <div className="my-4 mx-3">
        <input
          className="w-full rounded-lg border border-stroke bg-white py-3 px-3 font-bold text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
          type="text"
          placeholder="User Name"
          value={First_Name}
          disabled = {!Creat_Sub_User_Permission}
          onChange={(e) => setFirst_Name(e.target.value)}
        />
      </div>
      <div className="my-4 mx-3">
        <input
          className="w-full rounded-lg  border border-stroke bg-white py-3 px-3 font-bold text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
          type="text"
          placeholder="Email Address"
          value={Email}
          disabled = {!Creat_Sub_User_Permission} 
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>

      <div className="col-span-5 w-full rounded xl:col-span-2">
        <div className="my-2   border-stroke   dark:border-strokedark ">
          <div className=" border-stroke py-3 px-4  dark:border-strokedark">
            <h3 className="py-2  text-lg font-bold	 text-black dark:text-white">
              Select Permission
            </h3>
            <div class="mb-4 flex items-center">
              <input
                id="default-checkbox"
                type="checkbox"
                disabled = {!Creat_Sub_User_Permission}
                checked={dashboard}
                class="text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600 h-4 w-4 rounded focus:ring-2"
                onChange={(e) =>
                  {
                  setDashboard(e.target.checked);
                  
                  }
                }
              />
              <label
                for="default-checkbox"
                class="text-gray-900 dark:text-gray-300 ml-2 text-sm font-medium"
              >
               Dashboard
              </label>
            </div>
            <div class="mb-4 flex items-center">
              <input
                id="default-checkbox1"
                type="checkbox"
                disabled = {!Creat_Sub_User_Permission}
                checked={history}
                onChange={(e) =>
                  setHistory(e.target.checked)
                }
                class="text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600 h-4 w-4 rounded focus:ring-2"
              />
              <label
                for="default-checkbox1"
                class="text-gray-900 dark:text-gray-300 ml-2 text-sm font-medium"
              >
               History
              </label>
            </div>
            {/* <div class="mb-4 flex items-center">
              <input
                id="default-checkbox1"
                type="checkbox"
                disabled = {!Creat_Sub_User_Permission}
                checked={Billing_History_Access}
                onChange={(e) =>
                  setBilling_History_Access(e.target.checked)
                }
                class="text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600 h-4 w-4 rounded focus:ring-2"
              />
              <label
                for="default-checkbox1"
                class="text-gray-900 dark:text-gray-300 ml-2 text-sm font-medium"
              >
                Billing History
              </label>
            </div> */}


            {/* <div class="mb-4 flex items-center">
              <input
                id="default-checkbox2"
                type="checkbox"
                checked={Barcode_Layout_Access}
                disabled = {!Creat_Sub_User_Permission}
                onChange={(e) =>
                  setBarcode_Layout_Access(e.target.checked)
                }
                class="text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600 h-4 w-4 rounded focus:ring-2"
              />
              <label
                for="default-checkbox2"
                class="text-gray-900 dark:text-gray-300 ml-2 text-sm font-medium"
              >
                Barcode Layout
              </label>
            </div>                 */}

            
            <div class="mb-4 flex items-center">
              <input
                id="default-checkbox5"
                type="checkbox"
                disabled = {!Creat_Sub_User_Permission}
                checked={Check_IMEI_Access}
                onChange={(e) => setCheck_IMEI_Access(e.target.checked)}
                class="text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600 h-4 w-4 rounded focus:ring-2"
              />
              <label
                for="default-checkbox5"
                class="text-gray-900 dark:text-gray-300 ml-2 text-sm font-medium"
              >
                Check IMEI
              </label>
            </div>
            <div class="mb-4 flex items-center">
              <input
                id="default-checkbox5"
                type="checkbox"
                disabled = {!Creat_Sub_User_Permission}
                checked={Receivinglables}
                onChange={(e) => setReceivingLabels(e.target.checked)}
                class="text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600 h-4 w-4 rounded focus:ring-2"
              />
              <label
                for="default-checkbox5"
                class="text-gray-900 dark:text-gray-300 ml-2 text-sm font-medium"
              >
                Receiving Labels
              </label>
            </div>
            <div class="mb-4 flex items-center">
              <input
                id="default-checkbox5"
                type="checkbox"
                disabled = {!Creat_Sub_User_Permission}
                checked={account}
                onChange={(e) => setAccount(e.target.checked)}
                class="text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600 h-4 w-4 rounded focus:ring-2"
              />
              <label
                for="default-checkbox5"
                class="text-gray-900 dark:text-gray-300 ml-2 text-sm font-medium"
              >
                Account
              </label>
            </div>
            <div class="mb-4 flex items-center">
              <input
                id="default-checkbox5"
                type="checkbox"
                disabled = {!Creat_Sub_User_Permission}
                checked={users}
                onChange={(e) => setUsers(e.target.checked)}
                class="text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600 h-4 w-4 rounded focus:ring-2"
              />
              <label
                for="default-checkbox5"
                class="text-gray-900 dark:text-gray-300 ml-2 text-sm font-medium"
              >
                Users
              </label>
            </div>
            <div class="mb-4 flex items-center">
              <input
                id="default-checkbox"
                type="checkbox"
                disabled = {!Creat_Sub_User_Permission}
                checked={Bulk_Check_IMEI_Access}
                class="text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600 h-4 w-4 rounded focus:ring-2"
                onChange={(e) =>
                  {
                  setBulk_Check_IMEI_Access(e.target.checked);
                  
                  }
                }
              />
              <label
                for="default-checkbox"
                class="text-gray-900 dark:text-gray-300 ml-2 text-sm font-medium"
              >
                Bulk IMEI
              </label>
            </div>
            <div class="mb-4 flex items-center">
              <input
                id="default-checkbox4"
                type="checkbox"
                disabled = {!Creat_Sub_User_Permission}
                checked={API_Access}
                onChange={(e) => setAPI_Access(e.target.checked)}
                class="text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600 h-4 w-4 rounded focus:ring-2"
              />
              <label
                for="default-checkbox4"
                class="text-gray-900 dark:text-gray-300 ml-2 text-sm font-medium"
              >
                API Access
              </label>
            </div>
            <div class="mb-4 flex items-center">
            <input
              id="default-checkbox2"
              type="checkbox"
              checked={Delete_Reports}
              disabled = {!Creat_Sub_User_Permission}
              onChange={(e) =>
                SetDEleteREports(e.target.checked)
              }
              class="text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600 h-4 w-4 rounded focus:ring-2"
            />
            <label
              for="default-checkbox2"
              class="text-gray-900 dark:text-gray-300 ml-2 text-sm font-medium"
            >
              Delete Reports
            </label>
          </div>
          </div>
        </div>
      </div>
    </div>
    <button
      disabled = {!Creat_Sub_User_Permission || (First_Name ==="" ||!emailPattern.test(Email)) ||loading}
      className="my-4 flex w-full justify-center items-center bg-[#00AEEF] hover:bg-darkblue rounded-lg p-3 text-lg font-bold text-gray"
      onClick={handleAddUserClick}
    >
    <div className={`${loading?'block':'hidden'}`}>
    <CircularProgress thickness={8} size={20} className="mx-2"/>
    </div>
      Add New Users
    </button>
    

  </div>
</div>
</div>


</DefaultLayout>
    </div>
  );
};


export default BarcodeLayout;
