import React, { useEffect, useState } from "react";
import DefaultLayout from "../layout/DefaultLayout";
import Breadcrumb from "../components/Breadcrumb";
import Banner from "../components/PackageBanner/Banner";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "../css/billingplan.css"
import PuffLoader from "react-spinners/PuffLoader";
import Address_Update_Card from "../components/billign_address_update_Card"
import axios from "axios";
import { Link } from "react-router-dom";
import { MoonLoader }  from "react-spinners";
import { LOGO1 } from "../SVG";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CardUpdateForm from "../components/CardUpdateForm"
const stripePromise = loadStripe('pk_test_51NryWTKfQ1sQ4OinftW73z10043TnzxQR3YAtMmjwLXYg9EKgzpVeszdaxkbnGBonCzqhpblQKFYouuYcDc2C4iH00dAFxTptF');

const BillingPlan = () => {
  const getInitialState = () => {
    const value = "Pay As you Go";
    return value;
  };

  const [modalVisible, setModalVisible] = useState(false);
  const handleCloseModal = () => {
    setModalVisible(false);
  };

  console.log(modalVisible);

  const [card_string,set_acrd_String]= useState("")
  const [userPlan, setUserPlan] = useState(getInitialState);
  const [addressOne, setAddressOne] = useState();
  const [addressTwo, setAddressTwo] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [zipcode, setZIpCode] = useState();
  const [userPermission, setUserPermission] = useState(false)
  const [loadingtext, setloadingtext] = useState("Loading...");
  useEffect(() => {
  const interval = setInterval(() => {
    const loading_div = document.getElementById("black");
    loading_div.classList.add("hidden");
  }, 2000);

  return () => clearInterval(interval);
}, []);


  function change_address(ad1,ad2,state,city,code){
    setAddressOne(ad1)
    setAddressTwo(ad2)
    setCity(city)
    setState(state)
    setZIpCode(code)
  }

  const [subscribed_pkg,set_subscribed_pkg] = useState('')
  const [v1,setv1] = useState("");
  const [v2,setv2] = useState("");
  const [v3,setv3] = useState("");
  const [v4,setv4] = useState("");

  function API_Card_info(){
    var myHeaders = new Headers();
  myHeaders.append("token", localStorage.getItem("token"));

var requestOptions = {
  method: 'GET',
  headers: myHeaders,
  redirect: 'follow'
};

fetch("https://api.cellercertified.com/dashboard/card_info", requestOptions)
  .then(response => response.json())
  .then(result => {
    if(result.status){
      set_acrd_String(result.data.card)
    }
  })
  .catch(error => console.log('error', error));
  }

  useEffect(() =>{
    var myHeaders = new Headers();
    myHeaders.append("token", localStorage.getItem("token"));

var requestOptions = {
  method: 'GET',
  headers: myHeaders,
  redirect: 'follow'
};

fetch("https://api.cellercertified.com/Package_information", requestOptions)
  .then(response => response.json())
  .then(result => {
    set_subscribed_pkg(result.Sub_Package_Name)
    setUserPlan(result.Sub_Package_Name)

  })
  .catch(error => console.log('error', error));

fetch("https://api.cellercertified.com/User_Address_information", requestOptions)
  .then(response => response.json())
  .then(result => {
    if(result.status === false){toast.error('No user address information found');return;}
    setAddressOne(result.data?.Address)
    setAddressTwo(result.data?.Address_2)
    setCity(result.data?.City)
    setState(result.data?.Country)
    setZIpCode(result.data?.Zipcode)
  })
  .catch(error => console.log('error', error));

  API_Card_info();
  },[])
  
function change_values_to_p1(){
  setv1("Pay As You Go");
  setv2("Buy What You Need, When You Need");
  setv4("Single Check");
  setv3("$1.99");
}
function change_values_to_p2(){
  setv1("Pay As You Go");
  setv2("Buy What You Need, When You Need");
  setv4("150 Checks");
  setv3("$150");
}
function change_values_to_p3(){
  setv1("Pay As You Go");
  setv2("Buy What You Need, When You Need");
  setv4("500 Checks");
  setv3("$500");
}
function change_values_to_m1(){
  setv1("Starter");
  setv2("For individuals or companies just getting started with processing devices");
  setv3("$15/month");
  setv4("+ $0.75/check");
}
function change_values_to_m2(){
  setv1("Pro");
  setv2("For companies or individuals looking to process devices on a small scale with confidence");
  setv3("$50/month");
  setv4("+ $0.55/check");
}
function change_values_to_m3(){
  setv1("Enterprise");
  setv2("For companies or individuals looking to process devices on a larger scale with full automation and reporting");
  setv3("$100/month");
  setv4("+ $0.50/check");
}
  const handlePlanChange = (e) => {
    setUserPlan(e.target.value);
  };

  const [Updating_Subscription,set_us] = useState(false)

  async function Update_Subscription(){
    set_us(true);

    var myHeaders = new Headers();
    myHeaders.append("token", localStorage.getItem("token"));
    myHeaders.append("Content-Type", "application/json");
    
    var raw = JSON.stringify({
      "priceId": userPlan,
      "update_sub": true
    });
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    
    fetch("https://api.cellercertified.com/dashboard/Update_Subscription_Package", requestOptions)
      .then(response => response.json())
      .then(result => 
        {
          if(result?.status){
            toast.success('You package has been updated')
            set_subscribed_pkg(userPlan)
            set_us(false)
            return;
          }
          toast.error(result.msg)
          set_us(false)
        }
        )
      .catch(error => 
        {
          toast.error(error.mes)
          set_us(false)
        });




    

  }



  useEffect(()=>{
switch(userPlan){
  case "m1":change_values_to_m1();return;
  case "m2":change_values_to_m2();return;
  case "m3":change_values_to_m3();return;
  case "p1":change_values_to_p1();return;
  case "p2":change_values_to_p2();return;
  case "p3":change_values_to_p3();return;
  default:return;
}
    

  },[userPlan])

  const handleSubmitBillingAddres = (e) => {
    e.preventDefault();
  };

  async function Get_Sub_User_Permissions(){
    try{
        const res = await axios.get("https://api.cellercertified.com/dashboard/Sub_User_Permissions",{headers:{token:localStorage.getItem('token')}})
       
        const d =res?.data?.data;
        console.log(d[0])
        if(d===undefined) {return;}
        setUserPermission(d[0])
    }catch(e){}
    }
  
    useEffect(()=>{
      Get_Sub_User_Permissions()
    },[])
  return (
    <>
    <LOADING loadingtext={loadingtext} />
    <DefaultLayout>
      <Banner/>
      <div className="mx-auto max-w-270">
        <Breadcrumb pageName="Billing & Plan" pageLink="accBillingplan" />
        { userPermission && !userPermission.account&&(
          <div className=" fixed bg-[#D34053] text-white top-16 left-0 flex w-full justify-center">
             <div className="my-2  rounded-md danger p-1.5 dark:bg-meta-4">
              You dont have permission to access this page please upgrade you plan.
            </div>
          </div>)
        }
        <div className="grid min-h-screen grid-cols-5 gap-8 leading-6">
          <div className="col-span-5 xl:col-span-2 ">
            <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
              <div className=" border-stroke py-4 px-7 dark:border-strokedark">

                <h3 className="text-center font-bold text-black dark:text-white">
                  Your Current Plan is
                </h3>
                <h2
                  style={{ color: "#00AEEF" }}
                  className="py-2 text-center text-3xl font-bold  dark:text-white"
                >
                 {v1}
                </h2>
                <p className="text-center">
                  {v2}
                </p>
                <h1 className="py-2 text-center text-3xl font-bold  text-black dark:text-white">
                 {v3}
                </h1>{" "}
                <h3
                  style={{ color: "#00AEEF" }}
                  className=" text-1 text-center text-2xl  font-bold dark:text-white"
                >
                 {v4}
                </h3>
              </div>
              <div className=" px-7 pb-7  ">
                <div
                  className="relative z-20 inline-block "
                  style={{
                    width: "100%",
                  }}
                >
                  <select
                  disabled={Updating_Subscription || (userPermission && !userPermission.account)}
                    name="#"
                    id="#"
                    style={{ width: "100%" }}
                    className=" border border-stroke bg-gray py-3  text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                    onChange={handlePlanChange}
                    value={userPlan}
                  >
                    <option value="f" className="border-none">Free *only 1 check Available</option>
                    <option value="p1">Pay As you Go - Single Check</option>
                    <option value="p2">Pay As you Go - 150 Checks</option>
                    <option value="p3">Pay As you Go - 500 Checks</option>
                    <option value="m1">Monthly Subscription - Starter</option>
                    <option value="m2">Monthly Subscription - Pro</option>
                    <option value="m3">Monthly Subscription - Enterprise</option>
                  </select>
                  <span className="absolute top-1/2 right-3 z-10 mr-2 -translate-y-1/2 ">
                  </span>
                </div>
                <button 
                disabled={(subscribed_pkg===userPlan||Updating_Subscription)?true:false}
                className=" btn_default my-1 mt-4 flex w-full justify-center  rounded p-3 font-bold text-gray bg-meta-5 hover:bg-darkblue cursor-pointer"
                onClick={Update_Subscription}
                >
                  Update Your Plan
                </button>
{Updating_Subscription &&
                <div className="flex items-center">
                <MoonLoader size={20} />
                <h1 className="text-sm ml-2">Updaing Your Plan ...</h1>
                </div>
                }
              </div>
            </div>
          </div>





          <div className="col-span-5 xl:col-span-3 h-full ">
            <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
              <div className=" border-stroke px-7 pt-4 dark:border-strokedark">
                <h3 className=" font-bold text-black dark:text-white">
                  Billing Address
                </h3>
              </div>
              <div className="px-7 py-5">

              <table className="text-left text-lg bg-white">
              <tbody className="mt-10 bg-white" >
                <tr className="h-10">
                  <th>Country: </th>
                  <td className="border-0 " style={{overflowWrap: 'break-word', whiteSpace: 'pre-wrap'}}> {addressOne}</td>
                </tr>
                <tr className="h-10">
                  <th>Region: </th>
                  <td className="border-0 " style={{ whiteSpace: 'pre-wrap'}}>{state}</td>
                </tr>
                <tr className="h-10">
                  <th>City: </th>
                  <td className="border-0 " style={{ whiteSpace: 'pre-wrap'}}>{city}</td>
                </tr>
                <tr className="h-10">
                  <th>Address: </th>
                  <td className="border-0" style={{ overflowWrap: 'break-word',whiteSpace: 'pre-wrap' }}>{addressTwo}</td>
                </tr>
                <tr className="h-10">
                  <th>PostalCode: </th>
                  <td className="border-0 " style={{ whiteSpace: 'pre-wrap'}}>{zipcode===undefined?'n/a':zipcode}</td>
                </tr>
              </tbody>
            </table>

              <div className="mt-17">
              <label  
              
              htmlFor={`${userPermission && !userPermission.account ? '' : 'my-modal2'}`}
              className=" flex w-full justify-center rounded  p-3 my-2 font-bold text-gray bg-meta-5 hover:bg-darkblue cursor-pointer	">
              Update Billing Address
            </label>
            </div>
          <Address_Update_Card  modalVisible={modalVisible}
        handleCloseModal={handleCloseModal} fun={change_address}/>

                
              </div>
            </div>
          </div>


          <div className="col-span-5 w-full xl:col-span-5">
            <div className="rounded-lg border border-stroke bg-white py-5 px-5 shadow-default dark:border-strokedark dark:bg-boxdark">
              <h2 className="text-2xl font-bold">Payment Info</h2>
              <p className="py-3 text-lg font-medium">
                This is the payment card that will be billed monthly for your
                cellercertified subscription
              </p>
              <h1 className="flex py-4 text-3xl font-bold  ">
                <svg
                  width="30"
                  className="mx-2"
                  height="29"
                  viewBox="0 0 30 29"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_67_1392)">
                    <path
                      d="M14.8436 29C6.40605 28.8481 -0.156447 22.3193 -0.000196733 14.1204C0.156053 6.07327 7.03105 -0.15186 15.4686 -2.75536e-05C23.5936 0.151805 30.1561 6.83243 29.9998 14.7277C29.9998 22.7748 23.1248 29.1518 14.8436 29ZM12.8123 15.6387C12.3436 15.0314 12.0311 14.4241 11.4061 13.9686C10.6248 13.2094 9.6873 13.3612 8.90605 13.9686C8.28105 14.7277 8.1248 15.6387 8.90605 16.3979C9.84355 17.3089 10.6248 18.2199 11.5623 18.979C12.3436 19.5864 13.1248 19.7382 13.9061 19.1309C16.2498 16.8534 18.5936 14.5759 20.9373 12.2984C21.7186 11.5392 21.5623 10.6282 20.9373 10.0209C20.3123 9.41359 19.3748 9.41359 18.5936 10.0209C18.2811 10.3246 17.9686 10.6282 17.6561 10.7801C16.2498 12.2984 14.6873 13.8167 12.8123 15.6387Z"
                      fill="#00A651"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_67_1392">
                      <rect width="30" height="29" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                {card_string}
              </h1>

              <label
                className="text2 btn font-bold text-white bg-meta-5 hover:bg-darkblue cursor-pointer border-0"
                htmlFor={`${userPermission && !userPermission.account ? '' : 'my-modal'}`}
              >
                Update Card Info
              </label>
              <input type="checkbox" id="my-modal" className="modal-toggle" />

              <div className="modal ">
                <div className="modal-box bg-white dark:bg-black-2">
                  <h3 className="text-lg font-bold">Update Your Card Information</h3>
                  <p className="py-2">Attention this card will be used for future subscription payments</p>
                  
                  <div >
                  <Elements stripe={stripePromise}>
                  <CardUpdateForm />
                
                  </Elements>
                  
                  </div>

                </div>
              </div>
            </div>
            
          </div>
          <ToastContainer/>
        </div>
      </div>
    </DefaultLayout>
</>  );
};

function LOADING({ loadingtext }) {
  return (
    <div
      id="black"
      className="w-full h-full z-50 fixed  items-center justify-center flex flex-col"
      style={{ backgroundColor: "white", marginTop: "-50px" }}
    >
      <PuffLoader
        color={"skyblue"}
        loading={true}
        size={300}
        aria-label="Loading Spinner"
        data-testid="loader"
        cssOverride={{ position: "absolute" }}
      />

      <LOGO1  h={53} />
      <h1>{loadingtext}</h1>
    </div>
  );
}

export default BillingPlan;
