import React, { useState, useEffect } from "react";
import DefaultLayout from "../layout/DefaultLayout";
import Breadcrumb from "./Breadcrumb";
import axios from "axios";
import Banner from "./PackageBanner/Banner";
import { Link } from "react-router-dom";

const Customization = () => {
  const [userPermission, setUserPermission] = useState(false)
  const Customization = localStorage.getItem("customization");
  const [customization, setCustomization] = useState(() => {
    const checkboxes = [
      { name: "Battery Health", label: "Battery Health" },
      { name: "Grade", label: "Grade" },
      { name: "Cosmetic Grade", label: "Cosmetic Grade" },
      { name: "Functional Grade", label: "Functional Grade" },
      { name: "Notes", label: "Notes" },
      { name: "Internal Reference #", label: "Internal Reference #" },
      { name: "Customer ID", label: "Customer ID" },
      { name: "Price", label: "Price" },
      { name: "Color", label: "Color"},
    ];

    const initialState = checkboxes.reduce((state, checkbox) => {
      const checkboxValue = localStorage.getItem(checkbox.name) === "true";
      return {
        ...state,
        [checkbox.name]: checkboxValue,
      };
    }, {});

    return initialState;
  });

  const handleCheckboxChange = (event) => {
    const checkboxName = event.target.name;
    const checkboxValue = event.target.checked;

    // Update the state with the new checkbox value
    setCustomization((prevState) => ({
      ...prevState,
      [checkboxName]: checkboxValue,
    }));

    // Update local storage immediately after updating the state
    localStorage.setItem(checkboxName, checkboxValue.toString());
  };

  const renderCheckboxes = () => {
    return Object.entries(customization).map(([name, value]) => (
      <>
        {Customization === "true" ? (
          <>
            <div className="mb-5 flex items-center" key={name}>
              <input
                id={name}
                name={name}
                type="checkbox"
                className="text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600 h-4 w-4 rounded focus:ring-2"
                onChange={handleCheckboxChange}
                checked={value}
                disabled={userPermission && !userPermission.account}
              />
              <label
                htmlFor={name}
                className="text-gray-900 dark:text-gray-300 ml-2 text-sm font-medium"
              >
                {name}
              </label>
            </div>
          </>
        ) : (
          <>
            <div className="mb-4 flex items-center" key={name}>
              <input
                id={name}
                name={name}
                type="checkbox"
                className="text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600 h-4 w-4 rounded focus:ring-2"
                onChange={handleCheckboxChange}
                checked={value}
                disabled="true"
              />
              <label
                htmlFor={name}
                className="text-gray-900 dark:text-gray-300 ml-2 text-sm font-medium"
              >
                {name}
              </label>
            </div>
          </>
        )}
      </>
    ));
  };

  async function Get_Sub_User_Permissions(){
    try{
        const res = await axios.get("https://api.cellercertified.com/dashboard/Sub_User_Permissions",{headers:{token:localStorage.getItem('token')}})
       
        const d =res?.data?.data;
        console.log(d[0])
        if(d===undefined) {return ;}
        setUserPermission(d[0])
    }catch(e){}
    }
  
    useEffect(()=>{
      Get_Sub_User_Permissions()
    },[])
console.log("UserPermission", userPermission)
  return (
    <div className="leading-6">
      <DefaultLayout>
        {Customization === "true" ? (
          <>
            <Banner />
            <Breadcrumb pageName="Customization" />
            {userPermission && !userPermission.account&&(
          <div className=" fixed bg-[#D34053] text-white top-16 left-0 flex w-full justify-center">
              <div className="my-2  rounded-md danger p-1.5 dark:bg-meta-4">
              You dont have permission to access this page please upgrade you plan.
            </div>
          </div>)
        }
            <div className="col-span-5 min-h-screen w-100 rounded xl:col-span-2">
              <div className="rounded-lg border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
                <div className="rounded-lg border-b border-stroke py-4 px-7 dark:border-strokedark">
                  <h3 className="text-lg  font-bold text-black dark:text-white">
                    Additional Fields For Table
                  </h3>
                  <p className="text-sm text-gray-500 text-left mb-5">
                  Select checkbox to add field to IMEI History Table.
                </p>
                  {renderCheckboxes()}
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                backgroundColor: "#D34053",
                color: "white",
                marginBottom: "10px",
              }}
              className="max-w-64 flex w-full justify-center"
            >
              <div className="my-2 inline-flex items-center justify-center rounded-md danger p-1.5 dark:bg-meta-4">
                You dont have permission to access this page please upgrade your
                plan{" "}
                <Link to="/accBillingplan">
                  <strong>. Click Here</strong>
                </Link>
              </div>
            </div>
            <Breadcrumb pageName="Customization" />
            <div className="col-span-5 min-h-screen w-100 rounded xl:col-span-2">
              <div className="rounded-lg border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
                <div className="rounded-lg border-b border-stroke py-4 px-7 dark:border-strokedark">
                  <h3 className="text-lg pb-3 font-bold text-black dark:text-white">
                    Additional Fields to display
                  </h3>
                  {renderCheckboxes()}
                </div>
              </div>
            </div>
          </>
        )}
      </DefaultLayout>
    </div>
  );
};

export default Customization;
