import Barcode from "react-barcode";
import LOGO from "../images/logo/new-barcode-logo.svg"
import { QRCodeSVG } from "qrcode.react";

function LABEL_2({v1,v2,v3,v3_3,v4,v4_4,v5,v6,v6_6,v7,v7_7,v8,v8_8,v9,v9_9,v10,v10_10,v11,v12,v12_12,v13,v14, v14_14, v15,v15_15, v16, v16_16, v17, v17_17, v18, v18_18, v19, v19_19, v20, v20_20}) {
    return ( 
        <div className=" flex flex-row  h-full justify-between ">

<div className=" w-[70%]">


<div>
<img src={LOGO} alt="label logo" className="w-25  ml-4 mt-3 mb-0"/>
<div className="border-2 border-black-2 mt-2 p-[0.1vw] ml-4 w-[100%]">
<h1 className="text-xs  text-black-2 font-semibold">{v1}</h1>
</div>
    
<div className=" mt-2 mb-0 ml-2">
<h1 className="text-[10px] relative top-[-10px] left-2 h-0 z-9 text-black-2">IMEI: {v2}</h1>
<Barcode  height={8} width={1} value={v2} displayValue={false} ></Barcode>
</div>   

<div className=" h-fit mt-0 mb-0 ml-2">
<h1 className="text-[10px] relative top-[-10px] left-2 h-0 z-9 text-black-2">{v7_7&&<h1>SN: {v7}</h1>}</h1>
{(v7_7) &&<Barcode  height={8} width={1} value={v7} displayValue={false} ></Barcode>}
</div> 
<div className=" h-fit mt-0 mb-0 ml-2">
<h1 className="text-[10px] relative top-[-10px] left-2 h-0 z-9 text-black-2">{v14_14&&<h1>Internal ID: {v14}</h1>}</h1>
{(v14_14) &&<Barcode  height={8} width={1} value={v14} displayValue={false} ></Barcode>}
</div> 
</div>



<div className="absolute bottom-0 mb-2">
{/* {v6_6&&<h1>Manufacturer:{v6}</h1>} */}
<div className=" flex gap-x-1 text-[10px] leading-3 text-black-2  ml-4 w-fit font-medium">
{(v16_16&&v16)&&<h1>Blacklist Status: {v16}</h1>}/
{(v9_9&&v9)&&<h1>BH:{v9}%</h1>}
</div>
<div className=" flex gap-x-1 text-[10px] leading-3 text-black-2  ml-4 w-fit font-medium">
{(v19_19&&v19)&&<h1>Carrier Status: {v19}</h1>}/
{(v8_8&&v8)&&<h1>Carrier:{v8}</h1>}
</div>
</div>

</div> {/*------------------- */}

<div className=" relative pr-3 w-[30%] mt-2 mb-2">
{v12_12&&<div className=" text-[10px] leading-3 text-black-2 top-4 relative font-medium text-right  w-full"><h1>Date: {v12}</h1></div>}

<div className="top-8 relative text-xs mt-[-15px] text-black-2 font-semibold z-9 mb-0 text-right ">
{(v3_3&&v3)&&<h1>Grade: {v3}</h1>}
{(v4_4&&v4)&&<h1 className="">{v5}{v4}</h1>}
</div>

<div className="
w-full
absolute
right-3 bottom-18 tracking-tighter
text-[10px] leading-3 text-black-2  ml-4  font-medium text-right ">
{(v10)&&<h1>FMI: {v10_10}</h1>}
{(v15_15&&v15)&&<h1>Color: {v15}</h1>}
{(v20_20&&v20)&&<h1>Customer Id: {v20}</h1>}
{(v18_18&&v18)&&<h1>User: {v18}</h1>}
</div>

<div className="absolute right-2 bottom-0 ">  
<span className="text-black font-medium text-xs flex ">Celler</span>
<QRCodeSVG value={`https://www.cellercertified.com/publicreport?UID=${v13}`} size={40}></QRCodeSVG>
<span className="text-black font-medium text-xs flex">Certified</span></div>
</div>


</div>
         );
}

export default LABEL_2;
