import React, { useState } from 'react';
import { Button, Modal, Box, IconButton } from '@mui/material';
import ReportPDF from './Report_PDF';
import { PictureAsPdf } from '@mui/icons-material';

const SimpleModal = (data) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => {setOpen(true);};
    const handleClose = () => {setOpen(false);};
  
    return (
      < >
        
          <PictureAsPdf onClick={handleOpen}/>

        <Modal
          open={open}
          onClose={handleClose}
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '60%',
              bgcolor: 'background.paper',
              boxShadow: 24,
              maxHeight: '90vh',
              p: 2,
              overflow: 'auto'
              
            }}
          >
          
          <ReportPDF data={data.data}/>

            
          </Box>
        </Modal>
      </>
    );
  };
  
  export default SimpleModal;
  
  