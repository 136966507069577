import React, {useState} from "react";
import DefaultLayout from "../layout/DefaultLayout";
import CardOne from "../components/CardOne";
import CardTwo from "../components/CardTwo";
import CardThree from "../components/CardThree";
import ChartOne from "../components/ChartOne";
import ChartThree from "../components/ChartThree";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Banner from "../components/PackageBanner/Banner";
import Permissions from "../components/Permissions/Permissions";
import { DatePicker } from 'antd';
import "react-datepicker/dist/react-datepicker.css";


const DashboardAnalytics = () => {
  const { RangePicker } = DatePicker;
  const [filter, setFilter] = useState();
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [showDatePicker, setShowDatePicker] = useState()

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  const handleCustomFilterChange = () => {
    // You can use startDate and endDate to filter data in your custom way
    setFilter([startDate, endDate]);
    setShowDatePicker(false);
  };


  return (
    <div className="leading-6">
          <DefaultLayout >
      <ToastContainer />
      <Permissions/>
      <Banner/>
      <div className="max-w-64  flex w-full justify-start">
        <div className="my-2 inline-flex items-start justify-start gap-x-2 rounded-md bg-whiter p-1.5 dark:bg-meta-4">
        <button onClick={() => handleFilterChange("This Week")}
         className={`btn ${filter === "This Week" ? "bg-logocolor text-white" : "bg-white border-2 border-[#787878] text-black"}  h-fit hover:bg-logocolor hover:text-white hover:border-logocolor`}>
          Last Week
        </button>
        <button onClick={() => handleFilterChange("This Month")} 
        className={`btn ${filter === "This Month" ? "bg-logocolor text-white" : "bg-white border-2 border-[#787878] text-black"} h-fit hover:bg-logocolor hover:text-white hover:border-logocolor`}>
          This Month
        </button>
        <button onClick={() => handleFilterChange("This Year")} 
        className={`btn ${filter === "This Year" ? "bg-logocolor text-white" : "bg-white border-2 border-[#787878] text-black"} h-fit hover:bg-logocolor hover:text-white hover:border-logocolor`}>
          This Year
        </button>
        <button 
         onClick={() => setShowDatePicker(!showDatePicker)}
         className={`btn ${filter && filter.length === 2 ? "bg-logocolor text-white" : "bg-white border-2 border-[#787878] text-black"} h-fit hover:bg-logocolor hover:text-white hover:border-logocolor`}>
          Custom
        </button>
        </div>
      </div>
                {showDatePicker && (
                  <div className="flex gap-x-2 my-1 leading-6">
            <div className="flex items-center">
            <RangePicker 
      allowClear={<button onClick={()=>{console.log('hitting')}}>close</button>}
       className="bg-gray-100 p-2 rounded-md"
    onChange={(selectedDates) => {
      setStartDate(selectedDates != null ? selectedDates[0] : null);
      setEndDate(selectedDates != null ? selectedDates[1] : null);
    }}
    value={[startDate, endDate]}
    placeholder={['Start Date', 'End Date']}
    
      />
               </div>
              <button
                onClick={handleCustomFilterChange}
                className="btn bg-logocolor border-none text-white h-fit disabled:text-bodydark"
              >
                Apply
              </button>
           </div>
          )}
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-3 leading-6 2xl:gap-7.5">
        <CardOne filter={filter}/>
        <CardTwo filter={filter}/>
        <CardThree filter={filter}/>
      </div>

      <div className="mt-4 grid grid-cols-12 gap-4 md:mt-6 md:gap-6 leading-6 2xl:mt-7.5 2xl:gap-7.5">
        <ChartOne filter={filter}/>
        <ChartThree filter={filter}/>
        <div className="col-span-12 xl:col-span-8">{/* <TableOne /> */}</div>
      </div>
    </DefaultLayout>
    </div>
  );
};

export default DashboardAnalytics;
